import React from "react";
import clsx from 'clsx';

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import { slugify } from "../../Utils/StringUtils"
import HeaderStyleFunc from "../../Style/HeaderStyle"

import DropDownMenuStylesFunc from "../../Style/DropdownMenuStyle"




function Component({id, name, elements, displayedMenu, classes}) {
    let HeaderStyle = HeaderStyleFunc();
    let DropDownMenuStyles = DropDownMenuStylesFunc();
    const [shownCategory, setShownCategory] = React.useState(elements && elements.length > 0 ? elements[0]["name"] : null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [arrowRef, setArrowRef] = React.useState(null);
    // Categories
    const categoriesDom = [];
    const subCategoriesDom = [];
    if (elements != null) {
        for (const value of elements) {
            if (value["children"] && value["children"].length > 0 && categoriesDom.length < 6) {
                let categoryListUrl = "/category/" + id + "/" + slugify(name) + "/" + value["id"] + "/" + slugify(value["name"]);
                categoriesDom.push(
                    <ListItem key={"cat-" + value.id} selected={value["name"] === shownCategory} disableGutters>
                        <Link href={categoryListUrl} onMouseOver={() => setShownCategory(value.name)} style={{position: "relative"}} color={"inherit"}>
                            <Typography
                                className={clsx(HeaderStyle.secondRow.item, DropDownMenuStyles.categoryText, {[DropDownMenuStyles.selected] : false})}
                                key={value["name"]}>
                                {value["name"]}
                            </Typography>
                            <KeyboardArrowDown className={clsx(DropDownMenuStyles.categoryIcon)}/>
                        </Link>
                    </ListItem>
                );

                let subCategoryDom = [];
                for (const child of value["children"]) {
                    let categoryListUrl = "/category/" + id + "/" + slugify(name) + "/" + value["id"] + "/" + slugify(value["name"]) + "/" + child["id"] + "/" + slugify(child["name"]);
                    subCategoryDom.push(
                        <ListItem key={"cat2-" + child["name"]} disablePadding>
                            <Link href={categoryListUrl} color={"inherit"}>
                                <Typography className={DropDownMenuStyles.subCategory}>{child["name"]}</Typography>
                            </Link>
                        </ListItem>
                    )
                    if (subCategoryDom.length === 10) {
                        subCategoriesDom.push(<Box key={"cat-" + value.id + "-sub-" + subCategoriesDom.length}>
                            {shownCategory === value["name"] && <List dense disablePadding className={DropDownMenuStyles.dropdownBodyColumn}>{subCategoryDom}</List>}
                        </Box>);
                        subCategoryDom = [];
                    }

                }
                if (subCategoryDom.length > 0) {
                    subCategoriesDom.push(<Box key={"cat-" + value.id + "-sub-" + subCategoriesDom.length}>
                        {shownCategory === value["name"] && <List dense disablePadding className={DropDownMenuStyles.dropdownBodyColumn}>{subCategoryDom}</List>}
                    </Box>);
                }
            }
        }
    }

    let handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget === anchorEl ? null : event.currentTarget);
    };

    return (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Grid item onMouseLeave={(event) => setAnchorEl(null)}>
                <Box className={classes.item}>
                    <Link href={"#"} onClick={(event) => handleOpenMenu(event)} onMouseEnter={(event) => handleOpenMenu(event)}>
                        <Typography variant={"overline"}>{name} <KeyboardArrowDown className={clsx(classes.caretIcon, {[classes.caretIconOpened]: Boolean(anchorEl)})}/></Typography>
                    </Link>
                </Box>
                <Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    style={{zIndex: 9999999}}
                    placement={"bottom-start"}
                    modifiers={{
                        arrow: {
                            enabled: true,
                            element: arrowRef
                        },
                    }}>
                    <span className={classes.arrow} ref={setArrowRef} />
                    <Paper className={classes.dropdownWrapper}>
                        <Grid container direction={"column"}>
                            <Grid container item className={classes.dropdownHeader}>
                                <List style={{display:"flex"}}>{categoriesDom}</List>
                            </Grid>
                            <Grid container item className={classes.dropdownBody}>
                                {subCategoriesDom}
                            </Grid>
                        </Grid>
                    </Paper>
                </Popper>
            </Grid>
        </ClickAwayListener>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        openMenu: (menu) => {
            dispatch({type: 'OPEN_DROPDOWN_MENU', menu})
        },
        closeMenu: () => {
            dispatch({type: 'CLOSE_DROPDOWN_MENU'})
        }
    }
};

const mapStateToProps = state => {
    return {
        displayedMenu: state.menu.displayedMenu
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent;
