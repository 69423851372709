import React, { useState } from 'react';
import clsx from 'clsx';



import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import Text from "../../Component/FontText";
import CustomCheckbox from "../../Component/CustomCheckbox";
import { FontAwesome } from '../../../FontAwesome';

import { connect } from 'react-redux'
import TextInputIcon from "./TextInputIcon";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import ErrorStyleFunc from "../../Styles/Error";

import AddressSelectionModalStylesFunc from "../../../Style/AddressSelectionModalStyle"
import * as Constants from "../../../Style/Constants"
import TextInputIconStylesFunc from "../../../Style/TextInputIconStyle";
import axios from "axios";
import {getApiUrl} from "../../../Utils/QueryUtils";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";


function Component ({defaultAddress, visible, setVisible, setFullAddress}) {
    let ErrorStyle = ErrorStyleFunc();
    let AddressSelectionModalStyles = AddressSelectionModalStylesFunc();
    let TextInputIconStyles = TextInputIconStylesFunc();
    const { setError, handleSubmit, register, control, errors } = useForm({defaultValues: defaultAddress});
    let [useDefault, setUseDefault] = React.useState(false);

    let saveAddress = ({name, firstname, address, zipcode, city, phone, gender}) => {
        if (gender === 0) {
            setError("gender", {type: "invalid", message: "Ce champ est obligatoire"})
        } else {

            axios.get(getApiUrl('shop/validated_address', {city, zipcode, address})).then((response) => {

                setFullAddress({
                    name,
                    firstname,
                    address,
                    zipcode,
                    city,
                    phone,
                    gender,
                    useDefault
                });

                setVisible(false);
            }).catch((error) => {
                setError("address", {type: "custom"});
                setError("zipcode", {type: "custom"});
                setError("city", {type: "custom", message: "L'adresse est invalide"});
            });

        }
    };

    let _renderModalContent = () => (
        <Box className={AddressSelectionModalStyles.modalContent}>
            <Text className={AddressSelectionModalStyles.modalTitle}>Entrez l'adresse de livraison</Text>

            <Controller
                as={
                    <Select
                        variant={"outlined"}
                        className={clsx(AddressSelectionModalStyles.picker, {[ErrorStyle.error] : errors['gender']})}
                        placeholder={"Civilité"}
                        InputProps={{
                            startAdornment:<InputAdornment position="start" style={{borderRightWidth: 1}}>
                                    <FontAwesome name={'user'} size={Constants.FONT_SIZE_BIGGEST} className={clsx(TextInputIconStyles.inputIcon, {[ErrorStyle.error]: errors && errors['gender']})}/>
                                </InputAdornment>
                        }}>
                        <MenuItem value={1}>Mr.</MenuItem>
                        <MenuItem value={2}>Mme.</MenuItem>
                    </Select>
                }
                control={control}
                rules={{required: "Ce champ est obligatoire"}}
                name={"gender"}
            />
            <ErrorMessage name={"gender"} errors={errors} as={Text} className={ErrorStyle.error} />

            <TextInputIcon
                errors={errors}
                register={register}
                icon={"user"}
                placeholder={"Nom"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"name"}
            />

            <TextInputIcon
                errors={errors}
                register={register}
                icon={"user"}
                placeholder={"Prénom"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"firstname"}
            />

            <TextInputIcon
                errors={errors}
                register={register}
                icon={"home"}
                placeholder={"Adresse"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"address"}
            />

            <TextInputIcon
                errors={errors}
                register={register}
                icon={"home"}
                placeholder={"Code postal"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"zipcode"}
            />

            <TextInputIcon
                errors={errors}
                register={register}
                icon={"home"}
                placeholder={"Ville"}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"city"}
            />

            <TextInputIcon
                errors={errors}
                register={register}
                icon={"phone"}
                placeholder={"Numéro de téléphone"}
                rules={{
                    required: "Ce champ est obligatoire",
                    pattern: {
                        value: /^0[0-9]{9}$/,
                        message: "Numéro de téléphone invalide"
                    }
                }}
                name={"phone"}
            />

            <CustomCheckbox
                containerClass={AddressSelectionModalStyles.checkBox}
                checkedColor={Constants.PINK}
                uncheckedColor={Constants.FONT_LIGHT_GRAY}
                title={<Text className={AddressSelectionModalStyles.checkboxLabel}>Utiliser comme adresse principale</Text>}
                checked={useDefault} onPress={() => setUseDefault(!useDefault)}
                size={Constants.CHECKBOX_SIZE_BIG} />

            <Grid container justify={"space-between"}>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={handleSubmit(saveAddress)} className={AddressSelectionModalStyles.button}>
                    Enregistrer
                </Button>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={() => setVisible(false)}>
                    Annuler
                </Button>
            </Grid>
        </Box>
    );

    return (
        <Dialog open={visible} fullWidth>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        mondialRelayPoints: state.relayPoints.mondialRelayPoints,
        chronoRelayPoints: state.relayPoints.chronoRelayPoints,
        addresses: state.relayPoints.addresses
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchRelayPoints: (relayType, city, zipcode) => {
            dispatch({type: 'FETCH_RELAY_POINTS_REQUESTED', relayType, city, zipcode})
        },
        searchAddresses: (zipcode) => {
            dispatch({type: 'SEARCH_ADDRESS_REQUESTED', zipcode})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;
