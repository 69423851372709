import React from 'react';

import { Map, TileLayer, Popup } from 'react-leaflet'
import CustomMarker from './CustomMarker'
import L from 'leaflet';
import MarkerIcon from '../../Assets/Images/marker-icon.png'
import MarkerShadow from '../../Assets/Images/marker-shadow.png'

export default function CustomMap({mapStyle, mapClass, markers, bounds, visible, fixedViewpoint, center, zoom}) {
    const leaf = React.useRef();

    React.useEffect(() => {
        if (leaf.current) {
            leaf.current.leafletElement.invalidateSize();
            if (bounds) {
                leaf.current.leafletElement.flyToBounds(bounds);
            }
        }
    }, [visible]);

    React.useEffect(() => {
        if (leaf.current && bounds) {
            leaf.current.leafletElement.flyToBounds(bounds);
        }
    }, [bounds]);


    let markersDom = [];
    if (markers) {
        for (const marker of markers) {
            let icon = new L.icon({
                iconUrl: MarkerIcon,
                shadowUrl: MarkerShadow
            });
            markersDom.push(
                <CustomMarker
                    position={marker.position}
                    popupOpen={marker.selected}
                    icon={icon}
                >
                    {marker.text !== null && <Popup>
                        {marker.text}
                    </Popup>}
                </CustomMarker>
            )
        }
    }

    return (
        <Map bounds={bounds} center={center} zoom={zoom} className={mapClass} style={mapStyle} ref={leaf} zoomControl={!fixedViewpoint} scrollWheelZoom={!fixedViewpoint} dragging={!fixedViewpoint}
                keyboard={!fixedViewpoint} boxZoom={!fixedViewpoint} doubleClickZoom={!fixedViewpoint} tap={!fixedViewpoint} touchZoom={!fixedViewpoint}>
            <TileLayer
                url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
            />
            {markersDom}
        </Map>
    )
}
