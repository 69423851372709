import { call, put, takeLatest, cancelled, takeEvery } from 'redux-saga/effects'
import { SavePicture } from '../Endpoint/Picture'
import { handleError } from "../../Utils/SagaUtils";

function* savePicture(action) {
    try {
        const result = yield call(SavePicture, action.pictureData);
        yield put({type: "SAVE_PICTURE_SUCCEEDED", picture: result.data});
    } catch (e) {
        yield put(handleError("SAVE_PICTURE_SUCCEEDED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* mySaga() {
    yield takeEvery("SAVE_PICTURE_REQUESTED", savePicture);
}

export default mySaga;