import axios from 'axios';
import {API_URL} from '../../Config/API'
import { setDefaultHeaders } from '../../Utils/QueryUtils'

export const SavePicture = (pictureData) => {
    let data = new FormData();
    data.append('file', pictureData);
    return axios.post(API_URL + 'api/media_objects', data,  {
        headers: setDefaultHeaders(
            {"Content-type": "multipart/form-data"}
        )
    })
};
