import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"
import CommonStyles from "./CommonStyles";

export default makeStyles((theme) => {
    let commonStyles = CommonStyles(theme);
    return {
        container: Object.assign({}, commonStyles.centeredContainer, {}),
        logo : Object.assign({}, commonStyles.logo, {}),
        button: Object.assign({}, commonStyles.button, {}),
        welcomeLabel: Object.assign({}, commonStyles.welcomeLabel, {}),
        connectLabel: Object.assign({}, commonStyles.connectLabel, {}),
        connectWrapper: Object.assign({}, commonStyles.connectWrapper, {}),
        snLogo: {
            width: 32,
            height: 32,
            [theme.breakpoints.down('xs')]: {
                width: 22,
                height: 22,
            }
        },
        snButton: {
            padding: 0,
            minWidth: 0,
            marginLeft: 10,
            width: "max-content"
        },
        checkboxLabel: {
            color: Constants.FONT_LIGHT_GRAY
        },
        internalLinkWrapper: {},
        noAccountText: {
        },
        internalLinkLabel: {
            textDecoration: "underline",
            textDecorationColor: Constants.BLACK
        },
        orSeparator: {
            position: "relative",
            width: "300px",
            height: "50px",
            lineHeight: "50px",
            textAlign: "center",
            '&:before, &:after': {
                position: "absolute",
                width: "130px",
                height: "1px",
                top: "24px",
                backgroundColor: "#aaa",
                content: '""',
            },
            '&:before': {
                left: 0,
            },
            '&:after': {
                right: 0,
            },
        }
    }
});
