import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        width: Constants.GET_SIZE(322),
        height: Constants.GET_SIZE(354),
        marginBottom: Constants.GET_SIZE(42),
    },
    cardActionArea: {
        paddingTop: 35,
        paddingBottom: 40,
    },
    image: {
        width: 180,
        height: 180,
        // marginLeft: 70,
        // marginRight: 70,
        margin: "auto",
        [theme.breakpoints.down('xs')]: {
            width: "80%",
            aspectRatio: 1,
            height: 'auto',
        }
    },
    shopName: {
        textAlign: "center",
        marginTop: 15,

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    }
}));
