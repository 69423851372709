import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"
import CommonStyles from "./CommonStyles";

export default makeStyles((theme) => {
    let commonStyles = CommonStyles(theme);
    return {
        container: Object.assign({}, commonStyles.centeredContainer, {}),
        button: {
            marginTop: 50,
        },
        logo : Object.assign({}, commonStyles.logo, {}),
        welcomeLabel: {
            fontSize: Constants.FONT_SIZE_BIGGEST,
            marginBottom: 30,
            fontWeight: "bold",
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_BIGGEST_MOBILE
            }
        },
        connectLabel: {
            fontSize: Constants.FONT_SIZE_BIG,
            color: Constants.FONT_DARK_GRAY,
            fontWeight: "bold",
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_BIGGER_MOBILE
            }
        },
        snLogo: {
            width: 32,
            height: 32,
            [theme.breakpoints.down('xs')]: {
                width: 22,
                height: 22,
            }
        },
        snButton: {
            padding: 0,
            minWidth: 0,
            marginLeft: 10,
            width: "max-content"
        },
        checkboxLabel: {
            color: Constants.FONT_LIGHT_GRAY
        },
        internalLinkWrapper: {},
        noAccountText: {
        },
        internalLinkLabel: {
        },
    }
});
