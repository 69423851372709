import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle";


export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        subcontentWrapper: {
            flex: 1,
            marginLeft: 30,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0
            }
        },


        tabsWrapper: {
            ...GlobalStyle.rowCenteredHW,
            marginBottom: 40,
        },
        tabButton: {
            ...GlobalStyle.button,
            backgroundColor: Constants.WHITE,
            borderColor: Constants.PINK,
            borderWidth: 1,

        },
        tabButtonLabel: {
            ...GlobalStyle.buttonLabel,
            color: Constants.PINK
        },
        tabButtonActive: {
            borderColor: Constants.PINK,
            borderWidth: 1,
        },
        tabButtonLabelActive: {
            color: Constants.WHITE
        },
        productsWrapper: {
            // ...GlobalStyle.rowCentered,
            // flexWrap: "wrap",
            width: "100%",
            marginBottom: Constants.GET_SIZE(25),
            alignItems: "stretch"
        },
        productWrapper: {
            margin: "auto",
            height: "100%"
        }
    }
});
