import React from 'react';

import Box from "@material-ui/core/Box";

import Modal from "modal-enhanced-react-native-web"
import DatePicker from "react-datepicker"
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

import { connect } from 'react-redux'

import DisputeModalStylesFunc from "../../../Style/MiddleOffice/DisputeModalStyle"
import * as Constants from "../../../Style/Constants"
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";

registerLocale('fr', fr)

function Component ({visible, setVisible, datetime, setDatetime, endDatetime, setEndDatetime}) {
    let DisputeModalStyles = DisputeModalStylesFunc();
    let [internalDatetime, setInternalDatetime] = React.useState(datetime);
    let [internalEndDatetime, setInternalEndDatetime] = React.useState(endDatetime);

    let handleClickOk = () => {
        setDatetime(internalDatetime);
        setEndDatetime(internalEndDatetime);
        setVisible(false)
    };

    React.useEffect(() => {
        if (internalDatetime > internalEndDatetime) {
            setInternalEndDatetime(internalDatetime);
        }
    }, [internalDatetime]);

    let _renderModalContent = () => (
        <Box className={DisputeModalStyles.modalContent} style={{width: "auto"}}>
            <Box style={{width: "100%", alignItems: "center", height: Constants.GET_SIZE(250), justifyContent: "center", minHeight: 325, display: "flex", textAlign: "center"}}>
                <div>
                    <h2>Début</h2>
                    <DatePicker selected={internalDatetime} onChange={setInternalDatetime} inline showTimeInput locale={"fr"} />
                </div>
                <div>
                    <h2>Fin</h2>
                    <DatePicker selected={internalEndDatetime} onChange={setInternalEndDatetime} inline showTimeInput locale={"fr"} />
                </div>

            </Box>
            {/*<Box style={{width: "100%", alignItems: "center", height: Constants.GET_SIZE(250), justifyContent: "center", minHeight: 325}}>*/}
            {/*</Box>*/}

            <Grid container justify={"space-around"} style={{width: "100%", marginTop: 20}}>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={handleClickOk} className={DisputeModalStyles.button}>
                    Valider
                </Button>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={() => setVisible(false)}>
                    Annuler
                </Button>
            </Grid>
        </Box>
    );

    return (
        <Modal isVisible={visible}>
            {_renderModalContent()}
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;
