import React from "react";
import clsx from 'clsx';

import StylesFunc from "../../../Style/LiveMessageStyle"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import CardMedia from "@material-ui/core/CardMedia"
import Text from "../../Component/FontText";
import UserIcon from "../../../Assets/Images/user_icon.svg";
import {getImageUrl} from "../../../Utils/ImageUtils";
import useLongPress from "../../../Hooks/useLongPress"
import {connect} from "react-redux";

function LiveMessage({message, me, params, isAdmin, onLongPress, onRightClick, handleAccept, handleRefuse}) {
    let Styles = StylesFunc();

    let commission = 0;
    for (const param of params) {
        if (param['name'] === "commission") {
            commission = param['value'] / 100;
        }
    }

    const getAvatar = (message) => {
        return <CardMedia className={Styles.avatar} component={'img'}
                          src={message.user.shop && message.user.shop.media_object ? getImageUrl('/' + message.user.shop.media_object.file_path, 'medium') : UserIcon}/>;
    }

    const longPressEvent = useLongPress(onLongPress, () => {});

    const isProposition = !!message.product && !!message.price;
    const isReservation = !!message.product && !isProposition;
    let content;
    if (isProposition) {
        if(message.bidding_status === -2) {
            if (me && message.user.id === me.id) {
                content = <i className={Styles.adminPropRefusedWrapper}>Votre proposition de {message.price}€
                    pour {message.product.name} a été refusée</i>
            } else {
                content = <i className={Styles.adminPropRefusedWrapper}>
                    {getAvatar(message)}
                    La proposition de
                        <strong> {message.user.firstname} {message.user.name}</strong> ({message.price}€)
                    pour {message.product.name} a été refusée
                </i>
            }
        } else {
            if (message.bidding_status === 2) {
                let messageEnd = 'pour le produit ' + message.product.name + ' a été acceptée';
                if (me && message.user.id === me.id) {
                    content = <span>Votre offre {messageEnd}</span>;
                } else {
                    content = <span>
                        {getAvatar(message)}
                        L'offre de <strong>{message.user.firstname} {message.user.name}</strong> {messageEnd}
                    </span>;
                }
            } else {
                let author;
                if (me && message.user.id === me.id) {
                    author = "Vous avez";
                } else {
                    author = <span>
                    {getAvatar(message)}
                    <strong>{message.user.firstname} {message.user.name}</strong> a
                </span>;
                }
                if (message.bidding_status === 3) {
                    content = <span>{author} acheté le produit {message.product.name}</span>;
                } else {
                    let legend = null;
                    if (commission !== 0) {
                        legend = <span>*<br/><i>(*dont {(message.price - (message.price / (1 + commission))).toFixed(2)}€ de frais Violette Sauvage)</i></span>;
                    }

                    content = <span>{author} proposé {message.price}€ pour {message.product.name}{legend}</span>;
                }
            }

            if (me && message.user.id === me.id) {
                content = <i>{content}</i>;
            }
        }
    } else if (isReservation) {
        let author;
        if (me && message.user.id === me.id) {
            author = "Vous avez";
        } else {
            author = <span>
            <CardMedia className={Styles.avatar} component={'img'}
                       src={message.user.shop && message.user.shop.media_object ? getImageUrl('/' + message.user.shop.media_object.file_path, 'medium') : UserIcon}/>
            <strong>{message.user.firstname} {message.user.name}</strong> a
        </span>;
        }
        if (message.bidding_status === -1) {
            if (me && message.user.id === me.id) {
                content = <span>{author} annulé votre réservation pour le produit {message.product.name}</span>;
            } else {
                content = <span>{author} annulé sa réservation pour le produit {message.product.name}</span>;
            }
        } else if (message.bidding_status === 3) {
            content = <span>{author} acheté le produit {message.product.name}</span>;
        } else {
            content = <span>{author} reservé le produit {message.product.name}</span>;
        }

        if (me && message.user.id === me.id) {
            content = <i>{content}</i>;
        }
    } else {
        content = <span>
            <CardMedia className={Styles.avatar} component={'img'} src={message.user.shop && message.user.shop.media_object ? getImageUrl('/' + message.user.shop.media_object.file_path, 'medium') : UserIcon} />
            <strong>{message.user.firstname} {message.user.name} : </strong>
            {message.content}
        </span>;
    }

    return (
        <Grid container item xs={12} className={clsx(Styles.wrapper, {[Styles.adminWrapper]: isAdmin}, {[Styles.adminPropWrapper]: isAdmin && isProposition}, {[Styles.adminPropRefusedWrapper]: isAdmin && isProposition && message.bidding_status < 0})} alignItems={"center"} onContextMenu={isAdmin ? onRightClick : null} {...(isAdmin ? longPressEvent : [])} justify={"space-between"}>
            <Grid item xs={12}>
                <Text className={Styles.messageContent}>
                    { content }
                </Text>
            </Grid>
            { isAdmin && isProposition && message.bidding_status === 1 && <Grid container className={Styles.buttonRow}>
                <Grid item xs={12} md={6}>
                    <Button variant={"contained"} color={"primary"} disableElevation size={"small"} onClick={handleAccept} className={clsx(Styles.button, Styles.buttonFirst)}>
                        Accepter
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button variant={"contained"} color={"secondary"} disableElevation size={"small"} onClick={handleRefuse} className={clsx(Styles.button, Styles.buttonLast)}>
                        Refuser
                    </Button>
                </Grid>
            </Grid>}
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        me: state.user.me,
        params: state.params.params
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};


const ConnectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveMessage);

export default ConnectedComponent;
