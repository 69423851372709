import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle";


export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 5,
            paddingRight: 5,
            boxSizing: "border-box",
            margin: "auto",
            position: "relative",
            overflowY: "auto",
            overflowX: "hidden"
        },
        subcontentWrapper: {
            width: '100%',
            boxSizing: 'border-box',
            flex: 1,
            marginLeft: 10,
            padding: 10,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0
            }
        },
        productsWrapper: {
            // ...GlobalStyle.rowCentered,
            // flexWrap: "wrap",
            width: "100%",
            marginBottom: Constants.GET_SIZE(25),
            alignItems: "stretch"
        },
        productWrapper: {
            margin: "auto",
            height: "100%"
        },
        loaderWrapper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            opacity: 0.8,
            backgroundColor: Constants.BACKGROUND_DARK_GRAY
        },
        loaderSubWrapper: {
            left: "50%",
            top: "50%",
            position: "relative",
            transform: "translateY(-50%) translateX(-50%)",
            width: "fit-content"
        },
    }
});
