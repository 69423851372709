import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import { ApiLogin } from "../Endpoint/Login";
import { handleError } from "../../Utils/SagaUtils";
import {FetchParams, SaveParam} from "../Endpoint/Params";
import {SavePicture} from "../Endpoint/Picture";

// worker Saga: will be fired on REGISTER_REQUESTED actions
function* fetchParams(action) {
    try {
        let result = yield call(FetchParams);

        yield put({type: "FETCH_PARAMS_SUCCEEDED", params: result.data["hydra:member"]});
    } catch (e) {
        yield put(handleError("FETCH_PARAMS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}
function* saveParam(action) {
    try {
        yield call(SaveParam, action.paramName, action.json ? JSON.stringify(action.paramValue) : action.paramValue);

        yield put({type: "SAVE_PARAM_SUCCEEDED", paramName: action.paramName, paramValue: action.paramValue});
    } catch (e) {
        yield put(handleError("SAVE_PARAM_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* saveBanners(action) {
    try {
        let result;
        for (const banner of action.paramValue) {
            let picture = banner.picture;
            if (picture) {
                if (picture.id === -1) {
                    result = yield call(SavePicture, picture.file);
                    if (result && result.data) {
                        banner.media_object_id = result.data["id"];
                    }
                } else {
                    banner.media_object_id = picture ? picture.id : -1;
                }
                delete (banner.picture);
            }
        }

        yield call(SaveParam, action.paramName, JSON.stringify(action.paramValue));

        yield put({type: "SAVE_PARAM_SUCCEEDED", paramName: action.paramName, paramValue: action.paramValue});
    } catch (e) {
        yield put(handleError("SAVE_PARAM_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* saveTendanceSauvagePicture(action) {
    try {
        let result = yield call(SavePicture, action.picture.file);

        yield call(SaveParam, "tendance_sauvage_picture", result.data["id"].toString());

        yield put({type: "SAVE_PARAM_SUCCEEDED", paramName: "tendance_sauvage_picture", paramValue: result.data["id"]});
    } catch (e) {
        yield put(handleError("SAVE_PARAM_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* mySaga() {
    yield takeLatest("FETCH_PARAMS_REQUESTED", fetchParams);
    yield takeLatest("SAVE_PARAM_REQUESTED", saveParam);
    yield takeLatest("SAVE_BANNERS_REQUESTED", saveBanners);
    yield takeLatest("SAVE_TENDANCE_SAUVAGE_PICTURE_REQUESTED", saveTendanceSauvagePicture);
}

export default mySaga;