import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import {
    PostPurchase,
    FetchPurchases,
    ValidateDelivery,
    FetchPurchaseProduct,
    ReturnProduct,
    FetchSales
} from '../Endpoint/Purchase'
import { handleError } from "../../Utils/SagaUtils";

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* postPurchase(action) {
    try {
        const purchase = yield call(PostPurchase, action.user, action.purchaseProducts, action.deliveryAddress, action.facturationAddress, action.mondialRelayId, action.chronopostRelayId, action.chosenAssoId, action.livePurchase ? action.livePurchase : null);
        yield put({type: "POST_PURCHASE_SUCCEEDED", purchase: purchase.data});
    } catch (e) {
        yield put(handleError("POST_PURCHASE_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchPurchases(action) {
    try {
        const purchases = yield call(FetchPurchases, action.page, action.itemsPerPage, action.sort, action.sortDir);
        yield put({type: "FETCH_PURCHASES_SUCCEEDED", purchases: purchases.data["hydra:member"], pagination: purchases.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("FETCH_PURCHASES_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchSales(action) {
    try {
        const purchases = yield call(FetchSales, action.page, action.itemsPerPage, action.sort, action.sortDir, action.tab);
        yield put({type: "FETCH_SALES_SUCCEEDED", purchases: purchases.data["hydra:member"], pagination: purchases.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("FETCH_SALES_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* validateDelivery(action) {
    try {
        const result = yield call(ValidateDelivery, action.id, action.code ? action.code : null);
        yield put({type: "VALIDATE_DELIVERY_SUCCEEDED", purchaseProduct: result.data, purchaseId: action.purchaseId});
    } catch (e) {
        let error = handleError("VALIDATE_DELIVERY_FAILED", e);
        error.id = action.id;
        yield put(error);
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchPurchaseProduct(action) {
    try {
        const result = yield call(FetchPurchaseProduct, action.id);
        yield put({type: "FETCH_PURCHASE_PRODUCT_SUCCEEDED", purchaseProduct: result.data});
    } catch (e) {
        yield put(handleError("FETCH_PURCHASE_PRODUCT_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* returnProduct(action) {
    try {
        const result = yield call(ReturnProduct, action.id);
        yield put({type: "RETURN_PRODUCT_SUCCEEDED", purchaseProduct: result.data});
    } catch (e) {
        yield put(handleError("RETURN_PRODUCT_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* mySaga() {
    yield takeLatest("POST_PURCHASE_REQUESTED", postPurchase);
    yield takeLatest("FETCH_PURCHASES_REQUESTED", fetchPurchases);
    yield takeLatest("FETCH_SALES_REQUESTED", fetchSales);
    yield takeLatest("VALIDATE_DELIVERY_REQUESTED", validateDelivery);
    yield takeLatest("FETCH_PURCHASE_PRODUCT_REQUESTED", fetchPurchaseProduct);
    yield takeLatest("RETURN_PRODUCT_REQUESTED", returnProduct);
}

export default mySaga;
