import { call, put, takeLatest, takeEvery, cancelled } from 'redux-saga/effects'
import { handleError } from "../../Utils/SagaUtils";
import {
    FetchForTime,
    FetchFromTo,
    FetchNbDisputes,
    FetchNbProducts,
    FetchNbReturnsForUser,
    FetchNbShops
} from "../Endpoint/Stat";

// worker Saga: will be fired on REGISTER_REQUESTED actions
function* fetchFromTo(action) {
    try {
        let result = yield call(FetchFromTo, action.startDate, action.endDate);

        yield put({type: "FETCH_FROM_TO_STATS_SUCCEEDED", result: result.data["hydra:member"]});
    } catch (e) {
        yield put(handleError("FETCH_FROM_TO_STATS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}
function* fetchNbTransactions(action) {
    try {
        let result = yield call(FetchForTime, action.timeType, action.year, action.month, action.day);

        yield put({type: "FETCH_NB_TRANSACTIONS_SUCCEEDED", result: result.data["hydra:member"], timeType: action.timeType});
    } catch (e) {
        yield put(handleError("FETCH_NB_TRANSACTIONS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchNbReturns(action) {
    try {
        let result = yield call(FetchForTime, action.timeType, action.year, action.month, action.day);

        yield put({type: "FETCH_NB_RETURNS_SUCCEEDED", result: result.data["hydra:member"], timeType: action.timeType});
    } catch (e) {
        yield put(handleError("FETCH_NB_RETURNS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchNbReturnsForUser(action) {
    try {
        let result = yield call(FetchNbReturnsForUser, action.id);

        yield put({type: "FETCH_NB_RETURNS_FOR_USER_SUCCEEDED", result: result.data});
    } catch (e) {
        yield put(handleError("FETCH_NB_RETURNS_FOR_USER_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchNbDisputes(action) {
    try {
        let result = yield call(FetchNbDisputes);

        yield put({type: "FETCH_NB_DISPUTES_SUCCEEDED", result: result.data});
    } catch (e) {
        yield put(handleError("FETCH_NB_DISPUTES_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchNbShops(action) {
    try {
        let result = yield call(FetchNbShops);

        yield put({type: "FETCH_NB_SHOPS_SUCCEEDED", result: result.data});
    } catch (e) {
        yield put(handleError("FETCH_NB_SHOPS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchNbProducts(action) {
    try {
        let result = yield call(FetchNbProducts);

        yield put({type: "FETCH_NB_PRODUCTS_SUCCEEDED", result: result.data});
    } catch (e) {
        yield put(handleError("FETCH_NB_PRODUCTS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}



function* mySaga() {
    yield takeLatest("FETCH_FROM_TO_STATS_REQUESTED", fetchFromTo);
    yield takeLatest("FETCH_NB_DISPUTES_REQUESTED", fetchNbDisputes);
    yield takeEvery("FETCH_NB_TRANSACTIONS_REQUESTED", fetchNbTransactions);
    yield takeEvery("FETCH_NB_RETURNS_REQUESTED", fetchNbReturns);
    yield takeLatest("FETCH_NB_RETURNS_FOR_USER_REQUESTED", fetchNbReturnsForUser);

    yield takeLatest("FETCH_NB_SHOPS_REQUESTED", fetchNbShops);
    yield takeLatest("FETCH_NB_PRODUCTS_REQUESTED", fetchNbProducts);
}

export default mySaga;