import axios from 'axios';
import {API_URL} from '../../Config/API'
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils'
import Moment from "moment"

export const PostPurchase = (user, purchaseProducts, deliveryAddress, facturationAddress, mondialRelayId, chronoRelayId, chosenAssoId, livePurchase = null) => {
    const data = {
        "user": "/api/users/" + user.id,
        purchaseProducts: purchaseProducts,
        "createdAt": Moment().format(),
        chosenAssoId
    };

    if (deliveryAddress) {
        data["deliveryAddress"] = deliveryAddress.address;
        data["deliveryCity"] = deliveryAddress.city;
        data["deliveryZipcode"] = deliveryAddress.zipcode;
        data["deliveryName"] = deliveryAddress.name;
        data["deliveryFirstname"] = deliveryAddress.firstname;
        data["deliveryPhone"] = deliveryAddress.phone;
        data["deliveryGender"] = "" + deliveryAddress.gender;
        data["deliverySetAsDefault"] = deliveryAddress.useDefault;
    }

    if (facturationAddress) {
        data["facturationAddress"] = facturationAddress.address;
        data["facturationCity"] = facturationAddress.city;
        data["facturationZipcode"] = facturationAddress.zipcode;
        data["facturationName"] = facturationAddress.name;
        data["facturationFirstname"] = facturationAddress.firstname;
        data["facturationPhone"] = facturationAddress.phone;
        data["facturationGender"] = "" + facturationAddress.gender;
    }

    if (mondialRelayId) {
        data["mondialRelayId"] = mondialRelayId;
    }

    if (chronoRelayId) {
        data["chronopostRelayId"] = chronoRelayId;
    }

    if (livePurchase) {
        data["livePurchase"] = "/api/lives/" + livePurchase;
    }
    return axios.post(API_URL + 'api/purchases',
        data
    )
};

export const FetchPurchases = (page, itemsPerPage, sort, sortDir) => {
    return axios.get(getApiUrl('purchases/my_purchases', {page, itemsPerPage, sort, sortDir}), {
        headers: setDefaultHeaders()
    });
};

export const FetchSales = (page, itemsPerPage, sort, sortDir, tab) => {
    return axios.get(getApiUrl('purchases/my_sales', {page, itemsPerPage, sort, sortDir, tab}), {
        headers: setDefaultHeaders()
    });
};

export const ValidateDelivery = (purchaseProductId, code) => {
    return axios.get(getApiUrl('purchase_products/' + purchaseProductId + '/validate_delivery', {code}), {
        headers: setDefaultHeaders()
    });
};

export const FetchPurchaseProduct = (id) => {
    return axios.get(getApiUrl('purchase_products/' + id), {
        headers: setDefaultHeaders()
    });
};

export const ReturnProduct = (id) => {
    return axios.get(getApiUrl('purchase_products/' + id + '/return_product'), {
        headers: setDefaultHeaders()
    });
};
