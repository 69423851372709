const initialState = {
    brands: [],
    colors: [],
    heels: [],
    materials: [],
    sizes: [],
    states: [],
    styles: [],

    brandsList: [],
    brandsListPagination: {
        first: 1,
        last: 1
    },
};


function reduc(state = initialState, action) {
    let first, last, index;
    switch (action.type) {
        case "FETCH_CRITERIA_SUCCEEDED":
            return Object.assign({}, state, {
                brands: action.brands,
                colors: action.colors,
                heels: action.heels,
                materials: action.materials,
                sizes: action.sizes,
                states: action.states,
                styles: action.styles,

            });
        case "SEARCH_BRANDS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                brandsList: action.brands,
                brandsListPagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1
                },
            });

        case "UPDATE_BRAND_REQUESTED":
        case "UPDATE_BRAND_SUCCEEDED":
            index = state.brandsList.findIndex((element) => element.id === action.brand.id);
            if (index !== -1) {
                return Object.assign({}, state, {
                    brandsList: [
                        ...state.brandsList.slice(0, index),
                        Object.assign({}, state.brandsList[index], action.brand),
                        ...state.brandsList.slice(index + 1)
                    ]
                });
            } else {
                return state;
            }
    }

    return state
}

export default reduc