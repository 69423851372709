import React from 'react';

import Box from "@material-ui/core/Box";

import { TouchableOpacity } from 'react-native-web';
import Text from "../../Component/FontText";
import Modal from "modal-enhanced-react-native-web"

import { connect } from 'react-redux'
import TextInputIcon from "../../Front/Component/TextInputIcon";
import {useForm} from "react-hook-form";

import DisputeModalStylesFunc from "../../../Style/MiddleOffice/DisputeModalStyle"
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";


function Component ({visible, setVisible, user, blockUser, callback}) {
    let DisputeModalStyles = DisputeModalStylesFunc();
    const { handleSubmit, register, errors, setValue } = useForm();

    let handleClickOk = (data) => {
        blockUser(user.id, true, data.text);
        setValue("text", "");
        setVisible(false);
        callback();
    };

    let _renderModalContent = () => (
        <Box className={DisputeModalStyles.modalContent}>
            <Text className={DisputeModalStyles.modalTitle}>Bloquer l'utilisateur</Text>
            <TextInputIcon
                errors={errors}
                register={register}
                placeholder={"Entrer la raison"}
                multiline
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                name={"text"}
            />

            <Grid container justify={"space-around"} style={{width: "100%", marginTop: 20}}>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={handleSubmit(handleClickOk)} className={DisputeModalStyles.button}>
                    Envoyer
                </Button>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={() => setVisible(false)}>
                    Annuler
                </Button>
            </Grid>

            {/*<Box style={{width: "100%", alignItems: "center", marginTop: 10, flexDirection: "row", justifyContent: "space-around"}}>*/}
                {/*<TouchableOpacity onPress={handleSubmit(handleClickOk)} className={DisputeModalStyles.button}>*/}
                    {/*<Text className={DisputeModalStyles.buttonLabel}>Envoyer</Text>*/}
                {/*</TouchableOpacity>*/}
                {/*<TouchableOpacity onPress={() => setVisible(false)} className={DisputeModalStyles.button}>*/}
                    {/*<Text className={DisputeModalStyles.buttonLabel}>Annuler</Text>*/}
                {/*</TouchableOpacity>*/}
            {/*</Box>*/}
        </Box>
    );

    return (
        <Modal isVisible={visible}>
            {_renderModalContent()}
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        blockUser: (id, block, text) => {
            dispatch({type: 'BLOCK_USER_REQUESTED', id, block, text})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;
