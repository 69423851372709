import React from "react";

import Link from "@material-ui/core/Link";

import StaticCommon from "./StaticCommon";
import Banner from "../../../Assets/Images/banners/Partagez_nos_valeurs.jpg"


function Component({title}) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "Partagez nos valeurs, engagez-vous",
        blocks: [
            {
                title: "",
                content: [
                    {
                        text: ["Depuis 2010, Violette Sauvage porte les valeurs de seconde vie et de recyclage glamour et grâce à vous, nous sommes devenus ",
                            <strong>le plus grand
                                organisateur</strong>, " de vide-dressings dans toute la France. Réservez votre stand sur nos événements, partagez votre bonne humeur avec nos milliers de visiteurs, profitez de nos animations et faîtes du bien à votre porte-monnaie tout en rendant de ",
                            <strong>nouvelles(eaux) acheteuses(eurs) heureuses(eux) !</strong>]
                    }
                ]
            },
            {
                title: "Adhérez à notre cause",
                content: [
                    { text: ['En ouvrant une boutique sur la Marketplace Violette Sauvage, c’est contribuer à changer notre façon de consommer la mode en adoptant la seconde main de premier choix et développer le ', <strong>recyclage</strong>, ' et faire un ', <strong>geste pour notre planète</strong>, ' en limitant la fast fashion.'] },
                    { text: ['De plus, Violette Sauvage s’engage pour la création de nouvelles alternatives ', <strong>éco-responsable et éthique</strong>, ', une rubrique a donc été spécialement créée pour le shopping éco-responsable : matières bio, mode de fabrication éthique, teinture naturelle…'] },
                ]
            },
            {
                title: "Shopping solidaire",
                content: [
                    { text: ['Vider son dressing, ', <strong>participer au recyclage</strong>, ' et faire des rencontres, voilà l’âme de Violette Sauvage. Nous souhaitons encourager la ', <strong>seconde main</strong>, ' et pouvoir proposer des ', <strong>petits prix</strong>, ' pour des pièces de qualité afin de faire un maximum d’heureux !'] },
                    { text: [<strong>L’idée est de redonner une juste valeur aux choses et pousser à produire des pièces de qualité qui dureront plus longtemps.</strong>, ' Par exemple une pièce achetée 100€ sera revendue 60€ (elle n’aura donc coûté que 40€), cette même pièce sera revendue 40€, puis 20€ et peut-être encore 10€, ainsi une pièce aura satisfait 4 personnes, réduisant la production et rémunérant correctement le fabricant le motivant à produire de la qualité.'] },
                    { text: ['Nous sommes également solidaires de cette nouvelle génération de ', <strong>créateurs engagés</strong>, ' qui souhaite se faire entendre et inciter à un ', <strong>nouveau mode de consommation</strong>, ' plus éthique, c’est pourquoi nous souhaitons les mettre en avant sur notre Marketplace.'] },
                    { text: ['De plus, il est important pour nous de pouvoir aider ces jeunes créateurs à développer leur activité et leur donner les moyens de se lancer sur le marché.'] },
                ]
            },
            {
                title: "Les associations",
                content: [
                    { text: [<Link href={'associations'}>Les associations partenaires</Link>]},
                ]
            },
        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"Les valeurs de Violette Sauvage, un vide dressing engagé"}
                      metaDesc={"Chez Violette Sauvage, nous défendons corps et âme le recyclage glamour, les jeunes talents et la consommation réfléchie depuis octobre 2010."}
                      arianeCurrentName={"Nos valeurs"} />
    );
}

export default Component
