const initialState = {
    shopsList: [],
    lovedShopsList: [],
    pagination: {
        first:1,
        last:1
    },
    currentShop: null,
    previewShop: null,
    subscribedShopsList: [],
    subscribedShopsListPagination: {
        first:1,
        last:1
    },
    saveShopErrors: [],
    isSavingShop: false,
    shopError: null
};


function reduc(state = initialState, action) {
    let first;
    let last;
    let index;
    let result = {};
    switch (action.type) {
        case "SAVE_SHOP_REQUESTED":
            return Object.assign({}, state, {
                isSavingShop: true
            });
        case "SAVE_SHOP_FAILED":
            if (action.errors) {
                return Object.assign({}, state, {
                    saveShopErrors: action.errors,
                    isSavingShop: false
                });
            } else if (action.message) {
                return Object.assign({}, state, {
                    saveShopErrors: [{propertyPath: "general", message: action.message}],
                    isSavingShop: false
                });
            }
        case "SAVE_PREVIEW_SHOP":
            return Object.assign({}, state, {
                previewShop: action.shop
            });
        case "LOGOUT":
        case "RESET_PREVIEW_SHOP":
            return Object.assign({}, state, {
                previewShop: null
            });

        case "EVALUATE_SHOP_SUCCEEDED":
            return Object.assign({}, state, {
                currentShop: Object.assign({}, state.currentShop, {
                    note: action.shop.note,
                    myShopEval: action.shop.myShopEval
                })
            });
        case "FETCH_SHOP_SUCCEEDED":
            action.shop.pagination = {
                first: 1,
                last: 1
            };
            return Object.assign({}, state, {
                currentShop: action.shop
            });
        case "FETCH_SHOP_FAILED":
            return Object.assign({}, state, {
                shopError: action.message
            });
        case "RESET_SHOP_ERROR":
            return Object.assign({}, state, {
                shopError: null
            });
        case "FETCH_PRODUCT_SHOP_SUCCEEDED":
            action.shop.pagination = {
                first: 1,
                last: 1
            };
            return Object.assign({}, state, {
                currentShop: action.shop
            });

        case "SEARCH_SHOPS_REQUESTED":
            if (action.keepDirty) {
                return state;
            } else {
                return Object.assign({}, state, {
                    shopsList: [],
                    pagination: {
                        first: 1,
                        last: 1,
                    }
                });
            }

        case "SEARCH_SHOPS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                shopsList: action.shops,
                pagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1,
                }
            });
        case "FETCH_LOVED_SHOPS_SUCCEEDED":
            return Object.assign({}, state, {
                lovedShopsList: action.shops,
            });
        case "FETCH_SUBSCRIBED_SHOPS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                subscribedShopsList: action.shops,
                subscribedShopsListPagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1,
                }
            });
        case "SUBSCRIBE_TO_SHOP_SUCCEEDED":
            index = state.shopsList.findIndex((element) => element.id === action.shop["id"]);

            if (index !== -1) {
                result["shopsList"] = [
                    ...state.shopsList.slice(0, index),
                    Object.assign({}, state.shopsList[index], action.shop),
                    ...state.shopsList.slice(index + 1)
                ];
            }

            if (state.currentShop && action.shop["id"] === state.currentShop.id) {
                result.currentShop = Object.assign({}, state.currentShop, action.shop);
            }
            return Object.assign({}, state, result);
        case "SAVE_SHOP_SUCCEEDED":
            index = state.lovedShopsList.findIndex((element) => element.id === action.shop.id);

            if (index !== -1 && !action.shop.loved) {
                result.lovedShopsList = [
                    ...state.lovedShopsList.slice(0, index),
                    ...state.lovedShopsList.slice(index + 1)
                ];
            } else if (index === -1 && action.shop.loved) {
                result.lovedShopsList = [
                    action.shop,
                    ...state.lovedShopsList
                ];
            }

            if (state.currentShop && action.shop["id"] === state.currentShop.id) {
                result.currentShop = Object.assign({}, state.currentShop, action.shop);
            }

            result.isSavingShop = false;
            return Object.assign({}, state, result);
        case "GET_SHOPS_PRODUCTS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            index = state.shopsList.findIndex((element) => element.id === action.shopId);

            if (index !== -1) {
                result["shopsList"] = [
                    ...state.shopsList.slice(0, index),
                    Object.assign({}, state.shopsList[index], {
                        products: action.products,
                        productsLoaded: true,
                        pagination: {
                            first: first ? parseInt(first) : 1,
                            last: last ? parseInt(last) : 1,
                        }
                    }),
                    ...state.shopsList.slice(index + 1)
                ];
            }
            if (state.currentShop && action.shopId === state.currentShop.id) {
                result["currentShop"] = Object.assign(
                    {},
                    state.currentShop,
                    {
                        products: action.products,
                        pagination: {
                            first: first ? parseInt(first) : 1,
                            last: last ? parseInt(last) : 1,
                        }
                    }
                );
            }
            return Object.assign({}, state, result);
        case "GET_SHOP_SIMILAR_PRODUCTS_SUCCEEDED":
            if (state.currentShop && state.currentShop.id === action.shopId) {
                result.currentShop = Object.assign({}, state.currentShop, {similarProducts: action.products});;
                return Object.assign({}, state, result);
            }
            break;


        case "ADD_TO_WISHLIST_SUCCEEDED":
            // Product List
            if (state.currentShop && state.currentShop.products) {
                index = state.currentShop.products.findIndex((element) => element.id === action.product.id);
                if (index !== -1) {
                    state.currentShop.products = [
                        ...state.currentShop.products.slice(0, index),
                        Object.assign({}, state.currentShop.products[index], action.product),
                        ...state.currentShop.products.slice(index + 1)
                    ];
                }
            }

            return Object.assign({}, state, result);

        case "BLOCK_USER_SUCCEEDED":
            if (state.currentShop && state.currentShop.owner && action.user.id === state.currentShop.owner.id) {
                return Object.assign({}, state, {
                    currentShop: Object.assign({}, state.currentShop, {
                        owner: Object.assign({}, state.currentShop.owner, {
                            status: state.currentShop.owner.status === 1 ? 0 : 1
                        })
                    })
                });
            } else {
                return state;
            }
    }

    return state
}

export default reduc
