import axios from 'axios';
import {API_URL} from '../../Config/API'
import {setDefaultHeaders} from "../../Utils/QueryUtils";

export const FetchVideDressings = () => {
    return axios.get(API_URL + 'api/events', {
        headers: setDefaultHeaders()
    })
};

export const FetchVideDressing = (id) => {
    return axios.get(API_URL + 'api/events/' + id, {
        headers: setDefaultHeaders()
    })
};

export const DeleteVideDressing = (id) => {
    return axios.delete(API_URL + 'api/events/' + id, {
        headers: setDefaultHeaders()
    })
};

export const CreateVideDressing = (videDressing) => {
    return axios.post(API_URL + 'api/events', videDressing, {
        headers: setDefaultHeaders()
    })
};

export const EditVideDressing = (videDressing) => {
    return axios.put(API_URL + 'api/events/' + videDressing.id, videDressing, {
        headers: setDefaultHeaders()
    })
};

export const SubscribeToVideDressing = (id) => {
    return axios.get(API_URL + 'api/events/' + id + '/subscribe', {
        headers: setDefaultHeaders()
    })
};

export const UnsubscribeFromVideDressing = (id) => {
    return axios.get(API_URL + 'api/events/' + id + '/unsubscribe', {
        headers: setDefaultHeaders()
    })
};
