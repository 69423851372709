import React from "react";
import clsx from "clsx";
import { TouchableOpacity } from 'react-native-web';

import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";

import Text from "../../Component/FontText";
import TextInput from "../../Component/FontInput";
import { Slider } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';

import Color from './Color'
import CriterionStylesFunc from '../../../Style/CriterionStyle'
import SliderStyles from '../../../Style/SliderStyle'
import { FixedSizeList as List } from 'react-window';

import * as Constants from '../../../Style/Constants'

import useWindowSize from '../../../Hooks/useWindowSize'

const InfiniteRow = (CriterionStyles) => ({style, index, data}) =>
    <TouchableOpacity onPress={() => {data.onPressValue(data.values[index])}} style={style}>
        <Text className={clsx(CriterionStyles.value, {[CriterionStyles.selected]: data.selected.indexOf(Array.isArray(data.values[index]["ids"]) ? data.values[index]["ids"][0] : data.values[index]["id"]) !== -1})}>{data.values[index]["value"].toUpperCase()}</Text>
    </TouchableOpacity>



function Component({name, values, setValue, selected, searchable, scale, width, luxury}) {
    let CriterionStyles = CriterionStylesFunc();
    let [display, setDisplay] = React.useState(false);
    let [filter, setFilter] = React.useState("");
    let valuesDom = [];

    let onPressValue = (value) => {
        let valueIds = [];
        if (Array.isArray(value["ids"])) {
            valueIds = value["ids"];
        } else {
            valueIds = [value["id"]];
        }

        let newValues = [...selected];
        for (const valueId of valueIds) {
            const index = newValues.indexOf(valueId);
            if (index !== -1) {
                newValues = [
                    ...newValues.slice(0, index),
                    ...newValues.slice(index + 1)
                ]
            } else {
                newValues = [
                    ...newValues,
                    valueId
                ];
            }
        }
        setValue(newValues);
    };


    if (values) {
        if (searchable) {
            let realValues = values.filter(word => word["value"].toLowerCase().search(filter.toLowerCase()) !== -1 && (!luxury || word.luxury));
            // let lineHeight = (19 / Constants.MAQUETTE_WIDTH_VW) * windowSize.width / 100;
            // let rowHeights = new Array(realValues.length)
            //     .fill(true)
            //     .map((value, index) => {return realValues[index].value.length > 16 ? lineHeight * 2 : lineHeight});
            valuesDom.push(
                <List
                    key={filter}
                    height={150}
                    // height={"100%"}
                    itemCount={realValues.length}
                    // itemSize={index => {return rowHeights[index]}}
                    itemSize={16}
                    layout={"vertical"}
                    itemData={{values: realValues, onPressValue, selected}}>
                    {InfiniteRow(CriterionStyles)}
                </List>
            );
        } else {
            for (const value of values) {
                valuesDom.push(
                    <TouchableOpacity onPress={() => {
                        onPressValue(value)
                    }}>
                        {"Couleurs" !== name && <Text
                            className={clsx(CriterionStyles.value, {[CriterionStyles.selected] : selected.indexOf(Array.isArray(value["ids"]) ? value["ids"][0] : value["id"]) !== -1})}>{value["value"]}</Text>}
                        {"Couleurs" === name && <Color
                            textClass={clsx(CriterionStyles.colorValue, {[CriterionStyles.selected]: selected.indexOf(value["id"]) !== -1})}
                            color={value} circleSize={Constants.FONT_SIZE_NORMAL}/>}
                    </TouchableOpacity>
                )
            }
        }
    } else if (scale) {
        const IOSSlider = withStyles(SliderStyles)(Slider);
        valuesDom.push(
            <Box className={CriterionStyles.sliderWrapper}>
                <IOSSlider
                    min={scale["min"]}
                    max={scale["max"]}
                    step={1}
                    defaultValue={selected}
                    onChangeCommitted={(event, value) => {setValue(value)}}
                    aria-labelledby="range-slider"
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => value + "€"}

                />
            </Box>
        )
    }

    return (
        <Box className={CriterionStyles.superWrapper}>
            <Box className={clsx(CriterionStyles.wrapper)}>
                <Link onClick={() => setDisplay(!display)} style={{width: "100%", cursor: "pointer", color: "black"}}>
                    <Grid container xs={12} justify={"space-between"} className={CriterionStyles.titleWrapper}>
                        <Text className={clsx(CriterionStyles.title)}>{name}</Text>
                        <KeyboardArrowDown className={clsx(CriterionStyles.caret, {[CriterionStyles.caretOpen]: display})}/>
                    </Grid>
                </Link>
                <Collapse in={display} className={CriterionStyles.collapse}>
                    <Box style={{paddingTop: 10, paddingLeft: 10}}>
                        {searchable &&
                        <Box className={CriterionStyles.inputWrapper}>
                            <TextInput placeholder={"Rechercher"} className={CriterionStyles.input} onChange={(event) => setFilter(event.target.value)} />
                        </Box>
                        }
                        <Box className={clsx(CriterionStyles.contentWrapper, {[CriterionStyles.searchableContentWrapper]: searchable})}>
                            {valuesDom}
                        </Box>
                    </Box>
                </Collapse>
            </Box>
            <Box className={clsx(CriterionStyles.upwardTriangleBorder)} />
            <Box className={CriterionStyles.upwardTriangle} />
        </Box>
    );
}

export default Component;
