import React from "react";
import clsx from "clsx";
import { Image } from "react-native";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";

import Mail from "@material-ui/icons/Mail";

import Text from "../Component/FontText";
import Paginator from "./Component/Paginator";
import ShopSubscribeButton from "./Component/ShopSubscribeButton";
import { connect } from 'react-redux'
import BlogueurHeader from "../../Assets/Images/banners/Blogueur.jpg";
import EcoRespHeader from "../../Assets/Images/banners/Eco-responsable.jpg";
import CreateurHeader from "../../Assets/Images/banners/Créateur.jpg";
import ProductSquare from "./Component/ProductSquare";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ShopSquare from "./Component/ShopSquare";

import ShopListStylesFunc from '../../Style/ShopListStyle'
import * as Constants from '../../Style/Constants'
import {getImageUrl} from "../../Utils/ImageUtils";
import {LINKS} from "../Component/InternalLink";

import HorizontalSlider from "./Component/HorizontalSlider";
import MetaTags from "react-meta-tags";


function Component({history, navigation, shops, searchShops, getShopsProducts, firstPage, lastPage, isLoading, params, getParams}) {
    let ShopListStyles = ShopListStylesFunc();
    let [overlayVisible, setOverlayVisible] = React.useState(-1);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let { type, page } = useParams();

    if (!page) {
        page = 1;
    }

    React.useEffect(() => {
        if (type === "bloggers") {
            document.title += ' - Les blogueurs';
        } else if (type === "responsables") {
            document.title += ' - Les boutiques éco-responsables';
        }  else if (type === "createurs") {
            document.title += ' - Les créateurs';
        } else {
            document.title += ' - Les boutiques';
        }
        getParams();
    }, []);

    React.useEffect(() => {
        if (type === "search") {
            searchShops(page, null, history.location.state && history.location.state.search ? history.location.state.search : "");
        } else {
            searchShops(page, type, null, type === "bloggers" ? 16 : 10);
        }
    }, [type, page, history.location.state ? history.location.state.search : null]);

    React.useEffect(() => {
        if (shops && type !== "bloggers") {
            for (const shop of shops) {
                if (!shop.products) {
                    shop.products = [];
                    getShopsProducts(shop);
                }
            }
        }
    }, [shops]);


    if (type === "bloggers") {
        let header = {picture: BlogueurHeader, label: "Blogueurs", link: "/", internal: true};
        if (params) {
            params.map(param => {
                if (param.name === "banners" && param.pictures && param.pictures.length > 0) {
                    let banners = JSON.parse(param.value);
                    header.picture = getImageUrl(param.pictures[2].contentUrl, "real");
                    header.label = banners[2].description;
                    if (banners[2].redirect in LINKS) {
                        header.link = LINKS[banners[2].redirect].web;
                        header.internal = true;
                    } else {
                        header.link = banners[2].redirect;
                        header.internal = false;
                    }
                }
            })
        }

        let shopsDom = [];
        if (shops) {
            for (const shop of shops) {
                shopsDom.push(<Grid item container xs={12} sm justify={"center"}><ShopSquare shop={shop} wrapperClass={ShopListStyles.shopSquareWrapper}/></Grid>);
            }
        }

        return (
            <Box className={ShopListStyles.wrapper}>
                <MetaTags>
                    <title>Vide dressing de blogueurs | Violette Sauvage</title>
                    <meta name="description"
                          content={"Retrouvez toute la liste des boutiques de blogueurs inscrites sur Violette Sauvage, meilleur vide-dressing en ligne."}/>
                </MetaTags>
                <Grid container item xs={12} className={ShopListStyles.homepageHeaderWrapper}>
                    <Link className={ShopListStyles.homepageHeaderLeftWrapper} style={{backgroundImage: "url(" + header.picture + ")"}} href={header.link} target={header.internal ? '_self' : '_blank'}>
                        <Box className={clsx(ShopListStyles.homepageHeaderRectangle, ShopListStyles.homepageHeaderPinkRectangle)} />
                        <Text className={clsx(ShopListStyles.headerImageTitle, ShopListStyles.homepageHeaderText)}>{header.label}</Text>
                    </Link>
                </Grid>
                <Box className={ShopListStyles.bloggerDescriptionWrapper}>
                    <Text className={ShopListStyles.bloggerDescription}>
                        Le style d’un blogueur vous fait de l’œil ? Venez shopper son look dans cette rubrique !<br /><br />
                        Vous y trouverez toutes les pépites à la pointe de la mode : des styles tendances pour des articles de marques à prix tout doux, l’idéal pour parfaire votre dressing !
                    </Text>
                </Box>
                <Grid container className={ShopListStyles.shopListWrapper}>
                    {shopsDom}
                </Grid>
                <Paginator firstPage={firstPage} lastPage={lastPage} page={parseInt(page)} currentUrl={"/shops/" + type} />
            </Box>
        );
    } else {
        let allShopsLoaded = true;
        let shopsDom = [];
        if (shops) {
            for (const shop of shops) {
                let productsDom = [];
                if (shop.products) {
                    for (let i = 0; i < 5; i++) {
                        if (shop.products[i]) {
                            productsDom.push(
                                <Grid item xs={12} sm style={{maxWidth: 270}}><ProductSquare shop={shop} product={shop.products[i]} smaller={true} wrapperStyle={ShopListStyles.productWrapper} /></Grid>
                            )
                        }
                    }
                }

                allShopsLoaded &= shop.productsLoaded;

                shopsDom.push(
                    <Box className={ShopListStyles.shopWrapper}>
                        <Grid container>
                            <Grid item container xs={12} sm alignItems={"center"}>
                                <Button onClick={() => {history.push("/shop/" + shop["id"])}} style={{width: "max-content"}}>
                                    <Image source={shop.picture ? getImageUrl(shop.picture.contentUrl, "medium") : null} className={ShopListStyles.shopImage} onMouseEnter={() => setOverlayVisible(shop.id)} onMouseLeave={() => setOverlayVisible(-1)} />
                                    <Box>
                                        <Text className={ShopListStyles.shopName}>{shop.name}</Text>
                                        <Text className={ShopListStyles.shopName2}>{shop.nbProducts} produit{shop.nbProducts > 1 ? "s" : ""} mis en vente</Text>
                                    </Box>
                                </Button>
                                <IconButton onClick={() => history.push("/message/nouveau", {shopId: shop.id})} style={{marginLeft: "auto", paddingLeft: 0, paddingRight: 0}}>
                                    <Mail style={{color: Constants.PINK, fontSize: Constants.FONT_SIZE_BIGGEST}}/>
                                </IconButton>
                            </Grid>
                            <Grid container item alignItems={"center"} justify={"space-between"} sm={2} className={ShopListStyles.subscribeWrapper}>
                                <ShopSubscribeButton shop={shop} variant={"contained"} wrapperStyle={{width: 200, marginLeft: "auto", marginRight: "auto"}}/>
                            </Grid>
                        </Grid>
                        <Hidden xsDown>
                            <Grid container spacing={2}>
                                {productsDom}
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <HorizontalSlider wrapperClass={ShopListStyles.slider} carouselKey={"shop-" + shop.id + "-products"} nbElementsPerPage={1} elementList={productsDom} arrows />
                        </Hidden>
                        <Text className={ShopListStyles.overlay} style={overlayVisible === shop.id ? {} : {visibility: "hidden"}}>{shop.description}</Text>
                    </Box>
                );
            }
        }

        return (
            <Box className={ShopListStyles.wrapper}>
                <MetaTags>
                    <title>Vide dressing {type === "createurs" ? "de créateurs" : "éco-responsables"} | Violette Sauvage</title>
                    <meta name="description"
                          content={"Retrouvez toute la liste des boutiques " + (type === "createurs" ? "de créateurs" : "éco-responsables") + " inscrites sur Violette Sauvage, meilleur vide-dressing en ligne."}/>
                </MetaTags>
                <Grid container item xs={12} className={ShopListStyles.homepageHeaderWrapper}>
                    <Box className={ShopListStyles.homepageHeaderLeftWrapper} style={{backgroundImage: "url(" + (type === "createurs" ? CreateurHeader : EcoRespHeader)+ ")"}}>
                        <Box className={clsx(ShopListStyles.homepageHeaderRectangle, ShopListStyles.homepageHeaderPinkRectangle)} />
                        <Text className={clsx(ShopListStyles.headerImageTitle, ShopListStyles.homepageHeaderText)} component={"h1"}>{type === "createurs" ? "Créateurs" : type === "search" ? "Recherche" : "Boutiques responsables"}</Text>
                    </Box>
                </Grid>
                <Box className={ShopListStyles.container}>
                    {allShopsLoaded ? (shopsDom.length > 0 ? shopsDom : <Text className={ShopListStyles.noShopsText}>Aucune boutique ne correspond à votre recherche</Text>) : null}
                </Box>
                {allShopsLoaded ? <Paginator firstPage={firstPage} lastPage={lastPage} page={parseInt(page)} currentUrl={"/shops/" + type} /> : null}
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        shops: state.shop.shopsList,
        firstPage: state.shop.pagination.first,
        lastPage: state.shop.pagination.last,
        isLoading: state.loading.isLoadingGeneral,
        params: state.params.params
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchShops: (page, type, name, itemsPerPage) => {
            dispatch({type: 'SEARCH_SHOPS_REQUESTED', page, shopType: type, name, itemsPerPage})
        },
        getShopsProducts: (shop) => {
            dispatch({type: 'GET_SHOPS_PRODUCTS_REQUESTED', page: 1, shopId: shop.id, itemsPerPage: 5})
        },
        getParams: () => {
            dispatch({type: 'FETCH_PARAMS_REQUESTED'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
