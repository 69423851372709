import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    // Header
    homepageHeaderWrapper: {
        maxHeight: 200,
        height: 200,
    },
    homepageHeaderLeftWrapper: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        width: "100%",
        borderRight: "1px solid white"
    },

    homepageHeaderText: {
        color: Constants.FONT_LIGHT_GRAY,
        // width: 200
    },

    arianeText: {
        ...GlobalStyle.uppercaseText
    },
    headerImageTitle: {
        ...GlobalStyle.headerImageTitle,
        position: "absolute",
        bottom: 50,
        left: 37,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            bottom: 20,
            left: 25,
            width: "85%",
            overflowWrap: "break-word"
        }
    },

    homepageHeaderRectangle: {
        position: "absolute",
        bottom: 0,
        left: 37,
        width: 205,
        height: 15,
        [theme.breakpoints.down('xs')]: {
            left: 25,
        }
    },

    homepageHeaderPinkRectangle: {
        backgroundColor: Constants.PINK
    },



    block: {
        maxWidth: 1440,
        margin: "auto",
        boxSizing: "border-box",
        paddingLeft: 200,
        paddingRight: 200,
        paddingTop: 50,
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            textAlign: "center",
        }
    },

    blockTitle: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        marginBottom: 20,

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
            marginBottom: 10,
        }
    },

    blockSubTitle: {
        fontWeight: "bold",
        marginBottom: 10,
        marginTop: 10,

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },

    blockText: {
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
        }
    },

    blockTable: {
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
        }
    }

}));
