import React from "react";
import { connect } from 'react-redux'
import Link from "@material-ui/core/Link/Link";
import StaticCommon from "./StaticCommon";
import Banner from "../../../Assets/Images/banners/CGU.jpg"

function Component({title}) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "Conditions Générales d'utilisation",
        blocks: [
            {
                title: "Termes et Conditions d'utilisation",
                content: [
                    {
                        text: [<strong>CONDITIONS GÉNÉRALES D’UTILISATION</strong>, " - Version du 16 octobre 2020"],
                    },
                    {
                        text: [<strong>Pour toutes questions relatives à nos CONDITIONS GÉNÉRALES D’UTILISATION, veuillez nous contacter via l’adresse legal@violettesauvage.fr</strong>, "Le service d’annonces en ligne Violettesauvage.fr(ci-après le « Site ») est géré par la société VIOLETTE SAUVAGE SAS (ci-après « VIOLETTE SAUVAGE »), société par actions simplifiée ou « SAS », au capital social de 8.000 €, dont le siège social est 21 rue de la Convention, 94270 Le Kremlin Bicêtre, France, immatriculée au Registre du Commerce et des Sociétés de Créteil sous le numéro 537 532 764, numéro de TVA Intracommunautaire FR76537532764 dont l’email est legal@violettesauvage.fr Le service d’annonces en ligne Violettesauvage.fr a vocation à mettre en relation des clients particuliers, des vendeurs professionnels et non professionnels, par le biais d’annonces en ligne déposées par ces derniers sur le catalogue du site, à des fins d’achat. Le service d’annonces en ligne Violettesauvage.fr est orienté sur une proposition commerciale de produits mode, prêt à porter, accessoires, cosmétique, créations uniques, ou de service visant à la promotion de marchandise, neuve ou de seconde main. Avant de s’inscrire sur le Site, chaque Utilisateur doit lire les présentes Conditions Générales d’Utilisation (ci-après les « Conditions Générales »), la Politique de Confidentialité (telle qu’elle est précisée dans les définitions), ainsi que les autres dispositions obligatoires du Site, les accepter et s’engager à les respecter. Les présentes Conditions Générales régissent exclusivement les relations entre les Utilisateurs, les Visiteurs et VIOLETTESAUVAGE.FR, à l’exclusion de toute condition préalablement disponible sur le site web de VIOLETTESAUVAGE.FR. Les Conditions Générales applicables sont celles en vigueur à la date d’utilisation du service d’annonces en ligne."],
                    },
                ]
            },
            {
                title: "1. DÉFINITIONS",
                content: [
                    { text: <strong>Les présentes Conditions Générales définissent les relations entre VIOLETTE SAUVAGE et les Utilisateurs ou les Visiteurs.</strong> },
                    { text: [<strong>Acheteur - </strong>, "désigne tout Utilisateur qui achète ou souhaite acheter un ou plusieurs Articles sur le Site ;"]},
                    { text: [<strong>Articles -</strong>, "désignent les marchandises/articles dont la commercialisation n’est pas interdite en vertu de la législation applicable et qui sont référencé(e)s par l’Utilisateur sur le Catalogue du Site correspondant, aux fins de les vendre, échanger, ou donner ;"]},
                    { text: [<strong>Catalogue - </strong>, "désigne le catalogue électronique dans lequel les Articles d’un même type sont répertoriés, étant entendu que tous les Utilisateurs doivent se conformer aux règles du catalogue mises en place par VIOLETTE SAUVAGE et accessibles depuis le lien suivant : XXX"]},
                    { text: [<strong>Boutique - </strong>, "désigne l’espace dans le Site qui est dédié au Vendeur. Cet espace rassemble tous les Articles proposés à la vente par le Vendeur. La Boutique peut être personnalisée selon certains critères permis par le Site et choisis par le Vendeur ;"]},
                    { text: [<strong>Compte d'Utilisateur ou Compte - </strong>, "désigne l’inscription de l’Utilisateur sur le Site, le compte créé contient des données personnelles ;"]},
                    { text: [<strong>Comptes de Réseaux Sociaux - </strong>, "désignent les comptes sur Facebook, Twitter, Instagram, Pinterest, Youtube et tous autres réseaux sociaux, où des informations sont fournies à propos du Site et des Services qu’il propose, en partageant les contenus publiés par les Utilisateurs et le site ;"]},
                    { text: [<strong>Comande - </strong>, "demande de biens réalisée par l’Acheteur auprès du Vendeur ;"]},
                    { text: [<strong>Confirmation de réception - </strong>, "désigne la confirmation de la réception de l’Article par l’Acheteur ou l’Utilisateur ;"]},
                    { text: [<strong>Délai de livraison - </strong>, "période entre la date de la prise en charge de la Commande par le prestataire de transport et la date de la mise à disposition de la Commande par l’Acheteur ou L’Utilisateur ;"]},
                    { text: [<strong>Liste des tarifs - </strong>, "désigne le document qui indique les tarifs des Services payants ;"]},
                    { text: [<strong>Livraison - </strong>, "expédition de l’Article à l’Acheteur ;"]},
                    { text: [<strong>Messages privés - </strong>, "désignent la communication par échange de messages privés entre les Utilisateurs via le système de messagerie du Site que seuls les Utilisateurs concernés peuvent consulter ;"]},
                    { text: [<strong>Mode de livraison - </strong>, "désigne tout mode de livraison standard, en point relais, en click and collect ou en remise en main propre disponibles sur le Site au moment de la Commande ;"]},
                    { text: [<strong>Nouveautés - </strong>, "désigne une sélection parmi les derniers articles mis en vente ;"]},
                    { text: [<strong>Politique de confidentialité - </strong>, "désigne le document qui définit la manière dont VIOLETTE SAUVAGE recueille, utilise, conserve et transfère les données personnelles et autres informations importantes d’un Visiteur ou d’un Utilisateur ;"]},
                    { text: [<strong>Politique de cookies - </strong>, "désigne le document qui définit la manière dont VIOLETTE SAUVAGE recueille, utilise, conserve et transfère les données personnelles et autres informations importantes d’un Visiteur ou d’un Utilisateur recueillis à l’aide de cookies ;"]},
                    { text: [<strong>Services - </strong>, "désigne l’ensemble des services englobant les Services Principaux et les Services Complémentaires proposés sur le Site ;"]},
                    { text: [<strong>Services Complémentaires - </strong>, "désignent les services complémentaires payants fournis par VIOLETTE SAUVAGE et proposés sur le Site (comme indiqué dans la ", <Link href={'conditions_de_livraison'}>Condition de Livraison et Liste des Tarifs</Link>, ") ;"]},
                    { text: [<strong>Service de Paiement et d'Expédition Intégrés - </strong>, "désigne le Service, optionnel pour l’Acheteur, facilitant et sécurisant le paiement et la livraison d’un Article lorsque la Transaction consiste en un contrat de vente sur le territoire de la France métropolitaine et la Corse* (*=> Corse tarifs spéciaux). Il intègre le Service de Paiement Intégré et le Service d’Expédition Intégré ;"]},
                    { text: [<strong>Service de Paiement Intégré - </strong>, "désigne le Service de paiement en ligne des Articles accessible par l’Acheteur à partir du Site, les prestations de paiement étant exécutées par le partenaire de VIOLETTE SAUVAGE ;"]},
                    { text: [<strong>Service d'Expédition Intégré - </strong>, "désigne le Service d’expédition et de livraison qui permet au Vendeur de délivrer l’Article à l’Acheteur dans le cadre d’un contrat de vente, les prestations d’expédition, de transport et de livraison étant exécutées par l’un des prestataires de VIOLETTE SAUVAGE ;"]},
                    { text: [<strong>Services Principaux - </strong>, "désignent la possibilité offerte par VIOLETTE SAUVAGE aux Utilisateurs sans que cela soit limitatif, de créer leur boutique, de référencer des Articles sur le Catalogue correspondant, d’examiner les Articles, de participer directement à l’achat-vente ou à d’autres Transactions, d’utiliser le Service de Paiement et d’Expédition Intégré, de communiquer entre eux en privé et/ou en public ;"]},
                    { text: [<strong>Site - </strong>, "désigne le site Web à l’adresse https://www.violettesauvage.fr"]},
                    { text: [<strong>Transaction - </strong>, "désigne l’ensemble des Transactions, qui transfèrent la propriété et/ou l’utilisation d’un Article d’un Utilisateur à l’autre, notamment, sans que cela ne soit exhaustif, le contrat de vente conclu directement entre l’Acheteur et le Vendeur, ou le retour d’un Article (si accepté par le vendeur), ou toute autre Transaction effectuée entre Utilisateurs ;"]},
                    { text: [<strong>Utilisateur - </strong>, "désigne toute personne physique qui, après s’être inscrite sur le Site, est habilitée à utiliser tous les Services du Site, afin de satisfaire ses besoins personnels, ou pour le compte d’un tiers ;"]},
                    { text: [<strong>Vendeur - </strong>, "désigne tout Utilisateur possédant une Boutique, qui met en vente un ou plusieurs Articles sur le Catalogue du Site en vue de les vendre ;"]},
                    { text: [<strong>Visiteur - </strong>, "désigne toute personne physique non inscrite sur le Site et susceptible d’utiliser le Site sans avoir à s’inscrire, conformément aux présentes Conditions Générales."]},
                ]
            },
            {
                title: "2. OBJET",
                content: [
                    { text: [<strong>2.1. </strong>, "Les présentes Conditions Générales définissent les relations entre VIOLETTE SAUVAGE et les Utilisateurs ou les Visiteurs. Elles ont pour objet de définir les droits et obligations des Parties dans le cadre de l’utilisation du Site."]},
                    { text: [<strong>2.2. </strong>, "Conformément aux articles L.111-1 et L.111-4 du Code de la consommation, les caractéristiques essentielles et les prix des Services vendus par voie électronique sont disponibles sur le Site. Le professionnel communique également au consommateur les informations relatives à son identité, à ses coordonnées postales, téléphoniques et électroniques et à ses activités, ainsi que, s’il y a lieu, celles relatives aux garanties légales, aux fonctionnalités du contenu numérique, et, le cas échéant, à son interopérabilité, à l’existence et aux modalités de mise en œuvre des garanties et autres conditions contractuelles, conformément aux articles R.111-1 et R.111-2 du Code de la consommation. Par ailleurs, l’Utilisateur ou le Visiteur reçoit les informations prévues aux articles L.122-1 et L.221-11 du Code de la consommation notamment au moyen des présentes conditions générales."]},
                    { text: [<strong>2.3. </strong>, "Le Site met en outre en œuvre les moyens techniques permettant à chaque Vendeur professionnel de remplir ses propres obligations précontractuelles et contractuelles, dont la responsabilité incombe au seul Vendeur. Il est rappelé que les Vendeurs professionnels qui réalisent des transactions avec des Acheteurs sont soumis à des dispositions légales spécifiques dont le respect relève de leur seule responsabilité, Violettesauvage.fr n’agissant que comme simple intermédiaire. Les Vendeurs définissent notamment leurs propres conditions générales de vente et informent leurs Acheteurs conformément aux obligations légales qui leur incombent."]},
                    { text: [<strong>2.4. </strong>, "Vous pouvez contacter VIOLETTE SAUVAGE en écrivant un mail à l’adresse mail contact@violettesauvage.fr"]},
                    { text: [<strong>2.5. </strong>, "Le Site donne à l’Utilisateur la possibilité de participer à la vente et/ou l’achat d’Articles et à toute autre Transaction autorisée par les présentes Conditions Générales, et d’utiliser les autres Services Complémentaires proposés par le Site."]},
                    { text: [<strong>2.6. </strong>, "Les Services ne sont pas destinés aux mineurs. Pour les mineurs, un parent ou un tuteur (majeur) doit s’enregistrer pour l’utilisation des Services et superviser l’utilisation des Services par le mineur."]},
                    { text: [<strong>2.7. </strong>, "Lors de son inscription sur le Site, l’Utilisateur doit indiquer son nom d’utilisateur (pseudonyme), son adresse de courrier électronique, ses coordonnées et son mot de passe (expressément ou via son profil Facebook, ou son compte Google), ce qui permet au Site d’identifier l’Utilisateur à chaque fois qu’il accède au Site. Même si l’Utilisateur a fourni son nom et prénom au moment de son inscription au Site, les autres Utilisateurs ne visualisent que le nom d’utilisateur (pseudonyme) de l’Utilisateur, qui peut être différent de ses noms et prénoms. L’accès au Site ainsi que son usage, effectués au moyen du mot de passe personnel et confidentiel de l’Utilisateur, sont réputés être effectués par l’Utilisateur concerné et détenteur des codes d’accès. Violettesauvage.fr se réserve le droit de vérifier ou faire vérifier tout ou parties des informations communiquées. Le fait de remplir le formulaire d’inscription n’emporte pas automatiquement adhésion aux Services proposés par le Site. Violettesauvage.fr se réserve la faculté, à tout moment, sans engager sa responsabilité et sans indemnité, d’approuver ou de refuser l’adhésion d’un candidat, étant précisé que le refus sera alors notifié par courrier électronique."]},
                    { text: [<strong>2.8. </strong>, "VIOLETTE SAUVAGE peut, à son entière discrétion, modifier les Conditions Générales afin :"]},
                    { text: ["a) d’adapter les Conditions Générales aux dispositions législatives ou réglementaires en vigueur ou en cours d’adoption ;"]},
                    { text: ["b) d’adapter les Conditions Générales à toute décision rendue par une juridiction ou par toute autre autorité compétente et qui impacte le contenu du Site ;"]},
                    { text: ["c) de rectifier toute erreur jusqu’ici non décelée."]},
                    { text: ["d) de mettre à jour tous les tarifs présentement définis."]},
                    { text: ["Violettesauvage.fr se réserve par ailleurs le droit de modifier à tout moment les présentes Conditions Générales en publiant une nouvelle version sur le Site."]},
                    { text: ["Pour toute autre modification des Conditions Générales que celles visées aux a), b), c) et d) du présent article, les Utilisateurs seront informés par courriel envoyé à l’adresse de courrier électronique qu’ils auront indiqué lors de leur inscription au moins un (1) jour avant la prise d’effet de la modification ainsi que 1 jour avant la prise d’effet de la modification, si la modification implique l’acceptation de l’Utilisateur. Si l’Utilisateur continue d’utiliser le Site après la date butoir indiquée ci-dessus, l’Utilisateur sera réputé avoir accepté la modification et les Services seront fournis conformément aux Conditions Générales modifiées. Si l’Utilisateur refuse expressément les modifications apportées aux Conditions Générales, il cessera d’être inscrit et ne sera plus en mesure d’utiliser les Services à compter de la date à laquelle les modifications prendront effet. Toute modification aux Conditions Générales publiées sur le Site s’appliquera immédiatement aux Visiteurs."]},
                    { text: [<strong>2.9. </strong>, "Dans l’hypothèse où une ou plusieurs des stipulations de ces Conditions Générales serai(en)t considérée(s) comme non valable(s) ou non opposable(s) par une juridiction compétente ou par un texte de loi, cette (ces) stipulations(s) sera(ont) supprimée(s) ou réputées non écrites sans que la validité ainsi que l’opposabilité des autres stipulations de ces Conditions Générales n’en soient affectées qui demeureront en vigueur entre les parties."]},
                    { text: [<strong>2.10. </strong>, "Il est expressément rappelé à l’Utilisateur et au Visiteur qu'Internet n'est pas un réseau sécurisé. L’Utilisateur ou le Visiteur reconnaît être pleinement informé du défaut de fiabilité du réseau Internet, tout particulièrement en termes d'absence de sécurité relative à la transmission de données et de non garantie des performances relatives au volume et à la rapidité de transmission des données. Dans ces conditions, il appartient à l’Utilisateur ou au Visiteur de prendre toutes les mesures appropriées de façon à protéger ses propres données et/ou logiciels notamment de la contamination par d'éventuels virus circulant sur le réseau Internet."]},
                ]
            },
            {
                title: "3. SERVICES",
                content: [
                    { text: [<strong>3.1. </strong>, "VIOLETTE SAUVAGE offre aux Utilisateurs un espace d’échange virtuel, à savoir le Site, une Boutique et un Catalogue systématisé. Les Utilisateurs ont la possibilité de référencer leurs Articles sur le Catalogue approprié du Site, d’acheter ou de vendre des Articles, d’utiliser les Services de Paiement et d’Expédition Intégrés de communiquer en envoyant des Messages Privés et d’utiliser d’autres Services Complémentaires proposés sur le Site. VIOLETTE SAUVAGE n’est partie à aucune Transaction entre Utilisateurs et n’agit qu’en tant que simple intermédiaire. En aucun cas, VIOLETTE SAUVAGE n’achète, ne vend et n’échange des Articles présentés sur le Site ni ne prétend le faire. VIOLETTE SAUVAGE offre des Services facilitant les Transactions. VIOLETTE SAUVAGE ne peut garantir que l’Article du Vendeur satisfera l’Acheteur. De même, VIOLETTE SAUVAGE ne peut garantir au Vendeur que l’Article ne sera pas retourné ou que la vente ne sera pas annulée par l’Acheteur."]},
                    { text: [<strong>3.2. </strong>, "Les Utilisateurs peuvent commander des Services Complémentaires sur le Site dans les conditions présentées sur le Site et régler le prix correspondant à VIOLETTE SAUVAGE (comme indiqué dans la ", <Link href={'conditions_de_livraison'}>Condition de Livraison et Liste des Tarifs</Link>, ")"]},
                    { text: [<strong>3.3. </strong>, "Les Comptes de Réseaux Sociaux de VIOLETTE SAUVAGE peuvent véhiculer les mêmes informations que sur le Site car VIOLETTE SAUVAGE est à la fois l’éditeur des Comptes de Réseaux Sociaux et du Site. Violettesauvage.fr n’est en revanche pas l’éditeur des contenus des annonces renseignées par les Vendeurs dont ces derniers ont seuls la responsabilité. Les présentes Conditions Générales, la Politique de Confidentialité et les autres règlements publiés sur le Site de VIOLETTE SAUVAGE s’appliquent aux Utilisateurs et Visiteurs des Comptes de Réseaux Sociaux VIOLETTE SAUVAGE qui doivent s’y conformer."]},
                ]
            },
            {
                title: "4. RÉFÉRENCEMENT DES ARTICLES",
                content: [
                    { text: [<strong>4.1. </strong>, "A titre de condition préalable :"]},
                    { text: ["- Seuls les vêtements, chaussures, accessoires, sous-vêtements, bijoux, peuvent être référencés sur le Catalogue ; les autres marchandises ne peuvent pas faire l’objet d’une Transaction."]},
                    { text: ["- Le Vendeur doit s’assurer que ses Articles sont conformes aux règles du catalogue et à la législation en vigueur. Il s’engage notamment à assurer une certaine qualité quant aux Articles proposés et à respecter les conditions de disponibilité. "]},
                    { text: ["- Le Vendeur doit être le propriétaire desdits Articles et être en droit de les vendre, échanger ou donner."]},
                    { text: ["- La vente, l’échange, l’utilisation ou la possession de tels Articles ne doivent violer les droits d’aucun tiers, respecter les droits de propriété intellectuelle et ne doivent violer aucune loi ou réglementation locale, nationale ou internationale applicable."]},
                    { text: ["- L’Article doit répondre aux exigences communément appliquées sur le marché pour ce type d’Article en fonction de la nature de la Transaction, sauf accord écrit via la messagerie privée contraire entre l’Acheteur et le Vendeur."]},
                    { text: ["En outre, les produits suivants ne pourront en aucun être mis en vente :"]},
                    { text: ["- les produits de contrefaçons"]},
                    { text: ["- la fourrure véritable"]},
                    { text: ["- les cosmétiques et les parfums, "]},
                    { text: ["- les produits alimentaires"]},
                    { text: ["- les objets qui atteignent aux bonnes mœurs"]},
                    { text: [<strong>4.2. </strong>, "Le Vendeur, lorsqu’il référence un Article sur le Catalogue, doit remplir le questionnaire relatif au référencement de l’Article. Le Vendeur doit décrire l’Article aussi précisément que possible et signaler les défauts éventuels et indiquer le tarif de l’Article. Le Vendeur, lorsqu’il référence l’Article, déclare que ce dernier est conforme à la description qu’il fournit, et que cette description est exhaustive. Le dépôt des Articles sur le Catalogue du Site est gratuit."]},
                    { text: [<strong>4.3. </strong>, "L’Article référencé sur le Catalogue ne doit pas seulement être décrit dans le questionnaire relatif au référencement de l’Article, il doit également être photographié. Au moins trois photographies de bonne qualité doit être téléversées lors du référencement de l’Article (il est interdit d’utiliser des photographies trouvées sur Internet et/ou des photos d’un article similaire). La photographie doit refléter la qualité réelle et l’aspect extérieur de l’Article, ainsi que l’éventuelle présence de défauts sur l’Article."]},
                    { text: [<strong>4.4. </strong>, "La quantité d’Articles référencés sur le Catalogue du Site est illimitée. Il est interdit de référencer les mêmes Articles plus d’une fois sur le Catalogue du Site."]},
                    { text: ["Le Vendeur autorise VIOLETTE SAUVAGE à diffuser les annonces sur des sites tiers."]},
                    { text: [<strong>4.5. </strong>, "Le Vendeur qui a référencé l’Article peut le retirer ou modifier le tarif, à tout moment, avant d’être entré en contact avec un Utilisateur pour la vente."]},
                    { text: ["En revanche, dès lors qu’un Utilisateur a accepté l’offre du Vendeur, celui-ci ne peut plus la modifier et la Transaction, si elle a lieu, se fera dans les conditions proposées au moment de l’acceptation de l’Utilisateur."]},
                    { text: [<strong>4.6. </strong>, "En dehors des retours d’Articles convenus entre le Vendeur et l’Utilisateur à la suite d’une Transaction, les Articles mis en ligne sur le site ne peuvent faire l’objet d’un retour sans accord préalable du Vendeur."]},
                    { text: ["Dans le cas d’une Transaction effectuée sans souscription au Service de Paiement et d’Expédition Intégrés, l’Acheteur et le Vendeur sont seuls responsables de la bonne exécution de la Transaction, sans possibilité de recourir à l’assistance de VIOLETTE SAUVAGE dans ce cadre. A cet effet, il est précisé que l’Acheteur et le Vendeur s’entendent sur l’Article vendu et le tarif et l’Acheteur est tenu de régler l’Article commandé de manière appropriée et en temps opportun, et d’accepter l’Article s’il est conforme à la description fournie par le Vendeur. "]},
                    { text: ["Le Vendeur est quant à lui tenu de livrer l’Article commandé en conformité avec le descriptif qu’il a fourni, dans les délais auxquels il s’est engagé et selon les modalités de livraison convenues avec l’Acheteur."]},
                    { text: ["A noter que le Service de Paiement et d’Expédition Intégrés n’est toutefois disponible que lorsque le Vendeur et l’Acheteur sont situés tous deux sur le territoire de la France métropolitaine et la Corse. En dehors de ces zones le Vendeur et l’Acheteur acceptent de se voir appliquer d’autres conditions tarifaires et de transports."]},
                ]
            },
            {
                title: "5. VENTE D’ARTICLES ET PRIX DU SERVICE DE PAIEMENT ET D’EXPÉDITION INTÉGRÉS",
                content: [
                    { text: [<strong>5.1. </strong>, "Pour les besoins du Service de Paiement et d’Expédition Intégrés, le Vendeur devra fournir ses coordonnées bancaires ainsi que sa carte d’identité afin que le montant des ventes finalisées sur le Site soit transféré sur son compte bancaire personnel. Si un compte ou une carte bancaire appartenant à une tierce personne sont enregistrées sur le Site, l’équipe VIOLETTE SAUVAGE peut demander des pièces justificatives d’identité."]},
                    { text: [<strong>5.2. </strong>, "Dans le cadre du Service de Paiement et d’Expédition Intégrés, la mise en vente d’un Article du Catalogue constitue une offre de vente dudit article par le Vendeur."]},
                    { text: [<strong>5.3. </strong>, "Si l’Acheteur choisit le Service de Paiement et d’Expédition Intégrés, il est réputé avoir accepté l’offre du Vendeur dès lors qu’il clique sur « Passez la commande » sur la page de paiement, après avoir vérifié et validé le récapitulatif de sa commande. Dès lors, le contrat de vente portant sur l’Article du Catalogue en question est conclu. L’Acheteur reconnaît que son acceptation de l’offre est faite en considération de la description de l’Article, objet de la vente, et qu’elle vaut engagement ferme de contracter avec le Vendeur aux conditions de l’offre."]},
                    { text: [<strong>5.4. </strong>, "Le Service de Paiement et d’Expédition Intégrés donne lieu au paiement par l’Acheteur de frais dont les montants sont indiqués dans la Conditions de Livraison et Liste des Tarifs et payables en sus du prix de l’Article."]},
                    { text: ["Au moment de la validation de la commande, le prix à payer s’entend donc du prix total du ou des Articles achetés auquel se sont ajoutés les frais relatifs au Service de Paiement et d’Expédition intégrés."]},
                    { text: ["VIOLETTE SAUVAGE pourra modifier Les Conditions de Livraison et la Liste des Tarifs à tout moment. Comme indiqué à l’article 5.5. ci-dessous, l’Acheteur peut choisir de ne pas recourir au service d’Expédition Intégré, l’Acheteur et le Vendeur sont alors seuls responsables de la bonne exécution de la Transaction, sans pouvoir recourir à l’assistance de VIOLETTE SAUVAGE dans ce cadre."]},
                ]
            },
            {
                title: "6. MODALITÉS DU SERVICE DE PAIEMENT INTÉGRÉ",
                content: [
                    { text: [<strong>6.1. </strong>, "Dès lors qu’un Acheteur utilise  le Service de Paiement intégré, le Vendeur est tenu de s’y conformer."]},
                    { text: [<strong>6.2. </strong>, "Dans le cadre du Service de Paiement Intégré, l’Acheteur doit payer à l’avance, le ou les Articles acheté(s) au Vendeur par carte de crédit ou de débit à l’aide du Service de Paiement Intégré disponible sur le Site."]},
                    { text: [<strong>6.3. </strong>, "Les montants versés par l’Acheteur seront conservés par VIOLETTE SAUVAGE via l’intermédiaire de STRIPE dans un portefeuille électronique fonctionnant comme un compte séquestre."]},
                    { text: ["Au huitième jour à compter de validation de réception de l’Article par l’Acheteur, Les fonds pourront être transférés au Vendeur sur son Wallet."]},
                    { text: ["A la demande du vendeur ce dernier recevra les fonds sur son compte bancaire 2j ouvrés après sa demande. Quel que soit le montant du transfert, cinquante centimes d’euros seront retenu à chaque retrait du Wallet . A défaut, les fonds disponibles sur le portefeuille électronique pourront être utilisés pour effectuer des achats sur VIOLETTE SAUVAGE auprès d’autres Utilisateurs."]},
                    { text: [<strong>6.4. </strong>, "VIOLETTE SAUVAGE est seule habilitée à donner ordre au compte séquestre de procéder au paiement, au bénéfice du Vendeur, des sommes correspondantes au règlement de l’Article. Le transfert des fonds au bénéfice du Vendeur ne pourra cependant avoir lieu qu’une fois l’Acheteur aura confirmé à VIOLETTE SAUVAGE la bonne réception de l’Article conforme à l’offre ou que l’Acheteur sera réputé l’avoir reçu (via confirmation des prestataires de transport) et accepté dans les conditions prévues à l’article 8 ci-après. Voir l’article intitulé « Acceptation » ci-dessous pour obtenir des informations complémentaires."]},
                    { text: [<strong>6.5. </strong>, "VIOLETTE SAUVAGE fait appel à un prestataire de services tiers pour le traitement du paiement et la conservation des informations relatives à la carte de crédit ou de débit. Pour pouvoir utiliser le Service de Paiement Intégré, l’Acheteur déclare accepter les conditions générales dudit prestataire."]},
                    { text: ["La transaction est immédiatement débitée sur la carte bancaire de l’Acheteur après vérification des données de celle-ci, à réception de l’autorisation de débit de la part de la société émettrice de la carte bancaire utilisée par l’Acheteur."]},
                    { text: ["Conformément à l’article L.132-2 du Code monétaire et financier, l’engagement de payer donné au moyen d’une carte bancaire est irrévocable. En communiquant les informations relatives à sa carte bancaire, l’Acheteur autorise le prestataire choisi par Violettesauvage.fr à débiter sa carte bancaire du montant correspondant à l’achat effectué et des frais supplémentaires."]},
                    { text: ["A cette fin, l’Acheteur confirme qu’il est titulaire de la carte bancaire à débiter et que le nom figurant sur la carte bancaire est effectivement le sien."]},
                    { text: ["Dans le cas où le débit du montant des achats et des frais applicables serait impossible, la Transaction serait immédiatement résiliée de plein droit et la commande serait annulée."]},
                    { text: ["Violettesauvage.fr met en œuvre tous les moyens pour assurer la confidentialité et la sécurité des données transmises sur le Site."]},
                    { text: ["L’Acheteur sera en revanche seul responsable de la transmission des informations liées à sa carte de crédit ou de débit et VIOLETTE SAUVAGE décline par les présentes Conditions Générales toute responsabilité à ce titre."]},
                ]
            },
            {
                title: "7. MODALITÉS DU SERVICE D’EXPÉDITION INTÉGRÉ",
                content: [
                    { text: [<strong>7.1. </strong>, "Dans le cadre du Service d’Expédition Intégré, l’Acheteur choisit l’un des modes de livraison proposé sur le site à l’occasion de la réalisation de la Commande."]},
                    { text: ["Le mode de livraison choisi sera présenté automatiquement au Vendeur et ne pourra être refusé par lui."]},
                    { text: [<strong>7.2. </strong>, "Le coût du Service d’Expédition Intégré est indiqué dans les ", <Link href={'conditions_de_livraison'}>Conditions de Livraison et Liste des Tarifs</Link>, " et est dû par l’Acheteur."]},
                    { text: ["- les parties décident d’une \"Click and Collect\", ce qui signifie une remise en main propre de l'article à l'acheteur lors d'un événement de vide dressing organisé par VIOLETTE SAUVAGE ;"]},
                    { text: ["- Pour la Remise en mains propres les parties décident que la livraison se fera lors d'un rendez-vous que les deux parties auront décidés ensemble."]},
                    { text: [<strong>7.3. </strong>, "Dès lors qu’un contrat de vente a été conclu et le prix payé par une des deux parties, ou les deux, VIOLETTE SAUVAGE envoie une confirmation de la vente au Vendeur. "]},
                    { text: ["L’Acheteur choisit une adresse de livraison nécessairement située sur le territoire français, ou dans un pays disponible dans la liste des pays desservis, sous peine de refus du Service d’Expédition intégrée. L’Acheteur est seul responsable d’un défaut de livraison dû à un manque d’indications lors de la Commande."]},
                    { text: ["Le Vendeur est tenu de consulter les instructions de livraison qui lui seront envoyées dès la réception par VIOLETTE SAUVAGE du paiement. A ce titre un numéro de commande est généré ainsi que le numéro de suivi du colis, ou entrer en contact avec l'acheteur afin de convenir d'un rendez-vous pour une remise en main propre."]},
                    { text: [<strong>7.4. </strong>, "En cas de défaut d’expédition de l’Article à l’Acheteur dans les quatre (4) jours à compter de la réception des informations d’expédition, VIOLETTE SAUVAGE est en droit de rembourser le prix d’achat total, incluant les frais de port, à l’Acheteur en le versant sur son compte. Le remboursement du prix d’achat total constituera le seul recours de l’Acheteur au titre de son contrat avec le Vendeur."]},
                    { text: [<strong>7.5. </strong>, "L’Acheteur est tenu de vérifier l’état de l’emballage ainsi que les Articles lors de la réception de la livraison."]},
                    { text: ["Il appartient à l’Acheteur d’émettre les réserves et les réclamations qu’il estime nécessaires, voire de refuser le colis s’il est manifestement endommagé à la livraison."]},
                    { text: ["Lesdites réserves et réclamations doivent être adressées au transporteur par lettre recommandée avec demande d'avis de réception dans les trois (3) jours ouvrables, non compris les jours fériés, qui suivent la date de la Livraison des Articles."]},
                    { text: ["L’Acheteur doit par ailleurs faire parvenir une copie de cette lettre au Vendeur et à VIOLETTE SAUVAGE."]},
                    { text: ["Le défaut de réclamation dans le délai susmentionné éteint toute action contre le transporteur conformément à l'article L. 133-3 du Code de commerce"]},
                    { text: ["L’Acheteur doit s'assurer que les Articles qui lui ont été livrés correspondent à la Commande. En cas de non-conformité des Biens en nature ou en qualité ou en quantité aux spécifications mentionnées dans la commande, l’Acheteur doit informer le Vendeur par la messagerie et ouvrir un Litige."]},
                ]
            },
            {
                title: "8. ACCEPTATION",
                content: [
                    { text: [<strong>8.1. </strong>, "Dans le cadre du Service de Paiement et d’Expédition Intégrés, VIOLETTE SAUVAGE, afin de protéger les Acheteurs, conserve le prix d’achat d’un Article pendant au moins huit (8) jours après la date à laquelle il aura été livré ou après la date de livraison prévue, ou dès confirmation de bonne réception par l’Acheteur via son Compte d’utilisateur."]},
                    { text: [<strong>8.2. </strong>, "L’Acheteur est tenu d’accepter l’Article s’il est conforme à la description fournie par le Vendeur. L’Acheteur est tenu de vérifier l’état du ou des Articles achetés lors de la livraison. Il lui appartient d’émettre les réserves qu’il estime nécessaires, dans les conditions prévues ci-après, voire de refuser le colis s’il est manifestement endommagé à la livraison."]},
                    { text: ["L’Acheteur doit notamment s’assurer que le ou les Articles qui lui ont été livrés correspondent aux Articles commandés. En cas de non-conformité des Articles en nature ou en qualité aux spécifications du Vendeur, l’Acheteur doit procéder à une réclamation dans les conditions décrites ci-après. Si l’Acheteur reçoit un Article qui n’est pas substantiellement conforme à sa description sur le Site, l’Acheteur doit en informer le Vendeur via la Messagerie et ouvrir un Litige depuis son Compte d’Utilisateur."]},
                    { text: ["VIOLETTE SAUVAGE ne propose pas de conditions de retour, toute condition de retour pour l’Acheteur devra être négociée par l’Acheteur avec le Vendeur avant le début de la Transaction. Les litiges concernant un article non reçu (voir section 9.4) ou non conforme devront faire l’objet d’une déclaration dans un délai de deux (2) jours à compter de la réception de l’Article sur le Site VIOLETTE SAUVAGE, en passant la Messagerie et en ouvrant un Litige. Passé ce délai, l’Acheteur ne sera pas éligible à un remboursement et le paiement pourra être transféré au Vendeur. VIOLETTE SAUVAGE ne prend pas en charge les frais de ports de retour ils sont à la charge de l’Acheteur via la page des retours ou du Vendeur selon négociation entre eux."]},
                    { text: [<strong>8.3. </strong>, "Si le Vendeur ne procède pas à l’envoi de son article dans les quatre (4) jours après le paiement de la part de l'Acheteur) conformément à l’article 7.4 des présentes Conditions Générales, la Transaction s’annulera automatiquement et l’Acheteur sera remboursé du prix total d’achat."]},
                    { text: [<strong>8.4. </strong>, "Si un Acheteur ne reçoit pas un Article qu’il a acheté auprès d’un Vendeur, il doit dans un premier temps vérifier l’état de l’envoi avec le numéro de suivi auprès du prestataire de livraison choisi. Dans un second temps en informer VIOLETTE SAUVAGE à l’aide d'un email envoyé à receptions@violettesauvage.fr dans un délai de sept (7) jours après la date d’expédition de l’article, date d’expédition étant donnée par le numéro de suivi auprès du partenaire de livraison."]},
                    { text: [<strong>8.5. </strong>, "Si un Acheteur reçoit un Article non conforme à la description sur le Site, il doit en premier temps prendre contact avec le vendeur pour trouver une solution à l’amiable. En cas de non résolution à l’amiable il devra en informer VIOLETTE SAUVAGE à l’aide d'un email envoyé à retours@violettesauvage.fr"]},
                    { text: [<strong>8.6. </strong>, "Si un Acheteur ne contacte pas VIOLETTE SAUVAGE dans les deux (2) jours suivant la réception d’un Article conformément aux articles 8.2. et 8.5. ci-dessus, il sera réputé avoir accepté l’Article."]},
                    { text: [<strong>8.7. </strong>, "Lors de l’acceptation d’un Article, VIOLETTE SAUVAGE crédite le compte du Vendeur du prix de vente par lui fixé. "]},
                    { text: [<strong>8.8. </strong>, "Si l’Acheteur contacte VIOLETTE SAUVAGE conformément aux articles 8.2, 8.4 ou 8.5 ci-dessus, VIOLETTE SAUVAGE conserve le prix d’achat total jusqu’à ce que VIOLETTE SAUVAGE reçoive des instructions convenues d’un commun accord entre l’Acheteur et le Vendeur quant au compte sur lequel lesdites sommes doivent être créditées, ou que VIOLETTE SAUVAGE décide de l’arbitrage."]},
                    { text: [<strong>8.9. </strong>, "Le Vendeur accepte, par avance, que le paiement par VIOLETTE SAUVAGE du prix de la Transaction puisse être retardé, d’une part pour des raisons tenant aux contraintes bancaires et ce, pendant un délai de cinq (5) jours ouvrés en sus des délais de transfert des fonds prévus aux présentes conditions générales, et d’autre part dans l’hypothèse d’une réclamation de l’Acheteur pendant le délai d’examen de celle-ci par VIOLETTE SAUVAGE."]},
                    { text: ["En tout état de cause le Vendeur ne pourra pas se voir transférer les fonds correspondant à la Transaction avant un délai de huit (8) jours à compter de la réception des produits par l’Acheteur, conformément à l’article 6.3 susvisé, ce qu’il accepte."]},
                ]
            },
            {
                title: "9. PAIEMENT",
                content: [
                    { text: [<strong>9.1. </strong>, "VIOLETTE SAUVAGE est habilitée à percevoir des sommes au titre des Services fournis conformément aux tarifs indiqués dans Les Conditions de Livraison et la Liste des Tarifs. L’Utilisateur accepte que VIOLETTE SAUVAGE soit habilitée à modifier Les Conditions de Livraison et la Liste des Tarifs dans les conditions de l’article 5.4 ci-dessus. Les modifications de Tarifs ne s’appliquent pas aux Transactions en cours (à savoir celles pour lesquelles l’Acheteur a déjà effectué le paiement)."]},
                    { text: [<strong>9.2. </strong>, "Tous les tarifs sont exprimés en euros (EUR) toutes taxes comprises."]},
                    { text: [<strong>9.3. </strong>, "Dans le cas d’une Transaction effectuée avec le Service de Paiement et d’Expédition Intégrés, le paiement de l’Acheteur se fait par carte bancaire ou électronique sur l’interface de paiement intégré du Site."]},
                    { text: [<strong>9.4. </strong>, "Dans le cas d’une Transaction effectuée avec le Service de Paiement et d’Expédition Intégrés, l’Acheteur est débité du montant total (prix de l’Article et frais du Service de Paiement et d’Expédition Intégrés) et le Vendeur ne percevra le montant qui lui est dû qu’à compter de la confirmation de réception de l’article par l’Acheteur, dans les délais prévus à l’article 6.3 des présentes. Si l’Acheteur ne reçoit pas l’Article ou n’en est pas satisfait, son compte sera recrédité dans les conditions décrites à l’article 8 ci-avant."]},
                ]
            },
            {
                title: "10. INTERACTIONS ET MESSAGES SUR LE SITE",
                content: [
                    { text: [<strong>10.1. </strong>, "Messages privés"]},
                    { text: ["L’échange de Messages Privés entre les Utilisateurs a pour finalité principale l’échange d’informations relatives à des Articles du Catalogue. Dès lors, si un Utilisateur ou un Visiteur envoie des Messages Privés à un autre Utilisateur,", <strong>il doit s’assurer qu’il n’envoie pas :</strong>]},
                    { text: ["- des messages ou des informations à caractère publicitaire ;"]},
                    { text: ["- des spams ou des contenus diffusant des virus ou des vers ;"]},
                    { text: ["- des messages de masse de quelque autre type que ce soit (lorsqu’un message est envoyé à plus de cinq (5) Utilisateurs ou lorsque le même message est copié et envoyé à des Utilisateurs qui n’ont pas demandé à les recevoir) ;"]},
                    { text: ["- des messages contenant des textes contraires aux bonnes mœurs et à l’ordre public, inappropriés, insultants, diffamatoires ou considérés par ailleurs comme étant incompatibles avec les présentes Conditions Générales et les intérêts des Utilisateurs ;"]},
                    { text: ["- des messages à caractère illicite ou tentant par ailleurs de porter préjudice aux autres Utilisateurs et/ou au Site."]},
                    { text: ["VIOLETTE SAUVAGE utilise un logiciel automatisé permettant de détecter, grâce à des algorithmes, tout propos contraire aux bonnes mœurs et à l’ordre public, inapproprié, insultant ou diffamatoire communiqué par un Utilisateur via des Messages Privés. Dans l’hypothèse où un Message Privé contenant des de tels propos est détecté par le logiciel automatisé, le Message Privé sera automatiquement bloqué et ou caché à l’Utilisateur qui reçoit le Message Privé. L’Utilisateur, émetteur du Message Privé bloqué et/ou caché sera verti de son obligation de se conformer aux Conditions Générales."]},
                    { text: [<strong>10.2. </strong>, "Echange d’évaluations entre les Utilisateurs"]},
                    { text: ["Un Utilisateur est en droit de rédiger des évaluations sur un autre Utilisateur uniquement si une Transaction a été effectuée entre eux. Les évaluations d’un Utilisateur à l’égard d’un autre Utilisateur doivent toujours être justes et honnêtes. Les mensonges et les insultes, sont interdits."]},
                    { text: ["VIOLETTE SAUVAGE est habilitée à retirer du Site les évaluations qui enfreignent les présentes Conditions Générales ou les droits des autres Utilisateurs, y compris les évaluations faites par des Utilisateurs qui n’étaient pas en droit de rédiger des évaluations conformément aux présentes Conditions Générales."]},
                    { text: ["VIOLETTE SAUVAGE, après avoir identifié l’Utilisateur qui n’a pas respecté ces règles, peut faire usage de son droit de bloquer en tout ou partie le compte de l’Utilisateur concerné."]},
                ]
            },
            {
                title: "11. RESPONSABILITÉ",
                content: [
                    { text: [<strong>11.1. </strong>, "Chaque Utilisateur est entièrement responsable, en qualité d’éditeur, pour toutes les informations qu’il poste sur le Site et, le cas échéant, des Articles qu’il poste, vend, échange ou transfère par ailleurs à d’autres Utilisateurs. En particulier, il est précisé que lorsqu’un Utilisateur déposera des Articles sur le Catalogue correspondant, l’Utilisateur reconnaît et accepte qu’il est entièrement responsable du téléversement de l’Article sur le Catalogue correspondant, sa description, les confirmations, l’exactitude des autres renseignements fournis et la communication avec les autres Utilisateurs et, de manière générale, des Transactions réalisées avec d’autres Utilisateurs et des litiges susceptibles d’en découler (ci-après ensemble le « Contenu »)."]},
                    { text: ["De manière générale, les Utilisateurs sont seuls responsables sur le plan civil et pénal des obligations qu’ils contractent lors de la conclusion des contrats via le Site et notamment de l’inexécution ou de la mauvaise exécution de l’une ou plusieurs de leurs obligations ainsi que de la diffusion de contenus illicites."]},
                    { text: [<strong>11.2. </strong>, "A cet égard, le Visiteur et Utilisateur doit respecter toute législation française et internationale applicable. Les Utilisateurs et les Visiteurs doivent notamment s’abstenir de (I) porter atteinte aux droits des tiers, (II) de violations de droits de propriété intellectuelle ou de référencer des articles de contrefaçons, (III) d’inciter aux délits ou aux crimes, à la discrimination, la haine ou la violence fondées sur la race, l’origine ethnique ou la nationalité, (IV) de communiquer des informations erronées ou des informations confidentielles, (V) de proférer des propos diffamatoires ou des injures (VI) de commettre des actes qui pourraient mettre en danger des mineurs, (VII) d’atteintes à un droit de la personnalité et notamment de publier des données personnelles d’autres individus ou violer les droits à la vie privée ou (VIII) d’usurper l’identité d’autrui."]},
                    { text: [<strong>11.3. </strong>, "Dans le cas où le Contenu ne respecte pas la législation applicable conformément à l’article 11.2. ou, de manière générale, un Utilisateur ou un Visiteur ne respecte pas les présentes Conditions Générales, l’Utilisateur ou le Visiteur est informé qu’il est seul responsable sur le plan pénal des infractions éventuellement commises et sur le plan civil au titre des dommages directs et indirects qui en découleraient à l’égard des tiers ou de VIOLETTE SAUVAGE."]},
                    { text: ["En conséquence, l’Utilisateur ou le Visiteur reconnaît et accepte que la responsabilité de violettesauvage.fr qui ne procédera en aucun cas à la vérification du Contenu en vertu de sa qualité d’hébergeur au sens de l’article 6 de Loi n°2004-575 du 21 juin 2004, ne pourrait être engagée qu’à condition qu’ayant eu connaissance d’une activité manifestement illicite, elle n’aurait pas agi promptement pour en retirer les données litigieuses ou en rendre l’accès inaccessible, en application de la loi susvisée."]},
                    { text: ["Violettesauvage.fr ne saurait en aucun cas être tenue responsable des dommages qui découleraient du non-respect de la législation applicable et des présentes Conditions Générales par les utilisateurs et les Visiteurs et notamment, sans que cette liste ne soit exhaustive, VIOLETTE SAUVAGE ne saurait être tenue pour responsable : (I) des actes ou des omissions des Visiteurs et Utilisateurs, (II) des Informations postées sur le Site par les Utilisateurs et Visiteurs, dont leurs thèmes, leur exactitude, leur exhaustivité et/ou leur conformité avec la réglementation applicable ou (III) de la qualité et des quantités des Articles qu’ils vendent ou achètent via l’utilisation du Site, ni de leur conformité avec la description donnée."]},
                    { text: [<strong>11.4. </strong>, "VIOLETTE SAUVAGE n’est pas responsable du Contenu mis en ligne. Toutefois, si un Utilisateur ou Visiteur découvre un Contenu faisant l'apologie des crimes contre l'humanité, incitant à la haine raciale et/ou à la violence ou concernant la pornographie enfantine, il convient d’en informer VIOLETTE SAUVAGE immédiatement :"]},
                    { text: ["- soit en processus de signalement en écrivant à contact@violettesauvage.fr;"]},
                    { text: ["- soit en adressant un courrier circonstancié à l’adresse suivante : VIOLETTE SAUVAGE SAS, 21 rue de la convention, 94270 Le Kremlin Bicêtre"]},
                    { text: ["Si l’Utilisateur estime qu’un Contenu porte atteinte à ses droits ou aux droits d’un tiers (contrefaçon, insulte, atteinte à la vie privée), l’Utilisateur peut le notifier à VIOLETTE SAUVAGE :"]},
                    { text: ["- soit via legal@violettesauvage.fr;"]},
                    { text: ["- soit en adressant un courrier circonstancié à l’adresse suivante : VIOLETTE SAUVAGE SAS, 21 rue de la Convention, 94270 le Kremlin Bicêtre"]},
                    { text: ["Conformément aux dispositions de l’article 6-I-5 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, la notification devra contenir :"]},
                    { text: ["- la date de la notification ;"]},
                    { text: ["- si vous êtes une personne physique : votre nom, prénom, profession, domicile, nationalité, date et lieu de naissance ;"]},
                    { text: ["- les nom et domicile du destinataire ou, s'il s'agit d'une personne morale, sa dénomination et son siège social ;"]},
                    { text: ["- la description des faits litigieux et leur localisation précise (ex : lien URL du contenu litigieux) ;"]},
                    { text: ["- les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de faits ;"]},
                    { text: ["- la copie de la correspondance adressée à l'auteur ou à l'éditeur des informations ou activités litigieuses demandant leur interruption, leur retrait ou leur modification, ou la justification de ce que l'auteur ou l'éditeur n'a pu être contacté."]},
                    { text: ["Toute notification incomplète sera susceptible de ne pas être traitée."]},
                    { text: [<strong>ATTENTION : </strong>, "Le fait, pour toute personne, de présenter un contenu ou une activité comme étant illicite dans le but d'en obtenir le retrait ou d'en faire cesser la diffusion en lui présentant des informations fausses ou inexactes est puni d'une peine d'un an d'emprisonnement et de quinze mille (15 000) euros d'amende."]},
                    { text: [<strong>11.5. </strong>, "Dans le cas d’une Transaction effectuée sans le Service de Paiement et de Livraison Intégrés proposé par VIOLETTE SAUVAGE, dès lors que l’Acheteur et le Vendeur se sont entendus sur l’Article et le tarif, le Vendeur est responsable de la livraison en bonne et due forme et en temps opportun de l’Article à l’Acheteur. En tout état de cause, si un litige survient entre l’Acheteur et le Vendeur quant à la livraison de l’Article, le Vendeur doit prouver que l’Article a été expédié (il peut s’agir d’un numéro de suivi, d’une facture ou d’un document émanant du service de messagerie express)."]},
                    { text: [<strong>11.6. </strong>, "Dans le cas d’une Transaction effectuée sans le système de Service de Paiement et de Livraison Intégrés proposé par VIOLETTE SAUVAGE, l’Acheteur est responsable du règlement en bonne et due forme et en temps utile de l’Article commandé, conformément aux conditions communiquées par le Vendeur ou selon les conditions convenues d’un commun accord."]},
                    { text: [<strong>11.7. </strong>, "Dans ce cadre, l’Utilisateur comprend et accepte que VIOLETTE SAUVAGE n’est pas responsable des litiges survenant entre les Acheteurs et les Vendeurs et ne prend pas part à leur règlement. Ces litiges peuvent être résolus conformément au droit français, à moins que les parties aient décidé, d’un commun accord, que les lois d’un autre pays s’appliquaient."]},
                    { text: [<strong>11.8. </strong>, "De manière générale, les Transactions n’intervenant qu’entre le Vendeur et l’Utilisateur, la responsabilité de Violettesauvage.fr ne pourra pas être engagée au titre desdites Transactions auxquelles elle est étrangère."]},
                ]
            },
            {
                title: "12. OBLIGATIONS DE L’UTILISATEUR",
                content: [
                    { text: [<strong>12.1. </strong>, "L’Utilisateur s’engage :"]},
                    { text: ["- lors de son inscription sur le Site, à fournir des informations véridiques le concernant, notamment son nom d’utilisateur et son adresse électronique ;"]},
                    { text: ["- à s’inscrire sur le Site une seule fois, à ne pas créer plus d’un Compte, sauf dans le cas où des tiers ont eu frauduleusement connaissance et/ou accès à ce Compte, et uniquement après en avoir informé VIOLETTE SAUVAGE et avoir bloqué l’ancien Compte ;"]},
                    { text: ["- à ne pas utiliser le Site et/ou les Services en vue de réaliser des actes ou des transactions illicites ou des actes frauduleux ;"]},
                    { text: ["- lors de l’utilisation du Site, à fournir des informations objectives, correctes, exhaustives et circonstanciées sur l’échange et/ou la vente proposée ;"]},
                    { text: ["- à veiller que le tarif proposé de l’échange et/ou de la vente des Articles, ainsi que les autres informations y relatives, sont corrects ;"]},
                    { text: ["- à ne pas copier les informations figurant sur le Site et déposées par VIOLETTE SAUVAGE ou un autre Utilisateur du Site, ni à en faire par ailleurs un usage illicite."]},
                    { text: [<strong>12.2. </strong>, "L’Utilisateur et le Visiteur s’engagent, lors de leur utilisation du Site, à fournir des informations et des données, notamment des données électroniques (dossiers, messages privés, etc.) qui :"]},
                    { text: ["- ne sont ni trompeuses ni erronées ;"]},
                    { text: ["- n’encouragent pas l’acquisition de marchandises dont la commercialisation est interdite ou restreinte ;"]},
                    { text: ["- n’utilisent pas de photographies (I) pour lesquelles il ne détient pas les droits de propriété intellectuelle (en général, il s’agit de photos trouvées sur Internet), ou (II) qui contiennent des liens vers d’autres sites Web ;"]},
                    { text: ["- n’utilisent pas de photographies (I) sur lesquelles d’autres personnes que lui sont visibles, à moins que ces personnes aient donné leur accord pour la publication de ces photographies ; ou (II) qui sont ou peuvent être considérées comme des photographies érotiques ou pornographiques contraires aux bonnes mœurs ou à l’ordre public ;"]},
                    { text: ["- n’utilisent pas, ni directement ni indirectement, des marques dans la description des Articles sans en avoir obtenu les droits de propriété intellectuelle ;"]},
                    { text: ["- ne proposent pas de vendre/acheter/échanger/ou transférer par ailleurs des Articles qui ont été fabriqués en violant les droits de propriété intellectuelle des propriétaires de la marque déposée ;"]},
                    { text: ["- ne violent pas les droits de propriété, ni les droits personnels non patrimoniaux de tiers (y compris les droits de propriété intellectuelle) ;"]},
                    { text: ["- n’enfreignent pas la réglementation française et internationale ;"]},
                    { text: ["- ne sont pas contraires à l’ordre public ni aux bonnes mœurs ; ne contiennent pas de virus ni de programmes ou fichiers informatiques qui pourraient (I) perturber le fonctionnement normal du Site et/ou des Services, (II) se trouver sur les ordinateurs des Utilisateurs et entraîner par conséquent des dommages pour l’Utilisateur ou ses biens, empêcher l’Utilisateur d’utiliser le Site, les Services et l’ordinateur de l’Utilisateur ;"]},
                    { text: ["- ne proposent pas des liens vers d’autres sites Web qui fournissent des services analogues à ceux proposés sur le Site."]},
                    { text: [<strong>12.3. </strong>, "L’Utilisateur et le Visiteur s’engagent à ne pas recueillir, cumuler, transmettre à des tiers, rendre publiques, publier ou divulguer des données des Utilisateurs du Site ou des données sur les actes des Utilisateurs du Site, y compris les Transactions, leur nombre, leur type, leur prix, etc., si ces informations deviennent disponibles de manière illégale ou à la suite d’un acte illégal ou d’une omission (sauf pour les informations statistiques, publiées sur le Site). L’Utilisateur et le Visiteur s’engagent, en outre, à ne pas recueillir, cumuler, transmettre à des tiers, rendre publiques, publier ou divulguer, les informations figurant sur le Site, si cela est susceptible de porter atteinte aux droits des autres Utilisateurs. Cette restriction ne s’applique pas à la fonction « partage » qui existe sur le Site et donne la possibilité aux Utilisateurs de partager les informations publiques disponibles sur le Site et dans les Comptes de Réseaux Sociaux, et de se les envoyer par courriel."]},
                    { text: [<strong>12.4. </strong>, "VIOLETTE SAUVAGE n’est pas responsable du comportement d’un Utilisateur lorsque ce dernier utilise le Site ou les Services. En particulier, VIOLETTE SAUVAGE n’est pas responsable de la mauvaise exécution ou de l’inexécution des Transactions par les Utilisateurs."]},
                    { text: [<strong>12.5. </strong>, "L’Utilisateur s’engage à garder confidentiel son identifiant et son mot de passe à l’égard des tiers, sauf pour les personnes qui ont été autorisées par l’Utilisateur à utiliser son identifiant."]},
                    { text: [<strong>12.6. </strong>, "L’Utilisateur s’engage à mettre à jour, sans délai, les informations sur le Site qui ne sont plus pertinentes en raison de modifications de ses données (en particulier celles fournies pendant son inscription sur le Site), ainsi que des informations sur les Articles déposés sur le Catalogue et leur état."]},
                    { text: [<strong>12.7. </strong>, "Lors de son utilisation du Site, l’Utilisateur ou le Visiteur déclare :"]},
                    { text: ["- être une personne physique âgée de 18 ans ou plus (dans le cas contraire, le(s) parent(s) ou le tuteur légal doit s’inscrire à sa place et le mineur ne pourra utiliser le Site qu’avec l’autorisation parentale ou du Tuteur légal), qu’il utilise tous les Services du Site afin de satisfaire ses besoins personnels, et qu’il a l’entière capacité et tous les droits pour effectuer les Transactions sur le Site ;"]},
                    { text: ["- qu’il respecte les Conditions Générales et les autres règlements du Site ;"]},
                    { text: ["- qu’il comprend être entièrement responsable des Transactions effectuées ;"]},
                    { text: ["- qu’il comprend qu’après avoir commandé un Article, il s’engage à acheter ou échanger cet Article, et que l’inexécution de cette Transaction peut l’obliger à rembourser les pertes subies par le Vendeur ;"]},
                    { text: ["- qu’il comprend qu’il devra procéder au paiement des frais afférents aux Services au bénéfice de VIOLETTE SAUVAGE en sus du prix des Articles, s’il décide d’utiliser les Services payants selon les tarifs et la procédure indiqués dans Les Conditions de Livraison et la Liste des Tarifs."]},
                ]
            },
            {
                title: "13. DROITS ET OBLIGATIONS DE VIOLETTE SAUVAGE",
                content: [
                    { text: [<strong>13.1. </strong>, "VIOLETTE SAUVAGE peut interdire en tout ou partie l’utilisation du Site (en précisant les conditions de limitation à l’Utilisateur) ou mettre fin à la possibilité de l’Utilisateur ou du Visiteur d’utiliser le Site, notamment en supprimant le Contenu déposé sur le Site, en annulant le compte de l’Utilisateur et en empêchant l’Utilisateur de se réinscrire sur le Site ou en empêchant le Visiteur de se rendre sur le Site, sous réserve que VIOLETTE SAUVAGE envoie une notification officielle préalable à cet Utilisateur ou Visiteur, dans les hypothèses suivantes :"]},
                    { text: ["- si l’Utilisateur ou le Visiteur enfreint ou manque à ses obligations découlant des Conditions Générales, de la Politique de Confidentialité ou d’autres règlements du Site ;"]},
                    { text: ["- si l’Utilisateur ou le Visiteur fournit des informations incorrectes, incomplètes et/ou trompeuses au moment de son inscription sur le Site ou de son utilisation du Site ;"]},
                    { text: ["- si l’Utilisateur ou le Visiteur divulgue délibérément et intentionnellement des informations erronées sur le Site, insulte d’autres personnes ou agit de manière inappropriée ;"]},
                    { text: ["- si l’Utilisateur ou le Visiteur utilise l’identité d’autres Utilisateurs ou agit par ailleurs de manière illégale ;"]},
                    { text: ["- si l’Utilisateur ou le Visiteur se connecte à partir de la même adresse IP ou du même ordinateur que celle ou celui qui a été bloqué(e) en raison de violations préalables ; "]},
                    { text: ["- si l’Utilisateur ou le Visiteur a reçu au moins trois avertissements de VIOLETTE SAUVAGE."]},
                    { text: [<strong>13.2. </strong>, "Dans les mêmes circonstances que celles décrites ci-dessus, VIOLETTE SAUVAGE peut, moyennant l’envoi d’une notification officielle préalable à l’Utilisateur/Visiteur concerné, limiter, en tout ou partie, le Compte de l’Utilisateur ou l’accès du Visiteur."]},
                    { text: ["Le blocage partiel signifie que l’Utilisateur ne sera pas en mesure de déposer des Articles sur le Catalogue, de communiquer avec les autres Utilisateurs. Le blocage total signifie que le Compte de l’Utilisateur sera bloqué et/ou annulé, et que la possibilité d’utiliser le Site à partir de l’ordinateur de l’Utilisateur sera bloquée. Dans le cas d’un blocage total, l’Utilisateur perdra toute possibilité de se réinscrire sur le Site. L’annulation du Compte de l’Utilisateur n'entraînera pas la suppression de toutes les informations pertinentes du Compte, notamment les données personnelles de l’Utilisateur, Violettesauvage.fr se réservant la faculté de les stocker pour en savoir plus sur les circonstances de l’annulation ou du blocage du Compte par Violettesauvage.fr et pour transférer ces données aux institutions et autorités chargées de faire appliquer les lois, conformément à la Politique de Confidentialité."]},
                    { text: [<strong>13.3. </strong>, "VIOLETTE SAUVAGE, après en avoir été informée conformément à l’article 13.1. ci-dessus, supprime tout Contenu illicite. En effet, VIOLETTE SAUVAGE peut, à tout moment, supprimer du Site les Articles déposés par l’Utilisateur sur le Catalogue ou toutes autres informations fournies par l’Utilisateur si ces Articles ou ces Informations violent les Conditions Générales, la réglementation applicable ou portent atteinte aux bonnes mœurs ou à l’ordre public."]},
                    { text: [<strong>13.4. </strong>, "VIOLETTE SAUVAGE peut enquêter sur toute violation des Conditions Générales, et en informer les institutions et autorités compétentes chargées de faire appliquer les lois."]},
                    { text: [<strong>13.5. </strong>, "VIOLETTE SAUVAGE peut, à tout moment, réorganiser le Catalogue, les espaces publicitaires ou les autres informations sur les Articles, sous réserve que ces changements ne modifient pas le Contenu fourni par l’Utilisateur. VIOLETTE SAUVAGE peut publier des Nouveautés, en publiant la description, les instructions ou les règles liées à celles-ci."]},
                    { text: [<strong>13.6. </strong>, "VIOLETTE SAUVAGE peut, à tout moment, publier sur le Site des offres à court et long terme, des concours, des jeux ou des loteries conformes au droit français pour proposer de nouveaux Services, étant précisé que de tels Services n’emporteront pas modification des présentes Conditions Générales. Les principales informations relatives aux offres, concours, jeux ou loteries conformes au droit français, seront fournies sur le Site. En cas de contradiction entre les Conditions Générales et les conditions particulières publiées sur le Site (tels que les règlements, instructions et descriptions portant sur les Nouveautés), ces dernières prévaudront."]},
                    { text: [<strong>13.7. </strong>, "VIOLETTE SAUVAGE peut mettre fin ou suspendre ou céder à des tiers l’exploitation du Site, sous réserve d’en informer les Utilisateurs et les Visiteurs sur le Site, en respectant un préavis de trente (30) jours."]},
                ]
            },
            {
                title: "14. DONNÉES PERSONNELLES",
                content: [
                    { text: ["VIOLETTE SAUVAGE recueille et traite les Données Personnelles des Utilisateurs conformément aux dispositions de loi informatique et libertés du 6 janvier 1978 telle qu’amendée le 6 août 2004 et à la Politique de Confidentialité."]},
                ]
            },
            {
                title: "15. DISPOSITIONS GENERALES",
                content: [
                    { text: [<strong>15.1. </strong>, "Les Conditions Générales sont régies par la loi française. Tout litige y afférent sera soumis aux Juridictions françaises."]},
                    { text: [<strong>15.2. </strong>, "En vertu de l'article L. 612-1 du Code de la consommation \"Tout consommateur a le droit de recourir gratuitement à un médiateur de la consommation en vue de la résiliation amiable du litige qui l'oppose à un professionnel.\""]},
                    { text: ["Les litiges entrant dans le champ d'application de l'article L. 612-1 du Code de la consommation sont les litiges définis à l'article L. 611-1 du Code de la consommation à savoir les litiges de nature contractuelle, portant sur l'exécution d'un contrat de vente ou de fournitures de services, opposant un consommateur à un professionnel. Le texte couvre les litiges nationaux et les litiges transfrontaliers."]},
                    { text: ["Pour toute difficulté, les utilisateurs et Visiteurs sont invités à contacter Violettesauvage.fr à l’adresse suivante : support@violettesauvage.fr"]},
                    { text: ["Seules les réclamations relatives aux Services fournis pas Violettesauvage.fr seront prises en compte, cette dernière étant étrangère aux Transactions intervenues entre les Utilisateurs, lesquelles relèvent de leurs seules responsabilités."]},
                    { text: ["Dans le cadre d’un différend entre VIOLETTE SAUVAGE et l’Utilisateur et à la suite d’un échec d’une réclamation écrite de l’Utilisateur auprès de du service client ou en l’absence de réponse de ce service dans un délai raisonnable d’un (1) mois, l’Utilisateur peut recourir à tout mode alternatif de règlement des différends et notamment à une procédure de médiation en accédant à la plateforme européenne de résolution des litiges en ligne à l’adresse suivante : http://ec.europa.eu/odr qui tentera une résolution à l’amiable. A défaut de résolution du litige dans le cadre de cette médiation, le litige sera porté devant les juridictions françaises compétentes."]},
                    { text: ["L’Utilisateur ou le Visiteur pourra, à ses frais, se faire assister par un conseil."]},
                    { text: [<strong>15.3. </strong>, "L’Utilisateur peut mettre fin à ses relations avec VIOLETTE SAUVAGE à tout moment après s’être acquitté de toutes ses obligations et après annulation de son Compte sur le Site et après l’avoir notifié à VIOLETTE SAUVAGE au choix soit par voie postale (uniquement par lettre recommandée avec accusé de réception) ou soit par voie électronique, c’est-à-dire par courriel."]},
                    { text: ["VIOLETTE SAUVAGE peut mettre fin à sa relation avec l’Utilisateur moyennant un préavis écrit de 30 jours pour notamment les raisons suivantes : (I) la décision de VIOLETTE SAUVAGE de cesser de fournir les Services, (II) la décision de VIOLETTE SAUVAGE de cesser d’exercer ses activités, (III) la décision de VIOLETTE SAUVAGE de changer l’administrateur des activités du Site en tout ou partie. VIOLETTE SAUVAGE peut mettre fin à sa relation avec l’Utilisateur, avec effet immédiat et sans notification préalable, en cas de violation de la loi ou des dispositions des présentes Conditions Générales par l’Utilisateur."]},
                    { text: [<strong>15.4. </strong>, "VIOLETTE SAUVAGE détient tous les droits de propriété intellectuelle relatifs au Site, à son système (le Catalogue, leur diffusion, etc.), à la conception du Site et aux logiciels utilisés par le Site, y compris les marques et les noms de domaine."]},
                    { text: [<strong>15.5. </strong>, "Les Utilisateurs et les Visiteurs qui utilisent le Site ou les Services et/ou qui fournissent, déposent ou envoient par ailleurs des informations ou des données dont notamment des photographies sur le Site, i.e. le Contenu, concèdent par les présentes à VIOLETTE SAUVAGE et toute société appartenant au Groupe VIOLETTE SAUVAGE, sans contrepartie, une licence d’utilisation mondiale et non exclusive du Contenu. Les Utilisateurs et les Visiteurs sont exclusivement responsables du Contenu. VIOLETTE SAUVAGE peut utiliser le Contenu déposé sur le Site par l’Utilisateur ou le Visiteur, sur tout support connu ou inconnu à ce jour et notamment Internet (dans les bannières et les articles, les mettre sur d’autres sites Web) et les réseaux sociaux (Facebook, twitter, Instagram etc.), et ce à des fins commerciales et publicitaires, ce que l’Utilisateur et le Visiteur acceptent expressément."]},
                    { text: ["L’Utilisateur ou le Visiteur, lors du téléversement du Contenu sur le Site, a néanmoins la faculté d’interdire l’utilisation du Contenu de la manière et aux fins indiquées dans le présent paragraphe, en le notifiant à VIOLETTE SAUVAGE à l’adresse suivante : legal@violettesauvage.fr"]},
                    { text: [<strong>15.6. </strong>, "Toutes les notifications, demandes et autres informations de l’Utilisateur à VIOLETTE SAUVAGE seront adressées comme indiqué dans les Conditions Générales à l’article 15.10 des présentes à l’adresse mail contact@violettesauvage.fr."]},
                    { text: ["Toutes les notifications, demandes et autres informations de Violettesauvage.fr à l’Utilisateur lui seront adressées par courriel, à l’adresse de courrier électronique qu’il aura indiquée lors de son inscription sur le Site."]},
                    { text: [<strong>15.7. </strong>, "Les Conditions Générales n’ont pas pour effet de créer entre VIOLETTE SAUVAGE et les Utilisateurs un partenariat, des relations de travail, des relations entre un agent commercial et son client, ni des relations de franchise."]},
                    { text: [<strong>15.8. </strong>, "L’Utilisateur reconnaît et accepte expressément que VIOLETTE SAUVAGE peut céder les présentes Conditions Générales ainsi que l’ensemble des droits et obligations qui y sont attachés, à tout tiers, sans accord préalable et écrit de l’Utilisateur, notamment en cas de cession de branche d’activité, de fusion par constitution d’une nouvelle société, de fusion absorption, de scission ou de tout changement de contrôle affectant VIOLETTE SAUVAGE. L’Utilisateur accepte qu’une telle cession libère VIOLETTE SAUVAGE pour l’avenir."]},
                    { text: [<strong>15.9. </strong>, "Dans le cadre de la législation applicable aux plateformes de mise en relation, tendant à la transmission d’une information loyale, claire et transparente à nos utilisateurs, nous vous invitons à vous référer aux portails des Administrations Fiscales et Sociales dont les adresses sont ici indiquées pour être informés des obligations fiscales et sociales qui vous incombent dans le cadre des transactions que vous effectuerez sur notre site :"]},
                    { text: [<Link target={"_blank"} href={'https://www.impots.gouv.fr/portail/node/10841'}>- Obligations fiscales.</Link>]},
                    { text: [<Link target={"_blank"} href={'http://www.securite-sociale.fr/Vos-droits-et-demarches-dans-le-cadre-des-activites-economiques-entre-particuliers-Article-87'}>- Obligations sociales.</Link>]},
                    { text: [<strong>15.10. </strong>, "Un Utilisateur peut formuler des objections quant à l’accès, l’utilisation ou l’exploitation du Site ou des Services sous la forme d’une notification. Les notifications doivent être présentées à VIOLETTE SAUVAGE à l’adresse mail legal@violettesauvage.fr ou par courrier à VIOLETTE SAUVAGE SAS 21 rue de la convention 94270 Kremlin Bicêtre."]},
                    { text: ["Pour toutes requêtes légales, merci de nous contacter à cette adresse : legal@violettesauvage.fr."]},
                    { text: ["Le Site est hébergé par OVH."]},
                ]
            }
        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
            metaTitle={"Conditions Générales de Vente | Vide dressing Violette Sauvage"}
            metaDesc={"Retrouvez toutes les conditions générales de vente, de livraisons & de retours de votre vide-dressing Violette Sauvage."}
            arianeCurrentName={"CGV"} />
    );
}

export default Component
