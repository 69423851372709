import React from 'react';
import { useDispatch } from 'react-redux'
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";

export function useSubscribeLive(id, callback = null) {
    const [isLoading, setIsLoading] = React.useState(false);

    let trigger = (subscribe) => {
        setIsLoading(true);
        axios.get(
            getApiUrl('products/' + id + (subscribe ? '/subscribe_live' : '/unsubscribe_live')),
            {headers: setDefaultHeaders()}
        ).then(response => {
            callback(response.data.isLiveRequest);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }


    return [trigger, isLoading];
}
export function useAddToWishlist(product, callback = null) {
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();


    let trigger = (subscribe) => {
        setIsLoading(true);
        dispatch({type: 'NEW_ADD_TO_WISHLIST', product: Object.assign({}, product, {isWish: subscribe})});
        axios.get(
            getApiUrl('products/' + product.id + (subscribe ? '/subscribe' : '/unsubscribe')),
            {headers: setDefaultHeaders()}
        ).then(response => {
            dispatch({type: 'NEW_ADD_TO_WISHLIST', product: response.data});
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }


    return [trigger, isLoading];
}