import React from "react";
import clsx from "clsx";

import Box from "@material-ui/core/Box"

import Text from "../../Component/FontText";

import ColorStylesFunc from "../../../Style/ColorStyle"

const COLOR_ASSOCIATION = {
    "Noir": "#000000",
    "Bleu": "#0000FF",
    "Blanc": "#FFFFFF",
    "Multi": "#000000",
    "Gris": "#696969",
    "Beige": "#E0CDA9",
    "Marron": "#582900",
    "Rose": "#FD6C9E",
    "Rouge": "#FF0000",
    "Argent": "#C0C0C0",
    "Or": "#EFD807",
    "Vert": "#00FF00",
    "Violet": "#DDA0DD",
    "Kaki": "#94812B",
    "Orange": "#FF7F00",
    "Jaune": "#F7FF3C"
};

function Component({color, circleSize, textStyle, textClass, wrapperStyle, wrapperClass}) {
    let ColorStyles = ColorStylesFunc();
    return (
        <Box className={clsx(ColorStyles.wrapper, wrapperClass)} style={wrapperStyle ? wrapperStyle : {}}>
            <Box style={{width: circleSize, minWidth: circleSize, height: circleSize, backgroundColor: COLOR_ASSOCIATION[color.value], borderRadius: circleSize, marginRight: 7}} />
            <Text className={textClass} style={textStyle}>{color.value}</Text>
        </Box>
    );
}

export default Component;