import React from "react";
import clsx from 'clsx';

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { useParams } from "react-router-dom";
import Text from "../Component/FontText";
import CustomCheckbox from "../Component/CustomCheckbox";
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

import { CardCvcElement, CardNumberElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ErrorStyleFunc from "../Styles/Error";
import {InternalLink} from "../Component/InternalLink";

import ProductReturnStylesFunc from "../../Style/MiddleOffice/ProductReturnStyle"
import * as Constants from "../../Style/Constants"
import {getPdfUrl} from "../../Utils/ImageUtils";
import {getSingleProductDeliveryPrice} from "../../Utils/PriceUtils";


function Component({history, navigation, token, purchaseProduct, me, fetchMe, fetchPurchaseProduct, returnProduct, shippingPrices, params}) {
    let ProductReturnStyles = ProductReturnStylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { setError, clearError, control, errors, watch, register, setValue } = useForm();
    let { id } = useParams();
    let [page, setPage] = React.useState(0);
    let [cgv, setCGV] = React.useState(false);
    let [selectedCard, setSelectedCard] = React.useState(-1);
    let [memorizeCard, setMemorizeCard] = React.useState(false);

    const stripe = useStripe();
    const elements = useElements();

    React.useEffect(() => {
        fetchPurchaseProduct(id);
        returnProduct(id);
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        if (purchaseProduct) {
            if (purchaseProduct.status === 2) {
                setPage(1);
            } else {
                setPage(0);
            }

            if (page === 1 && !(purchaseProduct && purchaseProduct.returnParcel)) {
                setTimeout(() => fetchPurchaseProduct(id, true), 1000);
            }
        }
    }, [purchaseProduct]);


    const submitForm = async () => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        // event.preventDefault();

        if (!stripe || !elements || !purchaseProduct) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        let data = {};

        if (memorizeCard) {
            data["setup_future_usage"] = 'on_session';
        }

        if (selectedCard !== -1) {
            data["payment_method"] = me.savedCardId[selectedCard];
        } else {
            data["payment_method"] = {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: me.username
                }
            }
        }

        const result = await stripe.confirmCardPayment(purchaseProduct.returnStripeClientSecret, data);

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            setError("card", {type: "invalid", message: result.error.message});
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                fetchMe(token);
                returnProduct(id);
                setPage(1);
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
            }
        }
    };


    const createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: 'black',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    ...(padding ? {padding} : {}),
                },
                invalid: {
                    color: '#CA8A8F',
                },
            },
            hidePostalCode: true
        };
    };

    let savedCardsDom = [];
    if (me && me.savedCard) {
        for (let i = 0; i < me.savedCard.length; i++) {
            savedCardsDom.push(
                <CustomCheckbox
                    size={Constants.CHECKBOX_SIZE_BIG}
                    containerClass={ProductReturnStyles.checkboxContainer}
                    containerStyle={{marginBottom: Constants.GET_SIZE(10)}}
                    checked={selectedCard === i}
                    title={<Text className={ProductReturnStyles.checkboxLabel}>Carte enregistrée **** {me.savedCard[i]}</Text>}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    checkedIcon={"square"}
                    onPress={() => {setSelectedCard(i)}}
                />
            )
        }
    }

    let getPage1 = () => {
        return (
            <Box className={ProductReturnStyles.wrapper}>
                <CustomCheckbox
                    containerClass={ProductReturnStyles.checkboxContainer}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={ProductReturnStyles.checkboxLabel}>J'accepte les conditions générales de ventes.*</Text>}
                    checked={cgv}
                    onPress={() => setCGV(!cgv)}
                    size={Constants.CHECKBOX_SIZE_BIG}
                />
                <ErrorMessage name={"cgv"} errors={errors} as={Text} className={clsx(ErrorStyle.error, ProductReturnStyles.error)} />
                <InternalLink link={"HOMEPAGE"} label={"En savoir plus"} wrapperStyle={ProductReturnStyles.knowMoreText}/>

                {savedCardsDom}
                <CustomCheckbox
                    size={Constants.CHECKBOX_SIZE_BIG}
                    containerClass={ProductReturnStyles.checkboxContainer}
                    checked={selectedCard === -1}
                    title={<Text className={ProductReturnStyles.checkboxLabel}>Ajouter une autre carte</Text>}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    checkedIcon={"square"}
                    onPress={() => {setSelectedCard(-1)}}
                />
                <Box className={ProductReturnStyles.stripeFieldWrapper}>
                    <CardNumberElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>
                <Box className={ProductReturnStyles.stripeFieldWrapper}>
                    <CardExpiryElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>
                <Box className={ProductReturnStyles.stripeFieldWrapper}>
                    <CardCvcElement options={createOptions(Constants.FONT_SIZE_MEDIUM)} />
                </Box>
                <ErrorMessage name={"card"} errors={errors} as={Text} className={ErrorStyle.error} />
                <CustomCheckbox
                    containerClass={ProductReturnStyles.checkboxContainer}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={ProductReturnStyles.checkboxLabel}>Mémoriser cette carte</Text>}
                    checked={memorizeCard}
                    onPress={() => setMemorizeCard(!memorizeCard)}
                    size={Constants.CHECKBOX_SIZE_BIG}
                />
            </Box>
        );
    };

    let amSeller = false;
    if (purchaseProduct && purchaseProduct.product.shop && me) {
        if (purchaseProduct.product.shop.owner.id === me.id) {
            amSeller = true;
        }
    }

    let getPage2 = () => {
        return (
            <Box style={{alignItems: "center", maxWidth: 800, margin: "auto"}}>
                {!amSeller && <Text className={ProductReturnStyles.confirmationMessage}>Ta demande de retour est bien prise en compte, tu peux imprimer le bordereau depuis tes commandes, en cliquant sur le bouton "télécharger le bordereau" ou  le retrouver dans ta boite mails.</Text>}
                {!amSeller && <Text className={ProductReturnStyles.confirmationMessage2}>Après impression il te suffira de coller ce bordereau sur ton colis (soigneusement empaqueté) et le déposer au point relais de ton choix</Text>}
                {amSeller && <Text className={ProductReturnStyles.confirmationMessage}>Votre bordereau de retour a bien été édité. prévenez l'acheteur via la messagerie que ce bordereau est disponible dans sa rubrique "mes commandes" afin qu'il puisse renvoyer le produit.</Text>}

                {/*<Box className={ProductReturnStyles.confirmationMessageButtonWrapper}>*/}
                <Grid container justify={"space-around"} className={ProductReturnStyles.confirmationMessageButtonWrapper}>
                    <Button disableElevation variant={"contained"} color={"primary"} onClick={() => history.push("/mes_commandes")} className={ProductReturnStyles.firstButton}>
                        J'ai compris
                    </Button>
                    <Button disableElevation variant={"contained"} color={(purchaseProduct && purchaseProduct.returnParcel) ? "primary" : "secondary"} href={getPdfUrl(purchaseProduct && purchaseProduct.returnParcel ? purchaseProduct.returnParcel.contentUrl : null)} target={'_blank'} disabled={!(purchaseProduct && purchaseProduct.returnParcel)}>
                        {(purchaseProduct && purchaseProduct.returnParcel) ? "Télécharger le bordereau" : "Bordereau en cours de génération"}
                    </Button>
                {/*</Box>*/}
                </Grid>
            </Box>
        )
    };

    let getCart = () => {
        return (
            <Box style={{marginTop: Constants.GET_SIZE(50)}}>
                <Box className={ProductReturnStyles.shoppingCartBlock}>
                    <Box className={ProductReturnStyles.shoppingCartRow}>
                        <Text className={ProductReturnStyles.shoppingCartTotalText}>Frais de retour à votre charge</Text>
                        <Text className={ProductReturnStyles.shoppingCartTotalText}>{purchaseProduct ? getSingleProductDeliveryPrice(purchaseProduct.product, shippingPrices, params, 'chronoPrice', purchaseProduct.quantity) : "N/A"} €</Text>
                    </Box>
                </Box>
                <Box className={ProductReturnStyles.shoppingCartButtonWrapper}>
                    <Button onClick={() => {onClickContinue()}} variant={"contained"} color={"primary"} disableElevation size={"small"}>Payer</Button>
                </Box>
            </Box>
        );
    };

    let onClickContinue = () => {
        if (page === 0) {
            if (!cgv) {
                setError("cgv", {type: "invalid", message: "Vous devez accepter les conditions générales de vente pour continuer"});
            } else {
                submitForm();
            }
        }
    };

    return (
        <Box className={ProductReturnStyles.container}>
            <Grid container className={ProductReturnStyles.row}>
                <Box style={{flex: 2}}>
                    {page === 0 && getPage1()}
                    {page === 1 && getPage2()}
                </Box>
                {page === 0 &&
                    <Box className={ProductReturnStyles.shoppingCartWrapper}>
                        {getCart()}
                    </Box>
                }
            </Grid>
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        cart: state.product.cart,
        purchaseProduct: state.purchase.purchaseProduct,
        me: state.user.me,
        shippingPrices: state.family.shippingPrices,
        params: state.params.params
    }
};

const mapDispatchToProps = dispatch => {
    return {
        returnProduct: (id) => {
            dispatch({type: 'RETURN_PRODUCT_REQUESTED', id})
        },
        fetchPurchaseProduct: (id, hideLoader = false) => {
            dispatch({type: 'FETCH_PURCHASE_PRODUCT_REQUESTED', id, hideLoader})
        },
        fetchMe: (token) => {
            dispatch({type: 'FETCH_ME_REQUESTED', payload: {token}})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
