import React from "react";
import clsx from 'clsx';
import Box from "@material-ui/core/Box"
import LoaderStylesFunc from '../../Style/LoaderStyle'
import CircularProgress from '@material-ui/core/CircularProgress';


export default function Component({visible, general}) {
    let LoaderStyles = LoaderStylesFunc();

    return (
        <Box className={clsx(LoaderStyles.background, {[LoaderStyles.generalLoader]: general})} style={!visible ? {display: "none"} : {}}>
            <Box
                className={clsx(LoaderStyles.spinnerWrapper, {[LoaderStyles.generalLoader]: general})}>
                <CircularProgress color={"primary"}/>
            </Box>
        </Box>
    );
}

