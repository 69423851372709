import React, { useEffect, useRef } from 'react';
import { MAPS_API_URL } from '../../Config/API';
import TextField from "@material-ui/core/TextField";

function loadAsyncScript(src) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}

const AddressAutocomplete = ({ onPlaceSelected, placeholder, name, defaultValue }) => {
    const searchInput = useRef(null);
    const style = {
        marginBottom: 20
    }
    const initMapScript = () => {
        const src = MAPS_API_URL + "&libraries=places";
        return loadAsyncScript(src);
    }

    const initAutocomplete = () => {
        if (!searchInput.current) {
            return;
        }

        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
        autocomplete.setFields(["address_component", "geometry"]);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            const address = extractAddress(place);
            onPlaceSelected(address);
        });
    }

    const extractAddress = (place) => {
        const address = {
            street: "",
            route: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            plain() {
                const city = this.city ? this.city + ", " : "";
                const zip = this.zip ? this.zip + ", " : "";
                const state = this.state ? this.state + ", " : "";
                return city + zip + state + this.country;
            }
        }

        if (!Array.isArray(place?.address_components)) {
            return address;
        }

        place.address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;

            if (types.includes("street_number")) {
                address.street = value;
            }

            if (types.includes("route")) {
                address.route = value;
            }

            if (types.includes("locality")) {
                address.city = value;
            }

            if (types.includes("administrative_area_level_2")) {
                address.state = value;
            }

            if (types.includes("postal_code")) {
                address.zip = value;
            }

            if (types.includes("country")) {
                address.country = value;
            }
        });
        return address;
    }

    useEffect(() => {
        initMapScript().then(() => {
            initAutocomplete();
        });
    }, []);

    return (
        <TextField inputRef={searchInput} type="text" placeholder={placeholder} variant="outlined" name={name} style={style} defaultValue={defaultValue}/>

    );
}

export default AddressAutocomplete;