import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    row: {
        ...GlobalStyle.row,
    },
    title: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        marginBottom: Constants.GET_SIZE(50),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    button: {
        ...GlobalStyle.button,
        marginBottom: Constants.GET_SIZE(50),
        marginTop: Constants.GET_SIZE(37),
        width: Constants.GET_SIZE(225),
        padding: Constants.GET_SIZE(15),
        paddingLeft: Constants.GET_SIZE(20),
        paddingRight: Constants.GET_SIZE(20),
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },
    buttonWhite: {
        ...GlobalStyle.button,
        marginBottom: Constants.GET_SIZE(50),
        marginTop: Constants.GET_SIZE(37),
        width: Constants.GET_SIZE(225),
        padding: Constants.GET_SIZE(15),
        paddingLeft: Constants.GET_SIZE(20),
        paddingRight: Constants.GET_SIZE(20),

        borderWidth: 1,
        backgroundColor: Constants.WHITE,
        borderColor: Constants.PINK,
    },
    buttonWhiteLabel: {
        ...GlobalStyle.buttonLabel,
        color: Constants.PINK,
    },

    contentWrapper: {
        ...GlobalStyle.row,
        marginBottom: Constants.GET_SIZE(50),
        width: "100%"
    },
    subcontentWrapper: {
        flex: 1,
        marginLeft: Constants.GET_SIZE(50),
        marginRight: Constants.GET_SIZE(150)
    },
    descriptionWrapper: {
        width: Constants.GET_SIZE(625),
        marginBottom: Constants.GET_SIZE(50),
    },
    description: {

        textAlign: "center"

    },
    headerWrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: Constants.GET_SIZE(55),
    },
    headerStep: {

        ...GlobalStyle.uppercaseText,
        color: Constants.FONT_GRAY,
        fontWeight: "bold"
    },
    headerStepActive: {
        color: Constants.PINK
    },

    step1Wrapper: {
        flex: 1
    },
    step1Label: {

        marginTop: Constants.GET_SIZE(15),
    },
    step1SubLabel: {
        fontStyle: "italic",

        fontSize: Constants.FONT_SIZE_SMALL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
        }

    },
    step1CheckboxRow: {
        ...GlobalStyle.row
    },
    checkboxLabel: {

        color: Constants.FONT_GRAY
    },
    checkBox: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.WHITE,
        width: Constants.GET_SIZE(300)
    },

    step3Wrapper: {
        ...GlobalStyle.row,
        flex: 1
    },
    step3ColumnWrapper: {
        ...GlobalStyle.column,
        flex: 1
    },
    snInputWrapper: {
        ...GlobalStyle.rowCentered,
    },
    imageWrapper: {
        width: Constants.GET_SIZE(150),
        marginLeft: Constants.GET_SIZE(30),
        marginBottom: Constants.GET_SIZE(50),
        alignItems: "center"

    },
    image: {
        width: Constants.GET_SIZE(150),
        height: Constants.GET_SIZE(150),
        marginBottom: Constants.GET_SIZE(20),
        borderRadius: "50%"
    },
    imageButtonLabel: {

        color: Constants.PINK,
        textDecorationLine: "underline"
    },
    imageButton: {

    },

    pickerWrapper: {
        ...GlobalStyle.rowCentered,
        width: "90%",
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderRadius: Constants.GET_SIZE(10),
        height: Constants.GET_SIZE(58),
        marginBottom: Constants.GET_SIZE(13),
        marginTop: Constants.GET_SIZE(12),
    },
    pickerItem: {

    },
    picker: {

        borderWidth: 0,
        backgroundColor: Constants.WHITE,
        height: 60,
        width: "100%",
        // marginBottom: 20
        // marginRight:  Constants.FONT_SIZE_NORMAL,
        // marginLeft:  Constants.FONT_SIZE_NORMAL,
    },

    infoWrapper: {
        ...GlobalStyle.rowCentered,
        marginTop: Constants.GET_SIZE(39),
        marginBottom: Constants.GET_SIZE(13),
        height: Constants.GET_SIZE(58),
    },
    infoLabel: {
    },
    infoValue: {
    }
}));
