const initialState = {
    purchase: null,
    purchasesList: [],
    purchasesListPagination: {
        first: 1,
        last: 1
    },
    purchaseProduct: null,
    validateError: null,
    validateStatus: "IDLE",
    validateStatusType: -1
};


function reduc(state = initialState, action) {
    let index;
    switch (action.type) {
        case "LOGOUT":
            return Object.assign({}, state, {
                purchase: null,
                purchasesList: [],
                purchasesListPagination: {
                    first: 1,
                    last: 1
                },
                purchaseProduct: null,
                validateError: null
            });
        case "POST_PURCHASE_SUCCEEDED":
            return Object.assign({}, state, {
                purchase: action.purchase
            });
        case "FETCH_PURCHASES_SUCCEEDED":
        case "FETCH_SALES_SUCCEEDED":
            return Object.assign({}, state, {
                purchasesList: action.purchases,
                purchasesListPagination: action.pagination,
            });
        case "RETURN_PRODUCT_SUCCEEDED":
        case "FETCH_PURCHASE_PRODUCT_SUCCEEDED":
            return Object.assign({}, state, {
                purchaseProduct: action.purchaseProduct,
            });
        case "VALIDATE_DELIVERY_SUCCEEDED":
            index = state.purchasesList.findIndex((element) => element.id === action.purchaseId);
            if (index !== -1) {
                let purchase = state.purchasesList[index];
                let purchaseProductIndex = purchase.purchaseProducts.findIndex((element) => element.id === action.purchaseProduct.id);
                if (purchaseProductIndex !== -1) {
                    return Object.assign({}, state, {
                        purchasesList: [
                            ...state.purchasesList.slice(0, index),
                            Object.assign({}, purchase, {
                                purchaseProducts: [
                                    ...purchase.purchaseProducts.slice(0, purchaseProductIndex),
                                    Object.assign({}, purchase.purchaseProducts[purchaseProductIndex], action.purchaseProduct),
                                    ...purchase.purchaseProducts.slice(purchaseProductIndex + 1)
                                ]
                            }),
                            ...state.purchasesList.slice(index + 1)
                        ],
                        validateStatus: "OK",
                        validateStatusType:  action.purchaseProduct.status
                    });
                }
            }
            break;
        case "VALIDATE_DELIVERY_REQUESTED":
            return Object.assign({}, state, {
                validateError: null,
                validateStatus: "ONGOING",
                validateStatusType: -1
            });
        case "VALIDATE_DELIVERY_FAILED":
            return Object.assign({}, state, {
                validateError: {
                    id: action.id,
                    error: action.message
                },
                validateStatus: "ERROR",
                validateStatusType: -1
            });
        case "CREATE_DISPUTE_SUCCEEDED":
            index = state.purchasesList.findIndex((element) => element.id === action.purchase.id);
            if (index !== -1) {
                return Object.assign({}, state, {
                    purchasesList: [
                        ...state.purchasesList.slice(0, index),
                        Object.assign({}, state.purchasesList[index], {
                            disputes: [
                                ...state.purchasesList[index].disputes,
                                action.dispute
                            ]
                        }),
                        ...state.purchasesList.slice(index + 1)
                    ]
                });
            }
            break;


    }

    return state
}

export default reduc
