import React from "react";
import {useForm} from "react-hook-form";
import useApiForm from "./Hooks/useApiForm";
import useSave from "./Hooks/useSave";
import useFetch from "./Hooks/useFetch";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Form from "./Components/Form";
import FontText from "../Screens/Component/FontText";
import * as Constants from "../Style/Constants"

function Component({entityName, id, onSave, onError, onBack}) {
    const { watch, register, handleSubmit, setValue, errors, control, reset } = useForm();
    register('id');

    const { status, data, error, isFetching } = useApiForm(entityName);
    const { save, isSaving } = useSave(entityName, () => {if (onSave) {onSave();}}, (data) => {if (onError) {onError(data);} });
    const { data: entity, hasFetchedOnce } = useFetch(entityName, id);

    const commonFields = {register, errors, control, setValue, watch};

    React.useEffect(() => {
        if (entity && data) {
            reset(entity);
        }
    }, [entity, data]);

    if (!data) {
        return "Loading...";
    }

    {/* TODO : Hack for stripe account status. Find better way */}
    let stripeStatus = null;
    let stripeColor = null;
    if (hasFetchedOnce && entity) {
        if (entity.stripeAccountStatus.status === 'no_stripe_account') {
            stripeStatus = 'Veuillez remplir tous les champs pour valider le compte Stripe';
            stripeColor = Constants.PINK;
        } else if (entity.stripeAccountStatus.status === 'stripe_account_ok') {
            stripeStatus = 'Le compte Stripe est valide et prêt à recevoir des virements.';
            stripeColor = 'black';
        } else {
            stripeStatus = 'Erreur : ' + entity.stripeAccountStatus.status;
            stripeColor = Constants.PINK;
        }
    }

    return <form onSubmit={handleSubmit(save)}>
        {/* TODO : Hack for stripe account status. Find better way */}
        {stripeStatus && <FontText style={{color: stripeColor}}>{ stripeStatus }</FontText>}

        {hasFetchedOnce && <Form commonFields={commonFields} data={data} />}
        <Grid item container style={{marginTop: 20}}>
            <Button size={"small"} onClick={handleSubmit(save)} color={"primary"} variant={"contained"} disableElevation style={{marginRight: 20}}>
                { isSaving ? "Chargement..." : "Enregistrer" }
            </Button>
            {onBack && <Button size={"small"} onClick={onBack} color={"primary"} variant={"outlined"} disableElevation style={{marginRight: 20}}>
                Retour
            </Button>}
        </Grid>
    </form>
}

export default Component
