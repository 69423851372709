import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    container: {
        backgroundColor: Constants.WHITE,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: Constants.GET_SIZE(50),
    },
    contactHeaderWrapper: {
        position: "relative"
    },
    contactHeaderImage: {
        width: "100%",
        height: Constants.GET_SIZE(300)
    },
    contactHeaderText: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        position: "absolute",
        fontWeight: "bolder",
        top: Constants.GET_SIZE(188),
        left: Constants.GET_SIZE(75),

        fontSize: Constants.FONT_SIZE_BIGGER,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
        }
    },
    contactHeaderPinkRectangle: {
        position: "absolute",
        backgroundColor: Constants.PINK,
        bottom: 0,
        left: Constants.GET_SIZE(75),
        width: Constants.GET_SIZE(205),
        height: Constants.GET_SIZE(15)
    },
    nameText: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    dateText: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(25),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    addressText: {

        ...GlobalStyle.uppercaseText,
        color: Constants.PINK
    },

    shopsWrapper: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        maxWidth: 1440,
        width: "100%",
        margin: "auto",
        paddingTop: Constants.GET_SIZE(50),
        paddingLeft: Constants.GET_SIZE(96),
        paddingRight: Constants.GET_SIZE(96),
        paddingBottom: Constants.GET_SIZE(50),
    },
    shopWrapper: {
        width: 350,
        marginBottom: Constants.GET_SIZE(32),
        [theme.breakpoints.down('xs')]: {
            width: 300
        }
    }
}));