import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import {
    FetchCriterionBrand,
    FetchCriterionColor,
    FetchCriterionHeel,
    FetchCriterionMaterial,
    FetchCriterionSize,
    FetchCriterionState,
    FetchCriterionStyle,
    SearchCriterionBrand, UpdateCriterionBrand
} from '../Endpoint/Criteria'
import { handleError } from "../../Utils/SagaUtils";

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* fetchAllCriteria(action) {
    try {
        const params = {
            brands: action.brands ? action.brands : [],
            category: action.category ? action.category : null,
            deliveries: action.deliveries ? action.deliveries : [],
            colors: action.colors ? action.colors : null,
            family: action.family ? action.family : null,
            families: action.families ? action.families : [],
            materials: action.materials ? action.materials : null,
            price: action.price ? action.price : null,
            shopTypes: action.shopTypes ? action.shopTypes : [],
            sizes: action.sizes ? action.sizes : null,
            states: action.states ? action.states : null,
        }

        const colors = yield call(FetchCriterionColor, params);
        const heels = yield call(FetchCriterionHeel, params);
        const materials = yield call(FetchCriterionMaterial, params);
        const sizes = yield call(FetchCriterionSize, params);
        const states = yield call(FetchCriterionState, params);
        const styles = yield call(FetchCriterionStyle, params);
        const brands = yield call(FetchCriterionBrand, params);

        const disp = {type: "FETCH_CRITERIA_SUCCEEDED"};
        disp["brands"] = brands.data;
        disp["colors"] = colors.data;
        disp["heels"] = heels.data;
        disp["materials"] = materials.data;
        disp["sizes"] = sizes.data;
        disp["states"] = states.data;
        disp["styles"] = styles.data;
        yield put(disp);
    } catch (e) {
        yield put(handleError("FETCH_CRITERIA_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* searchBrands(action) {
    try {
        const result = yield call(SearchCriterionBrand, action.search, action.itemsPerPage, action.page, action.sort, action.sortOrder);

        yield put({type: "SEARCH_BRANDS_SUCCEEDED", brands: result.data["hydra:member"], pagination: result.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("SEARCH_BRANDS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* updateBrand(action) {
    try {
        const result = yield call(UpdateCriterionBrand, action.brand);

        yield put({type: "UPDATE_BRAND_SUCCEEDED", brand: result.data});
    } catch (e) {
        yield put(handleError("UPDATE_BRAND_SUCCEEDED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* mySaga() {
    yield takeLatest("FETCH_CRITERIA_REQUESTED", fetchAllCriteria);
    yield takeLatest("SEARCH_BRANDS_REQUESTED", searchBrands);
    yield takeLatest("UPDATE_BRAND_REQUESTED", updateBrand);
}

export default mySaga;