import { call, put, takeEvery, cancelled } from 'redux-saga/effects'
import { FetchMondialRelayPoints, FetchChronoRelayPoints } from '../Endpoint/RelayPoints'
import { handleError } from "../../Utils/SagaUtils";

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* fetchRelayPoints(action) {
    try {
        let relayPoints;
        if (action.relayType === "Chronopost") {
            relayPoints = yield call(FetchChronoRelayPoints, action.city, action.zipcode);
            yield put({type: "FETCH_RELAY_POINTS_SUCCEEDED", relayPoints: relayPoints.data, relayType: "Chronopost"});
        } else {
            relayPoints = yield call(FetchMondialRelayPoints, action.city, action.zipcode);
            yield put({type: "FETCH_RELAY_POINTS_SUCCEEDED", relayPoints: relayPoints.data, relayType: "MondialRelay"});
        }
    } catch (e) {
        yield put(handleError("FETCH_RELAY_POINTS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* mySaga() {
    yield takeEvery("FETCH_RELAY_POINTS_REQUESTED", fetchRelayPoints);
}

export default mySaga;