import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle";


export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,

        button: {
            ...GlobalStyle.button,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 20
            }
        },
        headerWrapper: {
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: Constants.GET_SIZE(55),
        },
        headerStep: {

            ...GlobalStyle.uppercaseText,
            color: Constants.FONT_GRAY,
            fontWeight: "bold"
        },
        headerStepActive: {
            color: Constants.PINK
        },

        step1Wrapper: {
            flex: 1
        },
        step1Label: {

            marginTop: Constants.GET_SIZE(15),
        },
        step1SubLabel: {
            fontStyle: "italic",

            fontSize: Constants.FONT_SIZE_SMALL,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
            }

        },
        step1CheckboxRow: {
            ...GlobalStyle.row
        },
        checkboxLabel: {

            color: Constants.FONT_GRAY
        },
        checkBox: {
            backgroundColor: Constants.WHITE,
            borderColor: Constants.WHITE,
            width: Constants.GET_SIZE(300)
        },

        checkBoxLarge: {
            backgroundColor: Constants.WHITE,
            borderColor: Constants.WHITE,
            marginBottom: Constants.GET_SIZE(15),
            marginTop: Constants.GET_SIZE(15)
        },

        step3Wrapper: {
            ...GlobalStyle.row,
            flex: 1
        },
        step3ColumnWrapper: {
            ...GlobalStyle.column,
            flex: 1
        },
        snInputWrapper: {
            ...GlobalStyle.rowCentered,
        },
        imageWrapper: {
            width: Constants.GET_SIZE(150),
            marginLeft: Constants.GET_SIZE(30),
            marginBottom: Constants.GET_SIZE(50),
            alignItems: "center"

        },
        image: {
            width: Constants.GET_SIZE(150),
            height: Constants.GET_SIZE(150),
            marginBottom: Constants.GET_SIZE(20),
            borderRadius: "50%"
        },
        imageButtonLabel: {

            color: Constants.PINK,
            textDecorationLine: "underline"
        },
        imageButton: {},

        step5ButtonWrapper: {
            width: "100%",
            alignItems: "center"
        },

        totalAmountWrapper: {
            ...GlobalStyle.rowCentered
        },
        totalAmountLabel: {

            width: "max-content"
        },
        totalAmountValue: {

            color: Constants.PINK
        },
        transferRequestInput: {
            marginBottom: 0,
        },

        bottomBlockHeader: {
            ...GlobalStyle.rowCentered,
            justifyContent: "space-between",
            marginBottom: Constants.GET_SIZE(30),
        },
        bottomBlockTitle: {
            fontSize: Constants.FONT_SIZE_BIG,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_BIG_MOBILE,
            }
        },
        stripeStatusErrorText: {

            color: Constants.COLOR_ERROR,
            marginBottom: Constants.GET_SIZE(30),
        }
    }
});
