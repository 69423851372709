import axios from 'axios';
import {API_URL} from '../../Config/API'
import {getApiUrl} from "../../Utils/QueryUtils";

export const ApiFetchFamilies = () => {
    return axios.get(API_URL + 'api/families.json');
};

export const FetchShippingPrices = () => {
    return axios.get(getApiUrl('shipping_prices'), {});
};
