const initialState = {
    livesList: [],
    nextLives: [],
    pagination: {
        first:1,
        last:1
    }
};


function reduc(state = initialState, action) {
    let first;
    let last;
    let index;
    let result = {};
    switch (action.type) {
        case "FETCH_MY_LIVES_REQUESTED":
            if (action.keepDirty) {
                return state;
            } else {
                return Object.assign({}, state, {
                    livesList: [],
                    pagination: {
                        first: 1,
                        last: 1,
                    }
                });
            }
        case "FETCH_MY_LIVES_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                livesList: action.lives,
                pagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1,
                }
            });
        case "FETCH_NEXT_LIVES_SUCCEEDED":
            return Object.assign({}, state, {
                nextLives: action.lives,
            });

        // case "LOGOUT":
        //     return Object.assign({}, state, {
        //         livesList: [],
        //         nextLives: [],
        //         pagination: {
        //             first:1,
        //             last:1
        //         }
        //     });
    }

    return state
}

export default reduc
