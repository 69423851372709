import React from 'react';
import { connect } from 'react-redux'

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";


import Text from "../../Component/FontText";

import ConfirmationModalStylesFunc from "../../../Style/ConfirmationModalStyle"


function Component ({visible, setVisible, message, onConfirm, onCancel}) {
    let ConfirmationModalStyles = ConfirmationModalStylesFunc();
    let _renderButtons = () => {
        if (!onCancel) {
            return (
                <Grid container justify={"center"}>
                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={onConfirm}>
                        J'ai compris
                    </Button>
                </Grid>
            );
        } else {
            return (
                <Grid container justify={"space-between"}>
                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={onConfirm}>
                        Confirmer
                    </Button>
                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={onCancel}>
                        Annuler
                    </Button>
                </Grid>
            );
        }
    }

    let _renderModalContent = () => (
        <Box className={ConfirmationModalStyles.modalContent}>
            <Text className={ConfirmationModalStyles.modalTitle}>{message}</Text>

            {_renderButtons()}
        </Box>
    );

    return (
        <Dialog open={visible} fullWidth>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        mondialRelayPoints: state.relayPoints.mondialRelayPoints,
        chronoRelayPoints: state.relayPoints.chronoRelayPoints,
        addresses: state.relayPoints.addresses
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchRelayPoints: (relayType, city, zipcode) => {
            dispatch({type: 'FETCH_RELAY_POINTS_REQUESTED', relayType, city, zipcode})
        },
        searchAddresses: (zipcode) => {
            dispatch({type: 'SEARCH_ADDRESS_REQUESTED', zipcode})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;