import React from "react";
import clsx from "clsx";
import axios from "axios";
import Moment from "moment";
import {connect, useDispatch} from 'react-redux'
import { useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { useLiveList} from "../../API/Hook/Live";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

import Text from "../Component/FontText";
import LiveSquare from "./Component/LiveSquare";
import StylesFunc from '../../Style/LiveListStyle'
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";
import LiveListExplanationModal from "./Component/LiveListExplanationModal";

const TABS = [{tab: 'en_cours', label: 'En cours'}, {tab: 'a_venir', label: 'A venir'}];

function Component({history, hideLiveListPopup, navigation}) {
    const Styles = StylesFunc();

    let { tab } = useParams();
    const dispatch = useDispatch();

    React.useEffect(() => {
        window.scrollTo(0, 0);
        if (tab === 'en_cours') {
            document.title += " - Les lives en cours";
        } else if (tab === 'a_venir') {
            document.title += " - Les lives à venir";
        } else {
            document.title += " - Les lives";
        }
    }, []);

    let [date, setDate] = React.useState(-1);
    let [nextDate, setNextDate] = React.useState(null);
    let [allDates, setAllDates] = React.useState([]);
    let [lives, isLoading] = useLiveList(tab, date > -1 && allDates.length > date ? allDates[date] : null);

    const [listPopupVisibility, setListPopupVisibility] = React.useState(false);

    if (allDates.length < 1) {
        const tmpArray = [];
        for (let i = 0; i < 7; i++) {
            tmpArray.push(Moment().add(i, 'days'));
        }
        setAllDates(tmpArray);
    }

    React.useEffect(() => {
        if (!hideLiveListPopup) {
            setListPopupVisibility(true);
        }
        if (!nextDate) {
            dispatch({type: 'START_LOADING_REQUESTED'});
            axios.get(
                getApiUrl('nextLiveDate'),
                {headers: setDefaultHeaders()}
            ).then((response) => {
                if (response && response.data && response.data.status === 200) {
                    setNextDate(response.data.date);
                }
            }).catch((error) => {
                console.error('Can\'t fetch next live date: ', error);
            }).finally(() => {
                dispatch({type: 'START_LOADING_SUCCEEDED'})
            });
        }
    }, []);


    React.useEffect(() => {
        if (nextDate) {
            const dayIndex = Moment(nextDate, 'YYYY-MM-DD').diff(Moment().startOf('day'), 'days');
            setDate(dayIndex);
        }
    }, [nextDate]);

    let handleClickTab = (event, tab) => {
        event.preventDefault();
        history.push('/lives/' + tab);
    };

    let _getTabs = () => {
        return <Box className={Styles.tabsWrapper}>
            {TABS.map(tabInfo =>
                <Button variant={tab === tabInfo.tab ? "contained" : "outlined"} color={"primary"} disableElevation href={'/lives/' + tabInfo.tab} size={"small"} onClick={(e) => handleClickTab(e, tabInfo.tab)}>
                    {tabInfo.label}
                </Button>
            )}
        </Box>
    }

    let _getDatePicker = () => {
        if (tab === 'a_venir' && date > -1) {
            return <Grid container className={Styles.dateWrapper} justify={'center'}>
                <Grid item>
                    <Button size={"small"} onClick={() => setDate(date - 1)} className={clsx(Styles.caret, {[Styles.hidden]: date === 0})}>{'<'}</Button>
                </Grid>
                <Select
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    IconComponent={() => null}
                    disableUnderline
                    classes={{select: Styles.datePickerSelect}}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}>
                    {allDates.map((date, key) => <MenuItem classes={{root: Styles.datePickerItemRoot}} value={key}>{key === 0 ? 'Aujourd\'hui' : key === 1 ? 'Demain' : date.format('dddd D MMMM')}</MenuItem>)}
                </Select>
                <Grid item>
                    <Button size={"small"} onClick={() => setDate(date + 1)} className={clsx(Styles.caret, {[Styles.hidden]: date === allDates.length - 1})}>{'>'}</Button>
                </Grid>
            </Grid>
        } else {
            return null;
        }
    }

    let nbLives = 0;
    let _getLivesAtHour = (hour, lives) => {
        let now = new Date();
        if (date === 0) {
            if (hour + 2 <= now.getHours()) {
                return null;
            }
        }
        if (lives.length > 0) {
            nbLives += lives.length;
            return <Grid container item xs={12}>
                {tab !== 'en_cours' && <Grid item container xs={12} alignItems={"center"} className={Styles.hourWrapper}>
                    <Divider className={Styles.hourDivider} />
                    <Text className={Styles.hour}>{hour}H</Text>
                    <Divider className={Styles.hourDivider} />
                </Grid>}
                <Grid container item className={Styles.liveList} justify={"space-between"} xs={12}>
                    {lives.map(live => <LiveSquare live={live}/>)}
                    <Box className={Styles.placeholder}/>
                    <Box className={Styles.placeholder}/>
                    <Box className={Styles.placeholder}/>
                    <Box className={Styles.placeholder}/>
                </Grid>
            </Grid>
        }
    }


    return (
        <Box className={Styles.wrapper}>
            <Text className={Styles.pageTitle}>
                Lives
            </Text>
            <Button href={"/inscription_live"} variant={"contained"} color={"primary"} disableElevation size={"small"} style={{marginBottom: 10}}>Créer mon live</Button>
            { _getTabs() }
            { _getDatePicker() }
            <Grid container>
                {lives.map((livesAtHour, index) => _getLivesAtHour(index, livesAtHour))}
                {!isLoading && nbLives === 0 && <Text style={{width: '100%', textAlign: 'center', marginTop: 20}}>Il n'y a aucun live {tab === 'en_cours' ? "en cours pour le moment" : (date > -1 ? "prévu à cette date" : "prévu dans les prochains jours")}</Text>}
            </Grid>
            <LiveListExplanationModal
                visible={listPopupVisibility}
                setVisible={setListPopupVisibility} />
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        hideLiveListPopup: state.login.hideLiveListPopup,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};


const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
