import React from "react";
import TextField from "./TextField";
import SelectField from "./SelectField";
import CheckboxField from "./CheckboxField";
import ListField from "./ListField";
import PictureField from "./PictureField";
import FileField from "./FileField";
import DateField from "./DateField";

function Component({data, commonFields, formName, index}) {
    let prefix = '';
    if (formName) {
        prefix = formName + '[' + index + '].';
        commonFields.register({name: prefix + 'id'});
        if (commonFields.arrayField && commonFields.arrayField.id) {
            commonFields.setValue(prefix + 'id', commonFields.arrayField.id);
        }
    }

    const _getField = (field) => {
        if (field.type === 'text') {
            return <TextField
                key={prefix + field.name}
                {...commonFields}
                label={field.label}
                name={field.name}
                namePrefix={prefix}
                required={field.required}/>
        } else if (field.type === 'textarea') {
            return <TextField
                key={prefix + field.name}
                {...commonFields}
                label={field.label}
                name={field.name}
                namePrefix={prefix}
                required={field.required}
                multiline />
        } else if (field.type === 'choice') {
            return <SelectField
                key={prefix + field.name}
                {...commonFields}
                label={field.label}
                name={field.name}
                namePrefix={prefix}
                choices={field.options.choices}
                required={field.required}
            />;
        } else if (field.type === 'bool') {
            return <CheckboxField
                key={prefix + field.name}
                {...commonFields}
                label={field.label}
                name={field.name}
                namePrefix={prefix}
                required={field.required}
            />;
        } else if (field.type === 'picture') {
            return <PictureField
                key={prefix + field.name}
                {...commonFields}
                label={field.label}
                name={field.name}
                namePrefix={prefix}
                required={field.required}
            />;
        } else if (field.type === 'subform') {
            return <ListField
                key={prefix + field.name}
                {...commonFields}
                label={field.label}
                name={field.name}
                namePrefix={prefix}
                form={field.form}
                options={field.options}
            />;
        } else if (field.type === 'file') {
            return <FileField
                key={prefix + field.name}
                {...commonFields}
                label={field.label}
                name={field.name}
                namePrefix={prefix}
                required={field.required}/>
        } else if (field.type === 'date') {
            return <DateField
                key={prefix + field.name}
                {...commonFields}
                label={field.label}
                name={field.name}
                namePrefix={prefix}
                required={field.required}/>
        } else {
            return null;
        }
    }

    if (!data) {
        return "Loading...";
    }


    return <div>
        { data.map(field => _getField(field)) }
    </div>;
}

export default Component
