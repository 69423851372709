import React from "react";

import Link from "@material-ui/core/Link";

import StaticCommon from "./StaticCommon";
import Banner from "../../../Assets/Images/banners/MeetingVS.jpg"


function Component({title}) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "A propos de nous",
        blocks: [
            {
                title: "A la rencontre de Violette Sauvage",
                content: [
                    { text: "Chez Violette Sauvage, nous défendons corps et âme le recyclage glamour, les jeunes talents et la consommation réfléchie depuis octobre 2010. Notre expérience fait désormais de Violette Sauvage le plus grand organisateur de vide-dressing d’Europe." },
                    { text: "Chacun de nos vide-dressings rassemble des centaines de vendeurs et des milliers de visiteurs. Mais comme tout le monde, nous ne sommes pas nés grands. Nous avons commencé petits, très petits, dans un simple appartement parisien pour ne rien vous cacher." },
                    { text: "Mais rapidement nos évènements ont pris de l’ampleur, jusqu’à investir pour la première fois en 2014 le Carreau du Temple de Paris ou le Pavillon Baltard en 2017 ! Bien évidemment, après Paris, la Team Sauvage a eu l’envie de voguer vers de nouveaux horizons : Lille d’abord, puis Lyon, Nantes et Bordeaux." },
                    { text: ["Nos évènements physiques remportent un tel succès auprès de notre communauté que nous avons décidé de prolonger l’aventure Sauvage sur internet et de créer une nouvelle expérience de shopping 2.0 grâce à la ", <strong>Marketplace Violette Sauvage.</strong>] },
                    { text: "Si nous en sommes là aujourd’hui c’est grâce à vous, à votre bonne humeur, à vos sourires et à vos conseils. C’est donc tout naturellement que nous vous remercions et continuerons à tout donner pour pouvoir vous offrir le meilleur." },
                ]
            },
            {
                title: "Nos engagements",
                content: [
                    { text: ["Chez Violette Sauvage nous avons nos convictions, des valeurs dont nous sommes fiers. Cela commence par le respect de notre planète et des ", <strong>écosystèmes, du mieux vivre ensemble.</strong>] },
                    { text: "Nous sommes convaincus qu’en choisissant de donner une deuxième vie à nos vêtements nous faisons un geste considérable pour limiter notre impact sur l’environnement. Engagés depuis toujours sur le plan écologique et dans la création de nouvelles alternatives de consommations, Violette Sauvage est aujourd’hui fière de soutenir des créateurs indépendants et fairtrade utilisant des matières et des processus de fabrication respectueux de l’environnement." },
                    { text: "Derrière notre combat contre le gaspillage, de quelque nature qu’il soit, nous soutenons également fièrement la cause animale. Nous sommes intimement persuadés que la mode n’a pas besoin d’exploiter les animaux pour rester tendance. C’est pourquoi nous avons décidé d’interdire la vente de fourrure véritable pendant nos vide-dressings et d’en faire de même sur la Marketplace." },
                    { text: "C’est dans le respect de nos valeurs que nous souhaitons avancer et nous espérons pouvoir compter sur vous pour soutenir ces causes qui nous sont chères. Le succès se partage et se construit ensemble, alors rejoignez l’aventure !" },
                ]
            },

        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"A propos de Violette Sauvage, le meilleur vide dressing en ligne"}
                      metaDesc={"Violette Sauvage est le plus grand organisateur de vide-dressing d’Europe ! Découvrez leurs valeurs et leurs engagements."}
                      arianeCurrentName={"A propos"} />
    );
}

export default Component
