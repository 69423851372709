import React from "react";
import {useParams, withRouter} from "react-router-dom";

import Base from "./Base"
import Text from "../Component/FontText";

import Box from "@material-ui/core/Box";

import APIForm from "../../APIForm";
import {useSnackbar} from "notistack";


function Component({history}) {
    const { enqueueSnackbar } = useSnackbar();
    let params = useParams();
    let id = params.id;

    let onSave = () => {
        enqueueSnackbar('Association bien modifiée', {variant: 'success'});
        goBackToList();
    }

    let onError = (error) => {
        enqueueSnackbar(error, {variant: 'error'});
    }

    let goBackToList = () => {
        history.push("/admin/associations");
    }

    return <Base
        title={"Modification de l'assocation"}
        currentScreen={"ASSOCIATION_LIST"}
        content={
            <Box style={{width: "95%"}}>
                <APIForm entityName={"association"} id={id} onSave={onSave} onError={data => onError(data['hydra:description'])} onBack={goBackToList}/>
            </Box>
        }
    />
}

export default withRouter(Component)
