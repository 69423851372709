import React from "react";

import Typography from "@material-ui/core/Typography";

function Component(props) {

    return (
        <Typography {...props} style={Object.assign({}, props.style, {fontFamily: 'Montserrat-Medium'})}>{props.children}</Typography>
    );
}

export default Component;
