import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import Email from "@material-ui/icons/Email";

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/AlertsStyle"
import * as Constants from "../../Style/Constants"
import Paginator from "../Front/Component/Paginator";
import Image from "react-native-web/dist/exports/Image";
import {getImageUrl} from "../../Utils/ImageUtils";
import TextInputIcon from "../Front/Component/TextInputIcon";


function Component({history, navigation, token, user, shops, pagination, subscribeToShop, fetchSubscribedShops}) {
    let Styles = StylesFunc();
    let [page, setPage] = React.useState(1);

    React.useEffect(() => {
        fetchSubscribedShops(page, 5);
    }, [token, page]);

    React.useEffect(() => {
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Mes boutiques suivies</Text>

            <Box className={Styles.contentWrapper}>
                <Hidden xsDown>
                    <LeftMenu currentPage={"ALERTS"} />
                </Hidden>
                <Box className={Styles.shopsWrapper}>
                    {shops.map(shop =>
                        <Grid container className={Styles.shopWrapper}>
                            <Grid item container xs={12} sm>
                                <Link href={"/shop/" + shop.id}>
                                    <Image source={getImageUrl(shop.picture ? shop.picture.contentUrl : null, "medium")} className={Styles.shopImage} />
                                </Link>
                                <Grid xs item container alignItems={"center"} className={Styles.shopTextGrid}>
                                    <Link href={"/shop/" + shop.id}>
                                        <Box className={Styles.shopTextWrapper}>
                                            <Text className={Styles.shopName}>{shop.name}</Text>
                                            <Text className={Styles.shopNbProducts}>{shop.nbProducts} produits mis en vente</Text>
                                        </Box>
                                    </Link>
                                </Grid>
                                <Grid xs={1} item container alignItems={"center"}>
                                    <Email style={{cursor: "pointer", fontSize: Constants.FONT_SIZE_BIGGEST, color:Constants.PINK}} onClick={(event) => {event.stopPropagation(); history.push("/message/nouveau", {shopId: shop.id})}} />
                                </Grid>
                            </Grid>
                            <Button variant={"contained"} color={"primary"} disableElevation onClick={() => subscribeToShop(shop, page, 5)} className={Styles.button}>
                                Se désabonner
                            </Button>
                        </Grid>
                    )}
                </Box>
            </Box>
            {pagination && <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE}/>}
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        shops: state.shop.subscribedShopsList,
        pagination: state.shop.subscribedShopsListPagination,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        subscribeToShop: (shop, page, itemsPerPage) => {
            dispatch({type: 'SUBSCRIBE_TO_SHOP_REQUESTED', id: shop.id, subscribe: !shop.isSubscribed, page, itemsPerPage})
        },
        fetchSubscribedShops: (page, itemsPerPage) => {
            dispatch({type: 'FETCH_SUBSCRIBED_SHOPS_REQUESTED', page, itemsPerPage})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
