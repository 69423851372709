import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle"
import Box from "@material-ui/core/Box/Box";
import React from "react";
import List from "@material-ui/core/List/List";

export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        headerWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 55,
            [theme.breakpoints.down('xs')]: {
                justifyContent: "center",
                marginBottom: 20,
            }
        },
        headerStep: {
            ...GlobalStyle.uppercaseText,
            color: Constants.LIGHT_GRAY,
            fontWeight: "bold",
            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
                display: "none"
            }
        },
        headerStepActive: {
            color: Constants.PINK,
            display: "block"
        },

        grayBlock: {
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            paddingTop: 25,
            paddingBottom: 25,
            paddingRight: 50,
            paddingLeft: 50
        },

        section: {
            marginBottom: 30
        },
        sectionTitle: {
            fontSize: Constants.FONT_SIZE_NORMAL,
            textTransform: 'uppercase',
            marginBottom: 10,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
            }
        },
        sectionDescription: {
            fontSize: Constants.FONT_SIZE_NORMAL,
            marginBottom: 10,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
            }
        },
        paymentTitle: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            textTransform: "uppercase",
            marginBottom: 20,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            }
        },
        paymentText: {
            fontSize: Constants.FONT_SIZE_NORMAL,
            marginBottom: 10,
            fontWeight: "bold",
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
            }
        },
        promoPaymentText: {
            marginBottom: 0
        },
        promoWrapper: {
            marginBottom: 10
        },
        startAtPicker: {
            marginBottom: 10,
            backgroundColor: Constants.WHITE
        },
        slotColumn1: {
            paddingRight: 5,
            boxSizing: "border-box",
            [theme.breakpoints.down('xs')]: {
                paddingRight: 0
            }
        },
        slotColumn2: {
            paddingLeft: 5,
            boxSizing: "border-box",
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0
            }
        },
        slotButton: {
            marginBottom: 10,
            width: "100%",
            borderRadius: 5
        },
        slotButtonUnselected: {
            backgroundColor: Constants.WHITE,
            borderColor: Constants.WHITE,
            borderStyle: "solid",
            borderWidth: 1
        },
        productWrapper: {
            marginLeft: 0,
            marginRight: 0,
        },
        productWrapperWrapper: {
            marginLeft: 0,
            marginRight: 10,
        },


        wrapper: {
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            padding: 50,
            paddingLeft: 75,
            paddingRight: 30,
            [theme.breakpoints.down('xs')]: {
                padding: 10
            }
        },
        checkboxContainer: {
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            borderWidth: 0,
            padding: 0,
            margin: 0
        },
        knowMoreText: {
            marginTop: 10,
            marginBottom: 20,
            marginLeft: 10,
        },
        stripeFieldWrapper: {
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            marginBottom: Constants.GET_SIZE(10),
            marginTop: Constants.GET_SIZE(10),
            justifyContent: "center",
            backgroundColor: Constants.WHITE,
            padding: Constants.GET_SIZE(10),
            marginLeft: Constants.GET_SIZE(10),
        },
        promotionCodeInput: {
            backgroundColor: "white",
            maxWidth: 200,
            height: 30,
            paddingRight: 5,
            boxSizing: "border-box",
            [theme.breakpoints.down('xs')]: {
                maxWidth: '100%',
                width: '100%'
            }
        },
        promotionCodeIcon: {
            color: Constants.PINK
        }
    }
});
