import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import Text from '../../Component/FontText';

import { useDispatch } from 'react-redux';
import TextInputIcon from '../../Front/Component/TextInputIcon';
import { useForm, Controller } from 'react-hook-form';

import DisputeModalStylesFunc from '../../../Style/MiddleOffice/DisputeModalStyle';

import ConfirmationModal from '../../Front/Component/ConfirmationModal';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import { ErrorMessage } from '@hookform/error-message';

export default function Component ({ visible, setVisible, purchase, shop, product }) {
    let DisputeModalStyles = DisputeModalStylesFunc();
    let [confirmationModalVisible, setConfirmationModalVisible] = React.useState(false);
    const { handleSubmit, register, errors, control, reset } = useForm();

    const dispatch = useDispatch();

    const createDispute = useCallback((text, purchase, shop, product) => {
        dispatch({ type: 'CREATE_DISPUTE_REQUESTED', text, purchase, shop, product });
    }, []);

    const handleClickOk = useCallback((data) => {
        createDispute(data.text, purchase, shop, data.product || product);
        setVisible(false);
        reset({ product: '' });
        setConfirmationModalVisible(true);
    }, [createDispute, reset, setConfirmationModalVisible, setVisible, shop]);

    let _renderModalContent = () => (
        <Box className={DisputeModalStyles.modalContent}>
            <Text className={DisputeModalStyles.modalTitle}>Déclarer un litige</Text>
            {product ?
                <Text className={DisputeModalStyles.productTitle}>Pour le produit <strong>{product.name}</strong></Text> :
                <FormControl className={DisputeModalStyles.pickerWrapper} error={errors['product']}>
                    <InputLabel id="dispute-product-input-label" className={DisputeModalStyles.pickerLabel}>Article*</InputLabel>
                    <Controller
                        name="product"
                        control={control}
                        defaultValue={product || ''}
                        rules={{ validate: (value) => value !== '' || 'Ce champ est obligatoire' }}
                        as={
                            <Select
                                labelId="dispute-product-input-label"
                                variant="outlined"
                                className={clsx(DisputeModalStyles.picker)}
                                disabled={product}
                            >
                                <MenuItem value="">Choisis un article</MenuItem>
                                {purchase.purchaseProducts.map(purchaseProduct => <MenuItem value={purchaseProduct.product}>{purchaseProduct.product.name}</MenuItem>)}
                            </Select>
                        }
                    />
                    <ErrorMessage name="product" errors={errors} render={({ message }) => <Text color={'error'}>{message}</Text>}/>
                </FormControl>}

            <TextInputIcon
                errors={errors}
                register={register}
                placeholder={'Entre ta déclaration'}
                multiline
                rules={{
                    required: 'Ce champ est obligatoire'
                }}
                name={'text'}
            />
            <Grid container justify={'space-between'} style={{ width: '100%' }}>
                <Button disableElevation size={'small'} variant={'contained'} color={'primary'} onClick={handleSubmit(handleClickOk)} className={DisputeModalStyles.button}>
                    Enregistrer
                </Button>
                <Button disableElevation size={'small'} variant={'contained'} color={'primary'} onClick={() => setVisible(false)}>
                    Annuler
                </Button>
            </Grid>
        </Box>
    );

    return (
        <Box>
            <Dialog open={visible} fullWidth>
                {_renderModalContent()}
            </Dialog>
            <ConfirmationModal
                visible={confirmationModalVisible}
                setVisible={setConfirmationModalVisible}
                message={'Votre litige va être traité par l\'équipe Violette Sauvage, nous vous ferons un retour par mail dans les plus brefs délais.'}
                onConfirm={() => {setConfirmationModalVisible(false);}}/>
        </Box>
    );
}
