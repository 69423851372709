import React from 'react';
import { Image } from "react-native";

import Box from "@material-ui/core/Box";

import { TouchableOpacity } from 'react-native-web';
import Text from "../../Component/FontText";
import Modal from "modal-enhanced-react-native-web"

import { connect } from 'react-redux'
import {getImageUrl} from "../../../Utils/ImageUtils";

import DisputeModalStylesFunc from "../../../Style/MiddleOffice/DisputeModalStyle"
import * as Constants from "../../../Style/Constants"


function Component ({visible, setVisible, picture}) {
    let DisputeModalStyles = DisputeModalStylesFunc();
    let _renderModalContent = () => (
        <Box className={DisputeModalStyles.modalContent}>
            <Image source={picture && picture.id === -1 ? picture.dataURI : picture ? getImageUrl(picture.contentUrl, "real") : null} style={{width: Constants.GET_SIZE(350), height: Constants.GET_SIZE(500)}}/>

            <Box style={{width: "100%", alignItems: "center", marginTop: 10, flexDirection: "row", justifyContent: "space-around"}}>
                <TouchableOpacity onPress={() => setVisible(false)} className={DisputeModalStyles.button}>
                    <Text className={DisputeModalStyles.buttonLabel}>Fermer</Text>
                </TouchableOpacity>
            </Box>
        </Box>
    );

    return (
        <Modal isVisible={visible}>
            {_renderModalContent()}
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;