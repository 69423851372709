import { createTheme } from '@material-ui/core/styles';
import * as Constants from '../Style/Constants'

// Typography variant use (as we cannot create new one or rename them) :
// h1           : Page title
// h2           : Product name
// h3           :
// h4           :
// h5           :
// h6           :
// subtitle1    :
// subtitle2    :
// body1        : Default text (font size 14)
// body2        :
// button       : Button
// caption      :
// overline     : Link
const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat-Medium',
        h1: {
            fontSize: Constants.FONT_SIZE_38,
        },
        overline: {
            fontSize: Constants.FONT_SIZE_13,
            fontWeight: "bold",
            color: Constants.BLACK,
            textDecoration: "none"
        },
        body1: {
            fontSize: Constants.FONT_SIZE_14,
        },
        button: {
            color: Constants.WHITE
        }
    },
    palette: {
        primary: {
            main: Constants.PINK,
        },
        secondary: {
            main: '#54595F'
        },
        error: {
            main: '#CA8A8F'
        }
    },
    overrides: {
        MuiListItem: {
            root: {
                "&$selected": {
                    color: Constants.PINK,
                    backgroundColor: "inherit",
                    "&:hover": {
                        backgroundColor: "inherit"
                    },
                    "&& $svg": {
                        transform: "rotate(-180deg)",
                    }
                },
                "&& $svg": {
                    transform: "rotate(-0deg)",
                    transition: "transform 0.2s"
                }
            }
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': 'Montserrat',
            },
        },
        MuiLink: {
            color: "black",
            textDecoration: "none",
            underlineHover: {
                "&:hover": {
                    textDecoration: "none"
                }
            }
        },
        MuiTextField: {
            root: {
                width: "100%"
            }
        },
        MuiOutlinedInput: {
            root: {
                "&.Mui-disabled": {
                    color: "inherit"
                },
                "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                    borderColor: Constants.BORDER_GRAY,
                    borderRadius: 10
                }
            },
            input: {
                height: 60,
                paddingTop: 0,
                paddingBottom: 0,
                width: "100%",
                marginRight: Constants.FONT_SIZE_NORMAL,
                marginLeft: Constants.FONT_SIZE_NORMAL
            },
            inputMultiline: {
                height: "100%"
            },
            multiline: {
                height: 210,
            },
            notchedOutline: {
                borderColor: Constants.BORDER_GRAY,
                borderRadius: 10
            }
        },
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiOutlinedInput-root"] $input': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginRight: Constants.FONT_SIZE_NORMAL,
                    marginLeft: Constants.FONT_SIZE_NORMAL
                },
                '&&[class*="MuiOutlinedInput-root"]': {
                    padding: 0
                },
                '&&[class*="MuiOutlinedInput-root"] $input:first-child': {
                    paddingLeft: 18.5,
                }
            }
        },
        MuiInputAdornment: {
            root: {
                maxHeight: 60,
                height: 60,
                borderColor: Constants.BORDER_GRAY,
                borderWidth: 0,
                borderStyle: "solid"
            }
        },
        MuiButton: {
            root: {
                fontSize: Constants.FONT_SIZE_NORMAL,
                padding: 20,
                paddingLeft: 60,
                paddingRight: 60,
                justifyContent: "center",
                borderRadius: 0
            },
            containedSizeSmall: {
                padding: 18,
                paddingLeft: 30,
                paddingRight: 30,
            },
            outlinedSizeSmall: {
                padding: 18,
                paddingLeft: 30,
                paddingRight: 30,
            },

            fullWidth: {
                paddingLeft: 0,
                paddingRight: 0,
            },
            label: {
                textTransform: "uppercase",
                fontWeight: "bold",
                textAlign: "center",
                color: "inherit"
            },
            outlinedPrimary: {
                padding: 20,
                paddingLeft: 60,
                paddingRight: 60,
                color: Constants.PINK,
                backgroundColor: Constants.WHITE
            },
            containedPrimary: {
                color: Constants.WHITE,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: Constants.PINK
            },
            iconSizeMedium: {
                "& *:first-child": {
                    fontSize: Constants.FONT_SIZE_BIGGEST
                }
            },
            contained: {
                "&.Mui-disabled": {
                    borderColor: "rgba(0, 0, 0, 0.12)"
                }
            }
        },
        MuiSelect: {
            outlined: {
                height: 60,
                margin: 0,
                lineHeight: "60px",
                paddingLeft: "calc(0.875rem + 18.5px)"
            }
        },
        MuiInputLabel: {
            shrink: {
                backgroundColor: "white",
                zIndex: 10,
                transform: "translate(10px, -5px) scale(0.75)"
            }
        },
        MuiRating: {
            root: {
                color: Constants.PINK
            }
        },
        MuiFormControlLabel: {
            label: {
                width: "100%"
            },
            labelPlacementStart: {
                marginRight: 0,
                justifyContent: "space-between",
                "& .MuiIconButton-root": {
                    padding: 0
                }
            }
        },
        MuiCheckbox: {
            root: {
                color: "#E0E3E5"
            }
        },
    },
    props: {
        MuiContainer: {
            maxWidth: 'lg'
        },
    },
});

theme.typography.body1[theme.breakpoints.down('xs')] = {
    fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
};

theme.overrides.MuiButton.root[theme.breakpoints.down('xs')] = {
    width: "100%",
    fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
    padding: 10
};
theme.overrides.MuiButton.containedSizeSmall[theme.breakpoints.down('xs')] = {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
};
theme.overrides.MuiButton.outlinedSizeSmall[theme.breakpoints.down('xs')] = {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 10,
};

theme.overrides.MuiButton.iconSizeMedium["& *:first-child"][theme.breakpoints.down('xs')] = {
    fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
};

export default theme;
