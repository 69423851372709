import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: "column",
        // alignItems: "center",
        width: "100%",
    },
    row: {
        ...GlobalStyle.rowCentered,
    },

    datePickerWrapper: {
        alignItems: "center",
        marginLeft: Constants.GET_SIZE(90),
        marginRight: Constants.GET_SIZE(90)
    },
    datePickerLabel: {

        ...GlobalStyle.uppercaseText
    },
    datePickerPicker: {

    },

    grayBlock: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        paddingTop: Constants.GET_SIZE(25),
        paddingBottom: Constants.GET_SIZE(25),
        paddingRight: Constants.GET_SIZE(50),
        paddingLeft: Constants.GET_SIZE(50)
    },

    whiteBlock: {
        paddingTop: Constants.GET_SIZE(25),
        paddingBottom: Constants.GET_SIZE(25),
        paddingRight: Constants.GET_SIZE(50),
        paddingLeft: Constants.GET_SIZE(50)
    },

    label: {

        marginLeft: Constants.GET_SIZE(10),
        marginRight: Constants.GET_SIZE(10)
    },
    bannerLabel: {
        width: Constants.GET_SIZE(200)
    },
    value: {

        color: Constants.PINK
    },

    button: {
        ...GlobalStyle.button,
        paddingTop: Constants.GET_SIZE(10),
        paddingBottom: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(15),
        paddingRight: Constants.GET_SIZE(15),
        marginLeft: 15,
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },
    rowTendanceSauvageText: {

        marginLeft: Constants.GET_SIZE(10),
        marginRight: Constants.GET_SIZE(10),
        color: Constants.FONT_GRAY
    },
    pictureDeleteIconWrapper: {
        position: "absolute",
        top: -Constants.GET_SIZE(4),
        right: -Constants.GET_SIZE(4),
    },
    pictureDeleteIcon: {
        color: Constants.FONT_GRAY,

        fontSize: Constants.FONT_SIZE_BIGGER,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
        }
    },
    addIcon: {
        color: Constants.PINK,

        fontSize: Constants.FONT_SIZE_BIGGER,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
        }
    },
    calendarIcon: {
        width: Constants.GET_SIZE(58),
        height: Constants.GET_SIZE(58)
    },
    inputWrapper: {
        width: 250,
        marginBottom: 0,
        marginLeft: 15
    },
    smallInput: {
        height: Constants.GET_SIZE(30),
    },
    smallInputWrapper: {
        height: Constants.GET_SIZE(40),
        width: Constants.GET_SIZE(150),
        marginLeft: Constants.GET_SIZE(10),
        marginRight: Constants.GET_SIZE(10),
        marginTop: 0,
        marginBottom: 0
    },
    lovedShopCard: {
        backgroundSize: 'contain',
        objectFit: 'contain',
        width: Constants.GET_SIZE(255),
        height: Constants.GET_SIZE(220),
        borderRadius: "5%",
        marginRight: Constants.GET_SIZE(10),
        marginLeft: Constants.GET_SIZE(10),
        marginTop: Constants.GET_SIZE(10),
        marginBottom: Constants.GET_SIZE(10)
    },
    lovedShopCardText: {

        color: Constants.WHITE,
        width: "100%",
        textAlign: "center",
        position: "absolute",
        bottom: Constants.GET_SIZE(25),
        backgroundColor: "#000000A8",
        paddingTop: Constants.GET_SIZE(3),
        paddingBottom: Constants.GET_SIZE(3),
    },
    imageWrapper: {
        width: Constants.GET_SIZE(150),
        marginBottom: Constants.GET_SIZE(10),
        alignItems: "center"

    },
    image: {
        width: Constants.GET_SIZE(150),
        height: Constants.GET_SIZE(150),
        marginBottom: Constants.GET_SIZE(20),
        borderRadius: "10%"
    },


    searchShopWrapper: {
        zIndex: 999,
        position: "relative"
    },
    shopAutocompleteWrapper: {
        position: "absolute",
        top: Constants.GET_SIZE(70),
        width: Constants.GET_SIZE(430),
        zIndex: 999,
        backgroundColor: Constants.WHITE,
        borderWidth: 1,
        borderColor: Constants.BORDER_GRAY
    },
    shopAutocompleteButton: {
        paddingLeft: Constants.GET_SIZE(10),
        paddingRight: Constants.GET_SIZE(10),
        paddingTop: Constants.GET_SIZE(5),
        paddingBottom: Constants.GET_SIZE(5),
        borderBottomWidth: 1,
        borderColor: Constants.BORDER_GRAY
    },
    shopAutocompleteLabel: {
    },
    pickerWrapper: {
        ...GlobalStyle.rowCentered,
        width: Constants.GET_SIZE(150),
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderRadius: Constants.GET_SIZE(10),
        height: Constants.GET_SIZE(58),
    },
    pickerItem: {

    },
    picker: {

        borderWidth: 0,
        backgroundColor: Constants.WHITE,
        height: Constants.GET_SIZE(43),
        width: "100%",
        marginRight:  Constants.FONT_SIZE_NORMAL,
        marginLeft:  Constants.FONT_SIZE_NORMAL,
    },

    slider: {
        width: "100%",
        height: 250,
        [theme.breakpoints.down('xs')]: {
            width: "97%"
        }
    }
}));
