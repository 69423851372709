import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Resizer from "react-image-file-resizer";
import Grid from '@material-ui/core/Grid';
import TextInputIcon from './Component/TextInputIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CameraAlt from '@material-ui/icons/CameraAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import Text from '../Component/FontText';
import { ErrorMessage } from '@hookform/error-message';
import Hidden from '@material-ui/core/Hidden';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Image } from 'react-native';
import { dataURLtoFile, getImageUrl, pickImage } from '../../Utils/ImageUtils';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import CustomCheckbox from '../Component/CustomCheckbox';
import * as Constants from '../../Style/Constants';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlineChecked from '@material-ui/icons/CheckBoxOutlined';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import ErrorStyleFunc from '../Styles/Error';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Check from '@material-ui/icons/Check';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { VariableSizeList } from 'react-window';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import PictureCropModal from '../Middle/Component/PictureCropModal';
import ConfirmationModal from './Component/ConfirmationModal';
import StylesFunc from '../../Style/MiddleOffice/CreateProductStyle';
import useDebounce from '../../Hooks/useDebounce';
import { useCallbackDebugger } from '../../Hooks/useHooksDebug';
import { useGetBrandIdFromName } from '../../Hooks/useGetBrandIdFromName';


const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1024,
            768,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const COLOR_ASSOCIATION = {
    'Noir': '#000000',
    'Bleu': '#0000FF',
    'Blanc': '#FFFFFF',
    'Multi': 'linear-gradient(135deg, purple 18%, blue 31%, teal 44%, green 57%, yellow 70%, orange 83%, red 100%)',
    'Gris': '#696969',
    'Beige': '#E0CDA9',
    'Marron': '#582900',
    'Rose': '#FD6C9E',
    'Rouge': '#FF0000',
    'Argent': '#C0C0C0',
    'Or': '#EFD807',
    'Vert': '#00FF00',
    'Violet': '#DDA0DD',
    'Kaki': '#94812B',
    'Orange': '#FF7F00',
    'Jaune': '#F7FF3C',
};

const COLORED_CHECKBOXES = {};

for (const color of Object.keys(COLOR_ASSOCIATION)) {
    const ColoredCheckbox = withStyles({
        root: {
            color: COLOR_ASSOCIATION[color]
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    COLORED_CHECKBOXES[color] = props => (
        <Tooltip title={color}>
            <div>
                <ColoredCheckbox
                    icon={
                        <Box style={{
                            width: 20,
                            height: 20,
                            lineHeight: '20px',
                            marginRight: 5,
                            marginLeft: 5,
                            borderRadius: '50%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: COLOR_ASSOCIATION[color],
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: color === 'Blanc' ? Constants.PINK : COLOR_ASSOCIATION[color]
                        }}>
                        </Box>
                    }
                    checkedIcon={
                        <Box style={{
                            width: 16,
                            height: 16,
                            lineHeight: '16px',
                            marginRight: 5,
                            marginLeft: 5,
                            borderRadius: '50%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: COLOR_ASSOCIATION[color],
                            borderWidth: 3,
                            borderStyle: 'solid',
                            borderColor: Constants.PINK
                        }}>
                            <Check style={{ fontSize: Constants.FONT_SIZE_NORMAL, color: Constants.PINK }}/>
                        </Box>
                    }
                    {...props} />
            </div>
        </Tooltip>
    );
}

// Adapter for react-window
const LISTBOX_PADDING = 0; // px
function renderRow (props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache (data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent (props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        // if (React.isValidElement(child) && child.type === ListSubheader) {
        //     return 48;
        // }
        return itemSize;
    };

    const getHeight = () => {
        // if (itemCount > 8) {
        //     return 8 * itemSize;
        // }
        // return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        return Math.min(itemCount, 8) * itemSize;
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="div"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

function CustomSelect ({ classes, control, errors, name, label, value, placeholder, choices, required, display = true }) {
    return <FormControl className={classes.pickerWrapper} style={{ display: !display ? 'none' : 'block' }}>
        {(label/* || (placeholder && value != -1)*/) && <InputLabel id={'select-' + name + '-label'}>{label ? label : placeholder ? placeholder + '*' : null}</InputLabel>}
        <Controller
            as={
                <Select
                    labelId={label || placeholder ? 'select-' + name + '-label' : null}
                    variant={'outlined'}
                    className={clsx(classes.picker, { [classes.pickerPlaceholder]: value == -1 })}
                    error={errors && errors[name]}
                >
                    {placeholder && <MenuItem value={-1} disabled={true}>{placeholder}*</MenuItem>}
                    {choices}
                </Select>
            }
            control={control}
            rules={{ validate: (value) => required === false || display === false || value !== -1 || 'Ce champ est obligatoire' }}
            name={name}
            defaultValue={value}
        />
        <ErrorMessage name={name} errors={errors} render={({ message }) => <Text color={'error'}>{message}</Text>}/>
    </FormControl>;
}

const Component = forwardRef(({ screenKey, familyTree, criteria, number, productKey, product, deleteHandler, previewHandler, saveCurrentProduct, currentProductsCount }, ref) => {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { trigger, setValue, getValues, setError, handleSubmit, register, control, errors, watch, formState } = useForm({ shouldFocusError: true });
    const { id } = useParams();
    const getBrandIdFromName = useGetBrandIdFromName();

    let inputFileRef = React.useRef(null);
    let familyRef = React.useRef(null);
    let pictureButtonRef = React.useRef(null);
    let sizeRef = React.useRef(null);
    let colorsRef = React.useRef(null);
    let materialRef = React.useRef(null);
    let brandRef = React.useRef(null);
    let stateRef = React.useRef(null);
    let deliveryModesRef = React.useRef(null);

    let [pictureCropModalVisible, setPictureCropModalVisible] = React.useState(false);
    let [imageErrorModalVisible, setImageErrorModalVisible] = React.useState(false);
    let [currentPictureUri, setCurrentPictureUri] = React.useState(null);
    const [currentCroppedPicture, setCurrentCroppedPicture] = React.useState(null);

    // Form input values
    let name = watch('name', '');
    let description = watch('description', '');
    let discount = watch('discount', '');
    let brandSearch = watch('brandSearch', '');
    let price = watch('price', 0);
    let family = watch('family', -1);
    let category = watch('category', -1);
    let subCat = watch('subCat', -1);
    let subCat2 = watch('subCat2', -1);
    let state = watch('state', -1);
    let material = watch('material', -1);
    let quantity = watch('quantity', 1);
    let colors = watch('colors', []);
    let sizes = watch('sizes', []);
    let deliveryModes = watch('deliveryModes', []);
    let pictures = watch('pictures', []);
    let returnPossible = watch('returnPossible', false);

    // Exposed function to ref
    useImperativeHandle(ref, () => ({
        validate () {
            return trigger();
        },

        submit (onSubmit, onError = () => {}) {
            handleSubmit(onSubmit, onError)();
        },

        getProductKey () {
            return productKey;
        }
    }), [trigger, handleSubmit, productKey]);

    const getLowestLevelCat = () => {
        let lowestLevelCat = null;
        let choseCategory = null;
        let choseSubCat = null;
        let choseFamily = null;

        if (familyTree) {
            for (const fam of familyTree) {
                if (getValues('family') == fam.id) {
                    choseFamily = fam;
                }
            }
        }

        if (choseFamily && choseFamily.children) {
            for (const cat of choseFamily.children) {
                if (getValues('category') == cat.id) {
                    choseCategory = cat;
                    lowestLevelCat = choseCategory;
                }
            }
        }

        if (choseCategory && choseCategory.children) {
            for (const subC of choseCategory.children) {
                if (getValues('subCat') == subC.id) {
                    choseSubCat = subC;
                    choseSubCat.parent = choseCategory;
                    lowestLevelCat = choseSubCat;
                }
            }
        }

        if (choseSubCat && choseSubCat.children) {
            for (const subC2 of choseSubCat.children) {
                if (getValues('subCat2') == subC2.id) {
                    lowestLevelCat = subC2;
                }
            }
        }

        return lowestLevelCat;
    }

    React.useEffect(() => {
        register({ name: 'colors' }, { validate: (value) => value && value.length > 0 || 'Vous devez sélectionner au moins une couleur' });
        register({ name: 'sizes' }, { validate: (value) => {
            const currentCat = getLowestLevelCat();
            return !currentCat || !currentCat.sizeType || (value && value.length > 0) || 'Vous devez sélectionner au moins une taille'
        } });
        register({ name: 'deliveryModes' }, { validate: (value) => value && value.length > 0 || 'Vous devez sélectionner au moins un mode de livraison' });
        register({ name: 'pictures' }, { validate: (value) => value && value.length > 2 || 'Vous devez sélectionner au moins 3 photos' });
        register({ name: 'brand' });
        register({ name: 'returnPossible' });
    }, [register]);

    let [displaySizes, setDisplaySizes] = React.useState(true);
    let [displayColors, setDisplayColors] = React.useState(true);
    let [displayDeliveryModes, setDisplayDeliveryModes] = React.useState(true);
    let [brandWarning, setBrandWarning] = React.useState(false);

    React.useEffect(() => {
        setValue('name', product.name);

        if (product.family) {
            setValue('family', parseInt(product.family.id));
        }

        if (product.category) {
            let categories = [];
            categories.push(product.category);
            if (product.category.parent) {
                categories.push(product.category.parent);
            }

            if (product.category.parent.parent) {
                categories.push(product.category.parent.parent);
            }
            categories = categories.reverse();
            setValue('category', categories[0].id);
            if (categories.length > 1) {
                setValue('subCat', categories[1].id);
                if (categories.length > 2) {
                    setValue('subCat2', categories[2].id);
                }
            }
        }

        setValue('description', product.description);
        setValue('pictures', product.pictures);
        if (product.brand) {
            setValue('brandSearch', product.brand.value);
        }

        setValue('sizes', product.sizes.map(size => size.id));
        setValue('colors', product.colors.map(color => color.id));

        if (product.state) {
            setValue('state', product.state.id);
        }

        if (product.material) {
            setValue('material', product.material.id);
        }

        if (product.quantity > 0) {
            setValue('quantity', product.quantity);
        }

        if (product.price > 0) {
            setValue('price', product.price);
        }

        if (product.discount !== 0) {
            setValue('discount', product.discount);
        }

        let deliveryModes = [];
        if (product.chronopost || product.mondialRelay) {
            deliveryModes.push(0);
        }

        if (product.handOver) {
            deliveryModes.push(2);
        }

        setValue('deliveryModes', deliveryModes);
        setValue('returnPossible', product.returnPossible);
    }, []);

    React.useEffect(() => {
        if (errors.family || errors.category || errors.subCat || errors.subCat2) {
            familyRef.current.focus();
        } else if (errors.pictures) {
            pictureButtonRef.current.focus();
        } else if (errors.brandSearch) {
            brandRef.current.focus();
        } else if (errors.sizes) {
            sizeRef.current.focus();
        } else if (errors.colors) {
            colorsRef.current.focus();
        } else if (errors.material) {
            materialRef.current.focus();
        } else if (errors.state) {
            stateRef.current.focus();
        } else if (errors.deliveryModes) {
            deliveryModesRef.current.focus();
        }
    }, [errors]);

    let handleAddPicture = async (e) => {
        if (pictures.length + (e.target.files ? e.target.files.length : 0) < 6) {
            if (e.target.files && e.target.files.length > 0) {
                const promises = [];
                const files = Array.from(e.target.files)
                let nfiles = [];
                for (const index in files) {
                    const file = files[index]

                    let resizedFile = await resizeFile(file);
                    const realIndex = parseInt(pictures.length) + parseInt(index);
                    let nfile = {
                        id: -1,
                        uri: resizedFile,
                        key: 'pic-new-' + realIndex,
                        type: 'new',
                        file: dataURLtoFile(resizedFile, 'product-' + name.replace(/[^A-Z0-9]/ig, '_') + '-image'),
                        sortOrder: realIndex
                    };
                    nfiles.push(nfile);
                    // promises.push(new Promise((resolve) => {
                    //     let reader = new FileReader();
                    //     reader.addEventListener(
                    //         'load',
                    //         function () {
                    //             const realIndex = parseInt(pictures.length) + parseInt(index)
                    //             const src = reader.result;
                    //
                    //             resolve({
                    //                 id: -1,
                    //                 uri: src,
                    //                 key: 'pic-new-' + realIndex,
                    //                 type: 'new',
                    //                 file: dataURLtoFile(src, 'product-' + name.replace(/[^A-Z0-9]/ig, '_') + '-image'),
                    //                 sortOrder: realIndex
                    //             })
                    //         },
                    //         false
                    //     );
                    //     reader.readAsDataURL(resizedFile);
                    // }))
                }

                saveCurrentProduct(productKey, { pictures: [...pictures, ...nfiles] });
                setValue('pictures', [...pictures, ...nfiles]);
                // Wait for all pictures to be processed before updating values
                // Promise.all(promises).then((newPictures) => {
                //     saveCurrentProduct(productKey, { pictures: [...pictures, ...newPictures] });
                //     setValue('pictures', [...pictures, ...newPictures]);
                //
                // })
            }
        } else {
            setError('pictures', { type: 'invalid', message: 'Vous ne pouvez pas ajouter plus de 5 photos' });
        }
        inputFileRef.current.value = '';
    };

    const handleEditPicture = useCallback((picture) => {
        setCurrentPictureUri(picture.uri);
        setCurrentCroppedPicture(picture);
        setPictureCropModalVisible(true);
    }, [setCurrentCroppedPicture, setCurrentPictureUri, setPictureCropModalVisible]);

    let handleCropPicture = (dataUrl) => {
        const newPictures = pictures.map(picture => {
            if (picture.key === currentCroppedPicture.key) {
                return {
                    ...picture,
                    uri: dataUrl,
                    file: dataURLtoFile(dataUrl, 'product-' + name.replace(/[^A-Z0-9]/ig, '_') + '-image')
                }
            }
            return picture;
        });

        saveCurrentProduct(productKey, { pictures: newPictures });
        setValue('pictures', newPictures);
        setPictureCropModalVisible(false);
    };

    let handleRemovePicture = (index) => {
        let newPictures = [...pictures.slice(0, index), ...pictures.slice(index + 1)];
        saveCurrentProduct(productKey, { pictures: newPictures });
        setValue('pictures', newPictures);
    };

    let handleChangePictureOrder = (result) => {
        if (!result.destination) {
            // console.log("DBG", " NO DESTINATION");
            return;
        }

        const newPictures = pictures.slice();
        newPictures.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);

        let destinationIndex = result.destination.index + (result.destination.droppableId === 'droppable2' ? (result.source.droppableId === 'droppable2' ? 3 : 2) : 0);
        let sourceIndex = result.source.index + (result.source.droppableId === 'droppable2' ? 3 : 0);

        // console.log("DBG dnd", result.source, result.destination, sourceIndex, destinationIndex);
        if (sourceIndex < destinationIndex) {
            // console.log("DBG dnd 2", "Moving picture forward", sourceIndex, destinationIndex);
            let encounteredSource = false;
            for (const picture of newPictures) {
                // console.log("DBG dnd 2.5", "=> Picture => ", picture.sortOrder);
                if (encounteredSource) {
                    // console.log("DBG dnd 3", "2) New sort order", picture.sortOrder, picture.sortOrder - 1);
                    picture.sortOrder = picture.sortOrder - 1;
                    if (picture.sortOrder + 1 === destinationIndex) {
                        break;
                    }
                } else if (picture.sortOrder === sourceIndex) {
                    // console.log("DBG dnd 3", "1) New sort order", picture.sortOrder, destinationIndex);
                    picture.sortOrder = destinationIndex;
                    encounteredSource = true;
                } else {
                    // console.log("DBG dnd 3", "ignoring", picture.sortOrder, destinationIndex);
                }
            }
        } else if (sourceIndex > destinationIndex) {
            // console.log("DBG dnd 2", "Moving picture backward");
            let encounteredDestination = false;
            for (const picture of newPictures) {
                if (picture.sortOrder === sourceIndex) {
                    // console.log("DBG dnd 3", "2) New sort order", picture.sortOrder, destinationIndex);
                    picture.sortOrder = destinationIndex;
                } else if (picture.sortOrder === destinationIndex || encounteredDestination) {
                    // console.log("DBG dnd 3", "1) New sort order", picture.sortOrder, picture.sortOrder + 1);
                    picture.sortOrder = picture.sortOrder + 1;
                    encounteredDestination = true;
                } else {
                    // console.log("DBG dnd 3", "ignoring", picture.sortOrder, destinationIndex);
                }
            }
        }

        saveCurrentProduct(productKey, { pictures: newPictures });
        setValue('pictures', newPictures);
    };

    React.useEffect(() => {
        if (brandSearch && brandSearch.length > 0 && getBrandIdFromName(brandSearch) === -1) {
            if (!brandWarning) {
                setBrandWarning(true);
            }
        } else {
            if (brandWarning) {
                setBrandWarning(false);
            }
        }
    }, [brandSearch, criteria.brands]);

    let lowestLevelCat = null;
    let familyChoices = [];
    let choseFamily = null;
    if (familyTree) {
        for (const fam of familyTree) {
            familyChoices.push({ id: fam.id, label: fam.name });
            if (family == fam.id) {
                choseFamily = fam;
            }
        }
    }

    let categoryChoices = [];
    let choseCategory = null;
    if (familyTree && family != -1 && choseFamily && choseFamily.children) {
        for (const cat of choseFamily.children) {
            categoryChoices.push({ id: cat.id, label: cat.name });
            if (category == cat.id) {
                choseCategory = cat;
                lowestLevelCat = choseCategory;
            }
        }
    }

    let subCatChoices = [];
    let choseSubCat = null;
    if (familyTree && family != -1 && category != -1 && choseCategory && choseCategory.children) {
        for (const subC of choseCategory.children) {
            subCatChoices.push({ id: subC.id, label: subC.name, parent: choseCategory });
            if (subCat == subC.id) {
                choseSubCat = subC;
                choseSubCat.parent = choseCategory;
                lowestLevelCat = choseSubCat;
            }
        }
    }

    let subCat2Choices = [];
    let choseSubCat2 = null;
    if (familyTree && family != -1 && category != -1 && subCat != -1 && choseSubCat && choseSubCat.children) {
        for (const subC2 of choseSubCat.children) {
            subCat2Choices.push({ id: subC2.id, label: subC2.name, parent: choseSubCat });
            if (subCat2 == subC2.id) {
                choseSubCat2 = subC2;
                choseSubCat2.parent = choseSubCat;
                lowestLevelCat = choseSubCat2;
            }
        }
    }

    let sizeChoices = [];
    if (lowestLevelCat) {
        for (const s of criteria.sizes) {
            if (s.type === lowestLevelCat.sizeType) {
                sizeChoices.push({ id: s.id, value: s.value });
            }
        }
    }

    let quantityChoices = [];
    for (let i = 1; i < 30; i++) {
        quantityChoices.push(i);
    }

    let onPressColor = (color) => {
        let index = colors.indexOf(color.id);
        if (index !== -1) {
            setValue('colors', [...colors.slice(0, index), ...colors.slice(index + 1)]);
        } else {
            setValue('colors', [color.id]);
        }
        if (formState.isSubmitted) {
            trigger('colors');
        }
    };

    let handleClickOnDeliveryMode = (mode) => {
        let index = deliveryModes.indexOf(mode);
        if (index === -1) {
            setValue('deliveryModes', [...deliveryModes, mode]);
        } else {
            setValue('deliveryModes', [...deliveryModes.slice(0, index), ...deliveryModes.slice(index + 1)]);
        }
        if (formState.isSubmitted) {
            trigger('deliveryModes');
        }
    };

    const handleClickSize = (size) => {
        let index = sizes.indexOf(size);
    
        if (index === -1) {
            setValue('sizes', [size]);
        } else {
            setValue('sizes', [...sizes.slice(0, index), ...sizes.slice(index + 1)]);
        }
    
        if (formState.isSubmitted) {
            trigger('sizes');
        }
    };

    let deliveryModesParam = [
        {
            'id': 0,
            'label': 'Livraison (chronopost / Mondial Relay)'
        },
    ];

    // if (user && user.shop && user.shop.videDressingSeller && user.shop.events.length > 0) {
    //     deliveryModesParam.push(
    //         {
    //             "id": 1,
    //             "label": "Click & collect"
    //         })
    // }

    deliveryModesParam.push(
        {
            'id': 2,
            'label': 'Remise en main propre'
        }
    );

    pictures.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);

    let categoryMaterials = lowestLevelCat ? criteria.materials.filter(mat => mat.type === lowestLevelCat.materialType) : [];
    categoryMaterials.sort((a, b) => {
        return a.value === 'J\'ai un doute' ? 1 : (b.value === 'J\'ai un doute' ? -1 : 0);
    });

    const onFormChange = useCallback(() => {
        let brand = getBrandIdFromName(brandSearch);

        let previewSizes = [];
        if (sizes) {
            for (const s of sizes) {
                previewSizes.push(criteria.sizes.filter(s2 => s == s2.id)[0]);
            }
        }

        let previewColors = [];
        if (colors) {
            for (const c of colors) {
                previewColors.push(criteria.colors.filter(c2 => c == c2.id)[0]);
            }
        }

        let realPrice = parseFloat(price);
        realPrice = Math.round(realPrice - (realPrice * ((discount ? discount : 0) / 100)));

        let productFormattedForAPI = {
            id: screenKey === 'EditProduct' ? id : null,
            family: familyTree ? familyTree.filter(f => family == f.id)[0] : null,
            name: name,
            description: description,
            brand: brand !== -1 ? criteria.brands.filter(b => brand == b.id)[0] : (brandSearch ? { 'value': brandSearch } : null),
            state: criteria.states.filter(s => state == s.id)[0],
            material: criteria.materials.filter(m => material == m.id)[0],
            sizes: previewSizes,
            colors: previewColors,
            quantity: parseInt(quantity),
            price: parseFloat(price),
            realPrice: realPrice,
            chronopost: deliveryModes.indexOf(0) !== -1,
            mondialRelay: deliveryModes.indexOf(0) !== -1,
            clickAndCollect: true,
            handOver: deliveryModes.indexOf(2) !== -1,
            returnPossible: returnPossible,
            category: lowestLevelCat
        };
        saveCurrentProduct(productKey, productFormattedForAPI);
    }, [brandSearch, colors, deliveryModes, description, discount, family, familyTree, getBrandIdFromName, lowestLevelCat, name, price, quantity, returnPossible, sizes]);

    const onFormChangeDebounced = useDebounce(onFormChange, 500);

    let handleClickPreview = useCallback((data) => {
        let brand = getBrandIdFromName(data.brandSearch);

        let previewSizes = [];
        if (data.sizes) {
            for (const s of data.sizes) {
                previewSizes.push(criteria.sizes.filter(s2 => s == s2.id)[0]);
            }
        }

        let previewColors = [];
        if (data.colors) {
            for (const c of data.colors) {
                previewColors.push(criteria.colors.filter(c2 => c == c2.id)[0]);
            }
        }

        let realPrice = parseFloat(data.price);
        realPrice = Math.round(realPrice - (realPrice * ((data.discount ? data.discount : 0) / 100)));

        let productFormattedForAPI = {
            id: screenKey === 'EditProduct' ? id : null,
            family: familyTree.filter(f => data.family == f.id)[0],
            name: data.name,
            description: data.description,
            brand: brand !== -1 ? criteria.brands.filter(b => brand == b.id)[0] : (data.brandSearch ? { 'value': data.brandSearch } : null),
            state: criteria.states.filter(s => data.state == s.id)[0],
            material: criteria.materials.filter(m => data.material == m.id)[0],
            sizes: previewSizes,
            colors: previewColors,
            quantity: parseInt(data.quantity),
            price: parseFloat(data.price),
            realPrice: realPrice,
            chronopost: data.deliveryModes.indexOf(0) !== -1,
            mondialRelay: data.deliveryModes.indexOf(0) !== -1,
            clickAndCollect: true,
            handOver: data.deliveryModes.indexOf(2) !== -1,
            returnPossible: data.returnPossible,
            category: lowestLevelCat
        };

        previewHandler(productKey, productFormattedForAPI, data.pictures);
    }, [criteria, id, lowestLevelCat, productKey, screenKey]);

    const handleClickDelete = useCallback(() => {
        deleteHandler(productKey);
    }, [deleteHandler, productKey]);

    return (
        <form onChange={onFormChangeDebounced} className={Styles.productForm}>
            <Grid container spacing={2} >
                <Grid item xs={12} className={Styles.column}>
                    {screenKey === 'CreateProduct' && <Text className={Styles.productTitle}>Article {number}</Text>}
                </Grid>
                <Grid item xs={12} md={6} className={Styles.column}>
                    <TextInputIcon
                        wrapperClass={Styles.inputWrapper}
                        errors={errors}
                        register={register}
                        defaultValue={product ? product.name : ''}
                        label={'Titre de l\'article*'}
                        rules={{
                            required: 'Ce champ est obligatoire'
                        }}
                        name={'name'}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={Styles.column}>
                    <div ref={familyRef} tabIndex={0}></div>
                    <CustomSelect
                        control={control}
                        errors={errors}
                        classes={Styles}
                        label={'Famille*'}
                        value={family}
                        ref={familyRef}
                        defaultValue={product && product.family ? product.family.id : -1}
                        choices={familyChoices.map((fam) => (fam.label.toUpperCase() !== 'LUXE' && fam.label.toUpperCase() !== 'CURVY') ? <MenuItem value={fam.id} style={{ transformText: 'uppercase' }}>{fam.label.toUpperCase()}</MenuItem> : null)}
                        name={'family'}/>
                </Grid>
                <Grid item xs={12} md={6} className={Styles.column}>
                    <CustomSelect
                        control={control}
                        errors={errors}
                        classes={Styles}
                        label={'Catégorie*'}
                        value={category}
                        choices={categoryChoices.slice().sort((cat1, cat2) => cat1.label > cat2.label ? 1 : -1).map((cat) => <MenuItem value={cat.id}>{cat.label}</MenuItem>)}
                        display={categoryChoices.length > 0}
                        name={'category'}/>
                </Grid>
                <Grid item xs={12} md={6} className={Styles.column}>
                    <CustomSelect
                        control={control}
                        errors={errors}
                        classes={Styles}
                        label={'Sous catégorie*'}
                        value={subCat}
                        choices={subCatChoices.slice().sort((cat1, cat2) => cat1.label > cat2.label ? 1 : -1).map((cat) => <MenuItem value={cat.id}>{cat.label}</MenuItem>)}
                        display={subCatChoices.length > 0}
                        name={'subCat'}/>
                    <CustomSelect
                        control={control}
                        errors={errors}
                        classes={Styles}
                        label={'Sous catégorie*'}
                        value={subCat2}
                        choices={subCat2Choices.slice().sort((cat1, cat2) => cat1.label > cat2.label ? 1 : -1).map((cat) => <MenuItem value={cat.id}>{cat.label}</MenuItem>)}
                        display={subCat2Choices.length > 0}
                        name={'subCat2'}/>
                </Grid>
                <Grid item xs={12} md={12} className={Styles.column}>
                    <TextInputIcon
        
                        errors={errors}
                        register={register}
                        defaultValue={description}
                        label={'Descriptif du produit*'}
                        multiline
                        rules={{
                            required: 'Ce champ est obligatoire',
                        }}
                        name={'description'}
                    />
                </Grid>

                <Grid item xs={12} md={6} className={Styles.column}>
                    <Box className={Styles.brandWrapper}>
                        <div ref={brandRef} tabIndex={1}></div>
                        <Autocomplete
                            freeSolo
                            options={criteria.brands.map(option => option.value)}
                            ListboxComponent={ListboxComponent}
                            value={brandSearch}
                            filterOptions={(options, state) => options.filter(value => !brandSearch || value.toLowerCase().indexOf(brandSearch.toLowerCase()) !== -1)}
                            renderInput={(params) => (
                                <Controller
                                    as={
                                        <TextField
                                            {...params}
                                            className={Styles.inputWrapper}
                                            label={'Marque*'}
                                            variant={'outlined'}
                                            error={!!errors.brandSearch}
                                            InputProps={{ ...params.InputProps, type: 'search' }}

                                        />
                                    }
                                    control={control}
                                    rules={{
                                        required: 'Ce champ est obligatoire'
                                    }}
                                    name={'brandSearch'}
                                    // defaultValue={brandSearch}
                                />
                            )}
                            onChange={(event, value) => {setValue('brandSearch', value);}}
                        />
                        <ErrorMessage name={'brandSearch'} errors={errors} as={Text} className={ErrorStyle.error}/>
                        {brandWarning && <Text className={Styles.brandWarning}>Cette marque n'étant pas présente dans notre base de données, ce produit sera soumis à modération.</Text>}
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} className={Styles.column}>
                    <div ref={materialRef} tabIndex={4}></div>
                    <CustomSelect
                        control={control}
                        errors={errors}
                        classes={Styles}
                        label={'Matière*'}
                        value={material}
                        ref={materialRef}
                        choices={categoryMaterials.map((choice) => <MenuItem value={choice.id}>{choice.value}</MenuItem>)}
                        name={'material'}/>
                </Grid>

                <Grid item xs={12} md={6} className={Styles.column}>
                    <div ref={stateRef} tabIndex={5}></div>
                    <CustomSelect
                        control={control}
                        errors={errors}
                        classes={Styles}
                        label={'Etat*'}
                        value={state}
                        ref={stateRef}
                        choices={criteria.states.map((sta) => <MenuItem value={sta.id}>{sta.value}</MenuItem>)}
                        name={'state'}/>
                </Grid>

                <Grid item md={6} xs={12} className={Styles.column}>
                    <CustomSelect
                        control={control}
                        errors={errors}
                        classes={Styles}
                        label={'Quantité*'}
                        value={quantity}
                        choices={quantityChoices.map((quant) => <MenuItem value={quant}>{quant}</MenuItem>)}
                        name={'quantity'}/>
                </Grid>

                <Grid item md={6} xs={12}>
                    <Box className={Styles.deliveryModesSuperWrapper}>
                        <div ref={colorsRef} tabIndex={3}></div>
                        <Box className={clsx(Styles.deliveryModesWrapper, { [Styles.deliveryModesWrapperError]: errors['colors'] })}>
                            <Link onClick={() => setDisplayColors(!displayColors)} style={{ width: '100%', cursor: 'pointer', color: 'black' }}>
                                <Grid container xs={12} justify={'space-between'} className={Styles.deliveryModesTitleWrapper}>
                                    <Text className={clsx(Styles.deliveryModesTitle, { [ErrorStyle.error]: errors['colors'] })}>Couleur*</Text>
                                    <KeyboardArrowDown className={clsx(Styles.deliveryModeCaret, { [Styles.deliveryModeCaretOpen]: displayColors }, { [ErrorStyle.error]: errors['colors'] })}/>
                                </Grid>
                            </Link>
                            <Collapse in={displayColors} className={Styles.deliveryModesList}>
                                <Box className={Styles.deliveryModesListContent}>
                                    <FormGroup row>
                                        {criteria.colors.map(color => COLORED_CHECKBOXES[color.value]({
                                            onChange: (event) => {onPressColor(color);},
                                            name: color.id,
                                            checked: colors.indexOf(color.id) !== -1
                                        }))}
                                    </FormGroup>
                                </Box>
                            </Collapse>
                        </Box>
                        <Box className={clsx(Styles.upwardTriangleBorder, { [Styles.upwardTriangleBorderError]: errors['colors'] })}/>
                        <Box className={Styles.upwardTriangle}/>
                        <ErrorMessage name={'colors'} errors={errors} as={Text} className={ErrorStyle.error}/>
                    </Box>
                </Grid>

                <Grid item md={6} xs={12}>
                    {sizeChoices.length > 0 &&
                    <Box className={Styles.deliveryModesSuperWrapper}>
                        <div ref={sizeRef} tabIndex={2}></div>
                        <Box className={clsx(Styles.deliveryModesWrapper, { [Styles.deliveryModesWrapperError]: errors['sizes'] })}>
                            <Link onClick={() => setDisplaySizes(!displaySizes)} style={{ width: '100%', cursor: 'pointer', color: 'black' }}>
                                <Grid container xs={12} justify={'space-between'} className={Styles.deliveryModesTitleWrapper}>
                                    <Text className={clsx(Styles.deliveryModesTitle, { [ErrorStyle.error]: errors['sizes'] })}>Taille*</Text>
                                    <KeyboardArrowDown className={clsx(Styles.deliveryModeCaret, { [Styles.deliveryModeCaretOpen]: displaySizes }, { [ErrorStyle.error]: errors['sizes'] })}/>
                                </Grid>
                            </Link>
                            <Collapse in={displaySizes} className={Styles.deliveryModesList}>
                                <Box className={Styles.deliveryModesListContent}>
                                    {sizeChoices.map(size => (
                                        <CustomCheckbox
                                            containerClass={Styles.deliveryModeCheckbox}
                                            rightIcon
                                            size={Constants.FONT_SIZE_NORMAL}
                                            checkedColor={Constants.PINK}
                                            uncheckedColor={Constants.FONT_LIGHT_GRAY}
                                            checked={sizes.indexOf(size.id) !== -1}
                                            onPress={() => handleClickSize(size.id)}
                                            title={<Text className={Styles.deliveryModeLabel}>{size.value}</Text>}
                                        />
                                    ))}
                                </Box>
                            </Collapse>
                        </Box>
                        <Box className={clsx(Styles.upwardTriangleBorder, { [Styles.upwardTriangleBorderError]: errors['sizes'] })}/>
                        <Box className={Styles.upwardTriangle}/>
                        <ErrorMessage name={'sizes'} errors={errors} as={Text} className={ErrorStyle.error}/>
                    </Box>}
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextInputIcon
                        wrapperClass={Styles.inputWrapper}
                        errors={errors}
                        // setValue={setPrice}
                        register={register}
                        // defaultValue={price}
                        label={'Prix (hors livraison)*'}
                        type={'number'}
                        rules={{
                            required: 'Ce champ est obligatoire',
                            min: {
                                value: 5,
                                message: 'Le prix minimum est de 5€'
                            }
                        }}
                        name={'price'}
                    />
                    <Text>Le prix affiché au public sera le prix avec les frais de fonctionnement</Text>
                    {screenKey === 'EditProduct' && <TextInputIcon
                        wrapperClass={Styles.inputWrapper}
                        errors={errors}
                        register={register}
                        defaultValue={discount}
                        label={'Réduction (en %)'}
                        name={'discount'}
                        rules={
                            {
                                validate: (value) => price - (price * (value / 100)) >= 5 || 'Le prix minimum est de 5€'
                            }
                        }
                    />}
                </Grid>

                <Grid item xs={12} md={12} className={Styles.column}>
                    <Box className={Styles.deliveryModesSuperWrapper}>
                        <div ref={deliveryModesRef} tabIndex={2}></div>
                        <Box className={clsx(Styles.deliveryModesWrapper, { [Styles.deliveryModesWrapperError]: errors['deliveryModes'] })}>
                            <Link onClick={() => setDisplayDeliveryModes(!displayDeliveryModes)} style={{ width: '100%', cursor: 'pointer', color: 'black' }}>
                                <Grid container xs={12} justify={'space-between'} className={Styles.deliveryModesTitleWrapper}>
                                    <Text className={clsx(Styles.deliveryModesTitle, { [ErrorStyle.error]: errors['deliveryModes'] })}>Modes de livraison*</Text>
                                    <KeyboardArrowDown className={clsx(Styles.deliveryModeCaret, { [Styles.deliveryModeCaretOpen]: displayDeliveryModes }, { [ErrorStyle.error]: errors['deliveryModes'] })}/>
                                </Grid>
                            </Link>
                            <Collapse in={displayDeliveryModes} className={Styles.deliveryModesList} style={{ overflowY: 'hidden' }}>
                                <Box className={Styles.deliveryModesListContent}>
                                    {deliveryModesParam.map(deliveryParam =>
                                        <CustomCheckbox
                                            containerClass={Styles.deliveryModeCheckbox}
                                            rightIcon
                                            size={Constants.FONT_SIZE_NORMAL}
                                            checkedColor={Constants.PINK}
                                            uncheckedColor={Constants.FONT_LIGHT_GRAY}
                                            checked={deliveryModes.indexOf(deliveryParam.id) !== -1}
                                            onPress={() => handleClickOnDeliveryMode(deliveryParam.id)}
                                            title={<Text className={Styles.deliveryModeLabel}>{deliveryParam.label}</Text>}
                                        />
                                    )}
                                </Box>
                            </Collapse>
                        </Box>
                        <Box className={clsx(Styles.upwardTriangleBorder, { [Styles.upwardTriangleBorderError]: errors['deliveryModes'] })}/>
                        <Box className={Styles.upwardTriangle}/>
                        <ErrorMessage name={'deliveryModes'} errors={errors} as={Text} className={ErrorStyle.error}/>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                    <input id="picture-input" type="file" accept="image/*" style={{ display: 'none' }} ref={inputFileRef} onChange={(result) => handleAddPicture(result)} multiple/>
                    <Button variant={'contained'} color={'secondary'} disableElevation className={Styles.pictureButton} startIcon={<CameraAlt/>} onClick={() => inputFileRef.current.click()} ref={pictureButtonRef}>
                        Ajouter des photos*
                    </Button>
                    <Text className={Styles.pictureSubtext}>
                        Vous devez ajouter au moins 3 photos et au maximum 5 photos. La première photo choisie, sera celle mise en avant dans votre annonce.
                    </Text>
                    <ErrorMessage name={'pictures'} errors={errors} as={Text} className={ErrorStyle.error}/>
                    <Hidden xsDown>
                        {pictures.length > 0 &&
                        <DragDropContext onDragEnd={handleChangePictureOrder}>
                            <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                    <Grid container
                                          ref={provided.innerRef}
                                          spacing={2}
                                          style={{ marginBottom: "20px"}}
                                          {...provided.droppableProps}
                                    >
                                        {pictures.slice(0, 3).map((picture, index) => (
                                            <Draggable key={picture.id !== -1 ? 'pic-' + picture.id : picture.key} draggableId={picture.id !== -1 ? 'pic-' + picture.id : picture.key} index={index}>
                                                {(provided, snapshot) => (
                                                    <Grid item xs={12} sm={4}
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          className={Styles.pictureWrapper}>
                                                        <Image source={picture.id === -1 ? picture.uri : getImageUrl(picture.contentUrl, 'medium')}
                                                               className={Styles.picture}/>
                                                        <IconButton className={Styles.pictureDeleteIconWrapper} onClick={() => handleRemovePicture(index)}>
                                                            <CancelIcon/>
                                                        </IconButton>
                                                        <IconButton className={Styles.pictureEditIconWrapper} onClick={() => handleEditPicture(picture)}>
                                                            <EditIcon className={Styles.pictureEditIcon}/>
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Grid>
                                )}
                            </Droppable>
                            {pictures.length > 3 && <Droppable droppableId="droppable2" direction="horizontal">
                                {(provided, snapshot) => (
                                    <Grid container spacing={2} style={{ marginBottom: "20px"}}
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                    >
                                        {pictures.slice(3).map((picture, index) => (
                                            <Draggable key={picture.id !== -1 ? 'pic-' + picture.id : picture.key} draggableId={picture.id !== -1 ? 'pic-' + picture.id : picture.key} index={index}>
                                                {(provided, snapshot) => (
                                                    <Grid item xs={12} sm={4}
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          className={Styles.pictureWrapper}>
                                                        <Image source={picture.id === -1 ? picture.uri : getImageUrl(picture.contentUrl, 'medium')}
                                                               className={Styles.picture}/>
                                                        <IconButton className={Styles.pictureDeleteIconWrapper} onClick={() => handleRemovePicture(index + 3)}>
                                                            <CancelIcon/>
                                                        </IconButton>
                                                        <IconButton className={Styles.pictureEditIconWrapper} onClick={() => handleEditPicture(picture)}>
                                                            <EditIcon className={Styles.pictureEditIcon}/>
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Grid>
                                )}
                            </Droppable>}
                        </DragDropContext>
                        }
                    </Hidden>
                    <Hidden smUp>
                        {pictures.length > 0 &&
                        <DragDropContext onDragEnd={handleChangePictureOrder}>
                            <Droppable droppableId="droppable" direction="vertical">
                                {(provided, snapshot) => (
                                    <Grid container style={{ width: 'initial', marginBottom: 20 }}
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                    >
                                        {pictures.map((picture, index) => (
                                            <Draggable key={picture.id !== -1 ? 'pic-' + picture.id : picture.key} draggableId={picture.id !== -1 ? 'pic-' + picture.id : picture.key} index={index}>
                                                {(provided, snapshot) => (
                                                    <Grid item xs={12} sm={4}
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          className={Styles.pictureWrapper}>
                                                        <Image
                                                            source={picture.id === -1 ? picture.uri : getImageUrl(picture.contentUrl, 'medium')}
                                                            className={Styles.picture}
                                                            style={{ margin: 'auto' }}
                                                        />
                                                        <IconButton className={Styles.pictureDeleteIconWrapper} onClick={() => handleRemovePicture(index)}>
                                                            <CancelIcon/>
                                                        </IconButton>
                                                        <IconButton className={Styles.pictureEditIconWrapper} onClick={() => handleEditPicture(picture)}>
                                                            <EditIcon className={Styles.pictureEditIcon}/>
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Grid>
                                )}
                            </Droppable>
                        </DragDropContext>
                        }
                    </Hidden>
                </Grid>

                <Grid item xs={12} md={12} className={Styles.column}>
                {deliveryModes.indexOf(0) !== -1 && <FormControlLabel
                    control={
                        <Checkbox
                            checked={returnPossible}
                            onChange={(event) => setValue('returnPossible', event.target.checked)}
                            icon={<CheckBoxOutline style={{ fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.FONT_LIGHT_GRAY }}/>}
                            checkedIcon={<CheckBoxOutlineChecked style={{ fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.PINK }}/>}
                        />
                    }
                    label={
                        <Box>
                            <Text className={Styles.checkboxLabel}>J'accepte les retours</Text>
                        </Box>
                    }
                />}
                </Grid>

                <Button onClick={handleSubmit(handleClickPreview)} color={'secondary'} variant={'contained'} disableElevation className={Styles.pictureButton} startIcon={<CameraAlt className={Styles.cameraIcon} fontSize={'inherit'}/>} style={{ marginTop: 20 }}>
                    Prévisualiser l'article
                </Button>

                {screenKey === 'CreateProduct' && currentProductsCount > 1 && <Button onClick={handleClickDelete} color={'secondary'} variant={'contained'} disableElevation className={Styles.pictureButton} startIcon={<DeleteIcon className={Styles.cameraIcon} fontSize={'inherit'}/>} style={{ marginTop: 20 }}>
                    Supprimer l'article
                </Button>}
       
                <PictureCropModal visible={pictureCropModalVisible} setVisible={setPictureCropModalVisible} pictureUri={currentPictureUri} validatePicture={handleCropPicture}/>
                <ConfirmationModal
                    visible={imageErrorModalVisible}
                    setVisible={setImageErrorModalVisible}
                    title={'Erreur à l\'importation de l\'image'}
                    message={'Le fichier que vous essayez d\'importer n\'est pas une image valide.'}
                    onConfirm={() => {setImageErrorModalVisible(false);}}/>
            </Grid>
        </form>
    );
});

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.login.token,
        criteria: state.criteria,
        familyTree: state.family.familyTree,
        previewProduct: state.product.previewProduct,
        product: state.product.currentProducts[ownProps.productKey],
        currentProductsCount: Object.keys(state.product.currentProducts).length,
        user: state.user.me,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveProduct: (product, pictures, newBrand) => {
            dispatch({ type: 'SAVE_PRODUCT_REQUESTED', product, pictures, newBrand });
        },
        savePreviewProduct: (product, pictures) => {
            dispatch({ type: 'SAVE_PREVIEW_PRODUCT', product, pictures });
        },
        fetchProduct: (id) => {
            dispatch({ type: 'FETCH_PRODUCT_REQUESTED', id });
        },
        saveCurrentProduct: (productKey, product) => {
            dispatch({ type: 'UPDATE_CURRENT_PRODUCT', productKey, product });
        },
    };
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(Component);

export default VisibleComponent;
