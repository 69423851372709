import { call, put, takeLatest, cancelled, takeEvery } from 'redux-saga/effects'
import {
    FetchShop,
    FetchShops,
    FetchLovedShops,
    FetchProductShop,
    SearchShops,
    SubscribeToShop,
    UnsubscribeFromShop,
    FetchSubscribedShops,
    SaveShop,
    EvaluateShop, ValidateAddress, DeleteShop
} from '../Endpoint/Shop'
import { SavePicture } from '../Endpoint/Picture'
import { handleError } from "../../Utils/SagaUtils";

function* fetchShop(action) {
    try {
        const shop = yield call(FetchShop, action.id);
        yield put({type: "FETCH_SHOP_SUCCEEDED", shop: shop.data});
    } catch (e) {
        yield put(handleError("FETCH_SHOP_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchProductShop(action) {
    try {
        const shop = yield call(FetchProductShop, action.id);
        yield put({type: "FETCH_PRODUCT_SHOP_SUCCEEDED", shop: shop.data, productId: action.id});
    } catch (e) {
        yield put(handleError("FETCH_PRODUCT_SHOP_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* addToCart(action) {
    try {
        if (!action.product.shop.name) {
            const shop = yield call(FetchProductShop, action.product.id);
            yield put({type: "FETCH_PRODUCT_SHOP_SUCCEEDED", shop: shop.data, productId: action.product.id, keepLoading: true});
        }
    } catch (e) {
        yield put(handleError("FETCH_PRODUCT_SHOP_FAILED", e));
    }
}

function* fetchShops(action) {
    try {
        const shops = yield call(FetchShops);
        yield put({type: "FETCH_SHOPS_SUCCEEDED", shops: shops.data["hydra:member"], pagination: shops.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("FETCH_SHOPS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* saveShop(action) {
    try {
        let result;
        if (action.picture) {
            result = yield call(SavePicture, action.picture);
            if (result && result.data) {
                action.shop.picture = "/api/media_objects/" + result.data["id"];
            }
        }

        result = yield call(SaveShop, action.shop);
        yield put({type: "SAVE_SHOP_SUCCEEDED", shop: result.data, keepLoading: action.hideLoader});
    } catch (e) {
        yield put(handleError("SAVE_SHOP_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* validateAddress(action) {
    try {
        let result = yield call(ValidateAddress, action.city, action.zipcode, action.address);
        yield put({type: "VALIDATE_SHOP_SUCCEEDED"});
    } catch (e) {
        yield put(handleError("SAVE_SHOP_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* searchShops(action) {
    try {
        const shops = yield call(SearchShops, action.page, action.shopType ? action.shopType : null, action.name, action.itemsPerPage);
        yield put({type: "SEARCH_SHOPS_SUCCEEDED", shops: shops.data["hydra:member"], pagination: shops.data["hydra:view"], keepLoading: action.hideLoader});
    } catch (e) {
        yield put(handleError("SEARCH_SHOPS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* evaluateShop(action) {
    try {
        const result = yield call(EvaluateShop, action.shopId, action.note);
        yield put({type: "EVALUATE_SHOP_SUCCEEDED", shop: result.data});
    } catch (e) {
        yield put(handleError("EVALUATE_SHOP_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchLovedShops(action) {
    try {
        const shops = yield call(FetchLovedShops);
        yield put({type: "FETCH_LOVED_SHOPS_SUCCEEDED", shops: shops.data["hydra:member"], pagination: shops.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("FETCH_LOVED_SHOPS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchSubscribedShops(action) {
    try {
        const shops = yield call(FetchSubscribedShops, action.page, action.itemsPerPage);
        yield put({type: "FETCH_SUBSCRIBED_SHOPS_SUCCEEDED", shops: shops.data["hydra:member"], pagination: shops.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("FETCH_SUBSCRIBED_SHOPS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* subscribeToShop(action) {
    try {
        let result;
        if (action.subscribe === true) {
            result = yield call(SubscribeToShop, action.id);
        } else {
            result = yield call(UnsubscribeFromShop, action.id);
        }

        if (action.page) {
            yield (fetchSubscribedShops(action));
        } else {
            yield put({type: "SUBSCRIBE_TO_SHOP_SUCCEEDED", shop: result.data});
        }
    } catch (e) {
        yield put(handleError("SUBSCRIBE_TO_SHOP_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* deleteShop(action) {
    try {
        let result = yield call(DeleteShop, action.id);
        yield put({type: "DELETE_SHOP_SUCCEEDED", id: action.id})

    } catch (e) {
        yield put(handleError("DELETE_SHOP_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* mySaga() {
    yield takeLatest("FETCH_SHOP_REQUESTED", fetchShop);
    yield takeLatest("FETCH_PRODUCT_SHOP_REQUESTED", fetchProductShop);
    yield takeLatest("FETCH_SHOPS_REQUESTED", fetchShops);
    yield takeLatest("SEARCH_SHOPS_REQUESTED", searchShops);
    yield takeLatest("FETCH_LOVED_SHOPS_REQUESTED", fetchLovedShops);
    yield takeLatest("SUBSCRIBE_TO_SHOP_REQUESTED", subscribeToShop);
    yield takeLatest("FETCH_SUBSCRIBED_SHOPS_REQUESTED", fetchSubscribedShops);
    yield takeLatest("SAVE_SHOP_REQUESTED", saveShop);
    yield takeLatest("EVALUATE_SHOP_REQUESTED", evaluateShop);
    yield takeLatest("DELETE_SHOP_REQUESTED", deleteShop);
    yield takeEvery("ADD_TO_CART", addToCart);
}

export default mySaga;