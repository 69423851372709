const initialState = {
    params: [],
};


function reduc(state = initialState, action) {
    switch (action.type) {
        case "FETCH_PARAMS_SUCCEEDED":
            return Object.assign({}, state, {
                params: action.params
            });
    }

    return state
}

export default reduc