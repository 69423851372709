import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
    },
    selectedValueWrapper: {
        ...GlobalStyle.rowCenteredHW,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        width: Constants.GET_SIZE(95),
        height: Constants.GET_SIZE(40),
    },
    selectedValue: {
        fontWeight: "bold"
    },
    dropdown: {
        position: "absolute",
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        top: Constants.GET_SIZE(40),
        width: Constants.GET_SIZE(95),
    },
    dropdownValue: {
        padding: Constants.GET_SIZE(10),
        backgroundColor: "white",
        textAlign: "center",
        fontWeight: "bold"
    }
}));
