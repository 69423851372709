import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        // alignItems: "center",
        width: "100%",
    },
    row: {
        ...GlobalStyle.rowCentered,
    },

    datePickerWrapper: {
        alignItems: "center",
        marginLeft: 70,
        marginRight: 70,
        display: "flex",
        flexDirection: "column"
    },
    datePickerLabel: {

        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        textAlign: "center"
    },
    datePickerPicker: {

    },
    datePickerInput: {

        color: Constants.PINK,
        fontWeight: "bold"
    },

    grayBlock: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        paddingTop: Constants.GET_SIZE(25),
        paddingBottom: Constants.GET_SIZE(25),
        paddingRight: Constants.GET_SIZE(50),
        paddingLeft: Constants.GET_SIZE(50)
    },

    label: {

        marginLeft: Constants.GET_SIZE(10),
        marginRight: Constants.GET_SIZE(10),
        fontWeight: "bold"
    },
    value: {

        color: Constants.PINK,
        fontWeight: "bold"
    },

    button: {
        ...GlobalStyle.button,
        paddingTop: Constants.GET_SIZE(10),
        paddingBottom: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(35),
        paddingRight: Constants.GET_SIZE(35),
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },

    pickerWrapper: {
        ...GlobalStyle.rowCentered,
        width: Constants.GET_SIZE(80),
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderRadius: Constants.GET_SIZE(10),
        height: Constants.GET_SIZE(58)
    },
    pickerItem: {

    },
    picker: {

        borderWidth: 0,
        backgroundColor: Constants.WHITE,
        height: Constants.GET_SIZE(43),
        width: "100%",
        marginRight:  Constants.FONT_SIZE_NORMAL,
        marginLeft:  Constants.FONT_SIZE_NORMAL,
        color: Constants.FONT_GRAY,
        fontWeight: "bold"
    },

    searchShopWrapper: {
        zIndex: 999,
    },
    userAutocompleteWrapper: {
        position: "absolute",
        top: Constants.GET_SIZE(45),
        maxWidth: Constants.GET_SIZE(430),
        maxHeight: Constants.GET_SIZE(180),
        zIndex: 999,
        backgroundColor: Constants.WHITE,
        borderWidth: 1,
        borderColor: Constants.BORDER_GRAY
    },
    shopAutocompleteWrapper: {
        position: "absolute",
        top: Constants.GET_SIZE(70),
        width: Constants.GET_SIZE(430),
        zIndex: 999,
        backgroundColor: Constants.WHITE,
        borderWidth: 1,
        borderColor: Constants.BORDER_GRAY
    },
    shopAutocompleteButton: {
        paddingLeft: Constants.GET_SIZE(10),
        paddingRight: Constants.GET_SIZE(10),
        paddingTop: Constants.GET_SIZE(5),
        paddingBottom: Constants.GET_SIZE(5),
        borderBottomWidth: 1,
        borderColor: Constants.BORDER_GRAY
    },
    shopAutocompleteLabel: {
    },
    smallInput: {
        height: Constants.GET_SIZE(17)
    },
    smallInputWrapper: {
        height: Constants.GET_SIZE(27),
        marginTop: 0,
        marginBottom: 0,
    }
}));
