import React from 'react';
import { connect } from 'react-redux'

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";


import Text from "../../Component/FontText";

import ConfirmationModalStylesFunc from "../../../Style/ConfirmationModalStyle"
import CustomCheckbox from "../../Component/CustomCheckbox";
import * as Constants from "../../../Style/Constants";
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../../Utils/QueryUtils";
import {CircularProgress} from "@material-ui/core";
import Link from "@material-ui/core/Link";


function Component ({visible, setVisible, updateMe}) {
    let ConfirmationModalStyles = ConfirmationModalStylesFunc();

    const [ignore, setIgnore] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const onConfirm = () => {
        if (ignore) {
            updateMe({
                hideLiveListPopup: true
            })
            setVisible(false);
        } else {
            setVisible(false);
        }
    }

    let _renderButtons = () => {
        return (
            <Grid container justify={"center"}>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"} onClick={onConfirm} disabled={loading}>
                    J'ai compris
                    {loading && <Grid container justify={"center"} alignItems={"center"} style={{position: "absolute", left: 0, right: 0, top: 0, bottom:0}}>
                        <CircularProgress color={"primary"} size={22} />
                    </Grid>}
                </Button>
            </Grid>
        );
    }

    let _renderModalContent = () => (
        <Box className={ConfirmationModalStyles.modalContent}>
            <Text className={ConfirmationModalStyles.modalTitle}>Les lives</Text>
            <Text>
                Avec les Lives, Violette Sauvage vous donne la possibilité d’acheter et de vendre
                en direct. Il est donc possible de voir un produit sous toutes ses coutures, poser
                des questions, y répondre et proposer un tarif directement à la vendeuse.
                <br /><br />
                <strong>Comment ça fonctionne ?</strong>
                <br /><br />
                <strong>Côté vendeur</strong>, le parcours est assez simple. Tout d’abord il faut <strong>suivre le
                tutoriel</strong> et télécharger le module OBS. Ensuite sélectionner le créneau de son
                choix pour le prochain live, choisir les produits à présenter durant le live, et
                partager/diffuser un maximum le lien du live auprès de sa communauté pour se
                garantir un maximum d’audience et s’assurer un maximum de ventes.
                <br /><br />
                <strong>Côté acheteur</strong>, c’est encore plus simple. Il faut se connecter à son compte,
                sélectionner le live que l’on veut suivre, participer en direct au live via le chat qui
                vous permettra de poser vos questions, demander des précisions et faire des
                offres d’achats au vendeur directement. Si votre offre est acceptée <strong>vous avez 2
                minutes</strong> pour finaliser le paiement, passé ce délais votre offre aura expiré et le
                produit sera de nouveau en vente durant le live.

            </Text>

            <Grid container style={{marginBottom: 10}}>
                <CustomCheckbox
                    containerClass={ConfirmationModalStyles.checkBox}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={ConfirmationModalStyles.checkboxLabel}>Ne plus afficher ce message</Text>}
                    checked={ignore} onPress={() => setIgnore(!ignore)}
                    size={Constants.CHECKBOX_SIZE_BIG} />
                {_renderButtons()}
            </Grid>
        </Box>
    );

    return (
        <Dialog open={visible} fullWidth>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateMe: (payload) => {
            dispatch({type: 'SET_HIDE_LIVE_LIST_POPUP', ignore: payload.hideLiveListPopup})
        },
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;