import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "../GlobalStyle";
import * as Constants from "../Constants"


export default makeStyles((theme) => ({
    // Global
    container: {
        backgroundColor: Constants.WHITE,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
        marginBottom: 70,
        paddingLeft: 20,
        paddingRight: 20,
        maxWidth: 1024,
        margin: "auto",

    },
    arianeText: {
        ...GlobalStyle.uppercaseText
    },
}));
