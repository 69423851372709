import React from "react";

import Link from "@material-ui/core/Link";

import StaticCommon from "./StaticCommon";
import Banner from "../../../Assets/Images/banners/MeetingVS.jpg"


function Component({title}) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "Mentions légales",
        blocks: [
            {
                title: "Identification de l'éditeur",
                content: [
                    { text: <strong>VIOLETTE SAUVAGE</strong>},
                    { text: "21, rue de la Convention - 94270 LE KREMLIN-BICÊTRE" },
                    { text: <Link href={'mailto:legal@violettesauvage.fr'}>legal@violettesauvage.fr</Link> },
                    { text: "N° SIREN : 537532764" },
                    { text: "N° SIRET : FR76537532764" },
                    { text: "RCS : Creteil B 537 532 764" },
                    { text: "Directeur de la publication : Rino coletti" },
                ]
            },
            {
                title: "Traitement des données à caractère personnel",
                content: [
                    { text: "Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez sur le site internet, d'un droit d'accès, de modification, de rectification et de suppression des données personnelles qui vous concernent. Vous pouvez demander la modification, la rectification ou la suppression de vos données personnelles vous concernant. Pour le faire, il vous suffit d'envoyer un courrier par voie électronique ou postale à la Direction de l'information légale et administrative citée ci-dessus en justifiant de votre identité." },
                ]
            },
            {
                title: "Conception graphique, référencement site internet, hébergement, gestionnaire des statistiques",
                content: [
                    { text: ["La création graphique, le développement ergonomique ainsi que le développement des modèles de pages ont été créés par la société ASB DIGITAL - 5 Place Charles Hernu, 69100 Villeurbanne - Téléphone : 04 81 65 02 75 - ", <Link href={'mailto:contact@asb-digital.fr'}>contact@asb-digital.fr</Link>] },
                ]
            },
            {
                title: "Liens hypertextes (responsabilité)",
                content: [
                    { text: "Ce site internet peut éventuellement proposer de nombreux liens vers d'autres sites comme dans un article de blog. Nous indiquons systématiquement vers quel site nous vous proposerons de naviguer. Cependant, ces pages web dont les adresses sont régulièrement vérifiées ne font pas partie de notre site : elles n'engagent donc pas la responsabilité de la rédaction du site." },
                ]
            },
            {
                title: "Propriété intellectuelle",
                content: [
                    { text: "Droit de reproduction"},
                    { text: "La reproduction ou la réutilisation des contenus de ce site est autorisée seulement sous réserve de mentionner la date et la source et plus globalement de respecter la licence ouverte. La copie des autres pages du site, des logos et des images doit avoir obtenu l'autorisation du directeur de la publication." },
                ]
            },

        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"Toutes les mentions légales & obligatoires  | Violette Sauvage"}
                      metaDesc={"Retrouvez toutes les mentions légales obligatoires de votre vide-dressing préféré Violette Sauvage."}
                      arianeCurrentName={"Mentions légales"} />
    );
}

export default Component
