
export const handleError = (type, error) => {
    if (error.response) {
        if (error.response.status === 404) {
            return {type: type, message: "NOT_FOUND"};
        } else if (error.response && error.response.data) {
            if (error.response.data.violations) {
                return {type: type, errors: error.response.data.violations};
            } else if (error.response.data.message) {
                return {type: type, message: error.response.data.message};
            } else if (error.response.data["hydra:description"]) {
                return {type: type, message: error.response.data["hydra:description"]};
            }
        }
    }

    return {type: type, message: error.message};
}
