import axios from 'axios';
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils'

export const FetchFromTo = (startDate, endDate) => {
    let url = getApiUrl('stat_days', {startDate, endDate});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const FetchForTime = (timeType, year, month, day) => {
    let url = getApiUrl('stat_days', {timeType, year, month, day});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const FetchNbDisputes = () => {
    let url = getApiUrl('disputes/count');

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const FetchNbReturnsForUser = (id) => {
    let url = getApiUrl('users/' + id + '/return_count');

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const FetchNbShops = () => {
    let url = getApiUrl('shops/count');

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const FetchNbProducts= () => {
    let url = getApiUrl('products/count');

    return axios.get(url, {headers: setDefaultHeaders()});
};
