import {makeStyles} from "@material-ui/core/styles";


import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    rpgdWrapper: {
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: Constants.PINK,
        borderTop: "1px solid white",
        zIndex: 9999,
        padding: 20,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 10,
            paddingBottom: 10,
        }
    },
    rgpdText: {
        color: "white",
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10
        }
    },
    rgpdButton: {
        backgroundColor: "white",
        color: "black"
    },
    rgpdButton2: {
        backgroundColor: "transparent",
        color: "white",
        border: "1px solid white",
        marginLeft: 5,
        padding: 19,
        paddingLeft: 69,
        paddingRight: 69
    },
    rpgdLink: {
        cursor: "pointer",
        color: "white",
        textDecoration: "underline"
    }
}));
