import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"
import {BORDER_GRAY, FONT_SIZE_BIGGER, FONT_SIZE_NORMAL} from "./Constants";


export default makeStyles((theme) => ({
    wholePage: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        minHeight: '100vh'
    },
    // Global
    headerContainer: {
        backgroundColor: Constants.WHITE,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 50,
        marginBottom: 70,
        paddingLeft: 20,
        paddingRight: 20,
        "& > div": {
            maxWidth: 1024,
            margin: "auto"
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 30
        }
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 50,
        paddingBottom: 70,
        paddingLeft: 20,
        paddingRight: 20,
        "& > div": {
            maxWidth: 1024,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 15,
        }
    },
    pageTitle: {
        textTransform: "uppercase",
        textAlign: "center",
        fontSize: Constants.FONT_SIZE_BIG,
        marginBottom: 40,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
            marginBottom: 20,
        }
    },

    description: {
        maxWidth: 768,
        textAlign: "center",
        fontSize: Constants.FONT_SIZE_NORMAL,
        margin: "auto",
        marginBottom: 87,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
            marginBottom: 30,
        }
    },

    openCategory: {
        "& > $categoryAssociations": {
            height: "auto"
        }
    },
    closedCategory: {

    },
    categoryHeader: {
        borderBottomColor: Constants.PINK,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        cursor: "pointer",
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 30,
        }
    },

    categoryName: {
        fontWeight: 700,
        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },

    categoryNameSuffix: {
        marginLeft: "auto",
        fontWeight: 700,
        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    categoryAssociations: {
    },


    associationCard: {
        backgroundColor: Constants.WHITE,
        padding: 50,
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 20
        }
    },
    associationCardPicturesWrapper: {
        marginBottom: 25,
        position: "relative",
        "& > div:first-child": {
            width: '100%'
        }
    },
    associationCardImage: {
        width: "100%",
        aspectRatio: "2 / 1",
        position: "relative",
        float: "left"
    },
    associationCardTitleRow: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column-reverse"
        }
    },
    associationCardTitle: {
        fontWeight: 700,
        fontSize: Constants.FONT_SIZE_BIGGER,
        color: Constants.PINK,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
        }
    },
    associationCardSubTitle: {
        fontSize: Constants.FONT_SIZE_NORMAL,
        color: Constants.PINK,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
        }
    },
    associationCardButtonWrapper: {
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    associationCardDescription: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        color: Constants.LIGHT_GRAY,
        marginTop: 15,
        lineHeight: '150%',
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },

    picturesOverlay: {
        position: "absolute",
        bottom: 10,
        left: "50%",
        transform: "translateX(-50%)",
        width: "fit-content"
    },
    dotIcon: {
        color: Constants.BORDER_GRAY,
        fontSize: 28,
        cursor: "pointer",
        "&:not(:first-child)": {
            marginLeft: 15
        }
    },
    dotIconSelected: {
        color: Constants.PINK
    },

    youtubeOverlay: {
        position: "absolute",
        left: 0, right: 0, top: 50, bottom: 50,
        cursor: "pointer"
    }
}));
