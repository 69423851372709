import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        position: "absolute",
        top: Constants.FONT_SIZE_BIG,
        alignItems: 'center',
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        backgroundColor: Constants.WHITE,
        overflow: "visible",
        zIndex: 999,
        width: "max-content"
    },
    wrapperLeft: {
        right: 0,
    },
    selected: {
        color: Constants.PINK
    },
    dropdownBodyColumn: {
        margin: 10
    },
    categoryText: {
        lineHeight: Constants.FONT_SIZE_28,
        paddingRight: 20
    },
    categoryIcon: {
        fontSize: Constants.FONT_SIZE_28,
        color: Constants.PINK,
        position: "absolute",
        right: 0,
        top: 0
    },
    subCategory: {
        fontWeight: "bold",
        "&:hover": {
            color: Constants.PINK
        }
    },
    upwardTriangle: {
        position: "absolute",
        left: Constants.GET_SIZE(27),
        top: Constants.GET_SIZE(10),
        width: 0,
        height: 0,
        borderLeftWidth: Constants.GET_SIZE(15),
        borderLeftColor: "transparent",
        borderRightWidth: Constants.GET_SIZE(15),
        borderRightColor: "transparent",
        borderBottomWidth: Constants.GET_SIZE(15),
        borderBottomColor: Constants.WHITE,
        zIndex: 1000,
    },
    upwardTriangleBorder: {
        position: "absolute",
        left: Constants.GET_SIZE(26),
        top: Constants.GET_SIZE(9),
        width: 0,
        height: 0,
        borderLeftWidth: Constants.GET_SIZE(16),
        borderLeftColor: "transparent",
        borderRightWidth: Constants.GET_SIZE(16),
        borderRightColor: "transparent",
        borderBottomWidth: Constants.GET_SIZE(16),
        borderBottomColor: Constants.BORDER_GRAY,
    }
}));
