import React from "react";
import clsx from 'clsx';
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import { Link } from 'react-router-dom';
import Text from "../../Component/FontText";
import StylesFunc from "../../../Style/PaginatorStyle";


function Component({page, setPage, firstPage, lastPage, backgroundColor, currentUrl, smallBottom, className}) {
    let Styles = StylesFunc();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);


    let paginatorDom = [];

    if (currentUrl) {
        paginatorDom.push(
            <Grid item className={clsx({[Styles.hidden]: page === firstPage})}>
                <Link to={currentUrl + "/" + (page - 1)} style={{textDecoration: "none"}}>
                    <Text className={Styles.page}>{"<"}</Text>
                </Link>
            </Grid>
        );

    } else {
        paginatorDom.push(
            <Grid item className={clsx({[Styles.hidden]: page === firstPage})}>
                <Button onClick={() => setPage(page - 1)} size={"small"} className={Styles.button}>
                    <Text className={Styles.page}>{"<"}</Text>
                </Button>
            </Grid>
        );
    }

    if (page - 4 > firstPage) {
        paginatorDom.push(
            <Grid item>
                <Text className={Styles.page}>...</Text>
            </Grid>
        )
    }

    for (let i = Math.max(firstPage, page - 4); i <= Math.min(lastPage, page + 4); i++) {
        if (currentUrl) {
            paginatorDom.push(
                <Grid item>
                    <Link to={currentUrl + "/" + i} style={{textDecoration: "none"}}>
                        <Text className={[Styles.page, i === page ? Styles.currentPage : {}]}>{i}</Text>
                    </Link>
                </Grid>
            );

        } else {
            paginatorDom.push(
                <Grid item>
                    <Button onClick={() => setPage(i)} size={"small"} className={Styles.button}>
                        <Text className={[Styles.page, i === page ? Styles.currentPage : {}]}>{i}</Text>
                    </Button>
                </Grid>
            )
        }
    }

    if (page + 4 < lastPage) {
        paginatorDom.push(
            <Grid item>
                <Text className={Styles.page}>...</Text>
            </Grid>
        )
    }

    if (currentUrl) {
        paginatorDom.push(
            <Grid item className={clsx({[Styles.hidden]: page === lastPage})}>
                <Link to={currentUrl + "/" + (page + 1)} style={{textDecoration: "none"}}>
                    <Text className={Styles.page}>{">"}</Text>
                </Link>
            </Grid>
        );

    } else {
        paginatorDom.push(
            <Grid item className={clsx({[Styles.hidden]: page === lastPage})}>
                <Button onClick={() => setPage(page + 1)} size={"small"} className={Styles.button}>
                    <Text className={Styles.page}>{">"}</Text>
                </Button>
            </Grid>
        );
    }

    if (lastPage !== firstPage && lastPage > 0) {
        return (
            <Grid container className={clsx(Styles.paginator, {[Styles.smallBottom]: smallBottom}, className)} style={backgroundColor ? {backgroundColor} : {}}>
                {paginatorDom}
            </Grid>
        )
    } else {
        return null;
    }
}

export default Component;