import React from "react";
import { withStyles } from '@material-ui/core/styles';

import MetaTags from "react-meta-tags";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import {useQuery} from "react-query";
import {getAssociationsByCategory} from "../../API/Queries/Associations";
import Text from "../Component/FontText";
import BreadcrumbStylesFunc from "../../Style/Components/BreadcrumbStyle";
import StylesFunc from "../../Style/AssociationsStyle";
import AssociationCard from "./Component/AssociationCard";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const Accordion = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        backgroundColor: "transparent",
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        // borderBottom: '1px solid rgba(0, 0, 0, .125)',
        // marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: 0,
    },
}))(MuiAccordionDetails);

function Component({navigation}) {
    let BreadcrumbStyles = BreadcrumbStylesFunc();
    let Styles = StylesFunc();

    let { status, data, error, isFetching } = useQuery({
        queryKey: 'associations',
        queryFn: getAssociationsByCategory
    });

    const [openCategory, setOpenCategory] = React.useState(false);

    const handleChange = (key) => (event, newExpanded) => {
        setOpenCategory(newExpanded ? key : false);
    };

    let _getCategory = (category, key) => {
        return <Grid container item xs={12}>
            <Accordion disableElevation expanded={key === openCategory} onChange={handleChange(key)} style={{width: '100%'}}>
                <AccordionSummary className={Styles.categoryHeader}
                                     expandIcon={key === openCategory ? <RemoveIcon /> : <AddIcon />}>
                    <Text className={Styles.categoryName}>{category.name}</Text>
                </AccordionSummary>
                <AccordionDetails  className={Styles.categoryAssociations}>
                    <Grid container item xs={12}>
                        {category.associations.map(association => <AssociationCard association={association}/>)}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>;
    }

    return (
        <Box className={Styles.wholePage}>
            <MetaTags>
                <title>Les associations | Violette Sauvage</title>
                <meta name="description"
                      content={"TODO"}/>
            </MetaTags>
            <Grid container className={Styles.headerContainer}>
                <Grid item container xs={12} sm style={{marginBottom: 20}}>
                    <Link href={"/"} style={{textDecoration: "none", color: "black"}}><Text className={BreadcrumbStyles.arianeText}>Accueil&nbsp;/&nbsp;</Text></Link>
                    <Text className={BreadcrumbStyles.arianeText}>Associations</Text>
                </Grid>
                <Grid item container xs={12} style={{justifyContent: "center"}}>
                    <Text className={Styles.pageTitle}>Associations</Text>
                </Grid>
            </Grid>
            <Grid container className={Styles.container}>
                <Grid item container xs={12}>
                    <Text className={Styles.description}>
                        Violette Sauvage s’engage auprès d'associations ! 1% de votre achat sera pris sur la commission
                        de Violette Sauvage pour être reversé à l'association de votre choix.
                        Découvrez la liste des associations.
                    </Text>
                </Grid>
                <Grid item container xs={12}>
                    { data && data['hydra:member'].length > 0 ? data['hydra:member'].map(_getCategory) : <Text style={{marginLeft: 30, marginBottom: 20}}>Pas d'association pour le moment.</Text>}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Component

