import React from "react";

import * as Constants from "../../Style/Constants";
import CheckBox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Controller } from "react-hook-form";

export default function CheckboxField({control, setValue, watch, errors, name, label, required, multiline}) {
    let rules = {};

    if (required) {
        rules['required'] = "Ce champ est obligatoire";
    }

    return (
        <Controller
            render={({ onChange, value, ...props }) => {
                return (
                    <FormControlLabel
                        {...props}
                        name={name}
                        style={{marginBottom: 20}}
                        control={<CheckBox
                            color={"primary"}
                            icon={<CheckBoxOutlineBlankIcon fontSize={Constants.CHECKBOX_SIZE_BIG}/>}
                            checkedIcon={<CheckBoxIcon fontSize={Constants.CHECKBOX_SIZE_BIG}/>}
                            checked={value ?? false}
                            onChange={e => onChange(e.target.checked)}
                        />}
                        label={label}
                        labelPlacement={"end"}
                    />
                )
            }}
            onChange={([, data]) => data}
            name={name}
            control={control}
        />
    );
}
