import React, { useState } from 'react';

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Divider from "@material-ui/core/Divider"
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Text from "../../Component/FontText";
import Color from "./Color";
import Picker from "../../Component/CustomPicker";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { connect } from 'react-redux'

import TextInput from "../../Component/FontInput";
import ProductStylesFunc from "../../../Style/LiveProductDetailsStyle";
import * as Constants from "../../../Style/Constants"
import Grid from "@material-ui/core/Grid";
import {TouchableOpacity} from "react-native-web";
import {Image} from "react-native";
import {getImageUrl} from "../../../Utils/ImageUtils";
import HorizontalSlider from "./HorizontalSlider";
import CardMedia from "@material-ui/core/CardMedia";
import clsx from "clsx";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {
    Pagination
} from 'swiper';
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import {useTheme} from "@material-ui/core/styles";
import ErrorStyleFunc from "../../Styles/Error";

SwiperCore.use([Pagination]);


function Component ({visible, setVisible, liveProduct, sendProposition, instantBuy, isLiveAdmin, propositionInProgress, setPropositionInProgress}) {
    let Styles = ProductStylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    let theme = useTheme();

    let [currentProposition, setCurrentProposition] = React.useState();
    let [error, setError] = React.useState(null);

    let onInstantBuy = () => {
        instantBuy(liveProduct);
        setVisible(false);
    };

    let onSendProposition = () => {
        setPropositionInProgress(true);
        let parsedPrice = currentProposition.replaceAll(',', '.');
        parsedPrice = parseFloat(parsedPrice);

        sendProposition(liveProduct.product.id, parsedPrice);

        // let waitForProp = () => {
        //     if (!propositionInProgress) {
        //         if (parsedPrice >= liveProduct.minPrice) {
        //             setVisible(false);
        //         } else {
        //             setError('L\'enchère minimum pour ce produit est de ' + liveProduct.minPrice + '€');
        //         }
        //     } else {
        //         setTimeout(waitForProp, 1000);
        //     }
        // }
        //
        // waitForProp();
    }

    let onCancel = () => {
        setVisible(false);
    };

    React.useEffect(() => {
        console.log("UseEffect liveProduct");
        if (currentProposition) {
            let parsedPrice = currentProposition.replaceAll(',', '.');
            parsedPrice = parseFloat(parsedPrice);

            console.log("1", parsedPrice, propositionInProgress);
            if (parsedPrice && !propositionInProgress) {
                console.log("2");
                if (parsedPrice >= liveProduct.minPrice) {
                    console.log("3");
                    setVisible(false);
                } else {
                    console.log("4");
                    setError('L\'enchère minimum pour ce produit est de ' + liveProduct.minPrice + '€');
                }
            }
        }
    }, [liveProduct, propositionInProgress]);

    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));


    let _renderButtons = () => (
        <Grid container>
            <Grid item xs={12}>
                <Text className={Styles.buttonsTitle}>Proposer un prix</Text>
            </Grid>
            <Grid item container>
                <Grid item xs={6}>
                    <TextInput
                        classes={{root: Styles.propositionInputRoot}}
                        className={Styles.propositionInput}
                        placeholder={"Proposition"}
                        value={currentProposition}
                        onChange={(event) => {setCurrentProposition(event.target.value); setError(null);}}
                        InputProps={{ disableUnderline: true }}/>
                </Grid>
                <Grid item xs={6}>
                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"} onClick={onSendProposition} className={Styles.button}>
                        Envoyer
                    </Button>
                </Grid>
                {error && <Grid item xs={12}>
                    <Text className={ErrorStyle.error}>{error}</Text>
                </Grid>}
            </Grid>
            {liveProduct.buyable && <Grid item container xs={12} className={Styles.buttonsDividerWrapper}>
                <Grid container item xs alignItems={"center"}>
                    <Divider className={Styles.buttonsDivider} />
                </Grid>
                <Grid item>
                    <Text className={Styles.buttonsDividerText}>OU</Text>
                </Grid>
                <Grid container item xs alignItems={"center"}>
                    <Divider className={Styles.buttonsDivider} />
                </Grid>
            </Grid>}
            {liveProduct.buyable && <Grid item xs={12}>
                <Button disableElevation size={"small"} variant={"contained"}  onClick={onInstantBuy} className={Styles.button}>
                    Acheter maintenant
                </Button>
            </Grid>}
        </Grid>
    );

    let product = liveProduct.product;

    let _renderModalContent = () => (
        <Box className={Styles.modalContent}>
            <Box className={Styles.productInfo}>
                <Grid container className={Styles.productInfoName} justify={"space-between"} alignItems={"center"}>
                    <Grid>
                        <Text className={Styles.productInfoNameText}>{product.name}</Text>
                    </Grid>
                    <Grid>
                        <IconButton onClick={onCancel}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Box className={Styles.productInfoCatBrandPrice}>
                    <Text
                        className={Styles.productInfoCatBrandText}>{product.category.name}{product.brand ? " - " + product.brand.value : ""}</Text>
                    <Text className={Styles.productInfoPriceText}>{product.realPrice} €</Text>
                </Box>
                <Box className={Styles.productionDescription}>
                    <Text
                        className={Styles.productionDescriptionContent}>{product.description}</Text>
                </Box>
                <Grid container justify={"space-between"} alignItems={"center"}>
                    {product.sizes && product.sizes.length > 0 &&
                    <Grid item className={clsx(Styles.productInfoWrapper)}>
                        <Text className={Styles.sectionTitle}>Taille</Text>
                        <Box className={clsx(Styles.productInfoValue, Styles.productInfoValueSelected)}>
                            <Text>{product.sizes[0].value}</Text>
                        </Box>
                    </Grid>
                    }
                    {product.colors && product.colors.length > 0 &&
                    <Grid item className={Styles.productInfoWrapper}>
                        <Text className={Styles.sectionTitle}>Couleur</Text>
                        <Box className={clsx(Styles.productInfoValue, Styles.productInfoValueSelected)}>
                            <Color color={product.colors[0]} circleSize={Constants.FONT_SIZE_MEDIUM}/>
                        </Box>
                    </Grid>
                    }
                </Grid>
                <Box>
                    <Box className={Styles.productImages}>
                        <Swiper
                            className={clsx(Styles.mobile, Styles.swipeableViews)}
                            pagination={{
                                clickable: false
                            }}
                            spaceBetween={20}>
                            {product.pictures.map(picture =>
                                <SwiperSlide className={Styles.swiperSlide}>
                                    <CardMedia component={"img"}
                                        image={picture.contentUrl ? getImageUrl(picture.contentUrl, "big") : picture.uri}
                                        className={Styles.image}/>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </Box>
                </Box>
            </Box>
            {!isLiveAdmin && <Box className={Styles.buttonRow}>
                {_renderButtons()}
            </Box>}
        </Box>
    );

    return (
        <Dialog open={visible} fullScreen={fullScreen}>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;
