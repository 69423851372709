import React from "react";
import clsx from "clsx";

import Box from "@material-ui/core/Box";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/Back/UserListStyle"
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import Button from "@material-ui/core/Button";

import {
    useQuery,
    useMutation,
    useQueryClient
} from 'react-query'
import {getAssociations, deleteAssociation} from "../../API/Queries/Associations";
import {useSnackbar} from "notistack";
import Link from "@material-ui/core/Link";


function Component({history, navigation, token}) {
    const { enqueueSnackbar } = useSnackbar();

    let queryClient = useQueryClient();
    let Styles = StylesFunc();
    let page, setPage, search, setSearch, type, setType;
    if (history.location.state && history.location.state.listState) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [page, setPage] = React.useState(history.location.state.listState.page);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [search, setSearch] = React.useState(history.location.state.listState.search);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [type, setType] = React.useState(history.location.state.listState.type ? history.location.state.listState.type : -1);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [page, setPage] = React.useState(1);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [search, setSearch] = React.useState("");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [type, setType] = React.useState(-1);
    }
    let [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
    let [deleteId, setDeleteId] = React.useState(null);


    let pagination = {};
    let { status, data, error, isFetching } = useQuery({
        queryKey: 'associations',
        queryFn: getAssociations
    });
    let { mutate, isLoading } = useMutation(deleteAssociation, {
        onSuccess: data => {
        },
        onSettled: () => {
            queryClient.invalidateQueries('associations');
            enqueueSnackbar('Association bien supprimée', {variant: 'success'});
        }
    });

    React.useEffect(() => {
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    let deleteAssociation2 = () => {
        mutate(deleteId);
    }

    let handleClickEditAssociation = (association) => {
        history.push("/admin/editer_association/" + association.id, {listState: {page, search}})
    };

    let handleClickDeleteAssociation = (association) => {
        setDeleteId(association.id);
        setDeleteModalVisible(true);
    };

    let _getAssociationHeaderRow = () => {
        return <Box className={Styles.headerRow} style={{paddingLeft: 30, paddingRight: 30}}>
            <Text className={clsx(Styles.headerCell)} style={{width: '20%'}}>Catégorie</Text>
            <Text className={clsx(Styles.headerCell)} style={{width: '20%'}}>Nom</Text>
        </Box>
    };

    let _getAssociationRow = (association) => {
        return <Box className={Styles.dataRow} style={{paddingLeft: 30, paddingRight: 30}}>
            <Text className={Styles.dataCellText} style={{width: '20%'}}>{association.category.name}</Text>
            <Text className={Styles.dataCellText} style={{width: '20%'}}>{association.name}</Text>
            <Link onClick={() => handleClickEditAssociation(association)} style={{cursor: "pointer", marginLeft: "auto", marginRight: 10}}>
                <Text className={clsx(Styles.dataCellText, Styles.link)} style={{width: 'max-content'}}>Editer</Text>
            </Link>
            <Link onClick={() => handleClickDeleteAssociation(association)} style={{cursor: "pointer"}}>
                <Text className={clsx(Styles.dataCellText, Styles.link)} style={{width: 'max-content'}}>Supprimer</Text>
            </Link>
        </Box>
    };

    return <Base
        title={"Liste des associations"}
        currentScreen={"ASSOCIATION_LIST"}
        pagination={pagination}
        page={page}
        setPage={setPage}
        content={
            <Box className={Styles.container}>
                <Box className={Styles.tabRow}>
                    <Button disableElevation variant={"contained"} color={"primary"} size={"small"} onClick={() => history.push('/admin/creer_association')}>Créer association</Button>
                </Box>
                {_getAssociationHeaderRow()}
                <Box className={Styles.dataWrapper}>
                    { data && data['hydra:member'].length > 0 ? data['hydra:member'].map(_getAssociationRow) : <Text style={{marginLeft: 30, marginBottom: 20}}>Pas d'association pour le moment.</Text>}
                </Box>
                <ConfirmationModal
                    visible={deleteModalVisible}
                    setVisible={setDeleteModalVisible}
                    title={"Suppression de l'association"}
                    message={"Êtes-vous sûr de vouloir supprimer cette association ?"}
                    onConfirm={() => {setDeleteModalVisible(false); deleteAssociation2(deleteId);}}
                    onCancel={() => {setDeleteModalVisible(false)}}/>
            </Box>
        }
    />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
