import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core//Paper';
import Typography from '@material-ui/core//Typography';
import Image from "react-native-web/dist/exports/Image";
import Grid from "@material-ui/core/Grid"
import Link from '@material-ui/core//Link';
import Box from '@material-ui/core//Box';

function Hero(props) {
  const { data } = props;

  return (
    <Paper
      style={{
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${data.picture})`,
        height: "248px"
      }}
    >
        <Link href={data.link} style={{ width: "100%", height: "100%", float: 'left' }}>
        <Box style={{ zIndex: 1, left: "20px", top: "20px", textAlign: "left", position: "absolute", width: "55%", color: "black" }}>
            <Typography component="p" variant="p" style={{ fontSize: "20px", lineHeight: '1.3', marginBottom: "10px" }}>
                Nouveau
            </Typography>
            <Typography component="h3" variant="h3" style={{ fontSize: "20px", lineHeight: '1.3' }}>
                {data.label}
            </Typography>
        </Box>
        </Link>
    </Paper>
  );
}

Hero.propTypes = {
  data: PropTypes.shape({
    picture: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default Hero;