import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';
import Text from "../Component/FontText";
import Base from "./Base";
import StylesFunc from "../../Style/Back/CriteriaListStyle";
import * as Constants from "../../Style/Constants";
import TextInput from "../Component/FontInput";
import SwitchSelector from "react-switch-selector";

function Component({
  history,
  token,
  searchBrands,
  brandsList,
  brandsPagination,
  updateBrand
}) {
  const Styles = StylesFunc();
  const [tab, setTab] = useState("BRANDS");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("status");
  const [sortOrder, setSortOrder] = useState("ASC");

  const pagination = {
    "BRANDS": brandsPagination,
  };

  useEffect(() => {
    const listState = history.location.state?.listState;
    if (listState) {
      const { tab, page, search, sort, sortOrder } = listState;
      setTab(tab);
      setPage(page);
      setSearch(search);
      setSort(sort);
      setSortOrder(sortOrder);
    }
  }, [history.location.state]);

  useEffect(() => {
    if (page > pagination[tab].last) {
      setPage(pagination[tab].last);
    } else if (page < pagination[tab].first) {
      setPage(pagination[tab].first);
    }
  }, [pagination, tab, page]);

  useEffect(() => {
    startSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, tab, history.location.state]);

  const startSearch = () => {
    searchBrands(search, 10, page, sort, sortOrder);
  };

  const optionsSwitch = [
    { label: "En attente", value: 0, selectedBackgroundColor: "#007bff" },
    { label: "Validée", value: 1, selectedBackgroundColor: "#28a745" },
    { label: "Rejetée", value: 2, selectedBackgroundColor: "#FF0000" }
  ];
  
  const changeBrandStatus = (brand, newValue) => {
    let newBrand = Object.assign({}, brand, {status: newValue});
    updateBrand(newBrand);
  };

  const getTabs = () => {
    return (
      <Box className={Styles.tabRow}>
        <Box className={Styles.tabInputWrapper}>
          <FontAwesome name="search" style={{ fontSize: Constants.FONT_SIZE_NORMAL }} />
          <TextInput
            className={Styles.tabInput}
            placeholder="Recherche"
            onPressEnter={startSearch}
            onChange={(event) => setSearch(event.target.value)}
            value={search}
          />
        </Box>
      </Box>
    );
  };

  const getBrandHeaderRow = () => {
    return (
      <Grid container className={clsx(Styles.headerRow)}>
        <Grid item style={{ width: 100 }}>
          <Text className={clsx(Styles.headerCell)}>ID</Text>
        </Grid>
        <Grid item xs>
          <Text className={clsx(Styles.headerCell)}>Marque</Text>
        </Grid>
        <Grid item>
          <Text className={clsx(Styles.headerCell)}>Statut</Text>
        </Grid>
      </Grid>
    );
  };

  const getBrandRow = (brand) => {
    return (
      <Grid container className={Styles.dataRow} key={brand.id}>
        <Grid item style={{ width: 100 }}>
          <Text className={clsx(Styles.dataCell)}>{brand.id}</Text>
        </Grid>
        <Grid item xs>
          <Text className={clsx(Styles.dataCell)}>{brand.value}</Text>
        </Grid>
        <Grid item style={{ width: 300 }}>
          <Box className={clsx(Styles.dataCell)}>
            <SwitchSelector
              name={brand.id}
              onChange={(e) => changeBrandStatus(brand, e)}
              options={optionsSwitch}
              initialSelectedIndex={brand.status}
              backgroundColor={"#353b48"}
              fontColor={"#f5f6fa"}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Base
      title={"Liste des marques"}
      currentScreen={"CRITERIA_LIST"}
      pagination={pagination[tab]}
      page={page}
      setPage={setPage}
      content={
        <Box className={Styles.container}>
          {getTabs()}
          {tab === "BRANDS" && getBrandHeaderRow()}
          <Box className={Styles.dataWrapper}>
            {tab === "BRANDS" && brandsList.length > 0 && brandsList.map(getBrandRow)}
          </Box>
        </Box>
      }
    />
  );
}

const mapStateToProps = state => ({
  token: state.login.token,
  user: state.user.me,
  brandsList: state.criteria.brandsList,
  brandsPagination: state.criteria.brandsListPagination,
});

const mapDispatchToProps = dispatch => ({
  searchBrands: (search, itemsPerPage, page, sort, sortOrder) => {
    dispatch({ type: 'SEARCH_BRANDS_REQUESTED', search, itemsPerPage, page, sort, sortOrder });
  },
  updateBrand: (brand) => {
    dispatch({ type: 'UPDATE_BRAND_REQUESTED', brand });
  }
});

const VisibleComponent = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Component));

export default VisibleComponent;