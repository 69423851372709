import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    modalTitle: {
        fontSize: Constants.FONT_SIZE_BIG,
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(30),
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    button: {
        marginRight: 10,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
            marginRight: 0,
        }

    },
    buttonLabel: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        fontWeight: "bold"
    },
    cancelButton: {
        backgroundColor: Constants.BACKGROUND_GRAY,
        padding: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(10),
        paddingRight: Constants.GET_SIZE(10),
        marginLeft: Constants.GET_SIZE(10),
    },
    buttonsRow: {
        ...GlobalStyle.row
    },
    cancelButtonLabel: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold"
    },
    modalContent: {
        backgroundColor: "white",
        padding: Constants.GET_SIZE(30),
        alignItems: "center",
        alignSelf: "center"
    },
    buttonRow: {
        width: "100%",
        alignItems: "center",
        marginTop: 10,
        flexDirection: "row",
        justifyContent: "space-around"
    },
    controls: {
        zIndex:999
    },
    controlLabel: {
        width: "max-content",
        marginRight: 10
    },
    controlWrapper: {
        ...GlobalStyle.rowCenteredHW,
        marginBottom: Constants.GET_SIZE(10),
        justifyContent: "space-between"
    }
}));
