import React from "react";
import clsx from "clsx"

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Text from './FontText'
import CustomPickerStylesFunc from '../../Style/CustomPickerStyle'
import * as Constants from "../../Style/Constants"


function Component({simpleValues, reactValues, defaultValue, value, onPickValue, maxValue, wrapperStyle, width}) {
    let CustomPickerStyles = CustomPickerStylesFunc();
    let [selected, setSelected] = React.useState(null);
    let [dropdownOpen, setDropdownOpen] = React.useState(false);

    React.useEffect(() => {
        let realDefaultValue = defaultValue;

        if (!React.isValidElement(defaultValue)) {
            realDefaultValue = <Text className={CustomPickerStyles.selectedValue}>{defaultValue} </Text>;
        }
        setSelected(realDefaultValue);
    }, [defaultValue]);

    let handleClickValue = (value) => {
        setDropdownOpen(false);
        if (React.isValidElement(value["label"])) {
            setSelected(value["label"]);
        } else {
            setSelected(<Text className={CustomPickerStyles.selectedValue}>{value["label"]} </Text>);
        }
        onPickValue(value["value"]);
    };

    let realValues;
    if (maxValue) {
        realValues = [];
        for (let i = 1; i < maxValue + 1; i++) {
            realValues.push({
                label: i,
                value: i
            });
        }
    } else if (simpleValues) {
        realValues = simpleValues;
    }

    let dropdownValuesDom = [];
    let valuesDom = [];


    if (!reactValues) {
        if (realValues) {
            for (const value of realValues) {
                valuesDom.push({
                    react: <Text className={CustomPickerStyles.dropdownValue}>{value.label}</Text>,
                    value: value.value,
                    label: <Text className={CustomPickerStyles.dropdownValue}>{value.label}</Text>
                });
            }
        } else {
            // console.log("DBG", simpleValues, reactValues, defaultValue);
        }
    } else {
        valuesDom = reactValues;
    }

    for (const valueArr of valuesDom) {
        dropdownValuesDom.push(
            <MenuItem value={valueArr.value}>
                {valueArr.label}
            </MenuItem>
        )
    }

    return (
        <Select variant={"outlined"} onChange={(event, child) => onPickValue(event.target.value)} style={Object.assign({}, {width: width}, wrapperStyle)} inputProps={{style:{paddingLeft: 0}}} defaultValue={defaultValue} value={value}>
            {dropdownValuesDom}
        </Select>
    );
}

export default Component;
