import { call, put, takeLatest, takeEvery, cancelled } from 'redux-saga/effects'
import {
    FetchLive,
    FetchMyLives,
    FetchNextLives
} from '../Endpoint/Live'
import { handleError } from "../../Utils/SagaUtils";
import { store } from '../../Store'

function* fetchMyLives(action) {
    try {
        const result = yield call(FetchMyLives, action.tab, action.page, action.itemsPerPage);
        yield put({type: "FETCH_MY_LIVES_SUCCEEDED", lives: result.data["hydra:member"], pagination: result.data["hydra:view"], keepLoading: action.hideLoader});
    } catch (e) {
        yield put(handleError("FETCH_MY_LIVES_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* fetchNextLives(action) {
    try {
        const result = yield call(FetchNextLives);
        yield put({type: "FETCH_NEXT_LIVES_SUCCEEDED", lives: result.data["hydra:member"], keepLoading: action.hideLoader});
    } catch (e) {
        yield put(handleError("FETCH_NEXT_LIVES_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* fetchLive(action) {
    try {
        const result = yield call(FetchLive, action.id);
        yield put({type: "FETCH_LIVE_SUCCEEDED", live: result.data, keepLoading: action.hideLoader});
    } catch (e) {
        yield put(handleError("FETCH_LIVE_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}




function* mySaga() {
    yield takeLatest("FETCH_MY_LIVES_REQUESTED", fetchMyLives);
    yield takeLatest("FETCH_NEXT_LIVES_REQUESTED", fetchNextLives);
    yield takeLatest("FETCH_LIVE_REQUESTED", fetchLive);
}

export default mySaga;
