import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"
import CommonStyles from "./CommonStyles";
import {BACKGROUND_GRAY, BACKGROUND_LIVE_CHAT_GRAY, BORDER_DARK_GRAY, FONT_SIZE_MEDIUM} from "./Constants";

export default makeStyles((theme) => {
    let commonStyles = CommonStyles(theme);
    return {
        container: Object.assign({}, commonStyles.centeredContainer, {
            ...GlobalStyle.column,
            width: "100%",
            margin: "auto",
            marginTop: 30,
            marginBottom: 30,
            maxWidth: 1440,
            [theme.breakpoints.down('xs')]: {
                width: "100%",
            },
            [theme.breakpoints.down('md')]: {
                margin: "auto"
            }}),
        logo : Object.assign({}, commonStyles.logo, {}),
        button: Object.assign({}, commonStyles.button, {}),
        welcomeLabel: Object.assign({}, commonStyles.welcomeLabel, {}),
        connectLabel: Object.assign({}, commonStyles.connectLabel, {}),
        connectWrapper: Object.assign({}, commonStyles.connectWrapper, {}),
        snLogo: {
            width: 32,
            height: 32,
            [theme.breakpoints.down('md')]: {
                width: 22,
                height: 22,
            }
        },
        snButton: {
            padding: 0,
            minWidth: 0,
            marginLeft: 10,
            width: "max-content"
        },
        checkboxLabel: {
            color: Constants.FONT_LIGHT_GRAY
        },
        internalLinkWrapper: {},
        noAccountText: {
        },
        internalLinkLabel: {
        },
        pageTitle: {
            textTransform: 'uppercase',
            fontSize: Constants.FONT_SIZE_BIG,
            marginBottom: 10,
            [theme.breakpoints.down('md')]: {
                fontSize: Constants.FONT_SIZE_BIG_MOBILE,
                marginTop: 10,
                textAlign: "center",
                width: '100%'
            },
            "& > *": {
                verticalAlign: "middle"
            }
        },
        playerWrapper: {
            backgroundColor: Constants.BACKGROUND_GRAY,
            height: 500,
            [theme.breakpoints.down('md')]: {
                marginBottom: 60,
                height: 360,
            }
        },
        playerWrapperScroll: {
            [theme.breakpoints.down('md')]: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 9999999999
            }
        },
        playerWrapperScrollPlaceholder: {
            height: 0,
            width: 0,
            display: "none",
            [theme.breakpoints.down('md')]: {
                height: 420,
                width: 1,
            }
        },
        playerErrorWrapper: {
            height: 500,
            [theme.breakpoints.down('md')]: {
                height: 360,
            }
        },
        chatWrapper: {
            backgroundColor: Constants.BACKGROUND_LIVE_CHAT_GRAY,
            height: 500,
            position: 'relative',
            // overflowY: 'scroll',
            [theme.breakpoints.down('md')]: {
                marginBottom: 0
            }
        },
        chatHeaderWrapper: {
            zIndex: 10,
            position: 'relative',
            backgroundColor: '#454545',
            color: Constants.WHITE,
            padding: 15
        },
        chatHeader: {
            fontWeight: 'bold',
            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('md')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            }
        },
        chatAdminStatus: {
            display: 'flex',
            position: 'absolute',
            left: 10,
            top: "50%",
            transform: "translateY(-50%)",
            '& > *': {
                verticalAlign: 'middle',
                fontSize: 10,
            }
        },
        chatTimeLeft: {
            display: 'flex',
            position: 'absolute',
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
            '& > *': {
                textAlign: 'right',
                fontSize: 10,
            }
        },
        chatAdminStatusCircle: {
            width: 15,
            height: 15,
            backgroundColor: "red",
            borderRadius: "50%",
            marginRight: 5
        },
        chatAdminStatusCircleActive: {
            backgroundColor: "green",
        },
        chatMessagesWrapper: {
            overflowY: 'scroll',
            minHeight: '72%',
            height: '72%',
            padding: 20,
            [theme.breakpoints.down('md')]: {
                padding: 10,
                flex: 1
            }
        },
        chatSendWrapper: {
            backgroundColor: Constants.BORDER_DARK_GRAY,
            padding: 20,
            [theme.breakpoints.down('md')]: {
                padding: 0,
                flex: 0
            }
        },
        chatSendInputWrapper: {
            backgroundColor: Constants.WHITE,
            display: "flex",
            flexDirection: "row",
            borderColor: Constants.PINK,
            height: 40,
            borderWidth: 1,
            [theme.breakpoints.down('md')]: {
                height: 35,
                borderColor: Constants.BORDER_DARK_GRAY,
                borderWidth: 1,
                borderStyle: 'solid'
            }
        },
        chatSend: {
            fontSize: Constants.FONT_SIZE_NORMAL,
            color: Constants.GRAY_FOOTER,
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 10,
            width: "100%",
            [theme.breakpoints.down('md')]: {
                fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
            }
        },
        chatSendDivider: {
            height: "100%",
            width: 1,
            backgroundColor: Constants.BLACK,
            display: 'none'
        },
        chatSendButton: {
            [theme.breakpoints.down('md')]: {
                width: 'auto'
            }
        },
        chatSendInputIcon: {
            margin: 10
        },
        chatHelpText: {
            fontStyle: "italic",
            marginBottom: 10,
            textAlign: "left",
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        chatHelpTextMobile: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                display: 'block !important'
            }
        },
        desktop: {
            [theme.breakpoints.down('md')]: {
                display: 'none !important'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none !important'
            }
        },
        mobileTab: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        mobileActiveTab: {
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column'
            }
        },
        tabsWrapper: {
            ...GlobalStyle.rowCenteredHW,
            marginBottom: 0,
        },
        shopImageSmall: {
            verticalAlign: 'middle',
            width: 32,
            height: 32,
            borderRadius: "50%",
            display: 'inline',
            marginLeft: 8,
            marginRight: 8
        },
        swipeableViews: {
            maxWidth: '100%',
            "& .swiper-button-next": {
                position: 'absolute',
                top: '50%',
                width: 27,
                height: 44,
                marginTop: -22,
                zIndex: 10,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left: 'auto',
                right: 10,
                color: Constants.PINK,
                '&:after': {
                    fontFamily: 'swiper-icons',
                    fontSize: 44,
                    textTransform: 'none !important',
                    letterSpacing: 0,
                    fontVariant: 'initial',
                    lineHeight: 1,
                    content: '"next"'
                }
            },
            "& .swiper-button-prev": {
                position: 'absolute',
                top: '50%',
                width: 27,
                height: 44,
                marginTop: -22,
                zIndex: 10,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                left: 10,
                right: 'auto',
                color: Constants.PINK,
                '&:after': {
                    fontFamily: 'swiper-icons',
                    fontSize: 44,
                    textTransform: 'none !important',
                    letterSpacing: 0,
                    fontVariant: 'initial',
                    lineHeight: 1,
                    content: '"prev"'
                }
            }
        }
    }
});
