import {makeStyles} from "@material-ui/core/styles";

import * as Constants from "../Constants"
import GlobalStyle from "../GlobalStyle";


export default (theme) => ({
    // Containers
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 1440,
        margin: "auto",
        [theme.breakpoints.down('xs')]: {
            width: '90%'
        }
    },
    subcontentWrapper: {
        flex: 1,
        maxWidth: 810,
        marginLeft: 50,
        [theme.breakpoints.down('xs')]: {
            margin: 0
        }
    },
    row: {
        ...GlobalStyle.row,
    },
    descriptionWrapper: {
        maxWidth: 625,
        marginBottom: 50,
        width: "100%"
    },

    // Texts
    title: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        maxWidth: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        marginBottom: 50,

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
            marginBottom: 20,
        }
    },
    subTitle: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        textAlign: "center",
        marginLeft: 50,
        marginBottom: 10,

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            margin: "auto",
            marginBottom: 5
        }
    },
    subTitleDesc: {
        width: "max-content",
        marginLeft: 50,
        marginBottom: 20,

        fontSize: Constants.FONT_SIZE_NORMAL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
            margin: "auto",
            marginBottom: 10
        }
    },
    description: {
        textAlign: "center"
    },

    // Graphic elements
    button: {
        marginBottom: 50,
        marginTop: 37,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
            marginTop: 20,
        }
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },
});
