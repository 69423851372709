import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga'
import LoginReducer from './Reducer/Login'
import FamilyReducer from './Reducer/Family'
import UserReducer from './Reducer/User'
import VideDressingReducer from './Reducer/VideDressing'
import MenuReducer from './Reducer/Menu'
import CriteriaReducer from './Reducer/Criteria'
import ProductReducer from './Reducer/Product'
import ShopReducer from './Reducer/Shop'
import PurchaseReducer from './Reducer/Purchase'
import RelayPointsReducer from './Reducer/RelayPoints'
import LoadingReducer from './Reducer/Loading'
import InstagramReducer from './Reducer/Instagram'
import MessageReducer from './Reducer/Message'
import DisputeReducer from './Reducer/Dispute'
import ParamsReducer from './Reducer/Params'
import StatReducer from './Reducer/Stat'
import LiveReducer from './Reducer/Live'

import LoginSaga from '../API/Saga/Login'
import UserSaga from '../API/Saga/User'
import FamilySaga from '../API/Saga/Family'
import ContactSaga from '../API/Saga/Contact'
import VideDressingSaga from '../API/Saga/VideDressing'
import CriteriaSaga from '../API/Saga/Criteria'
import ProductSaga from '../API/Saga/Product'
import ShopSaga from '../API/Saga/Shop'
import PurchaseSaga from '../API/Saga/Purchase'
import RelayPointsSaga from '../API/Saga/RelayPoints'
import NominatimSaga from '../API/Saga/Nominatim'
import InstagramSaga from '../API/Saga/Instagram'
import MessageSaga from '../API/Saga/Message'
import DisputeSaga from '../API/Saga/Dispute'
import PictureSaga from '../API/Saga/Picture'
import ParamsSaga from '../API/Saga/Params'
import StatSaga from '../API/Saga/Stat'
import LiveSaga from '../API/Saga/Live'

// Middleware: Redux Persist Config
const loginPersistConfig = {
    key: 'login',
    storage: storage,
    whitelist: [
        'token',
        'RGPDValidated',
        'iOSPWAIgnore',
        'hideLiveListPopup'
    ]
};

const paramPersistConfig = {
    key: 'param',
    storage: storage
};

const familyPersistConfig = {
    key: 'family',
    storage: storage
};

const userPersistConfig = {
    key: 'user',
    storage: storage,
    whitelist: ['me']
};

const productPersistConfig = {
    key: 'product',
    storage: storage,
    whitelist: ['cart', 'wishlist']
};

const shopPersistConfig = {
    key: 'shop',
    storage: storage,
    whitelist: ['previewShop']
};

const reducer = combineReducers(
    {
        login: persistReducer(loginPersistConfig, LoginReducer),
        family: persistReducer(familyPersistConfig, FamilyReducer),
        user: persistReducer(userPersistConfig, UserReducer),
        videDressing: VideDressingReducer,
        menu: MenuReducer,
        criteria: CriteriaReducer,
        product: persistReducer(productPersistConfig, ProductReducer),
        shop: persistReducer(shopPersistConfig, ShopReducer),
        purchase: PurchaseReducer,
        relayPoints: RelayPointsReducer,
        loading: LoadingReducer,
        instagram: InstagramReducer,
        message: MessageReducer,
        dispute: DisputeReducer,
        params: persistReducer(paramPersistConfig, ParamsReducer),
        stat: StatReducer,
        live: LiveReducer
    }
);

const persistedReducer = reducer;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
// const store = createStore(reducer, /* preloadedState, */ composeEnhancers(
//     applyMiddleware(sagaMiddleware)));
const store = createStore(persistedReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(LoginSaga);
sagaMiddleware.run(UserSaga);
sagaMiddleware.run(FamilySaga);
sagaMiddleware.run(ContactSaga);
sagaMiddleware.run(VideDressingSaga);
sagaMiddleware.run(CriteriaSaga);
sagaMiddleware.run(ProductSaga);
sagaMiddleware.run(ShopSaga);
sagaMiddleware.run(PurchaseSaga);
sagaMiddleware.run(RelayPointsSaga);
sagaMiddleware.run(NominatimSaga);
sagaMiddleware.run(InstagramSaga);
sagaMiddleware.run(MessageSaga);
sagaMiddleware.run(DisputeSaga);
sagaMiddleware.run(PictureSaga);
sagaMiddleware.run(ParamsSaga);
sagaMiddleware.run(StatSaga);
sagaMiddleware.run(LiveSaga);

let persistor = persistStore(store);

export { store, persistor} ;
