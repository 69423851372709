import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import {ApiFetchFamilies, FetchShippingPrices} from '../Endpoint/Family'
import { handleError } from "../../Utils/SagaUtils";

// worker Saga: will be fired on REGISTER_REQUESTED actions
function* fetchFamilies(action) {
    try {
        const result = yield call(ApiFetchFamilies);
        const pricesResult = yield call(FetchShippingPrices);

        const formattedPrices = {};
        for (const price of pricesResult.data['hydra:member']) {
            formattedPrices[price['weight']] = price;
        }

        yield put({type: "FETCH_FAMILIES_SUCCEEDED", familyTree: result.data, prices: formattedPrices});


    } catch (e) {
        yield put(handleError("FETCH_FAMILIES_FAILED", e));
    } finally {
        if (yield cancelled()) {
            // yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* mySaga() {
    yield takeLatest("FETCH_FAMILIES_REQUESTED", fetchFamilies);
}

export default mySaga;
