import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    modalTitle: {
        fontSize: Constants.FONT_SIZE_BIG,
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(30),
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    pickerWrapper: {
        ...GlobalStyle.rowCentered,
        width: "90%",
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderRadius: Constants.GET_SIZE(10),
        height: Constants.GET_SIZE(58),
        marginBottom: Constants.GET_SIZE(13),
    },
    pickerItem: {

    },
    divider: {
        height: "100%",
        backgroundColor: Constants.BORDER_GRAY,
        width: 1,
    },
    checkboxLabel: {
        color: Constants.FONT_GRAY
    },
    checkBox: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.WHITE,
    },
    inputIconWrapper: {
        width: Constants.GET_SIZE(68),
    },

    picker: {
        width: "100%",
        marginBottom: 20,
    },
    pickerError: {
        borderColor: Constants.COLOR_ERROR,
        color: Constants.COLOR_ERROR,
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
            marginBottom: 10
        }
    },
    modalContent: {
        backgroundColor: "white",
        padding: Constants.GET_SIZE(30),
        alignItems: "center",
        alignSelf: "center"
    },
    bottomModal: {
        justifyContent: "flex-end",
        margin: 0
    },
}));
