import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        width: "100%",
        alignItems: "center"
    },
    row: {
        display: "flex",
        flexDirection:"row",
        flexWrap: "wrap",
        width: "100%",
        // justifyContent: "space-around"
    },
    paginator: {
        display: "flex",
        flexDirection:"row",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    pageDot: {
        color: Constants.FONT_LIGHT_GRAY,
        marginLeft: Constants.GET_SIZE(5),
        marginRight: Constants.GET_SIZE(5),
    },
    active: {
        color: Constants.FONT_GRAY
    },
    arrowWrapper: {
        justifyContent: "center"
    },
    arrow: {
        fontSize: Constants.FONT_SIZE_BIGGER,
        color: Constants.PINK,
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE
        }
    },
    carousel: {
        display: "block",
        margin: "auto",
        '& button[aria-label="Next"]': {
            backgroundColor: "transparent !important",
            color: Constants.PINK + " !important",
            opacity: "1 !important",
            right: "-35px !important",
            '& .MuiSvgIcon-root': {
                fontSize: "3rem"
            }
        },
        '& button[aria-label="Previous"]': {
            backgroundColor: "transparent !important",
            color: Constants.PINK + " !important",
            opacity: "1 !important",
            left: "-35px !important",
            '& .MuiSvgIcon-root': {
                fontSize: "3rem"
            }
        }
    }
}));

