import axios from 'axios';
import {API_URL} from '../../Config/API'


export const ApiLogin = (login, password) => {
    return axios.post(API_URL + 'authentication_token', {email: login, password: password});
};

export const ApiLoginFacebook = (accessToken) => {
    return axios.get(API_URL + 'login/facebook?accessToken=' + accessToken);
};

export const ApiLoginGoogle = (accessToken) => {
    return axios.get(API_URL + 'login/google?accessToken=' + accessToken);
};

export const ApiPasswordReset = (mail) => {
    return axios.post(API_URL + 'api/password_resets', {email: mail});
};

export const ApiChangePassword = (id, plainPassword) => {
    return axios.post(
        API_URL + 'api/password_reset/change_password',
        {clearToken: id, plainPassword: plainPassword},
        {headers:{"Content-Type": "application/ld+json"}}
    );
};