import React from "react";

import Link from "@material-ui/core/Link";

import StaticCommon from "./StaticCommon";

import Banner from "../../../Assets/Images/banners/Manuel_du_vendeur.jpg"

function Component({title}) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "Manuel du vendeur",
        blocks: [
            {
                title: "Qui peut devenir vendeur ?",
                content: [
                    { text: "Amateurs, créateurs, blogueurs ou acteurs engagés dans le domaine de la mode éco-responsable... Tout le monde peut devenir vendeur chez Violette Sauvage !" },
                    { text: "Qu’il s’agisse de votre activité principale, d’un complément de revenus ou simplement l’envie de contribuer à l’économie participative et redonner une seconde vie à votre dressing, vous êtes tous les bienvenus !" },
                    { text: "La Marketplace Violette Sauvage offre également la possibilité aux professionnels d’avoir leur propre Boutique grâce à nos différentes catégories : Créateurs, Blogueurs et la section éco-responsable réserver au Fairtrade." },
                    { text: "Choisissez votre catégorie lors de votre inscription, laissez-vous guider à travers le formulaire et le tour est joué !" }
                ]
            },
            {
                title: "Comment ajouter vos articles ?",
                content: [
                    { text: "Pour vendre vos articles c’est très simple. Aussi facile que ces trois petites étapes à respecter :" },
                    { text: [<strong>Étape 1 : </strong>, "S’inscrire sur le site, ouvrir un compte et remplir tous les champs requis."] },
                    { text: [<strong>Étape 2 : </strong>, "Aller dans la rubrique « nouveau produit », charger les photos de votre article et rédigez une description détaillée de celui-ci : état, matière, couleur…"] },
                    { text: [<strong>Étape 3 : </strong>, "Fixer votre prix ", <strong>(minimum de 5€)</strong>, " et choisir les modes de livraison acceptés (voir la page ", <Link href={"/conditions_de_livraison"}>Conditions de livraison et tarifs</Link>, ")"] },
                    { text: "Et voilà, vous n’avez plus qu’à attendre vos premiers clients !" },
                ]
            },
            {
                title: "Conseils pour mieux vendre",
                content: [
                    { text: "Tout comme dans une vraie boutique, il est important de prêter une attention toute particulière aux petits détails de votre annonce pour pouvoir vendre rapidement vos articles. Pour cela, la Team Sauvage vous propose quelques pistes qui vous aideront à conclure de jolies ventes :" },
                    {
                        title: "Prendre de jolies photos",
                        text: "Les photos sont les points les plus importants de vos annonces. Ce sont elles qui vont aider l’acheteur à prendre une décision. Aussi, pour lui permettre de se représenter au mieux l’article, il est important que les photos soient le plus fidèle possible à la réalité."
                    },
                    { text: "Pour ne pas altérer les couleurs de vos articles nous vous conseillons de prendre vos photos à la lumière du jour et de réaliser plusieurs clichés : de face, de dos, de profil, des détails et défauts éventuels ainsi que de l’étiquette." },
                    { text: "Une photo portée permettra toujours à l’acheteur de se faire une meilleure idée de l’article. Cependant, nous n’avons pas toutes la chance d’avoir une personne disponible pour nous prendre en photo ou un(e) ami(e) prêt(e) à faire le modèle. C’est pourquoi des photos de l’article à plat ou sur cintres, sont également une bonne alternative." },

                    {
                        title: "Décrire vos articles",
                        text: "Dans la description de vos articles, n’oubliez pas de mentionner la taille, la marque, la couleur, la matière et également l’état de votre article. Si celui-ci comporte des petits défauts (tâches, éraflures…) n’oubliez pas de les mentionner afin d’éviter toute incompréhension et déception après-vente."
                    },

                    {
                        title: "Fixer vos prix",
                        text: "Vous êtes libres de fixer vos propres prix. Cependant, nous vous conseillons de regarder les prix des articles semblables en vente sur la Marketplace Violette Sauvage afin de déterminer le meilleur prix auquel vos articles ont le plus de chance de se vendre."
                    },

                ]
            },

        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"Devenez vendeur sur Violette Sauvage, vide dressing en ligne"}
                      metaDesc={"Vous souhaitez vendre vos créations ou vos vêtements de seconde main ? Participez à des Lives, vendez vos articles en ligne sur Violette Sauvage."}
                      arianeCurrentName={"Manuel du vendeur"} />
    );
}

export default Component
