import React from "react";
import Grid from "@material-ui/core/Grid";
import Carousel from "react-material-ui-carousel";
import clsx from 'clsx';
import HorizontalSliderStylesFunc from "../../../Style/HorizontalSliderStyle";

function HorizontalSlider({ elementList, nbElementsPerPage, carouselKey, arrows, wrapperClass }) {
    const HorizontalSliderStyles = HorizontalSliderStylesFunc();
    const [slices, setSlices] = React.useState([]);
    const maxElements = 4;

    // Limiter elementList à maximum 4 éléments
    const limitedElementList = elementList.slice(0, maxElements);

    const nbPages = Math.ceil(limitedElementList.length / nbElementsPerPage);

    let version = 0;
    React.useEffect(() => {
        let newSlices = [];
        for (let j = 0; j < nbPages; j++) {
            const slice = [];
            for (let i = j * nbElementsPerPage; i < j * nbElementsPerPage + nbElementsPerPage; i++) {
                slice.push(elementList[i % elementList.length]);
            }
            newSlices.push(<Grid container key={"slide-" + j + "-version-" + version} justify={"space-around"}>{slice}</Grid>)
        }
        setSlices(newSlices);
        version += 1;
    }, [elementList, nbElementsPerPage]);

    return (
        <Carousel
            style={{ margin: "auto" }}
            className={clsx(HorizontalSliderStyles.carousel, wrapperClass)}
            autoPlay={false}
            indicators={!arrows}
            navButtonsAlwaysInvisible={!arrows}
            key={carouselKey + "-" + slices.length}
        >
            {slices}
        </Carousel>
    );
}

export default HorizontalSlider;
