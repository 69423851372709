import React from 'react';

import Box from "@material-ui/core/Box";

import Grid from "@material-ui/core/Grid";
import Text from "../../Component/FontText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import { connect } from 'react-redux'
import { resizeCrop } from "../../../Utils/ImageUtils";
import {useForm} from "react-hook-form";

import ReactCrop from 'react-image-crop';
import DisputeModalStylesFunc from "../../../Style/MiddleOffice/DisputeModalStyle"
import 'react-image-crop/dist/ReactCrop.css';


function Component ({visible, setVisible, pictureUri, validatePicture}) {
    let DisputeModalStyles = DisputeModalStylesFunc();
    const { handleSubmit, control, errors } = useForm();
    let [crop, setCrop] = React.useState({unit: 'px'});
    let imgRef = React.useRef(null);

    let handleClickOk = () => {
        resizeCrop(imgRef.current, crop, validatePicture);
    };

    let onLoad = React.useCallback(img => {
        imgRef.current = img
        setCrop(Object.assign({}, crop, {unit: 'px'}))
    }, []);

    React.useEffect(() => {
        setCrop({unit: 'px'})
    }, [pictureUri]);

    let _renderModalContent = () => (
        <Box className={DisputeModalStyles.modalContent}>
            <Text className={DisputeModalStyles.modalTitle}>Modifier l'image</Text>
            <ReactCrop
                style={{maxHeight:"70vh"}}
                src={pictureUri}
                crop={crop}
                onChange={(crop, percentage) => setCrop(crop)}
                onImageLoaded={onLoad} />

            <Grid container justify={"space-between"} style={{width: "100%"}}>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"} onClick={handleSubmit(handleClickOk)} className={DisputeModalStyles.button}>
                    Enregistrer
                </Button>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"} onClick={() => setVisible(false)} className={DisputeModalStyles.button}>
                    Annuler
                </Button>
            </Grid>
        </Box>
    );

    return (
        <Dialog open={visible} onClose={() => setVisible(false)}>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createDispute: (text, purchase, shop) => {
            dispatch({type: 'CREATE_DISPUTE_REQUESTED', text, purchase, shop})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;