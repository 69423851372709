import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import {CreateDispute, DeleteDispute, FetchDispute, SearchDisputes, UpdateDispute} from '../Endpoint/Dispute'
import { handleError } from "../../Utils/SagaUtils";
import {SavePicture} from "../Endpoint/Product";

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* createDispute(action) {
    try {
        let result;
        let picturesIds = [];
        if (action.pictures) {
            for (const picture of action.pictures) {
                if (picture.id === -1) {
                    result = yield call(SavePicture, picture.file);
                    if (result && result.data) {
                        picturesIds.push(result.data["id"]);
                    }
                } else {
                    picturesIds.push(picture.id);
                }
            }
        }

        const dispute = yield call(CreateDispute, action.text, "/api/shops/" + action.shop.id, "/api/purchases/" + action.purchase.id, "/api/products/" + action.product.id, picturesIds);
        yield put({type: "CREATE_DISPUTE_SUCCEEDED", thread: dispute.data.messageThread, dispute: dispute.data, purchase: action.purchase, shop: action.shop});
    } catch (e) {
        console.log(e);
        yield put(handleError("CREATE_DISPUTE_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* searchDisputes(action) {
    try {
        const result = yield call(SearchDisputes, action.search, action.itemsPerPage, action.page, action.disputeType);
        yield put({type: "SEARCH_DISPUTES_SUCCEEDED", disputes: result.data["hydra:member"], pagination: result.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("SEARCH_DISPUTES_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchDispute(action) {
    try {
        const result = yield call(FetchDispute, action.id);
        yield put({type: "FETCH_DISPUTE_SUCCEEDED", dispute: result.data});
    } catch (e) {
        yield put(handleError("FETCH_DISPUTE_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* deleteDispute(action) {
    try {
        let result = yield call(DeleteDispute, action.id);
        yield put({type: "DELETE_DISPUTE_SUCCEEDED", dispute: result.data});

        // result = yield call(SearchDisputes, action.search, action.itemsPerPage, action.page);
        // yield put({type: "SEARCH_DISPUTES_SUCCEEDED", disputes: result.data["hydra:member"], pagination: result.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("DELETE_DISPUTE_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* updateDispute(action) {
    try {
        const dispute = yield call(UpdateDispute, action.id, action.status);
        yield put({type: "UPDATE_DISPUTE_SUCCEEDED", dispute: dispute.data, purchase: action.purchase, shop: action.shop});
    } catch (e) {
        yield put(handleError("UPDATE_DISPUTE_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* mySaga() {
    yield takeLatest("CREATE_DISPUTE_REQUESTED", createDispute);
    yield takeLatest("SEARCH_DISPUTES_REQUESTED", searchDisputes);
    yield takeLatest("FETCH_DISPUTE_REQUESTED", fetchDispute);
    yield takeLatest("DELETE_DISPUTE_REQUESTED", deleteDispute);
    yield takeLatest("UPDATE_DISPUTE_REQUESTED", updateDispute);
}

export default mySaga;
