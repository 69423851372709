const initialState = {
    isLoading: 0,
    isLoadingGeneral: 0
};

const IGNORE_LOADERS = ["SAVE_USER", "FETCH_ME", "FETCH_FAMILIES", "FETCH_PARAMS", "UPDATE_BRAND"];

function reduc(state = initialState, action) {
    if (action.type === "REMOVE_LOADER") {
        // if (action.general) {
        if (true) {
            if (!action.keepLoading) {
                return Object.assign({}, state, {
                    isLoadingGeneral: state.isLoadingGeneral - 1
                });
            }
        } else {
            return Object.assign({}, state, {
                isLoading: Math.max(state.isLoading - 1, 0)
            });
        }
    }

    let exp = action.type.split("_");
    let suffix = exp[exp.length - 1];
    let prefix = exp.slice(0, exp.length - 1).join("_");

    if (!action.hideLoader && IGNORE_LOADERS.indexOf(prefix) === -1) {
        if (true) {
            if (suffix === "REQUESTED") {
                if (action.type === 'SEND_MESSAGE_REQUESTED') {
                    return Object.assign({}, state, {
                        isLoadingMessage: true
                    });
                } else {
                    return Object.assign({}, state, {
                        isLoadingGeneral: state.isLoadingGeneral + 1
                    });
                }
            } else if ((suffix === "SUCCEEDED" || suffix === "FAILED") && !action.keepLoading) {
                if (action.type === 'SEND_MESSAGE_SUCCEEDED' || action.type === 'SEND_MESSAGE_FAILED') {
                    return Object.assign({}, state, {
                        isLoadingMessage: false
                    });
                } else {
                    return Object.assign({}, state, {
                        isLoadingGeneral: Math.max(state.isLoadingGeneral - 1, 0)
                    });
                }
            }
        } else {
            if (suffix === "REQUESTED") {
                return Object.assign({}, state, {
                    isLoading: state.isLoading + 1
                });
            } else if ((suffix === "SUCCEEDED" || suffix === "FAILED") && !action.keepLoading) {
                return Object.assign({}, state, {
                    isLoading: Math.max(state.isLoading - 1, 0)
                });
            }
        }
    }

    return state
}

export default reduc