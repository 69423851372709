import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import TextInputIcon from "../../Screens/Front/Component/TextInputIcon";
import React from "react";
import {MAQUETTE_WIDTH_VW} from "./../Constants";


export default makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    row: {
        ...GlobalStyle.row,
    },
    column: {
        maxWidth: 430,
    },
    title: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        marginBottom: Constants.GET_SIZE(20),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    subTitle: {

        marginBottom: Constants.GET_SIZE(50),
    },
    button: {
        ...GlobalStyle.button,
        marginBottom: 50,
        marginTop: 10,
        width: "max-content",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },
    buttonWhite: {
        ...GlobalStyle.button,
        marginBottom: Constants.GET_SIZE(50),
        marginTop: Constants.GET_SIZE(37),
        width: Constants.GET_SIZE(225),

        borderWidth: 1,
        backgroundColor: Constants.WHITE,
        borderColor: Constants.PINK,
    },
    buttonWhiteLabel: {
        ...GlobalStyle.buttonLabel,
        color: Constants.PINK,
    },

    contentWrapper: {
        ...GlobalStyle.row,
        marginBottom: Constants.GET_SIZE(50),
        width: "100%"
    },
    subcontentWrapper: {
        flex: 1,
        marginRight: 130,
        marginLeft: Constants.GET_SIZE(50),
        [theme.breakpoints.down('xs')]: {
            margin: 0
        }
    },
    descriptionWrapper: {
        width: Constants.GET_SIZE(625),
        marginBottom: Constants.GET_SIZE(50),
    },
    description: {

        textAlign: "center"

    },
    headerWrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: Constants.GET_SIZE(55),
    },
    headerStep: {

        ...GlobalStyle.uppercaseText,
        color: Constants.FONT_GRAY,
        fontWeight: "bold"
    },
    headerStepActive: {
        color: Constants.PINK
    },
    inputWrapper: {
        width: Constants.GET_SIZE(430)
    },

    step1Wrapper: {
        flex: 1
    },
    step1Label: {

        marginTop: Constants.GET_SIZE(15),
    },
    step1SubLabel: {
        fontStyle: "italic",

        fontSize: Constants.FONT_SIZE_SMALL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
        }

    },
    step1CheckboxRow: {
        ...GlobalStyle.row
    },
    checkboxLabel: {

        color: Constants.FONT_GRAY
    },
    checkboxLinkWrapper: {
        ...GlobalStyle.rowCentered
    },
    checkBox: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.WHITE,
        width: Constants.GET_SIZE(300)
    },

    step3Wrapper: {
        ...GlobalStyle.row,
        flex: 1
    },
    step3ColumnWrapper: {
        ...GlobalStyle.column,
        flex: 1
    },
    snInputWrapper: {
        ...GlobalStyle.rowCentered,
    },
    cameraIcon: {
        width: Constants.GET_SIZE(34),
        height: Constants.GET_SIZE(28),
        marginRight: Constants.GET_SIZE(10),
    },
    picturesWrapper: {
        ...GlobalStyle.rowCentered,
        flexWrap: "wrap",
        marginLeft: (-120 / Constants.MAQUETTE_WIDTH_VW) + "vw",
        marginTop: Constants.GET_SIZE(100),
        marginBottom: Constants.GET_SIZE(50),

    },
    picture: {
        width: Constants.GET_SIZE(270),
        height: Constants.GET_SIZE(220),
        marginBottom: Constants.GET_SIZE(20),
        marginRight: Constants.GET_SIZE(13),
        marginTop: Constants.GET_SIZE(13),
        borderWidth: 1,
        borderColor: Constants.BORDER_GRAY,
        borderRadius: "5%"
    },
    pictureButtonLabel: {
        ...GlobalStyle.buttonLabel
    },
    pictureButton: {
        ...GlobalStyle.button,
        ...GlobalStyle.rowCentered,
        marginBottom: Constants.GET_SIZE(15),
        marginTop: Constants.GET_SIZE(12),
        width: "max-content"
    },
    pictureSubtext: {

        color: Constants.FONT_GRAY,
        width: Constants.GET_SIZE(300),
    },
    pictureWrapper: {
        position: "relative",
        width: Constants.GET_SIZE(270),
        height: Constants.GET_SIZE(220),
    },
    pictureDeleteIcon: {
        color: Constants.FONT_GRAY,

        fontSize: Constants.FONT_SIZE_BIGGER,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
        }
    },
    pictureDeleteIconWrapper: {
        position: "absolute",
        top: -10,
        right: 0,
    },


    pickerWrapper: {
        width: "100%",
        marginBottom: 20,
        maxWidth: 430
    },
    picker: {
        width: "100%",
    },
    pickerPlaceholder: {
        color: "#a9a9a9"
    },

    colorsWrapper: {
        ...GlobalStyle.rowCentered,
        marginBottom: Constants.GET_SIZE(40),
        marginTop: Constants.GET_SIZE(40),
    },
    colorLabel: {

        marginRight:  Constants.GET_SIZE(30),
        marginLeft:  Constants.GET_SIZE(25),
        color: Constants.FONT_GRAY,
        width: "max-content"
    },
    colorPastille: {
        width: Constants.GET_SIZE(20),
        height: Constants.GET_SIZE(20),
        marginRight:  Constants.GET_SIZE(5),
        marginLeft:  Constants.GET_SIZE(5),
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center"
    },
    colorSelected: {
    },


    quantityWrapper: {
        ...GlobalStyle.rowCentered,
        width: Constants.GET_SIZE(430)
    },
    quantityLabel: {

        marginRight:  Constants.GET_SIZE(30),
        marginLeft:  Constants.GET_SIZE(25),
        color: Constants.FONT_GRAY,
        width: "max-content"
    },


    deliveryModesSuperWrapper: {
        marginBottom: Constants.GET_SIZE(25),
        marginTop: Constants.GET_SIZE(25),
    },
    deliveryModesWrapper: {
        width: Constants.GET_SIZE(317),
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    deliveryModesTitleWrapper: {
        padding: Constants.GET_SIZE(15),
    },
    deliveryModesTitle: {

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    deliveryModesList: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        paddingTop: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(10),
        width: "100%",
        overflowY: "auto",
        height: Constants.GET_SIZE(100)
    },
    deliveryModeCheckbox: {
        width: "100%",
        justifyContent: "space-between",
        padding: 0
    },
    deliveryModeLabel: {
    },

    upwardTriangle: {
        position: "absolute",
        left: Constants.GET_SIZE(20),
        top: (-9 / Constants.MAQUETTE_WIDTH_VW) + "vw",
        width: 0,
        height: 0,
        borderLeftWidth: Constants.GET_SIZE(20),
        borderLeftColor: "transparent",
        borderRightWidth: Constants.GET_SIZE(20),
        borderRightColor: "transparent",
        borderBottomWidth: Constants.GET_SIZE(20),
        borderBottomColor: Constants.WHITE,
    },
    upwardTriangleBorder: {
        position: "absolute",
        left: Constants.GET_SIZE(20),
        top: (-10 / Constants.MAQUETTE_WIDTH_VW) + "vw",
        width: 0,
        height: 0,
        zIndex: -1,
        borderLeftWidth: Constants.GET_SIZE(20),
        borderLeftColor: "transparent",
        borderRightWidth: Constants.GET_SIZE(20),
        borderRightColor: "transparent",
        borderBottomWidth: Constants.GET_SIZE(20),
        borderBottomColor: Constants.BORDER_GRAY,
    },

    brandWrapper: {
        zIndex: 999,
    },
    brandAutocompleteWrapper: {
        position: "absolute",
        top: Constants.GET_SIZE(70),
        width: Constants.GET_SIZE(430),
        zIndex: 999,
        backgroundColor: Constants.WHITE,
        borderWidth: 1,
        borderColor: Constants.BORDER_GRAY
    },
    brandAutocompleteButton: {
        paddingLeft: Constants.GET_SIZE(10),
        paddingRight: Constants.GET_SIZE(10),
        paddingTop: Constants.GET_SIZE(5),
        paddingBottom: Constants.GET_SIZE(5),
        borderBottomWidth: 1,
        borderColor: Constants.BORDER_GRAY
    },
    brandAutocompleteLabel: {
    },
    brandWarning: {

        color: Constants.PINK
    },
    congratsText: {
        textAlign: "center",

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    colorSelectedIcon: {
        color: Constants.PINK,

        fontSize: Constants.FONT_SIZE_SMALL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
        }
    }
}));
