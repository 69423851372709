import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    background: {
        backgroundColor: "#323232A0",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 99999999,
        minHeight: "100vh"
    },
    spinnerWrapper: {
        // position: "fixed",
        // left: "50vw",
        // top: "50vh",
        position: "absolute",
        left: "50%",
        top: "50%",
    },
    generalLoader: {
        position: "fixed",
    },
    spinner: {
        fontSize: Constants.FONT_SIZE_BIGGEST
    }
}));
