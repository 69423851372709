import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default {
    root: {
        color: Constants.PINK
    },
    valueLabel: {
        left: 'calc(-50% - 5px)',
        fontSize: Constants.FONT_SIZE_NORMAL,
        top: 20,
        '& *': {
            background: 'transparent',
            color: Constants.PINK,
        },
    }
};
