import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import ShoppingCartRightColumnStyle from "./ShoppingCartRightColumnStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => {
    let CommonStyles = ShoppingCartRightColumnStyle(theme);

    return {
        ...CommonStyles,

        button: {
            ...GlobalStyle.button,
            width: Constants.GET_SIZE(193),
            paddingLeft: 0,
            paddingRight: 0,
        },
        buttonLabel: {
            ...GlobalStyle.buttonLabel,
            textAlign: "center"
        },
        container: {
            maxWidth: 1440,
            margin: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "90%",
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: 60
            }
        },
        leftContent: {
            flex: 2,
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                flex: "initial",
            }
        },
        wrapper: {
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            padding: 50,
            paddingLeft: 30,
            paddingRight: 30,
            [theme.breakpoints.down('xs')]: {
                padding: 10
            }
        },
        row: {
            ...GlobalStyle.row,
            alignItems: "center"
        },
        rowInactive: {
            [theme.breakpoints.down('xs')]: {
                display: "none"
            }
        },
        pageTitle: {
            ...GlobalStyle.uppercaseText,
            [theme.breakpoints.down('xs')]: {
                textAlign: "center"
            }
        },
        headerBar: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            padding: Constants.GET_SIZE(50),
            paddingLeft: Constants.GET_SIZE(70),
            paddingRight: Constants.GET_SIZE(70),
            justifyContent: "space-around",
            [theme.breakpoints.down('xs')]: {
                padding: 10,
                paddingTop: 20
            }
        },
        headerBarText: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            ...GlobalStyle.uppercaseText,
            color: Constants.LIGHT_GRAY,
            fontWeight: "bold",
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            }
        },
        headerBarTextActive: {
            color: Constants.PINK,
        },
        headerTruckImage: {
            width: Constants.GET_SIZE(33),
            height: Constants.GET_SIZE(22),
            marginRight: Constants.GET_SIZE(10),
        },
        headerCardImage: {
            width: Constants.GET_SIZE(29),
            height: Constants.GET_SIZE(20),
            marginRight: Constants.GET_SIZE(10),
        },
        headerClockImage: {
            width: Constants.GET_SIZE(27),
            height: Constants.GET_SIZE(28),
            marginRight: Constants.GET_SIZE(10),
        },
        stripeFieldWrapper: {
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            marginBottom: Constants.GET_SIZE(10),
            marginTop: Constants.GET_SIZE(10),
            justifyContent: "center",
            backgroundColor: Constants.WHITE,
            padding: Constants.GET_SIZE(10),
            marginLeft: Constants.GET_SIZE(10),
        },

        addressPickerWrapper: {
            ...GlobalStyle.rowCentered,
            backgroundColor: Constants.WHITE,
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            padding: Constants.GET_SIZE(20),
            justifyContent: "space-around",
            marginTop: Constants.GET_SIZE(25),
            flex: 1
        },
        chronopostLogo: {
            width: Constants.GET_SIZE(193),
            height: Constants.GET_SIZE(43),
            marginRight: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                width: 100,
                height: 20,
                marginBottom: 10,
            }
        },
        mondialRelayLogo: {
            width: Constants.GET_SIZE(197),
            height: Constants.GET_SIZE(47),
            marginRight: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                width: 100,
                height: 22,
                marginBottom: 10,
            }
        },
        clickAndCollectLogo: {
            width: Constants.GET_SIZE(68),
            height: Constants.GET_SIZE(68),
            marginRight: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                width: 34,
                height: 34,
                marginBottom: 10,
            }
        },
        handsLogo: {
            width: Constants.GET_SIZE(83),
            height: Constants.GET_SIZE(68),
            marginRight: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                width: 40,
                height: 34,
                marginBottom: 10,
            }
        },
        deliveryNameWrapper: {
            flex: 1,
            paddingLeft: 15,
            paddingRight: 15,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
                paddingRight: 0,
                width: "100%",
                flex: "revert",
                marginBottom: 10,
                textAlign: "center"
            }
        },
        deliveryAddressWrapper: {
            flex: 1,
            paddingLeft: 15,
            paddingRight: 15,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
                paddingRight: 0,
                width: "100%",
                flex: "revert",
                marginBottom: 10,
                textAlign: "center"
            }
        },
        deliveryModeWrapper: {
            marginTop: 15
        },
        deliveryModeWrapperTitle: {
            marginBottom: 4
        },
        deliveryName: {
            marginBottom: 2
        },
        deliveryName2: {
            fontSize: Constants.FONT_SIZE_SMALL,
            color: Constants.LIGHT_GRAY,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
            }
        },
        horizontalDivider: {
            backgroundColor: Constants.BORDER_GRAY,
            width: "100%",
            height: 2,
            marginTop: Constants.GET_SIZE(25),
        },
        error: {
        },


        checkboxContainer: {
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            borderWidth: 0,
            padding: 0,
            margin: 0
        },

        checkboxLabel: {
        },

        knowMoreText: {
            marginTop: Constants.GET_SIZE(10),
            marginBottom: Constants.GET_SIZE(20),
            marginLeft: Constants.GET_SIZE(10),
        },


        confirmationMessage: {
            fontSize: Constants.FONT_SIZE_BIG,
            marginTop: Constants.GET_SIZE(40),
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_BIG_MOBILE,
                textAlign: "center"
            }
        },
        confirmationMessage2: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            marginTop: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
                textAlign: "center"
            }
        },
        confirmationMessage3: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            marginTop: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
                textAlign: "center"
            }
        },
        confirmationMessageButton: {
            marginBottom: 120,
            marginTop: 120,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 60,
                marginTop: 60,
            }
        },

        dialogContent: {
            padding: 20,
        },
        dialogTitle: {
            textAlign: "center",
            width: '100%',
            marginBottom: 20,
        },
        loadingOverlay: {
            position: "absolute",
            top: 0,
            left:0,
            right: 0,
            bottom: 0,
            backgroundColor: "#323232A0",
        },
        arrowBack: {
            position: "absolute",
            color: "pink",
            top: 45,
            left: 20,
            [theme.breakpoints.down('xs')]: {
                top: 20,
                left: 10
            }
        }
    }
});
