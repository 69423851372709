const initialState = {
    fromToStatDays: [],
    nbTransacDay: [],
    nbTransacMonth: [],
    nbTransacYear: [],
    nbTransacWeek: [],
    nbReturnsDay: [],
    nbReturnsMonth: [],
    nbReturnsYear: [],
    nbReturnsForUser: 0,
    nbDisputes: 0,
    nbShops: 0,
    nbProducts: 0
};


function reduc(state = initialState, action) {
    switch (action.type) {
        case "FETCH_FROM_TO_STATS_SUCCEEDED":
            return Object.assign({}, state, {
                fromToStatDays: action.result
            });
            break;
        case "FETCH_NB_TRANSACTIONS_SUCCEEDED":
            if (action.timeType === "DAY") {
                return Object.assign({}, state, {
                    nbTransacDay: action.result
                });
            } else if (action.timeType === "MONTH") {
                return Object.assign({}, state, {
                    nbTransacMonth: action.result
                });
            } else if (action.timeType === "YEAR") {
                return Object.assign({}, state, {
                    nbTransacYear: action.result
                });
            } else if (action.timeType === "WEEK") {
                return Object.assign({}, state, {
                    nbTransacWeek: action.result
                });
            }
            break;
        case "FETCH_NB_RETURNS_SUCCEEDED":
            if (action.timeType === "DAY") {
                return Object.assign({}, state, {
                    nbReturnsDay: action.result
                });
            } else if (action.timeType === "MONTH") {
                return Object.assign({}, state, {
                    nbReturnsMonth: action.result
                });
            } else if (action.timeType === "YEAR") {
                return Object.assign({}, state, {
                    nbReturnsYear: action.result
                });
            }
            break;
        case "FETCH_NB_DISPUTES_SUCCEEDED":
            return Object.assign({}, state, {
                nbDisputes: action.result
            });
            break;
        case "FETCH_NB_SHOPS_SUCCEEDED":
            return Object.assign({}, state, {
                nbShops: action.result
            });
            break;
        case "FETCH_NB_PRODUCTS_SUCCEEDED":
            return Object.assign({}, state, {
                nbProducts: action.result
            });
            break;
        case "FETCH_NB_RETURNS_FOR_USER_SUCCEEDED":
            return Object.assign({}, state, {
                nbReturnsForUser: action.result
            });
            break;


    }

    return state
}

export default reduc