import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
        }
    },
    row: {
        ...GlobalStyle.row,
    },
    title: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        marginBottom: Constants.GET_SIZE(15),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    welcome: {

        color: Constants.PINK,
        marginBottom: Constants.GET_SIZE(25),
    },
    descriptionWrapper: {
        marginBottom: Constants.GET_SIZE(50),
    },
    description: {
        textAlign: "center"
    },
    description2: {
        textAlign: "center"
    },
    blockageReason: {
        textAlign: "center",
        color: Constants.COLOR_ERROR
    },

    firstRow: {
        maxWidth: Constants.GET_SIZE(625),
        width: "100%",
        marginBottom: Constants.GET_SIZE(50),
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
        }
    },
    memberSinceWrapper: {

    },
    memberSinceTitle: {

        ...GlobalStyle.uppercaseText,
        textAlign: "center"

    },
    memberSinceValue: {

        color: Constants.PINK,
        textAlign: "center"

    },

    noteWrapper: {
        alignItems: "center",
        textAlign: "center"
    },
    noteTitle: {

        ...GlobalStyle.uppercaseText,

    },
    noteValue: {

        textAlign: "center"

    },
    starWrapper: {
        // maxWidth: Constants.GET_SIZE(100),
        width: "100%",
        textAlign: "center"
    },

    secondRow: {
        marginBottom: Constants.GET_SIZE(50),
        maxWidth: 750,
        width: "100%",
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
            marginBottom: 20,
        }

    },
    clickAndCollectWrapper: {
        paddingTop: 25,
        maxWidth: Constants.GET_SIZE(338),
        width: "100%",
    },
    clickAndCollectCheckbox: {
        width: "100%"
    },
    clickAndCollectLabel: {

        color: "#929699"

    },

    videsDressingsWrapper: {
        maxWidth: Constants.GET_SIZE(317),
        width: "100%",
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    videsDressingsTitleWrapper: {
        padding: Constants.GET_SIZE(15),
    },
    videsDressingsTitle: {

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    videsDressingsList: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        paddingTop: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(10),
        width: "100%",
        overflowY: "auto",
        height: Constants.GET_SIZE(100)
    },
    videDressingCheckbox: {
        width: "100%",
        justifyContent: "space-between",
        padding: 0
    },
    videDressingLabel: {
    },

    grayCardLinkWrapper: {
        width: "100%"
    },
    grayCardLinkLabel: {
        color: Constants.WHITE,
        fontSize: Constants.FONT_SIZE_NORMAL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL,
            marginBottom: 15
        }

    },
    grayCardLink: {

        color: Constants.WHITE

    },
    grayCard: {
        backgroundColor: Constants.GRAY_FOOTER,
        color: "white",
        maxWidth: 373,
        width: "100%",
        maxHeight: 208,
        paddingTop: 50,
        paddingLeft: 50,
        paddingBottom: 50,
        marginRight: 12.5,
        marginLeft: 12.5,
        marginTop: 12.5,
        marginBottom: 12.5,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            maxHeight: "initial",
        }
    },
    grayCardAdmin: {
        maxHeight: 300,
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    grayCardTitle: {
        ...GlobalStyle.uppercaseText,
        marginBottom: 15,
        color: Constants.WHITE,
        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            margin: 0,
            padding: 20
        }
    },
    deliveryModesSuperWrapper: {
        position: "relative",
        marginBottom: Constants.GET_SIZE(25),
        marginTop: Constants.GET_SIZE(25),
    },
    deliveryModesWrapper: {
        maxWidth: Constants.GET_SIZE(317),
        width: "100%",
        margin: "auto",
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%"
        }
    },
    deliveryModesWrapperError: {
        borderColor: Constants.COLOR_ERROR
    },
    deliveryModesTitleWrapper: {
        padding: Constants.GET_SIZE(15),
    },
    deliveryModesTitle: {

        fontSize: Constants.FONT_SIZE_NORMAL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
        }
    },
    deliveryModesList: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderTopStyle: "solid",
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        width: "100%",
        overflowY: "auto",
    },
    deliveryModesListContent: {
        paddingTop: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(10),
        height: Constants.GET_SIZE(100)
    },
    deliveryModeCheckbox: {
        width: "100%",
        justifyContent: "space-between",
        padding: 0
    },
    deliveryModeLabel: {
    },
    deliveryModeCaret: {
        transform: "rotate(-180deg);",
        transition: "transform 0.3s"
    },
    deliveryModeCaretOpen: {
        transform: "rotate(0deg);",
    },
    upwardTriangle: {
        position: "absolute",
        left: 45,
        top: -9,
        width: 0,
        height: 0,
        borderTopWidth: 0,
        borderLeftWidth: 20,
        borderLeftColor: "transparent",
        borderRightWidth: 20,
        borderRightColor: "transparent",
        borderBottomWidth: 20,
        borderBottomColor: Constants.WHITE,
        borderStyle: "solid",
    },
    upwardTriangleBorder: {
        position: "absolute",
        left: 45,
        top: -10,
        width: 0,
        height: 0,
        zIndex: -1,
        borderTopWidth: 0,
        borderLeftWidth: Constants.GET_SIZE(20),
        borderLeftColor: "transparent",
        borderRightWidth: Constants.GET_SIZE(20),
        borderRightColor: "transparent",
        borderBottomWidth: Constants.GET_SIZE(20),
        borderBottomColor: Constants.BORDER_GRAY,
        borderStyle: "solid",
    },
    categoryCaret: {
        transform: "rotate(-180deg);",
        transition: "transform 0.3s",
        color: Constants.WHITE,
        position: "absolute",
        right: 10
    },
    categoryCaretOpen: {
        transform: "rotate(0deg);",
    },
    star: {
        color: Constants.PINK,
        fontSize: Constants.FONT_SIZE_BIG
    },

    desktopOnly: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    mobileOnly: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    messagesNotification: {
        position: "absolute",
        backgroundColor: Constants.PINK,
        color: Constants.BLACK,
        width: 20,
        height: 20,
        borderRadius: 50,
        top: 0,
        left: 90,
        textAlign: "center",
        lineHeight: "20px",
    },

    moderatedBrandsNotification: {
        position: "absolute",
        backgroundColor: Constants.PINK,
        color: Constants.BLACK,
        width: 20,
        height: 20,
        borderRadius: 50,
        top: 0,
        left: 190,
        textAlign: "center",
        lineHeight: "20px",
    },

    transactionNotification: {
        position: "absolute",
        backgroundColor: Constants.PINK,
        color: Constants.BLACK,
        width: 20,
        height: 20,
        borderRadius: 50,
        top: 0,
        left: 130,
        textAlign: "center",
        lineHeight: "20px",
    },

    currentLiveBlock: {
        padding: 20,
        width: '100%',
        maxWidth: 900,
        backgroundColor: Constants.BACKGROUND_GRAY
    },
    currentLiveTitle: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    currentLiveTime: {
        marginBottom: 10,
        fontWeight: 'bold'
    }
}));
