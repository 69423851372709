const initialState = {
    productsList: [],
    tendancesSauvagesList: [],
    similarProductsList: [],
    shopSimilarProductsList: [],
    newProductsList: [],
    previewProduct: null,
    pagination: {
        first:1,
        last:1
    },
    totalCount: 0,
    currentProduct: null,
    cart: [],
    liveCart: [],
    wishlist: [],
    wishlistPagination: {
        first: 1,
        last: 1
    },
    productError: null,
    currentProducts: {},
    productsSavedSuccessfully: []
};

function setShopInsideProductList(result, state, list, shop, productId, nested) {
    // Set shop for product inside product list if we find it
    if (nested) {
        const index = state[list].findIndex((element) => element.product.id === productId);
        if (index !== -1) {
            result[list] = [
                ...state[list].slice(0, index),
                Object.assign({}, state[list][index], {
                    product: Object.assign({}, state[list][index]["product"], {
                        shop: shop
                    })
                }),
                ...state[list].slice(index + 1)
            ];
        }
    } else {
        const index = state[list].findIndex((element) => element.id === productId);
        if (index !== -1) {
            result[list] = [
                ...state[list].slice(0, index),
                Object.assign({}, state[list][index], {shop: shop}),
                ...state[list].slice(index + 1)
            ];
        }
    }
};


function reduc(state = initialState, action) {
    let index;
    let result = {};
    let first;
    let last;
    switch (action.type) {
        case "SAVE_PREVIEW_PRODUCT":
            if (action.product) {
                action.product.pictures = action.pictures;
            }
            return Object.assign({}, state, {
                previewProduct: action.product
            });
        case "FETCH_MY_PRODUCTS_SUCCEEDED":
        case "SEARCH_PRODUCTS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                productsList: action.products,
                pagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1,
                },
                totalCount: action.totalCount
            });
        case "SEARCH_SIMILAR_PRODUCTS_SUCCEEDED":
            return Object.assign({}, state, {
                similarProductsList: action.products
            });
        case "GET_SHOP_SIMILAR_PRODUCTS_SUCCEEDED":
            return Object.assign({}, state, {
                shopSimilarProductsList: action.products
            });
        case "FETCH_PRODUCT_SUCCEEDED":
            return Object.assign({}, state, {
                productError: null,
                currentProduct: action.product
            });
        case "FETCH_PRODUCT_REQUESTED":
            return Object.assign({}, state, {
                productError: null,
                currentProduct: null,
                similarProductsList: []
            });
        case "FETCH_PRODUCT_FAILED":
            return Object.assign({}, state, {
                productError: action.message
            });
        case "RESET_PRODUCT_ERROR":
            return Object.assign({}, state, {
                productError: null
            });
        case "FETCH_NEW_PRODUCTS_SUCCEEDED":
            return Object.assign({}, state, {
                newProductsList: action.products,
            });
        case "FETCH_WISHLIST_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                wishlist: action.products,
                wishlistPagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1,
                }
            });

        case "FETCH_TENDANCES_SAUVAGES_SUCCEEDED":
            return Object.assign({}, state, {
                tendancesSauvagesList: action.products
            });
        case "FETCH_PRODUCT_SHOP_SUCCEEDED":
            // Set base pagination to prevent crashes
            if (!action.shop.pagination) {
                action.shop.pagination = {
                    first: 1,
                    last: 1
                };
            }

            // Set shop for current product if id is good
            if (state.currentProduct && (state.currentProduct.id === action.productId)) {
                result.currentProduct = Object.assign({}, state.currentProduct, {
                    shop: action.shop
                });
            }

            setShopInsideProductList(result, state, "productsList", action.shop, action.productId);
            setShopInsideProductList(result, state, "similarProductsList", action.shop, action.productId);
            setShopInsideProductList(result, state, "newProductsList", action.shop, action.productId);
            setShopInsideProductList(result, state, "tendancesSauvagesList", action.shop, action.productId);
            setShopInsideProductList(result, state, "cart", action.shop, action.productId, true);


            return Object.assign({}, state, result);

        case "SAVE_PRODUCT_SUCCEEDED":
            index = state.tendancesSauvagesList.findIndex((element) => element.id === action.product.id);

            if (index !== -1 && !action.product.tendanceSauvage) {
                result.tendancesSauvagesList = [
                    ...state.tendancesSauvagesList.slice(0, index),
                    ...state.tendancesSauvagesList.slice(index + 1)
                ];
            } else if (index === -1 && action.product.tendanceSauvage) {
                result.tendancesSauvagesList = [
                    ...state.tendancesSauvagesList,
                    action.product
                ];
            }

            result.productsSavedSuccessfully = [...state.productsSavedSuccessfully, action.product];

            return Object.assign({}, state, result);
        // *************************** PREVIEW ***********************************
        case "SAVE_CURRENT_PRODUCTS":
            return Object.assign({}, state, {currentProducts: action.products});
        case "UPDATE_CURRENT_PRODUCT":
            const alreadyExistingProduct = state.currentProducts[action.productKey] || {};
            return {
                ...state,
                currentProducts: {
                    ...state.currentProducts,
                    [action.productKey]: {
                        ...alreadyExistingProduct,
                        ...action.product
                    }
                }
            };
        // *************************** CART ***********************************
        case "ADD_TO_CART":
            return Object.assign({}, state, {
                cart: [
                    ...state.cart,
                    {
                        product: action.product,
                        quantity: action.quantity ? action.quantity : 1,
                        color: action.color ? action.color : null,
                        size: action.size ? action.size : null,
                        delivery: -1
                    }
                ]
            });
        case "ADD_TO_CART_SUCCEEDED":
            index = state.cart.findIndex((element) => element.product.id === action.product["id"]);
            if (index !== -1) {
                return Object.assign({}, state, {
                    cart: [
                        ...state.cart.slice(0, index),
                        Object.assign({}, state.cart[index], {"fromBackend": true}),
                        ...state.cart.slice(index + 1)
                    ]
                });
            }
            break;
        case "REFRESH_CART_SUCCEEDED":
            result = Object.assign({}, state, {});
            for (const id in action.products) {
                index = result.cart.findIndex((element) => element.product.id == id);
                if (index !== -1) {
                    result = Object.assign({}, result, {
                        cart: [
                            ...state.cart.slice(0, index),
                            Object.assign({}, result.cart[index], {
                                product: Object.assign({}, result.cart[index].product, {
                                    currentLive: action.products[id].currentLive,
                                    quantity: action.products[id].quantity,

                                })
                            }),
                            ...state.cart.slice(index + 1)
                        ]
                    });
                }
            }
            return result;
        case "REMOVE_FROM_CART":
            index = state.cart.findIndex((element) => element.product.id === action.product["id"]);
            return Object.assign({}, state, {
                cart: [
                    ...state.cart.slice(0, index),
                    ...state.cart.slice(index + 1)
                ]
            });
        case "FETCH_ME_SUCCEEDED":
            let mergedCart = [];
            for (const p of state.cart) {
                let alreadyPresentProduct = null;
                for (const p2 of action.user.cartProducts) {
                    if (p.product.id === p2.product.id) {
                        alreadyPresentProduct = p2;
                        break;
                    }
                }

                if (!alreadyPresentProduct) {
                    if (!p["fromBackend"]) {
                        mergedCart.push(p);
                    }
                } else {
                    mergedCart.push(Object.assign({}, alreadyPresentProduct, p, {"fromBackend": true}));
                }
            }

            for (const p of action.user.cartProducts) {
                let alreadyPresentProduct = null;
                for (const p2 of mergedCart) {
                    if (p.product.id === p2.product.id) {
                        alreadyPresentProduct = p2;
                        break;
                    }
                }
                if (!alreadyPresentProduct) {
                    mergedCart.push(Object.assign({}, p, {"fromBackend": true}));
                }
            }

            let mergedWishlist = [...action.user.wishes];
            for (const p of state.wishlist) {
                let isAlreadyPresent = false;
                for (const p2 of mergedWishlist) {
                    if (p.id === p2.id) {
                        isAlreadyPresent = true;
                    }
                }
                if (!isAlreadyPresent) {
                    mergedWishlist.push(p);
                }
            }

            return Object.assign({}, state, {
                cart: mergedCart,
                wishlist: mergedWishlist
            });

        case "LOGOUT":
            return Object.assign({}, state, {
                currentProduct: null,
                productsList: [],
                cart: [],
                wishlist: []
            });
        case "EMPTY_CART":
            return Object.assign({}, state, {
                cart: []
            });
        case "SET_DELIVERY_FOR_PRODUCT":
            index = state.cart.findIndex((element) => {
                return element.product.id === action.product["id"];
            });
            let n = Object.assign({}, state, {
                cart: [
                    ...state.cart.slice(0, index),
                    Object.assign({}, state.cart[index], {
                        delivery: action.delivery
                    }),
                    ...state.cart.slice(index + 1)
                ]
            });
            return n;
        case "SET_QUANTITY_FOR_PRODUCT":
            index = state.cart.findIndex((element) => element.product["id"] === action.product["id"]);
            return Object.assign({}, state, {
                cart: [
                    ...state.cart.slice(0, index),
                    Object.assign({}, state.cart[index], {
                        quantity: action.quantity
                    }),
                    ...state.cart.slice(index + 1)
                ]
            });
        case "ADD_TO_WISHLIST":
        case "ADD_TO_WISHLIST_SUCCEEDED":
        case "NEW_ADD_TO_WISHLIST":
            let product = action.product ? action.product : {id: action.id, isWish: action.subscribe};

            let updateList = (list) => {
                index = state[list].findIndex((element) => element.id === product.id);
                if (index !== -1) {
                    result[list] = [
                        ...state[list].slice(0, index),
                        Object.assign({}, state[list][index], product),
                        ...state[list].slice(index + 1)
                    ];
                }
            }
            // Product List
            updateList('productsList');
            updateList('tendancesSauvagesList');
            updateList('similarProductsList');
            updateList('newProductsList');

            // Wish list
            index = state.wishlist.findIndex((element) => element.id === product.id);
            if (product.isWish) {
                if (index === -1) {
                    result.wishlist = [
                        ...state.wishlist,
                        product
                    ]
                } else {
                    result.wishlist = [
                        ...state.wishlist.slice(0, index),
                        Object.assign({}, state.wishlist[index], product),
                        ...state.wishlist.slice(index + 1)
                    ];
                }
            } else {
                if (index !== -1) {
                    result.wishlist = [
                        ...state.wishlist.slice(0, index),
                        ...state.wishlist.slice(index + 1)
                    ];
                }
            }

            if (state.currentProduct && product.id === state.currentProduct.id) {
                result.currentProduct = Object.assign({}, state.currentProduct, product);
            }
            return Object.assign({}, state, result);

        case "EMPTY_WISHLIST_SUCCEEDED":
            // TODO Set isWish to false for every product...
            return Object.assign({}, state, {wishlist: []})

    }

    return state
}

export default reduc
