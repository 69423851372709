import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        // alignItems: "center",
        width: "100%",
    },
    row: {
        ...GlobalStyle.rowCentered,
    },

    datePickerWrapper: {
        alignItems: "center",
        marginLeft: Constants.GET_SIZE(90),
        marginRight: Constants.GET_SIZE(90)
    },
    datePickerLabel: {

        ...GlobalStyle.uppercaseText
    },
    datePickerPicker: {

    },

    grayBlock: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        paddingTop: Constants.GET_SIZE(25),
        paddingBottom: Constants.GET_SIZE(25),
        paddingRight: Constants.GET_SIZE(50),
        paddingLeft: Constants.GET_SIZE(50)
    },

    label: {

        marginLeft: Constants.GET_SIZE(10),
        marginRight: Constants.GET_SIZE(10)
    },
    value: {

        color: Constants.PINK
    },

    button: {
        ...GlobalStyle.button,
        width: "max-content",
        marginTop: Constants.GET_SIZE(50)
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },

    pickerWrapper: {
        ...GlobalStyle.rowCentered,
        width: Constants.GET_SIZE(250),
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderRadius: Constants.GET_SIZE(10),
        height: Constants.GET_SIZE(58),
        marginBottom: Constants.GET_SIZE(13),
    },
    pickerItem: {

    },
    picker: {

        borderWidth: 0,
        backgroundColor: Constants.WHITE,
        height: Constants.GET_SIZE(43),
        width: "100%",
        marginRight:  Constants.FONT_SIZE_NORMAL,
        marginLeft:  Constants.FONT_SIZE_NORMAL,
    },
    checkboxLabel: {

        color: Constants.FONT_LIGHT_GRAY
    },
    checkBox: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.WHITE
    },
}));
