import axios from 'axios';
import {API_URL} from '../../Config/API'

export const FetchMondialRelayPoints = (city, zipcode) => {
    return axios.post(API_URL + "api/delivery/mondial_relay_list?city=" + city + "&zipcode=" + zipcode);
};

export const FetchChronoRelayPoints = (city, zipcode) => {
    return axios.post(API_URL + "api/delivery/chronopost_relay_list?city=" + city + "&zipcode=" + zipcode);
};
