import React from "react";

import Link from "@material-ui/core/Link";

import StaticCommon from "./StaticCommon";
import Banner from "../../../Assets/Images/banners/Ouvrir_une_boutique.jpg"


function Component({title}) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "Ouvrir une boutique",
        blocks: [
            {
                title: "Devenez vendeur",
                content: [
                    { text: "Particuliers, amateurs de mode, créateurs, blogueurs ou acteurs engagés dans le domaine de la mode éco-responsable... Tout le monde peut devenir vendeur chez Violette Sauvage !" },
                    { text: "Qu’il s’agisse de votre activité principale, d’un complément de revenus ou simplement l’envie de contribuer à l’économie participative et redonner une seconde vie à votre dressing, vous êtes tous les bienvenus !" },
                    { text: "La Marketplace Violette Sauvage offre également la possibilité aux professionnels d’avoir leur propre Boutique grâce à nos différentes catégories : Créateurs, Blogueurs et la section éco-responsable réserver au Fairtrade." },
                    { text: "Choisissez votre catégorie lors de votre inscription, laissez-vous guider à travers le formulaire et le tour est joué !" },
                    { text: "N’hésitez pas à indiquer vos présences aux vide-dressings afin d’avoir une plus grande visibilité et activer l’option « Click and collect » pour vos futurs clients et ainsi optimiser des ventes en amont de votre participation." },
                    { text: "Par ailleurs, au bout d’un certain nombre de ventes, vous aurez la possibilité de participer à l’un de nos vide-dressings gratuitement ! L’occasion de vivre l’expérience Violette Sauvage et rencontrer un millier de clients potentiels sur un week-end !" }
                ]
            },

        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"Ouvrez une boutique sur Violette Sauvage, vide dressing en ligne"}
                      metaDesc={"Vous êtes amateurs, un bloggueur/influenceur, un créateur, vous avez la fibre éco-responsable ? Ouvrez une boutique sur Violette Sauvage"}
                      arianeCurrentName={"Ouvrir une boutique"} />
    );
}

export default Component
