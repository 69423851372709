import { select, call, put, takeLatest, cancelled } from 'redux-saga/effects'
import { ApiLogin, ApiLoginFacebook, ApiLoginGoogle, ApiChangePassword, ApiPasswordReset } from '../Endpoint/Login'
import { ApiFetchMe } from "../Endpoint/User";
import { handleError } from "../../Utils/SagaUtils";

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* login(action) {
    try {
        let result = yield call(ApiLogin, action.payload.login, action.payload.pwd);
        const token = result.data.token;
        yield put({type: "LOGIN_SUCCEEDED", token});

        result = yield call(ApiFetchMe, token);
        yield put({type: "FETCH_ME_SUCCEEDED", user: result.data[0]})
    } catch (e) {
        yield put(handleError("LOGIN_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* loginFacebook(action) {
    try {
        const user = yield call(ApiLoginFacebook, action.payload.accessToken);
        const token = user.data.token;
        yield put({type: "LOGIN_SUCCEEDED", token});

        let result = yield call(ApiFetchMe, token);
        yield put({type: "FETCH_ME_SUCCEEDED", user: result.data[0]})
    } catch (e) {
        yield put(handleError("LOGIN_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* loginGoogle(action) {
    try {
        const user = yield call(ApiLoginGoogle, action.payload.accessToken);
        const token = user.data.token;
        yield put({type: "LOGIN_SUCCEEDED", token});

        let result = yield call(ApiFetchMe, token);
        yield put({type: "FETCH_ME_SUCCEEDED", user: result.data[0]})
    } catch (e) {
        yield put(handleError("LOGIN_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* passwordReset(action) {
    try {
        yield call(ApiPasswordReset, action.payload.mail);
        yield put({type: "PASSWORD_RESET_SUCCEEDED"});
    } catch (e) {
        yield put(handleError("PASSWORD_RESET_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* changePassword(action) {
    try {
        yield call(ApiChangePassword, action.payload.id, action.payload.password);
        yield put({type: "CHANGE_PASSWORD_SUCCEEDED"});
    } catch (e) {
        yield put(handleError("CHANGE_PASSWORD_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* mySaga() {
    yield takeLatest("LOGIN_REQUESTED", login);
    yield takeLatest("LOGIN_FACEBOOK_REQUESTED", loginFacebook);
    yield takeLatest("LOGIN_GOOGLE_REQUESTED", loginGoogle);
    yield takeLatest("PASSWORD_RESET_REQUESTED", passwordReset);
    yield takeLatest("CHANGE_PASSWORD_REQUESTED", changePassword);
}

export default mySaga;