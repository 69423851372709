import React from "react";
import clsx from 'clsx';
import { Image } from "react-native";

import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Collapse from "@material-ui/core/Collapse";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";

import Check from "@material-ui/icons/Check";
import CameraAlt from "@material-ui/icons/CameraAlt";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlineChecked from '@material-ui/icons/CheckBoxOutlined';
import CancelIcon from '@material-ui/icons/Cancel';


import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";

import Text from "../Component/FontText"
import PictureCropModal from "../Middle/Component/PictureCropModal"
import Base from "./Base"
import StylesFunc from "../../Style/Back/ProductEditStyle"
import * as Constants from "../../Style/Constants"
import TextInputIcon from "../Front/Component/TextInputIcon";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {getImageUrl, pickImage, dataURLtoFile} from "../../Utils/ImageUtils";
import ErrorStyleFunc from "../Styles/Error";
import CustomCheckbox from "../Component/CustomCheckbox";
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import {useTheme, withStyles} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {VariableSizeList} from "react-window";


const COLOR_ASSOCIATION = {
    "Noir": "#000000",
    "Bleu": "#0000FF",
    "Blanc": "#FFFFFF",
    "Multi": "#000000",
    "Gris": "#696969",
    "Beige": "#E0CDA9",
    "Marron": "#582900",
    "Rose": "#FD6C9E",
    "Rouge": "#FF0000",
    "Argent": "#C0C0C0",
    "Or": "#EFD807",
    "Vert": "#00FF00",
    "Violet": "#DDA0DD",
    "Kaki": "#94812B",
    "Orange": "#FF7F00",
    "Jaune": "#F7FF3C"
};

const COLORED_CHECKBOXES = {};

for (const color of Object.keys(COLOR_ASSOCIATION)) {
    const ColoredCheckbox = withStyles({
        root: {
            color: COLOR_ASSOCIATION[color]
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    COLORED_CHECKBOXES[color] = props => (
        <ColoredCheckbox
            icon={
                <Box style={{
                    width: 20,
                    height: 20,
                    lineHeight: "20px",
                    marginRight: 5,
                    marginLeft: 5,
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: COLOR_ASSOCIATION[color],
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: color === "Blanc" ? Constants.PINK : COLOR_ASSOCIATION[color]
                }}>
                </Box>
            }
            checkedIcon={
                <Box style={{
                    width: 20,
                    height: 20,
                    lineHeight: "20px",
                    marginRight: 5,
                    marginLeft: 5,
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: COLOR_ASSOCIATION[color],
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: color === "Blanc" ? Constants.PINK : COLOR_ASSOCIATION[color]
                }}>
                    <Check style={{fontSize: Constants.FONT_SIZE_NORMAL, color: Constants.PINK}} />
                </Box>
            }
            {...props} />
    );
}

// Adapter for react-window
const LISTBOX_PADDING = 0; // px
function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;


    const getChildSize = (child) => {
        // if (React.isValidElement(child) && child.type === ListSubheader) {
        //     return 48;
        // }
        return itemSize;
    };

    const getHeight = () => {
        // if (itemCount > 8) {
        //     return 8 * itemSize;
        // }
        // return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        return Math.min(itemCount, 8) * itemSize;
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="div"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

function CustomSelect({classes, control, errors, name, label, defaultValue, value, placeholder, choices, display = true}) {
    return <FormControl className={classes.pickerWrapper} style={{display: !display ? "none": "block"}}>
        {(label/* || (placeholder && value != -1)*/) && <InputLabel id={"select-" + name + "-label"}>{label ? label : placeholder ? placeholder + "*": null}</InputLabel>}
        <Controller
            as={
                <Select
                    labelId={label || placeholder ? "select-" + name + "-label" : null}
                    variant={"outlined"}
                    className={clsx(classes.picker, {[classes.pickerPlaceholder]: value == -1})}
                    error={errors && errors[name]}
                >
                    {placeholder && <MenuItem value={-1} disabled={true}>{placeholder}*</MenuItem>}
                    {choices}
                </Select>
            }
            control={control}
            rules={{required: "Ce champ est obligatoire", validate: (value) => value !== -1 || "Ce champ est obligatoire"}}
            name={name}
            defaultValue={value}
        />
        <ErrorMessage name={name} errors={errors} render={({message}) => <Text color={"error"}>{message}</Text>} />
    </FormControl>
}

function Component({navigation, history, screenKey, token, user, familyTree, criteria, storeProduct, previewProduct, fetchProduct, fetchCriteria, saveProduct, savePreviewProduct, admin, deleteProduct}) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { trigger, setValue, setError, handleSubmit, register, control, errors, watch, formState } = useForm();


    // React.useEffect(() => {
    //     if (!user || !user.shop) {
    //         history.push("/creer_ma_boutique");
    //     }
    // }, [user]);

    let product = null

    // 4 cas :
    // 1) Création de produit, pas de preview
    // 2) Création de produit, preview
    // 3) Edition de produit, pas de preview
    // 4) Edition de produit, preview avec meme id
    // 5) Edition de produit, preview != id
    if (previewProduct) {
        if (screenKey === "EditProduct" && storeProduct && previewProduct.id !== storeProduct.id) {
            product = storeProduct;
        } else {
            product = previewProduct;
        }
    } else if (storeProduct) {
        product = storeProduct;
    };

    let { id } = useParams();

    let [page, setPage] = React.useState(0);
    let [pictureCropModalVisible, setPictureCropModalVisible] = React.useState(false);
    let [imageErrorModalVisible, setImageErrorModalVisible] = React.useState(false);
    let [currentPictureUri, setCurrentPictureUri] = React.useState(null);
    let [ deleteModalVisible, setDeleteModalVisible ] = React.useState(false);

    let name = watch('name', "");
    let description = watch('description', "");
    let discount = watch('discount', "");
    let brandSearch = watch('brandSearch', "");
    let family = watch('family', -1);
    let category = watch('category', -1);
    let subCat = watch('subCat', -1);
    let subCat2 = watch('subCat2', -1);
    let state = watch('state', -1);
    let material = watch('material', -1);
    let quantity = watch('quantity', 1);
    let colors = watch('colors', []);
    let sizes = watch('sizes', []);
    let deliveryModes = watch('deliveryModes', []);
    let pictures = watch('pictures', []);
    React.useEffect(() => {
        register({name: 'colors'}, {validate: (value) => value && value.length > 0 || "Vous devez sélectionner au moins une couleur"});
        register({name: 'sizes'}, {validate: (value) => !lowestLevelCat || !lowestLevelCat.sizeType || (value && value.length > 0) || "Vous devez sélectionner au moins une taille"});
        register({name: 'deliveryModes'}, {validate: (value) => value && value.length > 0 || "Vous devez sélectionner au moins un mode de livraison"});
        register({name: 'pictures'}, {validate: (value) => value && value.length > 0 || "Vous devez sélectionner au moins une photo"});
        register({name: 'brand'})
    }, [register]);

    let [displaySizes, setDisplaySizes] = React.useState(true);
    let [displayDeliveryModes, setDisplayDeliveryModes] = React.useState(true);
    let [brandWarning, setBrandWarning] = React.useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    let loadProd = () => {
        // 4 cas :
        // 1) Création de produit, pas de preview
        // 2) Création de produit, preview
        // 3) Edition de produit, pas de preview
        // 4) Edition de produit, preview avec meme id
        // 5) Edition de produit, preview != id
        if (previewProduct) {
            if (screenKey === "EditProduct" && storeProduct && previewProduct.id != storeProduct.id) {
                product = storeProduct;
                savePreviewProduct(null, []);
            } else {
                product = previewProduct;
            }
        } else if (storeProduct) {
            product = storeProduct;
            savePreviewProduct(null, []);
        };

        if (product && (screenKey === "EditProduct" || previewProduct)) {
            // Global info
            setValue("name", product.name);
            setValue("family", parseInt(product.family.id));
            let categories = [];
            categories.push(product.category);
            if (product.category.parent) {
                categories.push(product.category.parent);
            }
            if (product.category.parent.parent) {
                categories.push(product.category.parent.parent);
            }
            categories = categories.reverse();
            setValue("category", categories[0].id);
            if (categories.length > 1) {
                setValue("subCat", categories[1].id);
                if (categories.length > 2) {
                    setValue("subCat2", categories[2].id);
                }
            }

            setValue("description", product.description);
            setValue("pictures", product.pictures);
            if (product.brand) {
                setValue("brandSearch", product.brand.value);
            }
            setValue("sizes", product.sizes.map(size => size.id));
            setValue("colors", product.colors.map(color => color.id));
            setValue("state", product.state.id);
            setValue("material", product.material.id);
            setValue("quantity", product.quantity);
            setValue("price", product.price);
            if (product.discount != 0) {
                setValue("discount", product.discount);
            }

            let delModes = [];
            if (product.chronopost || product.mondialRelay) {
                delModes.push(0);
            }
            // if (product.clickAndCollect) {
            //     delModes.push(1);
            // }
            if (product.handOver) {
                delModes.push(2);
            }
            setValue("deliveryModes", delModes);
            setValue("returnPossible", product.returnPossible);
        }
    };

    React.useEffect(() => {
        fetchCriteria();
    }, []);

    React.useEffect(() => {
        if (id) {
            fetchProduct(id);
        }
    }, [id]);

    React.useEffect(() => {
        if (storeProduct || previewProduct) {
            loadProd();
        }
    }, [storeProduct]);

    let handleResetPage = () => {
        if (screenKey === "EditProduct") {
            history.push("/ajouter_produit")
        } else {
            setValue("name", "");
            setValue("description", "");
            setValue("family", -1);
            setValue("category", -1);
            setValue("subCat", -1);
            setValue("subCat2", -1);
            setValue("pictures", []);
            setValue("sizes", []);
            setValue("colors", []);
            setValue("material", -1);
            setValue("state", -1);
            setValue("quantity", 1);
            setValue("price", "");
            setValue("deliveryModes", []);
            setValue("returnPossible", false);
            // setValue("CGV", false);
            setValue("brand", -1);
            setValue("brandSearch", "");

            setDisplaySizes(true);
            setDisplayDeliveryModes(true);
            setPage(0);
        }
    };

    let handleSaveProduct = (data) => {
        let brand = getBrandIdFromName(data.brandSearch);

        let newProduct = {
            id: screenKey === "EditProduct" ? id : null,
            family: "/api/families/" + parseInt(data.family).toString(),
            name: data.name,
            description: data.description,
            brand: brand !== -1 ? "/api/criterion_brands/" + brand : null,
            sizes: data.sizes.map(size => "/api/criterion_sizes/" + size),
            colors: data.colors.map(color => "/api/criterion_colors/" + color),
            state: "/api/criterion_states/" + data.state,
            material: "/api/criterion_materials/" + data.material,
            quantity: parseInt(data.quantity),
            price: parseFloat(data.price),
            discount: data.discount ? parseInt(data.discount) : 0,
            chronopost: data.deliveryModes.indexOf(0) !== -1,
            mondialRelay: data.deliveryModes.indexOf(0) !== -1,
            clickAndCollect: true,
            handOver: data.deliveryModes.indexOf(2) !== -1,
            returnPossible: data.returnPossible,
            category: "/api/categories/" + parseInt(lowestLevelCat.id).toString()

        };

        saveProduct(newProduct, data.pictures, brand === -1 ? data.brandSearch : null,);
        handleClickBack();

    };

    let handleClickPreview = (data) => {
        let brand = getBrandIdFromName(data.brandSearch);

        let previewSizes = [];
        for (const s of data.sizes) {
            previewSizes.push(criteria.sizes.filter(s2 => s == s2.id)[0]);
        }
        let previewColors = [];
        for (const c of data.colors) {
            previewColors.push(criteria.colors.filter(c2 => c == c2.id)[0]);
        }
        let realPrice = parseFloat(data.price);
        realPrice = Math.round(realPrice - (realPrice * (data.discount / 100)));
        let newProduct = {
            id: screenKey === "EditProduct" ? id : null,
            family: familyTree.filter(f => data.family == f.id)[0],
            name: data.name,
            description: data.description,
            brand: brand !== -1 ? criteria.brands.filter(b => brand == b.id)[0] : {"value": data.brandSearch},
            state: criteria.states.filter(s => data.state == s.id)[0],
            material: criteria.materials.filter(m => data.material == m.id)[0],
            sizes: previewSizes,
            colors: previewColors,
            quantity: parseInt(data.quantity),
            price: parseFloat(data.price),
            realPrice: realPrice,
            chronopost: data.deliveryModes.indexOf(0) !== -1,
            mondialRelay: data.deliveryModes.indexOf(0) !== -1,
            clickAndCollect: data.deliveryModes.indexOf(1) !== -1,
            handOver: data.deliveryModes.indexOf(2) !== -1,
            returnPossible: data.returnPossible,
            category: lowestLevelCat

        };
        savePreviewProduct(newProduct, data.pictures);
        history.push("/preview");
    };

    let handleClickContinue = (data) => {
        handleSaveProduct(data);
    };

    let handleAddPicture = () => {
        pickImage(result => {
            setCurrentPictureUri(result.uri);
            setPictureCropModalVisible(true);
        }, error => {
            if (error.type === "invalid_mime_type") {
                setImageErrorModalVisible(true);
            }
        });
    };

    let handleCropPicture = (picture) => {
        setValue("pictures", [...pictures, {"id": -1, key: "pic-new-" + pictures.length, "uri": picture, "type": "new", "file": dataURLtoFile(picture, "product-" + name.replace(/[^A-Z0-9]/ig, "_") + "-image"), sortOrder: pictures.length}]);
        setPictureCropModalVisible(false);
    };

    let handleRemovePicture = (index) => {
        let newPictures = [...pictures.slice(0, index), ...pictures.slice(index + 1)];
        setValue("pictures", newPictures);
    };

    let handleChangePictureOrder = (result) => {
        if (!result.destination) {
            return;
        }

        const newPictures = pictures.slice();
        newPictures.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);

        let destinationIndex = result.destination.index + (result.destination.droppableId === "droppable2" ? (result.source.droppableId === "droppable2" ? 3 : 2) : 0);
        let sourceIndex = result.source.index + (result.source.droppableId === "droppable2" ? 3 : 0);

        // console.log("DBG dnd", result.source, result.destination, sourceIndex, destinationIndex);
        if (sourceIndex < destinationIndex) {
            // console.log("DBG dnd 2", "Moving picture forward");
            let encounteredSource = false;
            for (const picture of newPictures) {
                if (encounteredSource) {
                    // console.log("DBG dnd 3", "2) New sort order", picture.sortOrder, picture.sortOrder - 1);
                    picture.sortOrder = picture.sortOrder - 1;
                    if (picture.sortOrder + 1 === destinationIndex) {
                        break;
                    }
                } else if (picture.sortOrder === sourceIndex) {
                    // console.log("DBG dnd 3", "1) New sort order", picture.sortOrder, destinationIndex);
                    picture.sortOrder = destinationIndex;
                    encounteredSource = true;
                } else {
                    // console.log("DBG dnd 3", "ignoring", picture.sortOrder, destinationIndex);
                }
            }
        } else if (sourceIndex > destinationIndex) {
            // console.log("DBG dnd 2", "Moving picture backward");
            let encounteredDestination = false;
            for (const picture of newPictures) {
                if (picture.sortOrder === sourceIndex) {
                    // console.log("DBG dnd 3", "2) New sort order", picture.sortOrder, destinationIndex);
                    picture.sortOrder = destinationIndex;
                } else if (picture.sortOrder === destinationIndex || encounteredDestination) {
                    // console.log("DBG dnd 3", "1) New sort order", picture.sortOrder, picture.sortOrder + 1);
                    picture.sortOrder = picture.sortOrder + 1;
                    encounteredDestination = true;
                } else {
                    // console.log("DBG dnd 3", "ignoring", picture.sortOrder, destinationIndex);
                }
            }
        }

        setValue("pictures", newPictures);
    };

    let getBrandIdFromName = (brandName) => {
        let result = -1;

        if (brandName) {
            for (const brand of criteria.brands) {
                if (brand.value.toLowerCase() === brandName.toLowerCase()) {
                    result = brand.id;
                    break;
                }
            }
        }

        return result;
    }

    React.useEffect(() => {
        if (brandSearch && brandSearch.length > 0 && getBrandIdFromName(brandSearch) === -1) {
            if (!brandWarning) {
                setBrandWarning(true);
            }
        } else {
            if (brandWarning) {
                setBrandWarning(false);
            }
        }
    }, [brandSearch, criteria.brands]);

    let lowestLevelCat = null;
    let familyChoices = [];
    let choseFamily = null;
    if (familyTree) {
        for (const fam of familyTree) {
            familyChoices.push({id: fam.id, label: fam.name})
            if (family == fam.id) {
                choseFamily = fam;
            }
        }
    }

    let categoryChoices = [];
    let choseCategory = null;
    if (familyTree && family != -1 && choseFamily && choseFamily.children) {
        for (const cat of choseFamily.children) {
            categoryChoices.push({id: cat.id, label: cat.name})
            if (category == cat.id) {
                choseCategory = cat;
                lowestLevelCat = choseCategory;
            }
        }
    }

    let subCatChoices = [];
    let choseSubCat = null;
    if (familyTree && family != -1 && category != -1 && choseCategory && choseCategory.children) {
        for (const subC of choseCategory.children) {
            subCatChoices.push({id: subC.id, label: subC.name, parent: choseCategory})
            if (subCat == subC.id) {
                choseSubCat = subC;
                choseSubCat.parent = choseCategory;
                lowestLevelCat = choseSubCat;
            }
        }
    }

    let subCat2Choices = [];
    let choseSubCat2 = null;
    if (familyTree && family != -1 && category != -1 && subCat != -1 && choseSubCat && choseSubCat.children) {
        for (const subC2 of choseSubCat.children) {
            subCat2Choices.push({id: subC2.id, label: subC2.name, parent: choseSubCat})
            if (subCat2 == subC2.id) {
                choseSubCat2 = subC2;
                choseSubCat2.parent = choseSubCat;
                lowestLevelCat = choseSubCat2;
            }
        }
    }



    let sizeChoices = [];
    if (lowestLevelCat) {
        for (const s of criteria.sizes) {
            if (s.type === lowestLevelCat.sizeType) {
                sizeChoices.push({id: s.id, value: s.value})
            }
        }
    }

    let quantityChoices = [];
    for (let i = 1; i < 30; i++) {
        quantityChoices.push(i);
    }

    let onPressColor = (color) => {
        let index = colors.indexOf(color.id);
        if (index !== -1) {
            setValue("colors", [...colors.slice(0, index), ...colors.slice(index + 1)]);
        } else {
            setValue("colors", [...colors, color.id]);
        }
        if (formState.isSubmitted) {
            trigger('colors')
        }
    };

    let handleClickOnDeliveryMode = (mode) => {
        let index = deliveryModes.indexOf(mode);
        if (index === -1) {
            setValue("deliveryModes", [...deliveryModes, mode]);
        } else {
            setValue("deliveryModes", [...deliveryModes.slice(0, index), ...deliveryModes.slice(index + 1)]);
        }
        if (formState.isSubmitted) {
            trigger('deliveryModes')
        }
    };

    let handleClickSize = (size) => {
        let index = sizes.indexOf(size);
        if (index === -1) {
            setValue("sizes", [...sizes, size]);
        } else {
            setValue("sizes", [...sizes.slice(0, index), ...sizes.slice(index + 1)]);
        }
        if (formState.isSubmitted) {
            trigger('sizes')
        }
    };

    let deliveryModesParam = [
        {
            "id": 0,
            "label": "Livraison (chronopost / Mondial Relay)"
        },
    ];

    // if (user && user.shop && user.shop.videDressingSeller) {
    //     deliveryModesParam.push(
    //         {
    //             "id": 1,
    //             "label": "Click & collect"
    //         })
    // }
    deliveryModesParam.push(
        {
            "id": 2,
            "label": "Remise en main propre"
        }
    );

    pictures.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);


    let categoryMaterials = lowestLevelCat ? criteria.materials.filter(mat => mat.type === lowestLevelCat.materialType) : [];

    let _getPage = () => {
        return <Grid container xs={12}>
            <Grid item xs={12} className={Styles.column}>
                <TextInputIcon
                    wrapperClass={Styles.inputWrapper}
                    errors={errors}
                    register={register}
                    defaultValue={product ? product.name : ""}
                    placeholder={"Titre de l'article*"}
                    rules={{
                        required: "Ce champ est obligatoire"
                    }}
                    name={"name"}
                />

                <CustomSelect
                    control={control}
                    errors={errors}
                    classes={Styles}
                    placeholder={"Famille"}
                    value={family}
                    defaultValue={product ? product.family.id : -1}
                    choices={familyChoices.map((fam) => <MenuItem value={fam.id} style={{transformText: "uppercase"}}>{fam.label.toUpperCase()}</MenuItem>)}
                    name={"family"} />

                <CustomSelect
                    control={control}
                    errors={errors}
                    classes={Styles}
                    placeholder={"Catégorie"}
                    value={category}
                    choices={categoryChoices.slice().sort((cat1, cat2) => cat1.label > cat2.label ? 1 : -1).map((cat) => <MenuItem value={cat.id}>{cat.label}</MenuItem>)}
                    display={categoryChoices.length > 0}
                    name={"category"} />

                <CustomSelect
                    control={control}
                    errors={errors}
                    classes={Styles}
                    placeholder={"Sous catégorie"}
                    value={subCat}
                    choices={subCatChoices.slice().sort((cat1, cat2) => cat1.label > cat2.label ? 1 : -1).map((cat) => <MenuItem value={cat.id}>{cat.label}</MenuItem>)}
                    display={subCatChoices.length > 0}
                    name={"subCat"} />

                <CustomSelect
                    control={control}
                    errors={errors}
                    classes={Styles}
                    placeholder={"Sous catégorie"}
                    value={subCat2}
                    choices={subCat2Choices.slice().sort((cat1, cat2) => cat1.label > cat2.label ? 1 : -1).map((cat) => <MenuItem value={cat.id}>{cat.label}</MenuItem>)}
                    display={subCat2Choices.length > 0}
                    name={"subCat2"} />

                <TextInputIcon
                    wrapperClass={Styles.inputWrapper}
                    errors={errors}
                    register={register}
                    defaultValue={description}
                    placeholder={"Descriptif du produit*"}
                    multiline
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"description"}
                />
                <Button variant={"contained"} color={"primary"} disableElevation className={Styles.pictureButton} onClick={() => handleAddPicture()} startIcon={<CameraAlt style={{fontSize: Constants.FONT_SIZE_BIGGEST}}/>}>
                    Ajouter une photo*
                </Button>
                <Text className={Styles.pictureSubtext}>
                    Vous pouvez ajouter 5 photos maximum. La première photo choisie, sera celle mise en avant dans votre annonce.
                </Text>
                <ErrorMessage name={"pictures"} errors={errors} as={Text} className={ErrorStyle.error} />
                <Hidden xsDown>
                    {pictures.length > 0 &&
                    <DragDropContext onDragEnd={handleChangePictureOrder}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided, snapshot) => (
                                <Grid container style={{marginRight: -400, marginLeft: -50, width: "initial", height: 240}}
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                >
                                    {pictures.slice(0,3).map((picture, index) => (
                                        <Draggable key={picture.id !== -1 ? "pic-" + picture.id : picture.key} draggableId={picture.id !== -1 ? "pic-" + picture.id : picture.key} index={index}>
                                            {(provided, snapshot) => (
                                                <Grid item xs={12} sm={4}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      className={Styles.pictureWrapper}>
                                                    <Image source={picture.id === -1 ? picture.uri : getImageUrl(picture.contentUrl, "medium")}
                                                           className={Styles.picture}/>
                                                    <IconButton className={Styles.pictureDeleteIconWrapper} onClick={() => handleRemovePicture(index)}>
                                                        <CancelIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                        {pictures.length > 3 && <Droppable droppableId="droppable2" direction="horizontal">
                            {(provided, snapshot) => (
                                <Grid container style={{marginRight: -400, marginLeft: -50, width: "initial", height: 240}}
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                >
                                    {pictures.slice(3).map((picture, index) => (
                                        <Draggable key={picture.id !== -1 ? "pic-" + picture.id : picture.key} draggableId={picture.id !== -1 ? "pic-" + picture.id : picture.key} index={index}>
                                            {(provided, snapshot) => (
                                                <Grid item xs={12} sm={4}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      className={Styles.pictureWrapper}>
                                                    <Image source={picture.id === -1 ? picture.uri : getImageUrl(picture.contentUrl, "medium")}
                                                           className={Styles.picture}/>
                                                    <IconButton className={Styles.pictureDeleteIconWrapper} onClick={() => handleRemovePicture(index + 3)}>
                                                        <CancelIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>}
                    </DragDropContext>
                    }
                </Hidden>
                <Hidden smUp>
                    {pictures.length > 0 &&
                    <DragDropContext onDragEnd={handleChangePictureOrder}>
                        <Droppable droppableId="droppable" direction="vertical">
                            {(provided, snapshot) => (
                                <Grid container style={{width: "initial", marginBottom: 20}}
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                >
                                    {pictures.map((picture, index) => (
                                        <Draggable key={picture.id !== -1 ? "pic-" + picture.id : picture.key} draggableId={picture.id !== -1 ? "pic-" + picture.id : picture.key} index={index}>
                                            {(provided, snapshot) => (
                                                <Grid item xs={12} sm={4}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      className={Styles.pictureWrapper}>
                                                    <Image
                                                        source={picture.id === -1 ? picture.uri : getImageUrl(picture.contentUrl, "medium")}
                                                        className={Styles.picture}
                                                        style={{margin: "auto"}}
                                                    />
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Grid>
                            )}
                        </Droppable>
                    </DragDropContext>
                    }
                </Hidden>
                <Box className={Styles.brandWrapper}>
                    <Autocomplete
                        freeSolo
                        options={criteria.brands.map(option => option.value)}
                        ListboxComponent={ListboxComponent}
                        value={brandSearch}
                        filterOptions={(options, state) => options.filter(value => !brandSearch || value.toLowerCase().indexOf(brandSearch.toLowerCase()) !== -1)}
                        renderInput={(params) => (
                            <Controller
                                as={
                                    <TextField
                                        {...params}
                                        className={Styles.inputWrapper}
                                        placeholder={"Marque"}
                                        variant={"outlined"}
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />
                                }
                                control={control}
                                rules={{}}
                                name={"brandSearch"}
                                // defaultValue={brandSearch}
                            />
                        )}
                        onChange={(event, value) => {setValue("brandSearch", value)}}
                    />
                    <ErrorMessage name={"brand"} errors={errors} as={Text} className={ErrorStyle.error} />
                    {brandWarning && <Text className={Styles.brandWarning}>Cette marque n'étant pas présente dans notre base de données, ce produit sera soumis à modération.</Text>}
                </Box>

                {sizeChoices.length > 0 &&
                <Box className={Styles.deliveryModesSuperWrapper}>
                    <Box className={clsx(Styles.deliveryModesWrapper, {[Styles.deliveryModesWrapperError]: errors["sizes"]})}>
                        <Link onClick={() => setDisplaySizes(!displaySizes)} style={{width: "100%", cursor: "pointer", color: "black"}}>
                            <Grid container xs={12} justify={"space-between"} className={Styles.deliveryModesTitleWrapper}>
                                <Text className={clsx(Styles.deliveryModesTitle, {[ErrorStyle.error]: errors["sizes"]})}>Tailles*</Text>
                                <KeyboardArrowDown className={clsx(Styles.deliveryModeCaret, {[Styles.deliveryModeCaretOpen]: displaySizes}, {[ErrorStyle.error]: errors["sizes"]})}/>
                            </Grid>
                        </Link>
                        <Collapse in={displaySizes} className={Styles.deliveryModesList}>
                            <Box className={Styles.deliveryModesListContent}>
                                {sizeChoices.map(size =>
                                    <CustomCheckbox
                                        rightIcon
                                        size={"small"}
                                        checked={sizes.indexOf(size.id) !== -1}
                                        onPress={() => handleClickSize(size.id)}
                                        title={<Text className={Styles.deliveryModeLabel}>{size.value}</Text>}
                                    />
                                )}
                            </Box>
                        </Collapse>
                    </Box>
                    <Box className={clsx(Styles.upwardTriangleBorder, {[Styles.upwardTriangleBorderError]: errors["sizes"]})} />
                    <Box className={Styles.upwardTriangle} />
                    <ErrorMessage name={"sizes"} errors={errors} as={Text} className={ErrorStyle.error} />
                </Box>}

                <Grid container xs={12} className={Styles.colorsWrapper}>
                    <Grid item xs={12} sm={3}>
                        <Text color={errors['colors'] ? 'error' : 'default'}>Couleurs* :</Text>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <FormGroup row>
                            {criteria.colors.map(color => COLORED_CHECKBOXES[color.value]({
                                onChange: (event) => {onPressColor(color)},
                                name: color.id,
                                checked: colors.indexOf(color.id) !== -1
                            }))}
                        </FormGroup>
                    </Grid>
                    <ErrorMessage name={"colors"} errors={errors} as={Text} className={ErrorStyle.error} />
                </Grid>

                <CustomSelect
                    control={control}
                    errors={errors}
                    classes={Styles}
                    placeholder={"Matière"}
                    value={material}
                    choices={categoryMaterials.map((choice) => <MenuItem value={choice.id}>{choice.value}</MenuItem>)}
                    name={"material"} />

                <CustomSelect
                    control={control}
                    errors={errors}
                    classes={Styles}
                    placeholder={"Etat"}
                    value={state}
                    choices={criteria.states.map((sta) => <MenuItem value={sta.id}>{sta.value}</MenuItem>)}
                    name={"state"} />

                <CustomSelect
                    control={control}
                    errors={errors}
                    classes={Styles}
                    label={"Quantité*"}
                    value={quantity}
                    choices={quantityChoices.map((quant) => <MenuItem value={quant}>{quant}</MenuItem>)}
                    name={"quantity"} />

                <TextInputIcon
                    wrapperClass={Styles.inputWrapper}
                    errors={errors}
                    // setValue={setPrice}
                    register={register}
                    // defaultValue={price}
                    placeholder={"Prix (hors livraison)*"}
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"price"}
                />
                { screenKey === "EditProduct" && <TextInputIcon
                    wrapperClass={Styles.inputWrapper}
                    errors={errors}
                    register={register}
                    defaultValue={discount}
                    placeholder={"Réduction (en %)"}
                    name={"discount"}
                />}

                <Box className={Styles.deliveryModesSuperWrapper}>
                    <Box className={clsx(Styles.deliveryModesWrapper, {[Styles.deliveryModesWrapperError]: errors["deliveryModes"]})}>
                        <Link onClick={() => setDisplayDeliveryModes(!displayDeliveryModes)} style={{width: "100%", cursor: "pointer", color: "black"}}>
                            <Grid container xs={12} justify={"space-between"} className={Styles.deliveryModesTitleWrapper}>
                                <Text className={clsx(Styles.deliveryModesTitle, {[ErrorStyle.error]: errors["deliveryModes"]})}>Modes de livraison*</Text>
                                <KeyboardArrowDown className={clsx(Styles.deliveryModeCaret, {[Styles.deliveryModeCaretOpen]: displayDeliveryModes}, {[ErrorStyle.error]: errors["deliveryModes"]})}/>
                            </Grid>
                        </Link>
                        <Collapse in={displayDeliveryModes} className={Styles.deliveryModesList} style={{overflowY: "hidden"}}>
                            <Box className={Styles.deliveryModesListContent}>
                                {deliveryModesParam.map(deliveryParam =>
                                    <CustomCheckbox
                                        rightIcon
                                        size={"small"}
                                        checked={deliveryModes.indexOf(deliveryParam.id) !== -1}
                                        onPress={() => handleClickOnDeliveryMode(deliveryParam.id)}
                                        title={<Text className={Styles.deliveryModeLabel}>{deliveryParam.label}</Text>}
                                    />
                                )}
                            </Box>
                        </Collapse>
                    </Box>
                    <Box className={clsx(Styles.upwardTriangleBorder, {[Styles.upwardTriangleBorderError]: errors["deliveryModes"]})} />
                    <Box className={Styles.upwardTriangle} />
                    <ErrorMessage name={"deliveryModes"} errors={errors} as={Text} className={ErrorStyle.error} />
                </Box>

                <FormControlLabel
                    control={
                        <Checkbox
                            name={"returnPossible"}
                            inputRef={register()}
                            icon={<CheckBoxOutline style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.FONT_LIGHT_GRAY}}/>}
                            checkedIcon={<CheckBoxOutlineChecked style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.PINK}}/>}
                        />
                    }
                    label={
                        <Box>
                            <Text className={Styles.checkboxLabel}>J'accepte les retours</Text>
                        </Box>
                    }
                />
                {/*<FormControlLabel*/}
                    {/*control={*/}
                        {/*<Checkbox*/}
                            {/*name={"cgv"}*/}
                            {/*inputRef={register({required: "Vous devez accepter les conditions générales de vente"})}*/}
                            {/*icon={<CheckBoxOutline style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.FONT_LIGHT_GRAY}}/>}*/}
                            {/*checkedIcon={<CheckBoxOutlineChecked style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.PINK}}/>}*/}
                        {/*/>*/}
                    {/*}*/}
                    {/*label={*/}
                        {/*<Box>*/}
                            {/*<Text component={"span"} className={clsx(Styles.checkboxLabel, {[ErrorStyle.error]: errors["cgv"]})}>J'ai lu et j'accepte les </Text>*/}
                            {/*<Link href={"/CGV"}>CGV et la politique de confidentialité</Link>*/}
                        {/*</Box>*/}
                    {/*}*/}
                {/*/>*/}

                {/*<ErrorMessage name={"cgv"} errors={errors} as={Text} className={ErrorStyle.error} />*/}

            </Grid>
            <Grid item container style={{marginTop: 20}} >
                <Button size={"small"} onClick={handleSubmit(handleClickContinue)} color={"primary"} variant={"contained"} disableElevation style={{marginRight: 20}}>
                    Sauvegarder
                </Button>
                <Button size={"small"} onClick={handleClickDelete} color={"primary"} variant={"contained"} disableElevation style={{marginRight: 20}}>
                    Supprimer
                </Button>
                <Button size={"small"} onClick={handleClickBack} color={"primary"} variant={"outlined"} disableElevation style={{marginRight: 20}}>
                    Retour
                </Button>
            </Grid>
        </Grid>
    };

    let handleClickBack = () => {
        if (history.location.state && history.location.state.listState) {
            history.push("/admin/liste", {listState: history.location.state.listState})
        } else {
            history.push("/admin/liste", {listState: {tab: "PRODUCT", page: 1, search: ""}})
        }
    };

    let handleClickDelete = () => {
        setDeleteModalVisible(true);
    };

    return <Base
        title={"Modification du produit"}
        currentScreen={"ADMIN_LIST"}
        content={
        <Box className={Styles.container}>
            <Box className={Styles.contentWrapper}>
                <Box className={Styles.subcontentWrapper}>
                    <Box className={Styles.contentWrapper}>
                        {page === 0 && _getPage()}
                        {page === 1 && <Box style={{alignItems: "center", width:"100%"}}>
                            <Text className={Styles.congratsText}>{getBrandIdFromName(brandSearch) != -1 || brandSearch === "" ? "Félicitations, ton article a bien été modifié !" : "Félicitations, ton article a bien été modifié. Il est cependant soumis à modération."}</Text>
                            <Grid container xs={12} justify={"center"} style={{marginTop: 20}}>
                                <Button onClick={() => handleResetPage()} color={"primary"} variant={"contained"} disableElevation className={Styles.button}>
                                    Mettre un autre article en vente
                                </Button>
                            </Grid>
                        </Box>}
                    </Box>
                </Box>
            </Box>
            <ConfirmationModal
                visible={deleteModalVisible}
                setVisible={setDeleteModalVisible}
                title={"Suppression du produit"}
                message={"Êtes-vous sûr de vouloir supprimer ce produit ?"}
                onConfirm={() => {setDeleteModalVisible(false); deleteProduct(product.id); handleClickBack();}}
                onCancel={() => {setDeleteModalVisible(false)}}/>
            <ConfirmationModal
                visible={imageErrorModalVisible}
                setVisible={setImageErrorModalVisible}
                title={"Erreur à l'importation de l'image"}
                message={"Le fichier que vous essayez d'importer n'est pas une image valide."}
                onConfirm={() => {setImageErrorModalVisible(false)}} />
            <PictureCropModal visible={pictureCropModalVisible} setVisible={setPictureCropModalVisible} pictureUri={currentPictureUri} validatePicture={handleCropPicture}/>
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        criteria: state.criteria,
        familyTree : state.family.familyTree,
        storeProduct: state.product.currentProduct,
        previewProduct: state.product.previewProduct,
        user: state.user.me,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveProduct: (product, pictures, newBrand) => {
            dispatch({type: 'SAVE_PRODUCT_REQUESTED', product, pictures, newBrand})
        },
        savePreviewProduct: (product, pictures) => {
            dispatch({type: 'SAVE_PREVIEW_PRODUCT', product, pictures})
        },
        fetchCriteria: () => {
            dispatch({type: 'FETCH_CRITERIA_REQUESTED'})
        },
        fetchProduct: (id) => {
            dispatch({type: 'FETCH_PRODUCT_REQUESTED', id})
        },
        deleteProduct: (id) => {
            dispatch({type: 'DELETE_PRODUCT_REQUESTED', id})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
