import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import ShoppingCartRightColumnStyle from "./ShoppingCartRightColumnStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => {
    let CommonStyles = ShoppingCartRightColumnStyle(theme);
    return {
        ...CommonStyles,

        button: {
            ...GlobalStyle.button
        },
        buttonLabel: {
            ...GlobalStyle.buttonLabel
        },

        wrapper: {
            ...GlobalStyle.row,
            marginTop: Constants.GET_SIZE(50),
            maxWidth: 1440,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "90%",
            }
        },
        leftColumn: {
            flex: 2
        },

        shopWrapper: {
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            paddingLeft: Constants.GET_SIZE(40),
            paddingTop: Constants.GET_SIZE(50),
            paddingBottom: Constants.GET_SIZE(50),
            paddingRight: Constants.GET_SIZE(20),
            marginBottom: Constants.GET_SIZE(22),
            [theme.breakpoints.down('xs')]: {
                paddingRight: 15,
                paddingLeft: 15,
            }
        },
        shopTitle: {
            ...GlobalStyle.uppercaseText,
            paddingLeft: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                textAlign: "center",
                paddingLeft: 0,
            }
        },
        shopInfoWrapper: {
            // ...GlobalStyle.rowCentered,
            marginBottom: Constants.GET_SIZE(25),
            marginTop: Constants.GET_SIZE(25),
            paddingRight: Constants.GET_SIZE(57),
            paddingLeft: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                paddingRight: 0,
                paddingLeft: 0,
            }
        },
        productImageWrapper: {
            flex: 2,
            [theme.breakpoints.down('xs')]: {
                flexGrow: 0,
                flexBasis: "100%",
                width: "90%",
                marginBottom: 10,
            }
        },
        productImage: {
            width: Constants.GET_SIZE(136),
            height: Constants.GET_SIZE(136),
            borderColor: Constants.BORDER_GRAY,
            borderWidth: 1,
            borderRadius: "5%",
            [theme.breakpoints.down('xs')]: {
                margin: "auto"
            }
        },
        productNameWrapper: {
            flex: 5,
            marginLeft: Constants.GET_SIZE(20),
            [theme.breakpoints.down('xs')]: {
                flex: 2,
                marginLeft: 0,
            }
        },
        productName: {
            lineHeight: Constants.FONT_SIZE_BIG,
            fontWeight: "bold",
        },
        productName2: {

            lineHeight: Constants.FONT_SIZE_MEDIUM,
            color: Constants.LIGHT_GRAY,
            [theme.breakpoints.down('xs')]: {

            }
        },
        productPrice: {
            flex: 1,
            color: Constants.LIGHT_GRAY
        },
        productQuantityWrapper: {
            ...GlobalStyle.rowCentered,
            flex: 1,
        },
        productQuantityPicker: {
            height: Constants.GET_SIZE(40),
            width: Constants.GET_SIZE(40),
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            borderColor: Constants.PINK,
            borderRadius: 2,
            marginRight: Constants.GET_SIZE(13),
        },
        productPriceTotal: {
            flex: 1,
            fontWeight: "bold",
            textAlign: "right"
        },
        shopDivider: {
            height: 1,
            width: "100%",
            backgroundColor: Constants.BORDER_GRAY
        },
        shopDeliveryTitle: {
            marginTop: Constants.GET_SIZE(20),
            marginBottom: Constants.GET_SIZE(25),
        },
        shopDeliveryGlobalWrapper: {
            paddingLeft: Constants.GET_SIZE(30),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
            }
        },
        shopDeliveryWrapper: {
            backgroundColor: Constants.WHITE,
            flex: 1,
            paddingLeft: Constants.GET_SIZE(38),
            paddingTop: Constants.GET_SIZE(28),
            paddingBottom: Constants.GET_SIZE(28),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 5,
                paddingTop: 10,
                paddingBottom: 10,
            }
        },

        deliveryCheckbox: {
            backgroundColor: Constants.WHITE,
            borderWidth: 0,
            marginBottom: 0,
            marginTop: 0,
            marginLeft: 0,
            padding: 0,
            textAlign: "center"
        },
        deliveryWrapper: {
            display: "flex",
            flexDirection: "row",
            flex: 9,
            textAlign: "left",
            alignItems: "center",
        },
        deliveryLogo: {
            width: Constants.GET_SIZE(80),
            alignItems: "center",
            // flex: 1,
            marginLeft: Constants.GET_SIZE(18),
            marginRight: Constants.GET_SIZE(18),
            justifyContent: "center",
            [theme.breakpoints.down('xs')]: {
                width: 40,
                marginLeft: 0,
                marginRight: 0,
            }
        },
        deliveryName: {
            color: Constants.LIGHT_GRAY,
            flex: 10,
            lineHeight: Constants.GET_SIZE(48),
            [theme.breakpoints.down('md')]: {
                lineHeight: "24px",
            },
            [theme.breakpoints.down('xs')]: {
                flex: 4,
                lineHeight: Constants.GET_SIZE(24),
                marginLeft: 10,
                marginRight: 10
            }
        },
        deliveryPrice: {
            minWidth: "max-content",
            color: Constants.LIGHT_GRAY,
            flex: 1,
            marginRight: 20,
            lineHeight: "48px",
            [theme.breakpoints.down('md')]: {
                lineHeight: "24px",
            },
            [theme.breakpoints.down('xs')]: {
                lineHeight: "24px",
                marginRight: 0,
            }
        },
        deliveryActive: {
            color: Constants.BLACK,
            fontWeight: "bold",
        },

        chronopostLogo: {
            width: Constants.GET_SIZE(80),
            height: Constants.GET_SIZE(16),
            [theme.breakpoints.down('xs')]: {
                width: 40,
                height: 8
            }
        },
        mondialRelayLogo: {
            width: Constants.GET_SIZE(72),
            height: Constants.GET_SIZE(16),
            [theme.breakpoints.down('xs')]: {
                width: 36,
                height: 8
            }
        },
        clickAndCollectLogo: {
            width: Constants.GET_SIZE(48),
            height: Constants.GET_SIZE(48),
            [theme.breakpoints.down('xs')]: {
                width: 24,
                height: 24
            }
        },
        handsLogo: {
            width: Constants.GET_SIZE(48),
            height: Constants.GET_SIZE(48),
            [theme.breakpoints.down('xs')]: {
                width: 24,
                height: 24
            }
        },
        iLogo: {
            width: Constants.GET_SIZE(18),
            height: Constants.GET_SIZE(18),
            marginLeft: Constants.GET_SIZE(28),
            verticalAlign: "middle",
            display: "inline-flex",
            [theme.breakpoints.down('xs')]: {
                display: "none"
            }
        },
        deliveryOverlay: {
            position: "absolute",
            top: 0,
            // width: Constants.GET_SIZE(200),
            // height: Constants.GET_SIZE(200),
            padding: Constants.GET_SIZE(30),
            backgroundColor: Constants.WHITE,
            borderColor: Constants.BORDER_GRAY,
            borderWidth: 1
        },
        deliveryOverlayText: {

            color: Constants.BLACK
        }
    }
});
