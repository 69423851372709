import React from "react";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/PurchasesStyle"
import * as Constants from "../../Style/Constants"
import Paginator from "../Front/Component/Paginator";
import Image from "react-native-web/dist/exports/Image";
import {getImageUrl, getPdfUrl} from "../../Utils/ImageUtils";
import Moment from "moment";
import DisputeModal from "./Component/DisputeModal";
import MenuItem from "@material-ui/core/MenuItem";


function Component({navigation, history, token, user, purchases, pagination, validateDelivery, fetchPurchases}) {
    let Styles = StylesFunc();
    let [page, setPage] = React.useState(1);
    let [sort, setSort] = React.useState(0);
    let [sortDir, setSortDir] = React.useState(0);
    let [closedPurchases, setClosedPurchases] = React.useState([]);
    let [disputeModalsVisiblity, setDisputeModalsVisiblity] = React.useState([]);

    React.useEffect(() => {
        fetchPurchases(page, 4, sort, sortDir);
    }, [token, page, sort, sortDir]);

    React.useEffect(() => {
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    let getDeliveryName = (deliveryType) => {
        switch (deliveryType) {
            case 0:
                return "Chronopost (livraison)";
            case 1:
                return "Chronopost (relais)";
            case 2:
                return "Mondial relay";
            case 3:
                return "Click & Collect";
            case 4:
                return "en main propre";
        }
    };

    let handleDeliveryButton = (purchase, purchaseProduct) => {
        validateDelivery(purchase, purchaseProduct);
    };

    let handlePressSort = (sortId) => {
        if (sort === sortId) {
            if (sortDir === 0) {
                setSortDir(1);
            } else {
                setSortDir(0);
            }
        } else {
            setSort(sortId);
            setSortDir(0)
        }
    }

    let _getPurchaseProduct = (purchase, purchaseProduct, shop) => {
        let hasDispute = false;
        let currentDispute = null;
        let isDisputeResolved = false;
        for (const dispute of purchase.disputes) {
            hasDispute = hasDispute || (dispute.shop.id === shop.shop.id && dispute.product.id === purchaseProduct.product.id);
            if (hasDispute) {
                currentDispute = dispute;
                isDisputeResolved = dispute.deleted || (dispute.status !== 0) || (purchaseProduct.status === 2 && purchaseProduct.returnParcel);
                break;
            }
        }

        let canHaveDispute = false;
        if (purchaseProduct.deliveryType <= 2) {
            canHaveDispute = true;
        }

        if (purchase.status === 2 || purchase.status === 4) {
            canHaveDispute = false;
        }

        return <Box className={Styles.productAndButtonWrapper}>
            <Grid container className={Styles.productWrapper}>
                <Image source={getImageUrl(purchaseProduct.product.pictures[0] ? purchaseProduct.product.pictures[0].contentUrl : null, "medium")} className={Styles.productImage} />
                <Box className={Styles.productTextWrapper}>
                    <Text className={Styles.productName}>{purchaseProduct.product.name}</Text>
                    <Text className={Styles.productBrand}>{purchaseProduct.product.brand ? purchaseProduct.product.brand.value : ""}</Text>
                    <Text className={Styles.productCategory}>{purchaseProduct.product.category.name} {purchaseProduct.size ? purchaseProduct.size.value : ""}</Text>
                    <Text className={Styles.productPrice}>{purchaseProduct.paidPrice ? purchaseProduct.paidPrice.toFixed(2) : "N/A"} €</Text>
                </Box>
                <Box className={Styles.productAddressWrapper}>
                    {purchaseProduct.deliveryType < 3 && <Box>
                        <Text className={Styles.productAddressTitle}>Adresse de livraison :</Text>
                        <Text className={Styles.productAddress}>{purchase.deliveryAddress}</Text>
                        <Text className={Styles.productAddress}>{purchase.deliveryZipcode} {purchase.deliveryCity}</Text>
                    </Box>}
                    {purchaseProduct.deliveryType >= 3 && <Box>
                        <Text className={Styles.productAddressTitle}>Coordonnées du vendeur :</Text>
                        <Text className={Styles.productAddress}>{purchaseProduct.product.shop.owner.firstname} {purchaseProduct.product.shop.owner.name}</Text>
                        <Text className={Styles.productAddress}>{purchaseProduct.product.shop.phone}</Text>
                    </Box>}
                    <Text className={Styles.productAddressTitle} style={{marginTop: Constants.GET_SIZE(10)}}>Adresse de facturation :</Text>
                    <Text className={Styles.productAddress}>{purchase.facturationAddress}</Text>
                    <Text className={Styles.productAddress}>{purchase.facturationZipcode} {purchase.facturationCity}</Text>
                </Box>
                {/*<Box className={Styles.productAddressWrapper}>*/}
                {/*</Box>*/}
                <Box className={Styles.productDeliveryInfoWrapper} style={{flex: 1}}>
                    <Text className={Styles.productDeliveryInfoTitle}>Livraison {getDeliveryName(purchaseProduct.deliveryType)}</Text>
                    {hasDispute && <Text className={Styles.productDeliveryTrackingNumber}>{isDisputeResolved ? "Litige résolu" : "Litige en cours"}</Text>}
                    {purchaseProduct.deliveryType < 3 &&
                        <Box>
                            <Box className={Styles.productDeliveryTrackingWrapper} style={{display: "flex", flexDirection: "row"}}>
                                <Text className={Styles.productDeliveryTrackingNumber}>N° de suivi :&nbsp;</Text>
                                <Link
                                    className={Styles.productDeliveryTrackingLink}
                                    href={purchaseProduct.deliveryType === 2 ? "https://www.mondialrelay.com/public/permanent/tracking.aspx?ens=BDTEST13&exp=" + purchaseProduct.trackingNumber: "http://www.chronopost.fr/expedier/inputLTNumbersNoJahia.do?listeNumeros=" + purchaseProduct.trackingNumber}
                                    target={'_blank'}>
                                    <Text>{purchaseProduct.trackingNumber}</Text>
                                </Link>
                            </Box>

                            {purchaseProduct.status === 0 ?
                                <Button disableElevation variant={"contained"} color={"primary"} size={"small"} className={Styles.productDeliveryButton}
                                        onClick={() => handleDeliveryButton(purchase, purchaseProduct)}>
                                    Valider la réception
                                </Button> : <Text className={Styles.productDeliveryTrackingNumber}>{purchaseProduct.status === 1 ? "Livraison confirmée" : purchaseProduct.status === 2 ? "Retour demandé" : purchaseProduct.status === 3 ? "Retour confirmé" : "Commande annulée"}</Text>}
                        </Box>
                    }
                    {purchaseProduct.deliveryType >= 3 &&
                        <Box className={Styles.productDeliveryTrackingWrapper} style={{display: "flex", flexDirection: "row"}}>
                            {purchaseProduct.status !== -1 && <Text className={Styles.productDeliveryCodeLabel}>Code :&nbsp;</Text>}
                            <Text className={Styles.productDeliveryCode}>{purchaseProduct.status === -1 ? 'Commande annulée' : purchaseProduct.deliveryCode}</Text>
                        </Box>
                    }
                    {purchaseProduct.deliveryType === 3 &&
                        <Box className={Styles.productDeliveryTrackingWrapper} style={{display: "flex", flexDirection: "column", marginTop: 10}}>
                            <Text className={Styles.productDeliveryInfoTitle}>Prochains vide-dressings :</Text>
                            {shop.shop.events && shop.shop.events.slice(0, 3).map(evt => {
                                // Nom de l'évènement - Nom du lieu - N°, rue - VILLE - Date : 01/08/2023 - 8h/17h30
                                return <Link href={"/vide-dressing/" + evt.id} target={"_blank"}>
                                        <Text className={Styles.productEvent} style={{marginBottom: 5}}>{evt.name} - {evt.city}</Text>
                                    </Link>
                            })}
                        </Box>
                    }
                </Box>
            </Grid>
            <Box className={Styles.productButtonWrapper}>
                <Grid container className={clsx(Styles.productFooter)}>
                    {purchaseProduct.deliveryType < 3 && purchaseProduct.product.returnPossible && purchaseProduct.status === 1 &&
                    <Button disableElevation variant={"outlined"} color={"primary"} size={"small"} onClick={() => history.push("/return_product/" + purchaseProduct.id)} className={Styles.disputeButton}>
                        Retourner le produit
                    </Button>
                    }
                    {canHaveDispute && <Button disabled={isDisputeResolved} disableElevation variant={"contained"} color={"primary"} size={"small"} onClick={() => !hasDispute ? history.push("/message/nouveau", {shopId: shop.shop.id, recipient: purchase.user, purchaseProduct, purchase, dispute: true}) : history.push("/message/" + currentDispute.messageThread.id)} className={Styles.disputeButton}>
                        {hasDispute ? (isDisputeResolved ? "Litige résolu" : "Litige en cours") : "Déclarer un litige"}
                    </Button>}
                    {purchaseProduct.status === 2 && purchaseProduct.returnParcel &&
                    <Button disableElevation variant={"outlined"} color={"primary"} size={"small"} accessibilityRole={"link"} href={getPdfUrl(purchaseProduct.returnParcel.contentUrl)} target={'_blank'} >
                        Télécharger le bordereau de renvoi
                    </Button>
                    }
                </Grid>
                <DisputeModal shop={shop.shop} purchase={purchase} product={purchaseProduct.product}
                              visible={disputeModalsVisiblity.indexOf(purchase.id + '-' + shop.shop.id + '-' + purchaseProduct.product.id) !== -1}
                              setVisible={() => toggleDisputeModal(purchase, shop.shop, purchaseProduct.product)}/>
            </Box>
        </Box>
    };

    let toggleDisputeModal = (purchase, shop, product) => {
        const index = disputeModalsVisiblity.indexOf(purchase.id + '-' + shop.id + '-' + product.id);
        if (index !== -1) {
            setDisputeModalsVisiblity([
                ...disputeModalsVisiblity.slice(0, index),
                ...disputeModalsVisiblity.slice(index + 1)
            ]);
        } else {
            setDisputeModalsVisiblity([
                ...disputeModalsVisiblity,
                purchase.id + '-' + shop.id + '-' + product.id
            ]);
        }
    };

    let _getPurchaseShop = (purchase, shop, isLast) => {
        let invoice;
        if (shop.products[0].invoice) {
            invoice = shop.products[0].invoice;
        }

        return <Box className={Styles.shopWrapper}>
            <Box className={Styles.shopHeader}>
                <Text className={Styles.shopHeaderLabel}>{shop.shop.name}</Text>
            </Box>
            {shop.products.map(purchaseProduct => _getPurchaseProduct(purchase, purchaseProduct, shop))}
            <Grid container className={clsx(Styles.shopFooter, {[Styles.shopFooterLast]: isLast})}>
                <Button disabled={!invoice} disableElevation variant={"contained"} color={"primary"} size={"small"} target={"_blank"} href={invoice ? getPdfUrl(invoice.contentUrl) : '#'} className={Styles.disputeButton}>
                    {invoice ? "Télécharger la facture": "Facture indisponible"}
                </Button>
                <Button disableElevation variant={"outlined"} color={"primary"} size={"small"} onClick={() => history.push("/message/nouveau", {shopId: shop.shop.id})}>
                    Contacter le vendeur
                </Button>
            </Grid>
        </Box>;
    };

    let getProductsByShop = (purchase) => {
        let shops = {};
        for (const purchaseProduct of purchase.purchaseProducts) {
            if (!shops[purchaseProduct.product.shop.id]) {
                shops[purchaseProduct.product.shop.id] = {
                    "shop": purchaseProduct.product.shop,
                    "products": []
                }
            }
            shops[purchaseProduct.product.shop.id].products.push(purchaseProduct);
        }
        return Object.values(shops);
    };

    let clickHeader = (purchase) => {
        let index = closedPurchases.indexOf(purchase.id);
        if (index !== -1) {
            setClosedPurchases([...closedPurchases.slice(0, index), ...closedPurchases.slice(index + 1)]);
        } else {
            setClosedPurchases([...closedPurchases, purchase.id]);
        }
    }

    let _getPurchase = (purchase) => {
        let productsByShops = getProductsByShop(purchase);
        return <Box className={Styles.purchaseWrapper}>
            <Button onClick={() => clickHeader(purchase)} className={Styles.purchaseHeader}>
                <Text className={Styles.purchaseHeaderLabel1}><FontAwesome name="chevron-down" className={Styles.purchaseHeaderCaret} /> {purchase.id}</Text>
                <Text className={Styles.purchaseHeaderLabel2}>{Moment(purchase.payedAt).format("DD/MM/YY")}</Text>
            </Button>
            {closedPurchases.indexOf(purchase.id) === -1 &&
            <Box className={Styles.productsWrapper}>
                {productsByShops && productsByShops.map((shop, index) => _getPurchaseShop(purchase, shop, index === (productsByShops.length - 1)))}
            </Box>}
        </Box>
    };

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Mes commandes</Text>

            <Box className={Styles.contentWrapper}>
                <Hidden xsDown>
                    <LeftMenu currentPage={"PURCHASES"} />
                </Hidden>
                <Box style={{width: "100%"}}>
                    <Box className={Styles.contentHeaderMobile}>
                        <Select variant={"outlined"} className={Styles.picker} value={sort} onChange={(event) => handlePressSort(event.target.value)}>
                            <MenuItem value={0}>Trier par n° de commande</MenuItem>
                            <MenuItem value={1}>Trier par date de paiement</MenuItem>
                        </Select>
                    </Box>
                    <Box className={Styles.purchasesWrapper}>
                        <Box className={Styles.contentHeader}>
                            <Text className={Styles.contentHeaderLabel}>Commande n°</Text>
                            <Text className={Styles.contentHeaderLabel}>Date de paiement</Text>
                            <Text className={Styles.contentHeaderLabel}> Adresses </Text>
                            <Text className={Styles.contentHeaderLabel}>Informations de livraison</Text>
                        </Box>
                        {purchases.map(_getPurchase)}
                    </Box>
                </Box>
            </Box>
            {pagination && <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE}/>}
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        purchases: state.purchase.purchasesList,
        pagination: state.purchase.purchasesListPagination
    }
};

const mapDispatchToProps = dispatch => {
    return {
        validateDelivery: (purchase, purchaseProduct) => {
            console.log(purchase.id, purchaseProduct.id);
            dispatch({type: 'VALIDATE_DELIVERY_REQUESTED', purchaseId: purchase.id, id: purchaseProduct.id})
        },
        fetchPurchases: (page, itemsPerPage, sort, sortDir) => {
            dispatch({type: 'FETCH_PURCHASES_REQUESTED', page, itemsPerPage, sort, sortDir})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
