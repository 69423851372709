import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle";
import Grid from "@material-ui/core/Grid/Grid";
import React from "react";
import {FONT_DARK_GRAY} from "./../Constants";


export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        threadsWrapper: {
            // width: Constants.GET_SIZE(1035),
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            marginLeft: Constants.GET_SIZE(25),
            paddingLeft: Constants.GET_SIZE(50),
            paddingRight: Constants.GET_SIZE(50),
            paddingTop: Constants.GET_SIZE(50),
            paddingBottom: Constants.GET_SIZE(75),
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                padding: 10
            }
        },
        subcontentWrapper: {
            flex: 1
        },

        image: {
            width: Constants.GET_SIZE(100),
            height: Constants.GET_SIZE(100),
            marginRight: Constants.GET_SIZE(25),
        },
        imageShop: {
            borderRadius: "50%"
        },
        threadHeader: {
            ...GlobalStyle.row,
            marginLeft: Constants.GET_SIZE(25),
            marginBottom: Constants.GET_SIZE(40),
            justifyContent: "space-between"
        },
        threadHeaderText: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        threadHeaderText1: {
            marginBottom: Constants.GET_SIZE(5),

            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            }
        },
        threadHeaderText2: {

            color: Constants.FONT_GRAY
        },
        threadHeaderText3: {

            color: Constants.FONT_LIGHT_GRAY
        },
        threadHeaderDispute: {
            color: "#878786",
            textDecoration: "underline"
        },
        threadWrapper: {
            ...GlobalStyle.row,
            maxWidth: Constants.GET_SIZE(900),
            height: Constants.GET_SIZE(100),
            backgroundColor: Constants.WHITE,
            borderColor: Constants.BORDER_GRAY,
            borderWidth: 1,
            paddingLeft: Constants.GET_SIZE(25),
            paddingRight: Constants.GET_SIZE(40),
            paddingTop: Constants.GET_SIZE(25),
            paddingBottom: Constants.GET_SIZE(10),
            marginBottom: Constants.GET_SIZE(25),
        },
        disputeWrapper: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: Constants.PINK
        },
        messageWrapper: {
            maxWidth: Constants.GET_SIZE(525),
            backgroundColor: Constants.WHITE,
            borderColor: Constants.BORDER_GRAY,
            borderStyle: "solid",
            borderWidth: 1,
            paddingLeft: Constants.GET_SIZE(25),
            paddingRight: Constants.GET_SIZE(40),
            paddingTop: Constants.GET_SIZE(25),
            paddingBottom: Constants.GET_SIZE(25),
            marginBottom: Constants.GET_SIZE(25),
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                padding: 20,
                boxSizing: "border-box",
                alignItems: "center"
            }
        },
        threadWrapperLeft: {
            maxWidth: Constants.GET_SIZE(525),
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        threadWrapperRight: {
            maxWidth: Constants.GET_SIZE(525),
            alignSelf: "flex-end",
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        authorImage: {
            width: 50,
            height: 50,
            marginRight: 25,
            borderRadius: "50%",
            [theme.breakpoints.down('xs')]: {
                width: 30,
                height: 30,
                marginRight: 10,
            }
        },
        textWrapper: {
            // width: Constants.GET_SIZE(525)
        },
        authorName: {
            color: Constants.BLACK
            // marginBottom: Constants.GET_SIZE(10),
        },
        messagePreview: {

            color: Constants.FONT_GRAY,
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxHeight: 35
        },
        messageComplete: {
            color: Constants.FONT_GRAY,
            maxWidth: Constants.GET_SIZE(300),
            [theme.breakpoints.down('xs')]: {
                display: "none"
            }

        },
        messageCompleteMobile: {
            color: Constants.FONT_GRAY,
            marginTop: 15,
            width: "100%",
            [theme.breakpoints.up('sm')]: {
                display: "none"
            }

        },
        dateWrapper: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
        },
        messageDate: {
        },
        disputeTag: {
            color: Constants.WHITE,
            backgroundColor: Constants.PINK,
            borderRadius: 40,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 30,
            paddingRight: 30,
            marginTop: 5
        },

        sendMessageWrapper: {
            marginLeft: 25,
            marginTop: 20,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
            }
        },

        button: {
            ...GlobalStyle.button,
            width: Constants.GET_SIZE(300),
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: Constants.GET_SIZE(25),
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        buttonLabel: {
            ...GlobalStyle.buttonLabel,
            textAlign: "center"
        },
        threadHeaderUnreadMessagesLabel: {

            marginRight: Constants.GET_SIZE(10)
        },
        threadHeaderUnreadMessages: {

            color: Constants.PINK
        },
        threadHeaderUnreadMessagesWrapper: {
            display: "flex",
            flexDirection: "row",
            marginLeft: Constants.GET_SIZE(200),
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                justifyContent: "center"
            }
        },

        threadsHeaderInputWrapper: {
            ...GlobalStyle.rowCentered,
            maxWidth: "min(350px, 90%)",
            height: 31,
            backgroundColor: "white",
            borderWidth: 2,
            borderColor: Constants.BORDER_GRAY,
            borderStyle: "solid",
            borderRadius: 45,
            paddingLeft: Constants.FONT_SIZE_NORMAL,
            paddingRight: Constants.FONT_SIZE_NORMAL,
            marginBottom: 50,
            marginTop: 25,
            marginLeft: 125,
            [theme.breakpoints.down('xs')]: {
                marginLeft: "auto",
                marginRight: "auto",
                boxSizing: "border-box",
                justifyContent: "center",
                width: "100%",
                marginBottom: 20,
            }
        },
        threadsHeaderInput: {
            marginLeft: 10,
            width: "100%"
        },


        picture: {
            width: 100,
            height: 80,
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            borderStyle: "solid",
            borderRadius: 10,
            resizeMode: "contain"
        },

        pictureThumb: {
            width: 100,
            height: 100,
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            borderStyle: "solid",
            borderRadius: 10,
            resizeMode: "contain",
            cursor: "pointer"
        },
        pictureButtonLabel: {
            ...GlobalStyle.buttonLabel
        },
        pictureSubtext: {

            color: Constants.FONT_GRAY,
            maxWidth: 300,
            marginBottom: 20,
        },
        pictureWrapper: {
            position: "relative",
            width: 100,
            height: 80,
            marginRight: 10,
        },
        pictureDeleteIcon: {
            color: Constants.FONT_GRAY,

            fontSize: Constants.FONT_SIZE_BIGGER,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
            }
        },
        pictureDeleteIconWrapper: {
            position: "absolute",
            top: -20,
            right: -20
        },
        pictureButton: {
            backgroundColor: Constants.WHITE,
            color: Constants.FONT_DARK_GRAY,
            borderRadius: 10,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            marginRight: 10,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 10,
                marginRight: 0,
            },
            "&:hover": {
                backgroundColor: Constants.PINK,
                color: Constants.WHITE,
                "& .MuiSvgIcon-root": {
                    color: Constants.WHITE + " !important"
                }
            }
        },
        pictureButtonLegend: {
            color: '#878786',

        }
    }
});
