import React from "react";
import clsx from "clsx";

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import Hidden from "@material-ui/core/Hidden"
import Button from "@material-ui/core/Button";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"

import { Image } from "react-native";
import { TouchableOpacity } from 'react-native-web';
import { connect } from 'react-redux'
import HomepageHeader1 from "../../Assets/Images/homepage_header_1.png"
import HomepageHeader2 from "../../Assets/Images/homepage_header_2.png"
import HomepageBlock1Image1 from "../../Assets/Images/homepage_block1_image1.svg"
import HomepageBlock1Image2 from "../../Assets/Images/homepage_block1_image2.svg"
import HomepageLovedShopsHeader from "../../Assets/Images/homepage_loved_shops_header-v2.png"
import HomepageDeliveryBackground from "../../Assets/Images/homepage_delivery_bg.svg"
import {InternalLink, LINKS} from "../Component/InternalLink";
import ProductSquare from "./Component/ProductSquare";
import LiveSquare from "./Component/LiveSquare";
import Text from "../Component/FontText";
import InstagramBlock from "./Component/InstagramBlock";
import BecomeSellerBlock from "./Component/BecomeSellerBlock";
import Moment from "moment";
import { withRouter } from "react-router-dom";
import ShopSquare from "./Component/ShopSquare";
import HorizontalSlider from "./Component/HorizontalSlider";
import Hero from "./Component/Hero";

import HomepageStylesFunc from "../../Style/HomepageStyle"
import {getImageUrl} from "../../Utils/ImageUtils";
import CardMedia from "@material-ui/core/CardMedia";
import MetaTags from "react-meta-tags";

function Component({history, navigation, token, width, fetchTendancesSauvages, tendancesSauvages, fetchNewProducts, newProducts, fetchEvents, events, fetchLovedShops, lovedShops, fetchNextLives, nextLives, isLoading, params, getParams}) {
    let HomepageStyles = HomepageStylesFunc();
    let [currentNewProduct, setCurrentNewProduct] = React.useState(0);
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        getParams();
        fetchTendancesSauvages();
        fetchNewProducts();
        fetchEvents();
        fetchLovedShops();
        fetchNextLives();
    }, []);

    const sWidth = window.innerWidth;
    let [widthOfSiblingSlidePreview, setWidthOfSiblingSlidePreview] = React.useState(sWidth - 360 + 20);


    let tendancesSauvagesDom = [];
    if (tendancesSauvages) {
        for (const tendanceSauvage of tendancesSauvages) {
            tendancesSauvagesDom.push(<div className={clsx(HomepageStyles.swipeWrapComponent, HomepageStyles.swipeWrapComponentTendanceSauvage)}><ProductSquare product={tendanceSauvage} /></div>);
        }
    }

    let newProductsDom = [];
    if (newProducts) {
        for (const newProduct of newProducts) {
            newProductsDom.push(<ProductSquare product={newProduct} />);
            if (newProductsDom.length >= 8) {
                break;
            }
        }
    }

    let lovedShopsDom = [];
    if (lovedShops) {
        for (const lovedShop of lovedShops) {
            lovedShopsDom.push(<ShopSquare shop={lovedShop} overlayDescription={true} wrapperClass={HomepageStyles.lovedShop} key={"loved-shop-" + lovedShop.id}/>);
        }
    }

    let nextLivesDom = [];
    if (nextLives) {
        for (const live of nextLives) {
            nextLivesDom.push(<LiveSquare live={live} key={"next-live-" + live.id}/>);
            if (nextLivesDom.length === 4) {
                if (nextLives.length > 4) {
                    let remaining = nextLives.length - 4;
                    nextLivesDom.push(<Link href={"/lives/en_cours"}>
                        <Grid item container className={HomepageStyles.otherLives} justify={"center"} alignItems={"center"}>
                            <Grid item>
                                <Text className={HomepageStyles.otherLivesText}>+ {remaining} live{remaining > 1 ? 's': ''} en cours</Text>
                            </Grid>
                        </Grid>
                    </Link>);
                }
                break;
            }
        }
    }
    
    let eventsDom = [];
    if (events) {
        for (const event of events) {
            let start = Moment(event.datetime).locale('fr');
            let end = Moment(event.endDatetime).locale('fr');
            let differentDays = event.endDatetime && start.format('DD MMMM YYYY') !== end.format('DD MMMM YYYY');
            eventsDom.push(
                <TouchableOpacity className={HomepageStyles.eventCard} onClick={() => {history.push("/vide-dressing/" + event.id)}}>
                    <Image source={event.picture ? getImageUrl(event.picture.contentUrl, "medium") : null} className={HomepageStyles.eventImage}/>
                    <Text className={HomepageStyles.eventCity}>{event.city}</Text>
                    <Text className={HomepageStyles.eventAddress}>{event.address.split("\n")[0]} - {event.locationName}</Text>
                    {differentDays && <Text className={HomepageStyles.eventDate}>Du {start.format('DD MMMM YYYY')} au {end.format('DD MMMM YYYY')}</Text>}
                    {!differentDays && <Text className={HomepageStyles.eventDate}>{start.format('DD MMMM YYYY')}</Text>}
                </TouchableOpacity>
            );
        }
    }

    let tendanceSauvagePicture = HomepageBlock1Image2;
    if (params) {
        params.map(param => {
            if (param.name === "tendance_sauvage_picture" && param.pictures && param.pictures.length > 0 && param.pictures[0] !== null) {
                tendanceSauvagePicture = getImageUrl(param.pictures[0].contentUrl, "real");
            }
        })
    }

    let header1 = {picture: HomepageHeader1, label: "Sortie\nViolette\nSauvage\nApp", link: "/", internal: true};
    let header2 = {picture: HomepageHeader2, label: "Le vide-dressing\ngéant, bons plans\net recyclage pour\nelle et lui !", link: "/", internal: true};
    if (params) {
        params.map(param => {
            if (param.name === "banners" && param.pictures && param.pictures.length > 0) {
                let banners = JSON.parse(param.value);
                header1.picture = getImageUrl(param.pictures[0].contentUrl, "real");
                header1.label = banners[0].description;
                if (banners[0].redirect in LINKS) {
                    header1.link = LINKS[banners[0].redirect].web;
                    header1.internal = true;
                } else {
                    header1.link = banners[0].redirect;
                    header1.internal = false;
                }
                header2.picture = getImageUrl(param.pictures[1].contentUrl, "real");
                header2.label = banners[1].description;
                if (banners[1].redirect in LINKS) {
                    header2.link = LINKS[banners[1].redirect].web;
                    header2.internal = true;
                } else {
                    header2.link = banners[1].redirect;
                    header2.internal = false;
                }
            }
        })
    }

    return (
        <Grid container justify={"center"}>
            <MetaTags>
                <title>:: Violette Sauvage :: Meilleur vide dressing en ligne pour Homme & Femme</title>
                <meta name="description"
                      content={"Violette Sauvage, votre vide dressing en ligne pour Femme, Homme & Enfants. Trouvez vos vêtements de marque d'occasion à tout petits prix et revendez-les !"}/>
            </MetaTags>

            <Grid container xs={12} className={HomepageStyles.heroBlock}>
                <Grid item container xs={12} spacing={2} style={{margin: "auto", width: "100%"}}>
                    <Grid item md={6} xs={12}>
                        <Hero data={header1} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Hero data={header2} />
                    </Grid>
                </Grid>
            </Grid>

            {tendancesSauvagesDom.length > 0 &&
            <Grid container item xs={12} className={HomepageStyles.tendanceSauvageBlock}>
                <Grid item container xs={12} style={{margin: "auto", maxWidth: 1440, width: "100%"}}>
                    <Grid item xs={12}>
                        <Text className={HomepageStyles.blockTitle} component={"h2"}>Tendances sauvages</Text>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item container xs={12} className={HomepageStyles.tendanceSauvagesWrapper}>
                            {tendancesSauvagesDom}
                        </Grid>
                    </Hidden>
                    <Hidden smUp>
                        <Swiper slidesPerView={2.25} loop={true} spaceBetween={10} centeredSlides={true}>
                            {tendancesSauvagesDom.map(dom => <SwiperSlide>{dom}</SwiperSlide>)}
                        </Swiper>
                    </Hidden>
                </Grid>
            </Grid>}
            
            {newProductsDom.length > 0 && <Grid item container xs={12} className={HomepageStyles.grayBlock}>
                <Grid item container xs={12} style={{margin: "auto", maxWidth: 1440, width: "100%", alignItems: "center"}}>
                    <Grid item xs={12}>
                        <Text className={HomepageStyles.blockTitle} component={"h2"}>Nouveaux ajouts</Text>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item container xs={12} className={HomepageStyles.newProductsWrapper}>
                            {newProductsDom}
                        </Grid>
                    </Hidden>
                    <Hidden smUp>
                        <Swiper slidesPerView={2.25} loop={true} spaceBetween={10} centeredSlides={true}>
                            {newProductsDom.map(dom => <SwiperSlide>{dom}</SwiperSlide>)}
                        </Swiper>
                    </Hidden>
                </Grid>
            </Grid>}
            {lovedShopsDom.length > 0 && <Grid item container xs={12} className={HomepageStyles.WhiteBlock} style={{paddingBottom: 40, backgroundImage: "url(" + HomepageLovedShopsHeader + ")", backgroundSize: "cover"}}>
                <Grid item container xs={12} style={{margin: "auto", maxWidth: 1440, width: "100%"}}>
                    <Grid item xs={12}>
                        <Text className={clsx(HomepageStyles.blockTitle, HomepageStyles.blockTitleWhite)} component={"h2"}>Nos boutiques coup de coeur</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Hidden xsDown>
                            <Swiper slidesPerView={3} loop={true} centeredSlides={true} centerInsufficientSlides={true} autoplay={true}>
                                {lovedShopsDom.map(dom => <SwiperSlide>{dom}</SwiperSlide>)}
                            </Swiper>
                        </Hidden>
                        <Hidden smUp>
                            <Swiper slidesPerView={2.25} loop={true} centeredSlides={true} centerInsufficientSlides={true} style={{ height: "100%" }}>
                                {lovedShopsDom.map(dom => <SwiperSlide>{dom}</SwiperSlide>)}
                            </Swiper>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>}

            {eventsDom.length > 0 && <Grid item container xs={12} className={HomepageStyles.whiteBlock}>
                    <Grid item xs={12} style={{width: "100%", alignItems: "center", maxWidth: 1440, margin: "auto"}}>
                        <Text className={HomepageStyles.blockTitle} component={"h2"}>Les vendeurs présents aux prochains vide-dressings</Text>
                        <Hidden xsDown>
                            <Swiper slidesPerView={3} loop={false} centeredSlides={false} centerInsufficientSlides={true}>
                                {eventsDom.map(dom => <SwiperSlide>{dom}</SwiperSlide>)}
                            </Swiper>
                        </Hidden>
                        <Hidden smUp>
                            <Swiper slidesPerView={1.2} loop={true} centeredSlides={false} centerInsufficientSlides={true}>
                                {eventsDom.map(dom => <SwiperSlide>{dom}</SwiperSlide>)}
                            </Swiper>
                        </Hidden>
                    </Grid>
            </Grid>}

            <Grid item container xs={12} className={HomepageStyles.grayBlock}>
                <Grid item container xs={12} style={{margin: "auto", maxWidth: 1440, width: "100%", alignItems: "center"}}>
                    <Grid item xs={12} style={{paddingLeft: 10, paddingRight: 10}}>
                        <Text className={HomepageStyles.blockTitle} component={"h2"}>Participez aux vide-dressings en live !</Text>
                    </Grid>
                    <Grid item container xs={12} className={HomepageStyles.newProductsWrapper}>
                        {nextLivesDom}
                    </Grid>
                    <Grid item container xs={12} className={HomepageStyles.newProductsWrapper}>
                        <Link href={"/lives/a_venir"}>
                            <Button variant={"contained"} color={"primary"} disableElevation className={HomepageStyles.button}>Voir le calendrier des lives</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={HomepageStyles.deliveryBlock}>
                <Box className={HomepageStyles.deliveryBackground} style={{backgroundImage: "url(" + HomepageDeliveryBackground + ")"}}>
                    <Text className={HomepageStyles.deliveryText1} component={"h2"}>
                        Livraison, remise en main propre, click and collect
                    </Text>
                    <Link href={"/conditions_de_livraison"}>
                        <Text className={HomepageStyles.deliveryText2}>
                            Voir les conditions de livraison
                        </Text>
                    </Link>
                </Box>
            </Grid>

            <BecomeSellerBlock />
            <InstagramBlock />
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        tendancesSauvages: state.product.tendancesSauvagesList,
        newProducts: state.product.newProductsList,
        events: state.videDressing.videDressingsList,
        lovedShops: state.shop.lovedShopsList,
        nextLives: state.live.nextLives,
        isLoading: state.loading.isLoading,
        params: state.params.params
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTendancesSauvages: () => {
            dispatch({type: 'FETCH_TENDANCES_SAUVAGES_REQUESTED'})
        },
        fetchNewProducts: () => {
            dispatch({type: 'FETCH_NEW_PRODUCTS_REQUESTED'})
        },
        fetchEvents: () => {
            dispatch({type: 'FETCH_VIDE_DRESSINGS_REQUESTED'})
        },
        fetchLovedShops: () => {
            dispatch({type: 'FETCH_LOVED_SHOPS_REQUESTED'})
        },
        fetchNextLives: () => {
            dispatch({type: 'FETCH_NEXT_LIVES_REQUESTED'})
        },
        getParams: () => {
            dispatch({type: 'FETCH_PARAMS_REQUESTED'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default withWidth()(VisibleComponent)