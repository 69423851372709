import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        maxWidth: 1440,
        margin: 'auto',
        paddingTop: 25,
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 20
        }
    },
    pageTitle: {
        textTransform: "uppercase",
        textAlign: "center",
        fontSize: Constants.FONT_SIZE_BIG,
        marginBottom: 40,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },

    tabsWrapper: {
        ...GlobalStyle.rowCenteredHW,
        marginBottom: 20,
    },
    tabButton: {
        ...GlobalStyle.button,
        backgroundColor: Constants.WHITE,
        borderColor: Constants.PINK,
        borderWidth: 1,

    },
    tabButtonLabel: {
        ...GlobalStyle.buttonLabel,
        color: Constants.PINK
    },
    tabButtonActive: {
        borderColor: Constants.PINK,
        borderWidth: 1,
    },
    tabButtonLabelActive: {
        color: Constants.WHITE
    },
    dateWrapper: {

    },
    datePickerSelect: {
        paddingRight: "0px !important",
        minWidth: 120,
        textAlign: "center"
    },
    datePickerItemRoot: {
        justifyContent: "center"
    },
    caret: {

    },
    hidden: {
        visibility: 'hidden'
    },


    liveList: {
    },
    placeholder: {
        width: 255,
        height: 0
    },
    hourWrapper: {
        marginTop: 20,
        marginBottom: 20,
    },
    hour: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        fontWeight: "bold",
        paddingLeft: 10,
        paddingRight: 10,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    hourDivider: {
        flex: 1
    }
}));
