import React from "react";
import {DropzoneArea} from 'material-ui-dropzone';
import {getImageUrl} from "../../Utils/ImageUtils";

export default function PictureField({register, errors, watch, setValue, name, namePrefix, label, required}) {
    let rules = {};

    if (required) {
        rules['required'] = "Ce champ est obligatoire";
    }

    React.useEffect(() => {
        register({name: namePrefix + name});
    }, [register]);

    let picture = watch(namePrefix + name);

    let handleChange = (files) => {
        let id = -1;
        if (picture && picture.contentUrl && picture.id) {
            if (picture.contentUrl.slice(1) === files[0].name) {
                id = picture.id;
            }
        }
        return setValue(namePrefix + name, {id: id, file: files[0]})
    }

    return (
        <DropzoneArea
            acceptedFiles={['image/*']}
            filesLimit={1}
            dropzoneText={"Veuillez glisser une image ou cliquer"}
            onChange={handleChange}
            initialFiles={picture ? [getImageUrl(picture.contentUrl, "small")] : []}
            />
    );
}
