import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    superWrapper: {
        position: "relative",
        marginTop: Constants.GET_SIZE(16),
        marginBottom: Constants.GET_SIZE(30),
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            marginBottom: 10,
        }
    },
    wrapper: {
        width: "100%",
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderStyle: "solid",
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%"
        }
    },
    titleWrapper: {
        padding: Constants.GET_SIZE(15),
    },
    title: {
        fontSize: Constants.FONT_SIZE_NORMAL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
        }
    },
    collapse: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderTopStyle: "solid",
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        width: "100%",
        overflowY: "auto",
    },
    contentWrapper: {
        height: Constants.GET_SIZE(205)
    },
    searchableContentWrapper: {
        height: Constants.GET_SIZE(150)
    },
    caret: {
        transform: "rotate(-180deg);",
        transition: "transform 0.3s"
    },
    caretOpen: {
        transform: "rotate(0deg);",
    },

    value: {
        fontWeight: "bold",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        lineHeight: "16px"
    },
    colorValue: {
        fontWeight: "bold",
        lineHeight: Constants.FONT_SIZE_BIG
    },
    body: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        paddingTop: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(10),
        height: Constants.GET_SIZE(206),
        width: "100%",
        overflowY: "auto",
    },
    selected: {
        color: Constants.PINK
    },
    inputWrapper: {
        ...GlobalStyle.rowCentered,
        borderColor: Constants.BORDER_GRAY,
        backgroundColor: Constants.WHITE,
        padding: Constants.GET_SIZE(5),
        marginBottom: Constants.GET_SIZE(15),
        marginRight: Constants.GET_SIZE(10),
        borderWidth: 1,
    },
    input: {
        height: Constants.GET_SIZE(30),
        width: "100%"
    },
    sliderWrapper: {
        width: Constants.GET_SIZE(145),
        marginLeft: Constants.GET_SIZE(15),
        marginRight: Constants.GET_SIZE(25),
    },
    upwardTriangle: {
        position: "absolute",
        left: 20,
        top: -9,
        width: 0,
        height: 0,
        borderTopWidth: 0,
        borderLeftWidth: 20,
        borderLeftColor: "transparent",
        borderRightWidth: 20,
        borderRightColor: "transparent",
        borderBottomWidth: 20,
        borderBottomColor: Constants.WHITE,
        borderStyle: "solid",
    },
    upwardTriangleBorder: {
        position: "absolute",
        left: Constants.GET_SIZE(20),
        top: -10,
        width: 0,
        height: 0,
        zIndex: -1,
        borderTopWidth: 0,
        borderLeftWidth: Constants.GET_SIZE(20),
        borderLeftColor: "transparent",
        borderRightWidth: Constants.GET_SIZE(20),
        borderRightColor: "transparent",
        borderBottomWidth: Constants.GET_SIZE(20),
        borderBottomColor: Constants.BORDER_GRAY,
        borderStyle: "solid",
    },
}));
