import React from "react";
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Link from "@material-ui/core/Link"
import Chip from "@material-ui/core/Chip"

import Text from "../../Component/FontText";
import { withRouter } from "react-router-dom";
import clsx from 'clsx';

import { connect } from "react-redux";

import ProductSquareStylesFunc from "../../../Style/ProductSquareStyle"
import * as Constants from "../../../Style/Constants"

import HeartIcon from "../../Icons/HeartIcon";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import {getImageUrl} from "../../../Utils/ImageUtils";
import PlaceholderProduct from "../../../Assets/Images/placeholder_product.jpg"
import AddToCartModal from "./AddToCartModal"
import CircularProgress from "@material-ui/core/CircularProgress";
import LiveProductDetailsModal from "./LiveProductDetailsModal"
import {useAddToWishlist} from "../../../API/Hook/Product";
import TextField from "@material-ui/core/TextField";


function Component({history, user, product, liveProduct, shop, addToCart, removeFromCart, token, cart, wishlist, addToWishlist, removeProduct, withoutBackground, smaller, wrapperStyle, currentWishlistPage, myProductsPage, currentPageParameters, itemsPerPage, addLiveProduct, isLiveProduct, setLiveFirstProduct, removeLiveProduct, canAddLiveProduct, isLivePage, setSubscribedLive, sendProposition, instantBuy, setMinPrice, minPrice, isLiveAdmin, propositionInProgress, setPropositionInProgress}) {
    if (liveProduct) {
        product = liveProduct.product;
    }
    let [modalVisible, setModalVisible] = React.useState(false);
    let [productDetailsPopupVisibility, setProductDetailsPopupVisibility] = React.useState(false);
    let [subscribeToProduct, isLoadingHeart] = useAddToWishlist(product);

    let ProductSquareStyles = ProductSquareStylesFunc();
    let onPressHeart = () => {
        subscribeToProduct(!isInWishlist)
        if (isLivePage) {
            if (isInWishlist) {
                product.nbWishers -= 1;
            } else {
                product.nbWishers += 1;
            }
        }
    };

    let onPressCart = () => {
        if (!isInCart) {
            if (product.quantity === 1 && product.colors.length <= 1 && product.sizes.length <= 1) {
                addToCart(product, 1, product.colors[0], product.sizes[0]);
            } else {
                setModalVisible(true);
            }
        } else {
            removeFromCart(product);
        }
        return false;
    };

    let onPressEdit = () => {
        history.push("/editer_produit/" + product.id)
    };

    let onPressRemove = () => {
        removeProduct(product, currentPageParameters);
    };

    if (shop && !product.shop) {
        product.shop = shop;
    }

    const isInCart = cart.reduce((acc, val) => acc || val.product.id === product.id, false);
    const isInWishlist = wishlist.reduce((acc, val) => acc || val.id === product.id, false);

    const brand = product.brand ? product.brand.value : "";
    const size  = !product.sizes || product.sizes.length === 0  ? "" : product.sizes.length === 1 ? " Taille " + product.sizes[0].value : " Plusieurs tailles";

    let getBottomRow = () => {
        return null;
    };

    let getWishlistBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"center"}>
                {(!user || !user.shop || user.shop.id !== product.shop.id) && product.quantity > 0 &&
                <Grid item xs={8}>
                    <Button variant={"contained"} color={"primary"} onClick={onPressCart} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10}} size={"small"}>
                        {isInCart ? "Retirer du panier" : "Ajouter au panier"}
                    </Button>
                </Grid>}
            </Grid>
        )
    };

    let getMyProductsBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"center"}>
                <Grid item xs={8}>
                    <Button variant={"outlined"} color={"primary"} onClick={onPressRemove} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10, visibility: currentPageParameters.productsType === 1 ? "hidden" : "visible"}}>
                        {product.status === 0 ? "Retirer de la vente" : "Remise en vente"}
                    </Button>
                </Grid>
                <Grid item xs={8}>
                    <Button variant={"contained"} color={"primary"} onClick={onPressEdit} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10, visibility: currentPageParameters.productsType === 1 ? "hidden" : "visible"}}>
                        Modifier le produit
                    </Button>
                </Grid>
            </Grid>
        )
    };

    let getLiveProductBottomRow = () => {
        return (
            <Grid container item xs={12} justify={"center"}>
                <Grid item xs={8}>
                    {(canAddLiveProduct || isLiveProduct) && <Button variant={isLiveProduct ? 'contained' : 'outlined'} color={"primary"} onClick={() => isLiveProduct ? removeLiveProduct(product) : canAddLiveProduct ? addLiveProduct(product) : null} fullWidth disableElevation style={{fontSize: Constants.FONT_SIZE_12, marginBottom: 10}} size={"small"}>
                        {isLiveProduct ? 'Retirer le produit' : canAddLiveProduct ? 'Ajouter le produit' : 'Limite atteinte'}
                    </Button>}
                    {(!canAddLiveProduct && !isLiveProduct) && <Text className={ProductSquareStyles.cantAddLive}>
                        Impossible d'ajouter plus de produits.
                    </Text>}
                </Grid>
            </Grid>
        )
    };

    let getCreateLiveBottomRow = () => {
        return (
            <Grid container direction={"column"}>
                <Grid container item xs={12} justify={"space-between"} alignItems={"center"}>
                    <Grid item style={{zIndex: 10}}>
                        <IconButton onClick={() => setLiveFirstProduct(product)}>
                            {liveProduct.position === 0 ? <StarIcon className={ProductSquareStyles.starIconSelected} /> : <StarBorderIcon className={ProductSquareStyles.starIcon}/>}
                        </IconButton>
                    </Grid>
                    <Grid item style={{zIndex: 10}}>
                        <IconButton onClick={() => removeLiveProduct(product)}>
                            <TrashIcon
                                className={clsx(ProductSquareStyles.starIcon)} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container item alignItems={"center"} justify={"space-between"}>
                    <Grid item><Text>Min. :</Text></Grid>
                    <Grid item style={{maxWidth: 170}}><TextField type={"number"} variant={"outlined"} placeholder={"Prix le plus bas"} value={minPrice} onChange={event => setMinPrice(event.target.value)}/></Grid>
                </Grid>
            </Grid>
        )
    };

    let bottomRow;
    if (currentWishlistPage) {
        bottomRow = getWishlistBottomRow();
    } else if (myProductsPage) {
        bottomRow = getMyProductsBottomRow();
    } else if (isLivePage) {

    } else if (addLiveProduct) {
        bottomRow = getLiveProductBottomRow();
    } else if (liveProduct) {
        bottomRow = getCreateLiveBottomRow();
    } else {
        bottomRow = getBottomRow();
    }

    let productAvailable = {
        0: {
            "label": "En attente de modération",
            "class": "warning"
        },
        1: {
            "label": "En ligne",
            "class": "success"
        },
        2: {
            "label": "Indisponible",
            "class": "danger"
        },
        3: {
            "label": "Rejeté",
            "class": "danger"
        }
    };
    let productBadge = '';

    if (product.brand.status === 0) {
        productBadge = productAvailable[0];
    } else if (product.brand.status === 2) {
        productBadge = productAvailable[3];
    } else if (product.quantity <= 0 || product.stock === 0) {
        productBadge = productAvailable[2];
    } else if (product.status === 1) {
        productBadge = productAvailable[2];
    } else {
        productBadge = productAvailable[1];
    }

    return (
        <Card
            style={{ height: "100%" }}
        >
            <div style={{ position: 'relative'}}>
                <Link href={isLivePage && liveProduct ? null : product.quantity <= 0 ? null : "/product/" + product.id}><CardMedia component="img" alt={product.title} image={getImageUrl(product.pictures[0] != null ? product.pictures[0].contentUrl : null, "medium")} onError={event => {
                event.target.src = PlaceholderProduct
                event.onerror = null
                }} /></Link>

              
                <div>
                    {(!myProductsPage && !addLiveProduct) && (
                        <IconButton onClick={onPressHeart} style={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '16px' }}>
                            {isLoadingHeart ? <CircularProgress color={"primary"} className={ProductSquareStyles.loader} size={30} /> : <HeartIcon className={clsx(ProductSquareStyles.heartIcon, {[ProductSquareStyles.heartIconSelected]: isInWishlist})} />}
                        </IconButton>
                    )}

                    {myProductsPage && (
                        <>
                            <Chip color="secondary" label={`Quantités: ${product.stock}`} style={{ position: 'absolute', top: '10px', left: '10px'}} />
                            <Chip color={productBadge.class} label={productBadge.label} style={{ position: 'absolute', bottom: '10px', right: '10px'}} />
                        </>
                    )}
                    {product.discount !== 0 &&<Chip color="primary" label={`-${product.discount}%`} style={{ position: 'absolute', bottom: '10px', left: '10px', color: 'white' }} />}
                    {product.quantity <= 0 && <Chip color="success" label="Vendu" style={{ position: 'absolute', bottom: '10px', left: '10px'}} />}
                    {product.quantity > 0 && !!product.reserved && product.reserved >= product.quantity && <Chip color="secondary" label="Réservé" />}
                </div>

            </div>
            
            <CardContent style={{ position: 'relative' }}>
                <Link href={isLivePage && liveProduct ? null : product.quantity <= 0 ? null : "/product/" + product.id} color="inherit">
                    {product.name && <Text className={ProductSquareStyles.productTitle}>{product.name}</Text>}
                    {brand && <Text className={ProductSquareStyles.colorAndSize}>{brand}</Text>}
                    {size && <Text className={ProductSquareStyles.colorAndSize}>{size}</Text>}
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        {product.discount !== 0 && <del className={ProductSquareStyles.colorAndSize} style={{marginRight: '5px'}}>{product.realPriceWithoutDiscount}€</del>}
                        <Text className={ProductSquareStyles.price}>{product.realPrice}€</Text>
                    </Grid>
                </Link>
            </CardContent>
            {bottomRow && <CardActions className={clsx(ProductSquareStyles.cardActions, {[ProductSquareStyles.smallCardActions]: !currentWishlistPage && !myProductsPage && !addLiveProduct})}>
            {bottomRow}
            {!myProductsPage && <AddToCartModal visible={modalVisible} setVisible={setModalVisible} product={product}/>}
            {isLivePage && liveProduct && <LiveProductDetailsModal isLiveAdmin={isLiveAdmin} liveProduct={liveProduct} visible={productDetailsPopupVisibility} setVisible={setProductDetailsPopupVisibility} instantBuy={instantBuy} sendProposition={sendProposition} propositionInProgress={propositionInProgress} setPropositionInProgress={setPropositionInProgress} />}
        </CardActions>}
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        cart: state.product.cart,
        wishlist: state.product.wishlist
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addToCart: (product, quantity, color, size) => {
            dispatch({type: 'ADD_TO_CART', product, quantity, color, size})
        },
        removeFromCart: (product) => {
            dispatch({type: 'REMOVE_FROM_CART', product})
        },
        addToWishlist: (product, currentWishlistPage, itemsPerPage) => {
            dispatch({type: 'ADD_TO_WISHLIST', product, subscribe: !product.isWish, page: currentWishlistPage, itemsPerPage})
        },
        removeProduct: (product, currentPageParameters) => {
            dispatch({type: 'REMOVE_PRODUCT_REQUESTED', id: product.id, ...currentPageParameters})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent;
