import React from "react";

import { autoPlay } from 'react-swipeable-views-utils';

import Text from "../../Component/FontText";
import Grid from "@material-ui/core/Grid";
import StylesFunc from "../../../Style/AssociationsStyle";
import Button from "@material-ui/core/Button";
import SwipeableViews from "react-swipeable-views";
import CardMedia from "@material-ui/core/CardMedia";
import {getImageUrl} from "../../../Utils/ImageUtils";
import DotIcon from "@material-ui/icons/FiberManualRecord";
import clsx from "clsx";
import {FontAwesome} from "../../../FontAwesome";
import * as Constants from "../../../Style/Constants";
import SwipeableYoutubeVideo from "../../Component/SwipeableYoutubeVideo";


const AutoPlaySwipeableViews = SwipeableViews;

function callPlayer(frame_id, func, args) {
    var iframe = document.getElementById(frame_id);
    if (iframe && iframe.tagName.toUpperCase() != 'IFRAME') {
        iframe = iframe.getElementsByTagName('iframe')[0];
    }
    if (iframe) {
        // Frame exists,
        iframe.contentWindow.postMessage(JSON.stringify({
            "event": "command",
            "func": func,
            "args": args || [],
            "id": frame_id
        }), "*");
    }
}

function Component({association}) {
    let Styles = StylesFunc();

    let [currentPicture, setCurrentPicture] = React.useState(0);

    const handleSetCurrentPicture = (index) => {
        if (!isNaN(index)) {
            setCurrentPicture(index);
        }
    }

    let _getPictureOverlay = (medias) => {
        if (medias.length > 1) {
            return <Grid
                className={Styles.picturesOverlay}
                container
                justify={"center"}>
                {medias.map(
                    (media, key) => <DotIcon
                        onClick={() => setCurrentPicture(key)}
                        className={clsx(Styles.dotIcon, {[Styles.dotIconSelected]: currentPicture === key})}
                        key={'picture-dot-' + media.id} />
                )}
            </Grid>;
        } else {
            return null;
        }
    };

    let _getAssociationMedia = (media) => {
        if (media.youtubeLink) {
            return <SwipeableYoutubeVideo youtubeLink={media.youtubeLink} id={media.id} />;
        } else if (media.picture) {
            return <CardMedia image={getImageUrl(media.picture.contentUrl, "associations")} className={Styles.associationCardImage} key={'picture-' + media.id} />
        }
    }

    return <Grid container item xs={12} className={Styles.associationCard}>
        {association.medias && association.medias.length > 0 && <Grid container item xs={12} className={Styles.associationCardPicturesWrapper}>
            <AutoPlaySwipeableViews
                enableMouseEvents
                onChangeIndex={handleSetCurrentPicture}
                index={currentPicture}>
                {association.medias.map(_getAssociationMedia)}
            </AutoPlaySwipeableViews>
            {_getPictureOverlay(association.medias)}
        </Grid>}
        <Grid container item xs={12} className={Styles.associationCardTitleRow}>
            <Grid item>
                <Text className={Styles.associationCardTitle}>{association.name}</Text>
                {association.subTitle && <Text className={Styles.associationCardSubTitle}>{association.subTitle}</Text>}
            </Grid>
            {association.url && <Grid item className={Styles.associationCardButtonWrapper}>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"} href={association.url} target={"_blank"}>
                    <FontAwesome prefix="fas" name="search-plus" style={{fontSize: Constants.FONT_SIZE_MEDIUM, marginRight: 10}} color={Constants.WHITE} />
                    Voir le site
                </Button>
            </Grid>}
        </Grid>
        {association.description && <Text className={Styles.associationCardDescription}>{association.description}</Text>}
    </Grid>;
}

export default Component

