import { call, put, takeLatest } from 'redux-saga/effects'
import { ApiContact } from '../Endpoint/Contact'

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* contact(action) {
    try {
        yield call(ApiContact, action.payload.name, action.payload.firstname, action.payload.email, action.payload.city, action.payload.message);
        yield put({type: "CONTACT_SUCCEEDED"});
    } catch (e) {
        yield put({type: "CONTACT_FAILED", message: e.message});
    }
}

function* mySaga() {
    yield takeLatest("CONTACT_REQUESTED", contact);
}

export default mySaga;