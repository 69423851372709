import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    modalContent: {
        padding: 20,
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },
    productImages: {
        ...GlobalStyle.row,
        paddingLeft: Constants.GET_SIZE(60),
        paddingRight: Constants.GET_SIZE(60),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    productInfo: {
    },
    swiperSlide: {
        display: "flex",
        alignItems: "center"
    },
    image: {
        height: "initial",
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 15,
        resizeMode: "contain"
    },
    productInfoNameText: {
        fontSize: Constants.FONT_SIZE_BIG,
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },
    productInfoCatBrandPrice: {
        ...GlobalStyle.row,
    },
    productInfoCatBrandText: {
        fontSize: Constants.FONT_SIZE_BIG,
        color: Constants.FONT_LIGHT_GRAY,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    productInfoPriceText: {
        fontSize: Constants.FONT_SIZE_BIG,
        color: Constants.PINK,
        marginLeft: 10,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },
    productInfoPriceTextMobile: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
            display: "block",
            margin: "auto"
        }
    },
    productInfoStateMat: {
        marginTop: Constants.GET_SIZE(30),
        marginBottom: Constants.GET_SIZE(30)
    },
    productInfoStateMatText: {
    },
    productQuantity: {
        zIndex: 10
    },
    sectionTitle: {
        color: Constants.FONT_MEDIUM_GRAY
    },
    productInfoValue: {
        fontWeight: "bold",
        padding: Constants.GET_SIZE(10),
        alignSelf: "flex-start",
        marginLeft: 10

    },
    productInfoValueSelected: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderStyle: "solid"
    },
    productInfoWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center"
    },
    productionDescription: {
        marginTop: 20,
        marginBottom: 20
    },
    productionDescriptionContent: {
        fontWeight: "bold",
    },

    buttonsTitle: {
        textTransform: "uppercase",
        marginBottom: 15,
        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    propositionInputRoot: {
        height: "100%"
    },
    propositionInput: {
        fontSize: Constants.FONT_SIZE_NORMAL,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: Constants.BORDER_DARK_GRAY,
        height: "100%",
        boxSizing: "border-box",
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
        }
    },
    buttonsDividerWrapper: {
        marginTop: 24,
        marginBottom: 24
    },
    buttonsDivider: {
        width: '100%',
        height: 2
    },
    buttonsDividerText: {
        textTransform: "uppercase",
        fontSize: Constants.FONT_SIZE_NORMAL,
        marginLeft: 15,
        marginRight: 15,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE,
        }
    },
    button: {
        width: '100%'
    }
}));
