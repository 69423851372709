import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let FontAwesome = ({name, className, prefix, ...props}) => {
    if (!prefix) {
        prefix = 'fas';
        if (['twitter', 'instagram', 'facebook', 'facebook-f'].indexOf(name) !== -1) {
            prefix = 'fab';
        }
    }
    return <FontAwesomeIcon icon={[prefix, name]} className={className} {...props} />;
}

export { FontAwesome };
