import React from "react";

import {ConfirmEmail} from '../../API/Endpoint/User';

import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";

import Alert from "@material-ui/lab/Alert"

import Text from "../Component/FontText";
import { connect } from 'react-redux'
import gplusLogo from "../../Assets/Images/gplus_logo.svg"
import fbLogo from "../../Assets/Images/fb_logo.svg"
import { InternalLink } from "../Component/InternalLink"
import { Redirect } from "react-router-dom"
import { useForm } from 'react-hook-form'
import TextInputIcon from "./Component/TextInputIcon"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { withRouter, useLocation } from "react-router-dom";

import LoginStylesFunc from '../../Style/LoginStyle'
import * as Constants from '../../Style/Constants'
import MetaTags from "react-meta-tags";

function Login({history, navigation, token, onLogin, loginError, loginFacebook, loginGoogle, isLoading}) {
    let LoginStyles = LoginStylesFunc();
    const { setError, handleSubmit, errors, register } = useForm()
    const [confirmationEmail, setConfirmationEmail] = React.useState(null);
    const [googleError, setGoogleError] = React.useState(null);
    const query = new URLSearchParams(useLocation().search);
    const confirmationToken  = query.get('confirmation');

    React.useEffect(() => {
        const confirmLogin = async () => {
          if (confirmationToken) {
            try {
                await ConfirmEmail(confirmationToken).then((response) => {
                    setConfirmationEmail(
                        {
                            statut : 'success',
                            message : 'Votre email a bien été confirmer, vous pouvez vous connecter à votre compte.'
                         }
                    );
                }).catch((error) => {
                    if (error.response) {
                        setConfirmationEmail(
                            {
                                statut : 'error',
                                message : error.response.data['hydra:title']
                             }
                        );
                    } else {
                        setConfirmationEmail(
                            {
                                statut : 'error',
                                message : error.message
                            }
                        );
                    }
                });
            } catch (error) {
                setConfirmationEmail(
                    {
                        statut : 'error',
                        message : "Une erreur est survenue lors de la validation de votre email."
                    }
                );
            }
          } else {
            setConfirmationEmail(null);
          }
        };
    
        confirmLogin();
    }, [confirmationToken]);
    

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title += ' - Se connecter';
        if ('fbAsyncInit' in window) {
            // setTimeout(() => window.fbAsyncInit(), 100);
        }
    }, []);

    let onClickLogin = ({username, password}) => {
        onLogin(username, password)
    };

    React.useEffect(() => {
        if (loginError) {
            if (loginError === 'Invalid credentials.') {
                setError("username", {type: "notMatch"});
                setError("password", {type: "notMatch", message: "Adresse e-mail / Mot de passe incorrect"});
            } else {
                setError("username", {type: "notMatch"});
                setError("password", {type: "notMatch", message: loginError});
            }
        }
    }, [loginError, setError]);

    let redirectTo = "/";
    if (history.location.state && history.location.state.redirectTo) {
        redirectTo = history.location.state.redirectTo;
    }

    const registerFacebook = (response) => {
        if (response.accessToken) {
            loginFacebook(response.accessToken);
        } else {
            setGoogleError('Une erreur a eu lieu. Veuillez réessayer.');
        }
    };


    const registerGoogle = (response) => {
        loginGoogle(response.accessToken);
    };

    const registerGoogleFailure = (response) => {
        console.log(response);
        if (response.details && response.details === "Cookies are not enabled in current environment.") {
            setGoogleError('Les cookies doivent être activés pour se connecter avec Google');
        }
    };

    if (token) {
        return <Redirect to={redirectTo} />
    } else {
        return (
            <Grid container className={LoginStyles.container} alignItems={"center"}>
                <MetaTags>
                    <title>Connectez-vous sur Violette Sauvage, vide dressing en ligne</title>
                    <meta name="description"
                          content={"Connectez-vous à votre compte personnel sur Violette Sauvage, votre vide dressing en ligne pour Femme, Homme & Enfants."}/>
                </MetaTags>
                <Grid container item xs={12} justify={"center"} className={LoginStyles.connectWrapper}>
                    <Text className={LoginStyles.connectLabel}>Se connecter avec</Text>
                    <GoogleLogin
                        // clientId="526247064655-51pg5hgahi10al745olf4pd476gjkanu.apps.googleusercontent.com"
                        clientId={"239500840545-qjglv9pcvr99etqn2sl5cr7up5m5su44.apps.googleusercontent.com"}
                        buttonText="Login"
                        onSuccess={registerGoogle}
                        onFailure={registerGoogleFailure}
                        cookiePolicy={'single_host_origin'}
                        render={renderProps => (
                            <Button onClick={renderProps.onClick} className={LoginStyles.snButton}>
                                <CardMedia className={LoginStyles.snLogo} src={gplusLogo} component={"img"} />
                            </Button>
                        )}/>
                    <FacebookLogin
                        // appId="2519008774979459"
                        appId={"4322364311117912"}
                        fields="first_name,last_name,email"
                        callback={registerFacebook}
                        render={renderProps => (
                            <Button onClick={() => {renderProps.onClick()}} className={LoginStyles.snButton}>
                                <CardMedia className={LoginStyles.snLogo} src={fbLogo} component={"img"} />
                            </Button>
                        )}/>
                    {googleError && <Grid item xs={12}>
                        <Text color={"error"}>{googleError}</Text>
                    </Grid>}
                </Grid>

                <Grid container item xs={12} justify={"center"}>
                    <div className={LoginStyles.orSeparator}>ou</div>
                </Grid>

                {confirmationEmail &&
                    <Grid container item xs={12} justifyContent={"center"} className={LoginStyles.connectWrapper} >
                        <Alert severity={confirmationEmail.statut}>{confirmationEmail.message}</Alert>
                    </Grid>
                }

                <Grid item xs={12}>
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        icon={"user-circle"}
                        onPressEnter={handleSubmit(onClickLogin)}
                        placeholder={"Adresse e-mail"}
                        rules={{required: "Ce champ est obligatoire"}}
                        name={"username"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        icon={"key"}
                        onPressEnter={handleSubmit(onClickLogin)}
                        placeholder={"Mot de passe"}
                        rules={{required: "Ce champ est obligatoire"}}
                        name={"password"}
                        secure={true}
                        enterkeyhint="done"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button onClick={handleSubmit(onClickLogin)} color={"primary"} variant={"contained"} disableElevation className={LoginStyles.button}>
                        Connexion
                    </Button>
                </Grid>

                <Grid item xs={12} style={{marginBottom: 10, marginTop: 10}}>
                    <InternalLink
                        navigation={navigation}
                        label={"Mot de passe oublié ?"}
                        link={"FORGOT_PASSWORD"}
                        linkStyle={{fontSize: Constants.FONT_SIZE_NORMAL}}
                        wrapperClass={LoginStyles.internalLinkWrapper}
                        labelClass={LoginStyles.internalLinkLabel}
                        center
                    />
                </Grid>
                <Grid item xs={12}>
                    <Text className={LoginStyles.noAccountText}>Vous n'avez pas encore de compte ?</Text>
                </Grid>
                <Grid item xs={12}>
                    <InternalLink
                        navigation={navigation}
                        label={"Inscrivez-vous"}
                        link={"REGISTER"}
                        linkStyle={{fontSize: Constants.FONT_SIZE_NORMAL}}
                        wrapperClass={LoginStyles.internalLinkWrapper}
                        labelClass={LoginStyles.internalLinkLabel}
                        center
                    />
                </Grid>

            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        loginError: state.login.loginError,
        isLoading: state.loading.isLoading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (login, pwd) => {
            dispatch({type: 'LOGIN_REQUESTED', payload: {login, pwd}})
        },
        loginFacebook: (accessToken) => {
            dispatch({type: 'LOGIN_FACEBOOK_REQUESTED', payload: {accessToken}})
        },
        loginGoogle: (accessToken) => {
            dispatch({type: 'LOGIN_GOOGLE_REQUESTED', payload: {accessToken}})
        }
    }
};

const VisibleLogin = connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Login));

export default VisibleLogin
