
import { getApiUrl } from "../Utils/QueryUtils";

export default [
    {
        label: "Mes achats",
        links: [
            {
                label: "Mes commandes",
                type: "link",
                link: "PURCHASES",
            },
            {
                label: "Mes coups de coeur",
                type: "link",
                link: "WISHLIST",
            },
            {
                label: "Mes boutiques suivies",
                type: "link",
                link: "ALERTS",
            }
        ]
    },
    {
        label: "Mes ventes",
        links: [
            {
                label: "Mes transactions",
                type: "link",
                link: "MY_SALES",
            },
            {
                label: "Mes annonces",
                type: "link",
                link: "MY_PRODUCTS",
            },
            {
                label: "Mettre en vente un article",
                type: "link",
                link: "CREATE_PRODUCT",
            }
        ]
    },
    {
        label: "Lives",
        links: [
            {
                label: "Tuto live",
                type: "link",
                link: "TUTO_LIVES",
            },
            {
                label: "Mes lives",
                type: "link",
                link: "MY_LIVES",
            },
            {
                label: "Créer un live",
                type: "link",
                link: "CREATE_LIVE",
            }
        ]
    },
    {
        label: "Paiements",
        links: [
            {
                label: "Mon porte-monnaie",
                type: "link",
                link: "MY_WALLET",
            },
            {
                label: "Mes coordonnées bancaires",
                type: "link",
                link: "MY_BANK_INFO",
            }
        ]
    },
    {
        label: "Paramètres du compte",
        links: [
            {
                label: "Ma boutique",
                type: "link",
                link: "MY_SHOP",
            },
            {
                label: "Mes informations personnelles",
                type: "link",
                link: "MY_INFO",
            },
            {
                label: "Messagerie",
                type: "link",
                link: "MESSAGE_THREADS",
            },
            {
                label: "Déconnexion",
                type: "action",
                action: "logout",
            }
        ]
    },
    {
        label: "Administration du site",
        admin: true,
        links: [
            {
                label: "Tableau de bord",
                type: "link",
                link: "ADMIN_LIST",
            },
            {
                label: "Modération des marques",
                type: "link",
                link: "CRITERIA_LIST",
            },
            {
                label: "Administration manuelle",
                type: "link",
                link: "ADMIN_DASHBOARD",
            },
            {
                label: "Gestion des notifications",
                type: "link",
                link: "NOTIF_DASHBOARD",
            },
            {
                label: "Suivi des transactions",
                type: "link",
                link: "MONEY_DASHBOARD",
            },
            {
                label: "Codes promo",
                type: "link",
                link: "LIVE_PROMOTION_LIST",
            },
            {
                label: "Associations",
                type: "link",
                link: "ASSOCIATION_LIST",
            },
            {
                label: "Export Click and Collect",
                type: "csv",
                link: getApiUrl("export/clickncollect")
            }
        ]
    }
];
