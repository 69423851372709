import React from "react";

import Link from "@material-ui/core/Link";

import StaticCommon from "./StaticCommon";
import Banner from "../../../Assets/Images/banners/Tarifs_et_livraisons.jpg"


function Component({title}) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "Voir les conditions de livraison",
        blocks: [
            {
                title: "Livraison à domicile",
                content: [
                    {
                        text: "La Marketplace Violette Sauvage propose 3 options de livraisons en partenariat avec Chronopost et Mondial Relay dans toute la France."
                    },
                    {
                        title: "Grille des tarifs",
                        table: {
                            header: ["", "Prix (selon poids)", "Délai"],
                            rows: [
                                ["Chronopost à domicile", "A partir de 8.84€*", "24h à compter de la prise en charge par un agent."],
                                ["Chronopost en point relais", "A partir de 5.16€*", "48h à compter de la prise en charge par un agent."],
                                ["Mondial Relay", "A partir de 3.91€*", "3 jours ouvrés."],
                            ]
                        }
                    },
                    {
                        text: "*un supplément est à prévoir pour une livraison en Corse."
                    },
                    {
                        title: "Comment suivre vos colis ?",
                        text: "Une fois votre colis envoyé, vous pouvez suivre son acheminement via votre compte personnel dans la rubrique « mes commandes »."
                    },
                    {
                        text: "Si vous choisissez la livraison par Chronopost, vous pourrez suivre via l’application l’acheminement complet de votre colis en temps réel."
                    },
                    {
                        text: ['Si vous choisissez la livraison Chronopost en point relais, vous pourrez suivre via l’application ', <Link href={"https://www.chronoshop2shop.fr/fr"} target={"_blank"}>Shop2shop</Link>, ' l’acheminement complet de votre colis en temps réel.']
                    }
                ]
            },
            {
                title: "Livraison en mains propres",
                content: [
                    { text: 'Pour chaque article mis en vente sur la Marketplace, vous avez la possibilité de voir où est localisé le vendeur. Vérifiez la proximité avec ce dernier et profiter de l’option « Remise en main propre » afin d’éviter les frais de ports supplémentaires !' },
                    { text: 'Pour cela, choisissez l’option « Remise en main propre » lors de la validation de votre achat, le vendeur vous contactera ensuite afin que vous puissiez fixer un rendez-vous.' },
                ]
            },
            {
                title: "Livraison en Click and collect",
                content: [
                    { text: ['Avec la livraison Click and Collect, vous avez la possibilité de venir récupérer vos achats directement sur nos vide-dressings, ', <strong>si l’option est possible sur les articles que vous souhaitez</strong>, '.']},
                    { text: 'Cette commande vous permettra de rentrer gratuitement au vide-dressing : le jour de l’événement, munissez-vous de votre carte d’identité et présentez-vous à l’entrée du vide-dressing, un membre de notre équipe vous indiquera ensuite le stand du vendeur afin de récupérer votre colis.' },
                    { text: 'L’avantage? Cela vous permettra d’essayer l’article sur place grâce aux cabines d’essayage présentes sur nos événements mais aussi de découvrir les nombreux autres stands ainsi que toutes les animations !' },
                    { text: ['Pour plus d’informations, n’hésitez pas à consulter la page ', <Link href={"/livraison_click_and_collect"}>Livraison Click and Collect</Link>] },
                ]
            },
            {
                title: "Assurance",
                content: [
                    {
                        title: 'Chronopost',
                        text: 'Si la responsabilité de Chronopost est établie, vous êtes indemnisé pour la valeur d\'origine de la marchandise, le montant de sa réparation ou les frais directs de reconstitution des documents, dans la limite de notre responsabilité contractuelle et sur présentation de justificatifs.'
                    },
                    { text: ['Pour plus d’informations, merci de contacter le Service Client Particuliers disponible au 09 69 391 391 (appel non surtaxé) ou de vous rendre directement sur le site de ', <Link href={"https://www.chronopost.fr/fr/aide/faq/reclamation/suis-je-indemnise-en-cas-de-perte-de-mon-colis"} target={"_blank"}>Chronopost</Link>, '.'] },

                    {
                        title: 'Mondial Relay',
                        text: 'La responsabilité de Mondial Relay est engagée en cas de perte du colis ou de dégâts constatés lors de la remise du colis à l’acheteur. De ce fait, il s’engage à indemniser le contractant à hauteur de 25€ par colis.'
                    },
                    { text: ['Pour plus d’informations, rendez-vous sur les ', <Link target={"_blank"} href={"https://www.mondialrelay.fr/envoi-de-colis/conditions-generales-de-vente/"}>conditions générales de ventes</Link>,' de Mondial Relay.'] },

                ]
            },
        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"Tous les tarifs & livraisons disponibles | Vide dressing Violette Sauvage"}
                      metaDesc={"Retrouvez l'ensemble des informations tarifaires et les modes de livraisons disponibles sur le site de Violette Sauvage."}
                      arianeCurrentName={"Tarifs & livraisons"} />
    );
}

export default Component
