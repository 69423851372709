import React from "react";
import TextInputIcon from "../../Screens/Front/Component/TextInputIcon";
import MUITextField from "@material-ui/core/TextField";
import { KeyboardDatePicker as DatePicker } from '@material-ui/pickers';
import {ErrorMessage} from "@hookform/error-message";
import Text from "../../Screens/Component/FontText";
import * as Constants from "../../Style/Constants";

export default function DateField({register, name, namePrefix, label, required, setValue, watch}) {
    let rules = {};

    React.useEffect(() => {
        register({name: namePrefix + name});
    }, [register]);

    if (required) {
        rules['required'] = "Ce champ est obligatoire";
    }

    let date = watch(namePrefix + name);

    let handleChange = (date) => {
        return setValue(namePrefix + name, date)
    }

    let maxBirthdate = new Date();
    maxBirthdate.setFullYear(maxBirthdate.getFullYear() - 18);

    return <div>
        <DatePicker
            value={null}
            onChange={handleChange}
            format="DD/MM/YYYY"
            showYearDropdown
            yearDropdownItemNumber={5}
            placeholder={label}
            invalidDateMessage={"Format de date invalide"}
            maxDateMessage={"Le dirigeant doit être majeur"}
            minDateMessage={"Format de date invalide"}
            inputVariant={"outlined"}
        />
    </div>
}
