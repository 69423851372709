import React from "react";

import Link from "@material-ui/core/Link";

import StaticCommon from "./StaticCommon";

import Banner from "../../../Assets/Images/banners/Livraison_Click_and_Collect.jpg"

function Component({title}) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "La livraison Click and Collect",
        blocks: [
            {
                title: "Vous êtes acheteur :",
                content: [
                    {
                        text: "Avec la livraison Click and Collect, vous avez la possibilité de venir récupérer vos achats directement sur nos vide-dressings, si l’option est possible sur les articles que vous souhaitez."
                    },
                    {
                        title: "Comment ça marche ?",
                        text: "Chaque vendeur a la possibilité d’indiquer à quel vide-dressing il sera présent, cela active donc l’option “Livraison Click and Collect” sur sa boutique."
                    },
                    {
                        text: "Vous pouvez donc choisir cette option et dès validation de votre commande, vous recevrez un bon de commande qui vous permettra d’accéder gratuitement au vide-dressing afin de récupérer votre colis. Munissez-vous de votre carte d’identité et présentez-vous à l’entrée du vide-dressing, un membre de notre équipe vous indiquera ensuite le stand du vendeur."
                    },
                    {
                        text: "L’avantage? Cela vous permettra d’essayer l’article sur place grâce aux cabines d’essayage présentes sur nos événements mais aussi de découvrir les nombreux autres stands ainsi que toutes les animations !"
                    },
                    {
                        text: ["Vous pouvez également retrouver toutes les boutiques présentes aux différents vide-dressings dans la rubrique “Les vendeurs présents aux prochains vide-dressings”, visible sur la ", <Link href={"/"}>Homepage</Link>, " de la marketplace."]
                    }
                ]
            },
            {
                title: "Vous êtes vendeur :",
                content: [
                    {
                        text: ["Dès votre inscription sur la Marketplace et l’ouverture de votre boutique, vous pourrez indiquer sur votre tableau de bord les dates auxquelles vous participez aux vide-dressings Violette Sauvage via le bouton “Vendeur au vide-dressing”. Cochez la case et la date de l’événement où vous serez présent(e), l’option “Click and Collect” s’activera ensuite automatiquement sur tous vos articles."]
                    },
                    {
                        text: "Ainsi, les acheteurs auront la possibilité de choisir l’option “Livraison Click and Collect” afin de venir récupérer leur colis au vide-dressing directement. Cette livraison est gratuite et vous permet d’effectuer des ventes en amont de votre participation à l’événement, une rentabilité optimisée au maximum !"
                    },
                    {
                        text: ["Dès que l’acheteur aura validé une commande avec une livraison Click and Collect, il recevra ",<strong>un code</strong>, " qui lui permettra d’accéder à l’événement et qui servira de preuve d’achat afin de récupérer son colis."]
                    },
                    {
                        text: ["Une fois la transaction effectuée, ", <strong>n’oubliez pas de demander à l’acheteur de vous transmettre le code de vérification</strong>, " afin de bien finaliser la commande via votre compte."]
                    },
                    {
                        text: ["Par ailleurs, après avoir indiqué votre présence aux différents vide-dressings, votre boutique sera visible dans la rubrique “Les vendeurs présents aux prochains vide-dressings”, visible sur la ", <Link href={"/"}>Homepage</Link>, " de la marketplace."]
                    }
                ]
            },

        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"Livraison en Click & Collect | Vide dressing Violette Sauvage"}
                      metaDesc={"Avec la livraison Click and Collect, récupérez vos achats directement sur l'un de nos vide-dressings existants."} />
    );
}

export default Component
