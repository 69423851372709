const initialState = {
    currentDispute: null,
    disputesList: [],
    pagination: {
        first: 1,
        last: 1
    },
};


function reduc(state = initialState, action) {
    switch (action.type) {
        case "SEARCH_DISPUTES_SUCCEEDED":
            let first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            let last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                disputesList: action.disputes,
                pagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1
                }
            });
        case "FETCH_DISPUTE_SUCCEEDED":
            return Object.assign({}, state, {
                currentDispute: action.dispute
            });
        case "UPDATE_DISPUTE_SUCCEEDED":
            return Object.assign({}, state, {
                currentDispute: action.dispute
            });
        case "DELETE_DISPUTE_SUCCEEDED":
            let index = state.disputesList.findIndex((element) => element.id === action.dispute.id);
            return Object.assign({}, state, {
                cart: [
                    ...state.disputesList.slice(0, index),
                    ...state.disputesList.slice(index + 1)
                ]
            });

    }

    return state
}

export default reduc
