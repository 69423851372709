import React from "react";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/AddCircleOutline';
import TextField from "@material-ui/core/TextField";

import { connect } from 'react-redux';

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/Back/CriteriaListStyle"
import ErrorStylesFunc from "../Styles/Error"
import Moment from "moment";
import {useLivePromotionList} from "../../API/Hook/LivePromotion";

function Component({}) {
    let Styles = StylesFunc();
    let ErrorStyles = ErrorStylesFunc();

    let [newPromoCode, setNewPromoCode] = React.useState("");
    let [newPromoValue, setNewPromoValue] = React.useState("");
    let [newPromoError, setNewPromoError] = React.useState(null);

    const onPromoCreated = () => {
        setNewPromoValue("");
        setNewPromoCode("");
    }
    const onPromoCreationError = (error) => {
        setNewPromoError(error);
    }

    React.useEffect(() => {
        setNewPromoError(null);
    }, [newPromoCode, newPromoValue]);

    let [livePromotionList, pagination, page, setPage, createPromotion, refresh, isLoading] = useLivePromotionList(onPromoCreated, onPromoCreationError);


    let _getHeaderRow = () => {
        return <Grid container className={clsx(Styles.headerRow)}>
            <Grid item style={{width: 200}}>
                <Text className={clsx(Styles.headerCell)}>Code promo</Text>
            </Grid>
            <Grid item style={{width: 200}}>
                <Text className={clsx(Styles.headerCell)}>Promotion</Text>
            </Grid>
            <Grid item xs>
                <Text className={clsx(Styles.headerCell)}>Date de création</Text>
            </Grid>
        </Grid>
    };

    let _getRow = (livePromotion) => {
        return <Grid container className={Styles.dataRow}>
            <Grid item style={{width: 200}}>
                <Text className={clsx(Styles.dataCell)}>{livePromotion.code}</Text>
            </Grid>
            <Grid item style={{width: 200}}>
                <Text className={clsx(Styles.dataCell)}>{livePromotion.value.toFixed(2)}€</Text>
            </Grid>
            <Grid item xs>
                <Text className={clsx(Styles.dataCell)}>{Moment(livePromotion.createdAt).format('YYYY/MM/DD hh:mm')}</Text>
            </Grid>
        </Grid>
    };

    let _getCreateRow = () => {
        return <Grid container className={Styles.dataRow}>
            <Grid item style={{width: 200}}>
                <TextField
                    variant={"outlined"}
                    placeholder={"Nouveau code"}
                    style={{paddingRight: 20, boxSizing: "border-box"}}
                    inputProps={{style: {padding: 5, height: 20}}}
                    value={newPromoCode}
                    onChange={e => setNewPromoCode(e.target.value)} />
            </Grid>
            <Grid item style={{width: 200}}>
                <TextField
                    variant={"outlined"}
                    placeholder={"Nouvelle promotion"}
                    style={{paddingRight: 20, boxSizing: "border-box"}}
                    inputProps={{style: {padding: 5, height: 20}}}
                    value={newPromoValue}
                    onChange={e => setNewPromoValue(e.target.value)} />
            </Grid>
            <Grid item xs container justify={"space-between"} alignItems={"center"}>
                <Text className={clsx(Styles.dataCell, ErrorStyles.error)}>{newPromoError ?? ""}</Text>
                <IconButton className={Styles.link} onClick={() => createPromotion(newPromoCode, newPromoValue)}>
                    <AddIcon />
                </IconButton>
            </Grid>
        </Grid>
    };

    return <Base
        title={"Liste des codes promo"}
        currentScreen={"LIVE_PROMOTION_LIST"}
        pagination={pagination}
        page={page}
        setPage={setPage}
        content={
            <Box className={Styles.container}>
                {_getHeaderRow()}
                <Box className={Styles.dataWrapper}>
                    {_getCreateRow()}
                    {livePromotionList.length > 0 && livePromotionList.map(_getRow)}
                </Box>

            </Box>
        }
    />
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent
