import axios from 'axios';
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils'

export const FetchMyLives = (tab, page, itemsPerPage) => {
    return axios.get(getApiUrl('lives/my_lives', {tab, page, itemsPerPage}), {headers: setDefaultHeaders()});
};

export const FetchNextLives = () => {
    return axios.get(getApiUrl('lives/next', {}), {headers: setDefaultHeaders()});
};

export const FetchLive = (id) => {
    return axios.get(getApiUrl('lives/' + id), {headers: setDefaultHeaders()});
};
