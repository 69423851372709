import React from "react";
import {Picker} from "react-native";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Base from "./Base"
import Text from "../Component/FontText"
import StylesFunc from "../../Style/Back/PushManagementStyle"
import * as Constants from "../../Style/Constants";
import TextInputIcon from "../Front/Component/TextInputIcon";
import "react-datepicker/dist/react-datepicker.css";
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import CustomCheckbox from "../Component/CustomCheckbox";


function Component({history, navigation, token, sendNotif}) {
    let Styles = StylesFunc();
    const { setValue, setError, handleSubmit, register, errors, watch } = useForm();
    let [target, setTarget] = React.useState(0);
    let [push, setPush] = React.useState(false);
    let [mail, setMail] = React.useState(false);

    const handleClickOk = (data) => {
        sendNotif(data.title, data.message, push, mail, target == 0 ? "BUYERS" : target == 1 ? "SELLERS" : target == 2 ? "SELLERS_PRO" : "ALL_DEVICES")
    };

    return <Base
        title={"Gestion des notifications"}
        currentScreen={"NOTIF_DASHBOARD"}
        content={
            <Box className={Styles.container}>
                <TextInputIcon
                    errors={errors}
                    register={register}
                    placeholder={"Objet"}
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"title"}
                />
                <TextInputIcon
                    wrapperClass={Styles.inputWrapper}
                    errors={errors}
                    register={register}
                    placeholder={"Votre message"}
                    multiline
                    rules={{
                        required: "Ce champ est obligatoire",
                    }}
                    name={"message"}
                />
                <Text className={Styles.label} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>Les utilisateurs</Text>

                <Box className={Styles.pickerWrapper}>
                    <Picker
                        className={Styles.picker}
                        onValueChange={(value, index) => {
                            setTarget(value);
                        }}
                        selectedValue={target} >
                        <Picker.Item label={'Acheteurs'} value={0} color={'black'} className={Styles.pickerItem} />
                        <Picker.Item label={"Vendeurs"} value={1} color={'black'} className={Styles.pickerItem} />
                        <Picker.Item label={"Vendeurs pro"} value={2} color={'black'} className={Styles.pickerItem} />
                        <Picker.Item label={"Les trois"} value={3} color={'black'} className={Styles.pickerItem} />
                    </Picker>
                </Box>

                <Text className={Styles.label} style={{marginBottom: Constants.GET_SIZE(10), marginTop: Constants.GET_SIZE(25)}}>Types de notifications</Text>
                <Box className={Styles.row}>
                    <CustomCheckbox
                        containerClass={Styles.checkBox}
                        checkedColor={Constants.PINK}
                        uncheckedColor={Constants.FONT_LIGHT_GRAY}
                        title={<Text className={Styles.checkboxLabel}>Push</Text>}
                        checked={push} onPress={() => setPush(!push)}
                        size={Constants.CHECKBOX_SIZE_BIG} />
                    <CustomCheckbox
                        containerClass={Styles.checkBox}
                        checkedColor={Constants.PINK}
                        uncheckedColor={Constants.FONT_LIGHT_GRAY}
                        title={<Text className={Styles.checkboxLabel}>Mail</Text>}
                        checked={mail} onPress={() => setMail(!mail)}
                        size={Constants.CHECKBOX_SIZE_BIG} />

                </Box>

                <Button disableElevation variant={"contained"} color={"primary"} onClick={handleSubmit(handleClickOk)}>
                    Envoyer
                </Button>
            </Box>
        }
    />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        usersList: state.user.usersList,
        productsList: state.product.productsList,
        shopsList: state.shop.shopsList,
        userPagination: state.user.usersListPagination,
        productPagination: state.product.pagination,
        shopPagination: state.shop.pagination,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        sendNotif: (title, message, push, mail, target) => {
            dispatch({type: 'SEND_FIREBASE_PUSH_REQUESTED', title, message, push, mail, target})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
