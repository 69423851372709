const initialState = {
    chronoRelayPoints: [],
    mondialRelayPoints: [],
    addresses: []
};


function reduc(state = initialState, action) {
    switch (action.type) {
        case "FETCH_RELAY_POINTS_SUCCEEDED":
            if (action.relayType === "Chronopost") {
                return Object.assign({}, state, {
                    chronoRelayPoints: action.relayPoints
                });
            } else {
                return Object.assign({}, state, {
                    mondialRelayPoints: action.relayPoints
                });
            }
        case "SEARCH_ADDRESS_SUCCEEDED":
            return Object.assign({}, state, {
                addresses: action.addresses
            });
        case "SEARCH_ADDRESS_REQUESTED":
            return Object.assign({}, state, {
                addresses: []
            });

    }

    return state
}

export default reduc