import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        marginBottom: 10,
    },
    adminWrapper: {
        transition: 'background-color 0.3s ease',
        cursor: 'pointer',
        paddingLeft: 10,
        [theme.breakpoints.up('sm')]: {
            '&:hover': {
                backgroundColor: Constants.BACKGROUND_GRAY
            }
        },
        [theme.breakpoints.down('xs')]: {
            '&:active': {
                backgroundColor: Constants.BACKGROUND_GRAY
            }
        },
    },
    adminPropWrapper: {
        backgroundColor: Constants.WHITE,
        padding: 10
    },
    adminPropRefusedWrapper: {
        opacity: '50%'
    },
    messageContent: {
        lineHeight: "24px",
        textAlign: "left"
    },
    avatar: {
        borderRadius: '50%',
        verticalAlign: 'middle',
        display: 'inline',
        width: 24,
        height: 24,
        marginRight: 10
    },
    button: {
        maxHeight: 50,
        width: "100%",
        marginTop: 5,
    },
    buttonFirst: {
        [theme.breakpoints.up('sm')]: {
            marginRight: 4
        }
    },
    buttonLast: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 4
        }
    }
}));
