import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "../GlobalStyle";
import ShoppingCartRightColumnStyle from "../ShoppingCartRightColumnStyle";
import * as Constants from "../Constants"


export default makeStyles((theme) => {
    let CommonStyles = ShoppingCartRightColumnStyle(theme);

    return {
        ...CommonStyles,

        container: {
            maxWidth: 1440,
            margin: "auto",
            [theme.breakpoints.down('xs')]: {
                width: "90%",
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: 60
            }
        },

        button: {
            ...GlobalStyle.button,
            width: Constants.GET_SIZE(300),
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: Constants.GET_SIZE(25),
        },
        buttonLabel: {
            ...GlobalStyle.buttonLabel,
            textAlign: "center"
        },
        wrapper: {
            width: "100%",
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            padding: Constants.GET_SIZE(50),
            paddingLeft: Constants.GET_SIZE(75),
            paddingRight: Constants.GET_SIZE(30),
            boxSizing: "border-box"
        },
        row: {
            ...GlobalStyle.row
        },
        pageTitle: {
            ...GlobalStyle.uppercaseText,

        },
        headerBar: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: "white",
            padding: Constants.GET_SIZE(50),
            paddingLeft: Constants.GET_SIZE(70),
            paddingRight: Constants.GET_SIZE(70),
            justifyContent: "space-around",
        },
        headerBarText: {
            ...GlobalStyle.uppercaseText,
            color: Constants.LIGHT_GRAY,
            fontWeight: "bold",

            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            }
        },
        headerBarTextActive: {
            color: Constants.PINK,
        },
        headerTruckImage: {
            width: Constants.GET_SIZE(33),
            height: Constants.GET_SIZE(22),
            marginRight: Constants.GET_SIZE(10),
        },
        headerCardImage: {
            width: Constants.GET_SIZE(29),
            height: Constants.GET_SIZE(20),
            marginRight: Constants.GET_SIZE(10),
        },
        headerClockImage: {
            width: Constants.GET_SIZE(27),
            height: Constants.GET_SIZE(28),
            marginRight: Constants.GET_SIZE(10),
        },
        stripeFieldWrapper: {
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            marginBottom: Constants.GET_SIZE(10),
            marginTop: Constants.GET_SIZE(10),
            justifyContent: "center",
            backgroundColor: Constants.WHITE,
            padding: Constants.GET_SIZE(10),
            marginLeft: Constants.GET_SIZE(10),
        },

        addressPickerWrapper: {
            ...GlobalStyle.rowCentered,
            backgroundColor: Constants.WHITE,
            borderWidth: 1,
            borderColor: Constants.BORDER_GRAY,
            padding: Constants.GET_SIZE(20),
            justifyContent: "space-around",
            marginTop: Constants.GET_SIZE(25),
            flex: 1
        },
        chronopostLogo: {
            width: Constants.GET_SIZE(193),
            height: Constants.GET_SIZE(43),
            marginRight: Constants.GET_SIZE(30),
        },
        mondialRelayLogo: {
            width: Constants.GET_SIZE(197),
            height: Constants.GET_SIZE(47),
            marginRight: Constants.GET_SIZE(30),
        },
        clickAndCollectLogo: {
            width: Constants.GET_SIZE(68),
            height: Constants.GET_SIZE(68),
            marginRight: Constants.GET_SIZE(30),
        },
        handsLogo: {
            width: Constants.GET_SIZE(83),
            height: Constants.GET_SIZE(68),
            marginRight: Constants.GET_SIZE(30),
        },
        deliveryNameWrapper: {
            flex: 1
        },
        deliveryAddressWrapper: {
            flex: 1
        },
        deliveryName: {

            fontSize: Constants.GET_FONT_SIZE(10),
            marginBottom: Constants.GET_SIZE(8),
        },
        deliveryName2: {

            fontSize: Constants.GET_FONT_SIZE(10),
            color: Constants.LIGHT_GRAY
        },
        horizontalDivider: {
            backgroundColor: Constants.BORDER_GRAY,
            width: "100%",
            height: 2,
            marginTop: Constants.GET_SIZE(25),
        },
        error: {

        },



        checkboxContainer: {
            backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
            borderWidth: 0,
            padding: 0,
            margin: 0
        },

        checkboxLabel: {

        },

        knowMoreText: {

            marginTop: Constants.GET_SIZE(10),
            marginBottom: Constants.GET_SIZE(20),
            marginLeft: Constants.GET_SIZE(10),
        },




        confirmationMessage: {
            marginTop: Constants.GET_SIZE(80),
            // marginLeft: Constants.GET_SIZE(200),
            // marginRight: Constants.GET_SIZE(200),
            textAlign: "center",

            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
                marginTop: 30,
            }
        },
        confirmationMessage2: {
            marginTop: Constants.GET_SIZE(30),
            // marginLeft: Constants.GET_SIZE(200),
            // marginRight: Constants.GET_SIZE(200),
            textAlign: "center",

            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
                marginTop: 10,
            }
        },
        confirmationMessageButtonWrapper: {
            width: "100%",
            marginBottom: 120,
            marginTop: 60,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
                marginTop: 30,
                marginBottom: 40,
            }
        },
        firstButton: {
            [theme.breakpoints.down('xs')]: {
                marginBottom: 10
            }
        }

    }
});
