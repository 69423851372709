import { call, put, takeLatest, takeEvery, cancelled } from 'redux-saga/effects'
import {
    SearchProducts,
    FetchProduct,
    FetchNew,
    FetchTendancesSauvages,
    GetShopsProducts,
    GetShopSimilarProducts,
    SubscribeToProduct,
    UnsubscribeFromProduct,
    FetchWishlist,
    SaveProduct,
    SavePicture,
    FetchMyProducts,
    RemoveProduct,
    SaveBrand,
    AddProductToCart,
    RemoveProductFromCart, DeleteProduct, RefreshProductsState, FetchAllMyProducts
} from '../Endpoint/Product'
import { handleError } from "../../Utils/SagaUtils";
import {DeleteShop} from "../Endpoint/Shop";
import { store } from '../../Store'

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* searchProducts(action) {
    try {
        const products = yield call(
            SearchProducts,
            action.family ? action.family : null,
            action.families ? action.families : null,
            action.category ? action.category : null,
            action.name ? action.name : null,
            action.respShop ? action.respShop : null,
            action.createurShop ? action.createurShop : null,
            action.blogShop ? action.blogShop : null,
            action.sizes ? action.sizes : null,
            action.brands ? action.brands : null,
            action.states ? action.states : null,
            action.price ? action.price : null,
            action.deliveries ? action.deliveries : null,
            action.materials ? action.materials : null,
            action.colors ? action.colors : null,
            null,
            action.page ? action.page : null,
            action.itemsPerPage ? action.itemsPerPage : null,
            action.sort ? action.sort : null
        );
        yield put({type: "SEARCH_PRODUCTS_SUCCEEDED", products: products.data["hydra:member"], pagination: products.data["hydra:view"], totalCount: products.data["hydra:totalItems"]});
    } catch (e) {
        yield put(handleError("SEARCH_PRODUCTS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* searchSimilarProducts(action) {
    try {
        const product = action.product;
        let result = yield call(
            SearchProducts,
            product.family.id, // action.family
            null, // action.families
            product.category.id, // action.category,
            null, // action.name,
            null, // action.respShop,
            null, // action.createurShop,
            null, // action.blogShop,
            [product.sizes && product.sizes.length > 0 ? product.sizes[0].id : -1], // action.size,
            null, // action.brand,
            null, // action.state,
            null, // action.price,
            null, // action.delivery,
            null, // action.material,
            null, // action.color,
            null, // action.shop,
            1, // action.page
            null, // action.itemsPerPage
            null // action.sort
        );

        let products = result.data["hydra:member"];

        if (products.length < 4) {
            result = yield call(
                SearchProducts,
                null, // action.family
                null, // action.families
                null, // action.category,
                null, // action.name,
                null, // action.respShop,
                null, // action.createurShop,
                null, // action.blogShop,
                [product.sizes && product.sizes.length > 0 ? product.sizes[0].id : -1], // action.size,
                null, // action.brand,
                null, // action.state,
                null, // action.price,
                null, // action.delivery,
                null, // action.material,
                null, // action.color,
                null, // action.shop,
                1, // action.page
                null, // action.itemsPerPage
                null // action.sort
            );
            for (const p of result.data["hydra:member"]) {
                products.push(p);

                if (products.length >= 4) {
                    break;
                }
            }
        }

        yield put({type: "SEARCH_SIMILAR_PRODUCTS_SUCCEEDED", products: products});
    } catch (e) {
        yield put(handleError("SEARCH_SIMILAR_PRODUCTS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchProduct(action) {
    try {
        const product = yield call(FetchProduct, action.id);
        yield put({type: "FETCH_PRODUCT_SUCCEEDED", product: product.data});
    } catch (e) {
        yield put(handleError("FETCH_PRODUCT_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchMyProducts(action) {
    try {
        const result = yield call(FetchMyProducts, action.productsType, action.page, action.itemsPerPage);
        yield put({type: "FETCH_MY_PRODUCTS_SUCCEEDED", products: result.data["hydra:member"], pagination: result.data["hydra:view"], keepLoading: action.hideLoader});
    } catch (e) {
        yield put(handleError("FETCH_MY_PRODUCTS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* fetchAllMyProducts(action) {
    try {
        const result = yield call(FetchAllMyProducts);
        yield put({type: "FETCH_MY_PRODUCTS_SUCCEEDED", products: result.data["hydra:member"], pagination: result.data["hydra:view"], keepLoading: action.hideLoader});
    } catch (e) {
        yield put(handleError("FETCH_ALL_MY_PRODUCTS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* fetchNew(action) {
    try {
        // const products = yield call(FetchNew);
        const products = yield call(SearchProducts, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1, null, null);
        yield put({type: "FETCH_NEW_PRODUCTS_SUCCEEDED", products: products.data["hydra:member"]});
    } catch (e) {
        yield put(handleError("FETCH_NEW_PRODUCTS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchTendancesSauvages(action) {
    try {
        const products = yield call(FetchTendancesSauvages);
        yield put({type: "FETCH_TENDANCES_SAUVAGES_SUCCEEDED", products: products.data["hydra:member"]});
    } catch (e) {
        yield put(handleError("FETCH_TENDANCES_SAUVAGES_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* getShopsProducts(action) {
    const prefix = "GET_SHOPS_PRODUCTS_";
    try {
        // const products = yield call(GetShopsProducts, action.shopId, action.page, action.itemsPerPage);
        const products = yield call(SearchProducts, null, null, null, null, null, null, null, null, null, null, null, null, null, null, action.shopId, action.page, action.itemsPerPage, null);
        yield put({type: prefix + "SUCCEEDED", shopId: action.shopId, products: products.data["hydra:member"], pagination: products.data["hydra:view"]});
    } catch (e) {
        yield put(handleError(prefix + "FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* getShopSimilarProducts(action) {
    const prefix = "GET_SHOP_SIMILAR_PRODUCTS_";
    try {
        const products = yield call(GetShopSimilarProducts, action.shopId, action.size);
        yield put({type: prefix + "SUCCEEDED", shopId: action.shopId, products: products.data["hydra:member"]});
    } catch (e) {
        yield put(handleError(prefix + "FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* subscribeToProduct(action) {
    if (store.getState().login.token) {
        try {
            let result;
            if (action.subscribe === true) {
                result = yield call(SubscribeToProduct, action.product.id);
            } else {
                result = yield call(UnsubscribeFromProduct, action.product.id);
            }

            if (action.page) {
                yield (fetchWishlist(action));
            } else {
                yield put({type: "ADD_TO_WISHLIST_SUCCEEDED", product: result.data, keepLoading: true});
            }
        } catch (e) {
            yield put(handleError("ADD_TO_WISHLIST_FAILED", e));
        } finally {
            if (yield cancelled()) {
                yield put({type: "REMOVE_LOADER", general: false});
            }
        }
    } else {
        action.product.isWish = action.subscribe;
        yield put({type: "ADD_TO_WISHLIST_SUCCEEDED", product: action.product, keepLoading: true});
    }
}

function* addProductToCart(action) {
    if (store.getState().login.token) {
        try {
            let result = yield call(AddProductToCart, action.product.id, action.quantity, action.size ? action.size.id : -1, action.color ? action.color.id : -1);

            yield put({type: "ADD_TO_CART_SUCCEEDED", product: result.data, keepLoading: true});
        } catch (e) {
            yield put(handleError("ADD_TO_CART_FAILED", e));
        } finally {
            if (yield cancelled()) {
                yield put({type: "REMOVE_LOADER", general: false});
            }
        }
    }
}

function* refreshCart(action) {
    const state = store.getState();
    try {
        const cart = state.product.cart;
        if (cart && cart.length > 0) {
            const ids = [];
            for (const p of cart) {
                ids.push(p.product.id);
            }
            let result = yield call(RefreshProductsState, ids);

            yield put({type: "REFRESH_CART_SUCCEEDED", products: result.data});
        } else {
            yield put({type: "REFRESH_CART_SUCCEEDED", products: []});
        }

    } catch (e) {
        yield put(handleError("REFRESH_CART_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* removeProductFromCart(action) {
    if (store.getState().login.token) {
        try {
            let result = yield call(RemoveProductFromCart, action.product.id);

            yield put({type: "REMOVE_FROM_CART_SUCCEEDED", product: result.data, keepLoading: true});
        } catch (e) {
            yield put(handleError("REMOVE_FROM_CART_FAILED", e));
        } finally {
            if (yield cancelled()) {
                yield put({type: "REMOVE_LOADER", general: false});
            }
        }
    }
}

function* removeProduct(action) {
    try {
        let result;
        result = yield call(RemoveProduct, action.id);

        if (action.page) {
            yield (fetchMyProducts(action));
        } else {
            yield put({type: "REMOVE_PRODUCT_SUCCEEDED", product: result.data});
        }
    } catch (e) {
        yield put(handleError("REMOVE_PRODUCT_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchWishlist(action) {
    try {
        const products = yield call(FetchWishlist, action.page, action.itemsPerPage);
        yield put({type: "FETCH_WISHLIST_SUCCEEDED", products: products.data["hydra:member"], pagination: products.data["hydra:view"]});
    } catch (e) {
        yield put(handleError("FETCH_WISHLIST_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* saveProduct(action) {
    try {
        let result;
        let picturesIds = [];
        for (const picture of action.pictures) {
            if (picture.id === -1) {
                result = yield call(SavePicture, picture.file);
                if (result && result.data) {
                    picturesIds.push({id: "/api/media_objects/" + result.data["id"], sortOrder: picturesIds.length});
                }
            } else {
                picturesIds.push({id: "/api/media_objects/" + picture.id, sortOrder: picturesIds.length});
            }
        }
        if (picturesIds.length > 0) {
            action.product.pictures = picturesIds;
        }

        if (action.newBrand) {
            result = yield call(SaveBrand, action.newBrand);
            action.product.brand = "/api/criterion_brands/" + result.data["id"]
        }

        result = yield call(SaveProduct, action.product);
        yield put({type: "SAVE_PRODUCT_SUCCEEDED", product: {...result.data, key: action.key || ''}, keepLoading: action.hideLoader});
    } catch (e) {
        yield put(handleError("SAVE_PRODUCT_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false, keepLoading: action.hideLoader});
        }
    }
}

function* deleteProduct(action) {
    try {
        let result = yield call(DeleteProduct, action.id);
        yield put({type: "DELETE_PRODUCT_SUCCEEDED", id: action.id})

    } catch (e) {
        yield put(handleError("DELETE_PRODUCT_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* mySaga() {
    yield takeLatest("SEARCH_PRODUCTS_REQUESTED", searchProducts);
    yield takeLatest("SEARCH_SIMILAR_PRODUCTS_REQUESTED", searchSimilarProducts);
    yield takeLatest("FETCH_PRODUCT_REQUESTED", fetchProduct);
    yield takeLatest("FETCH_MY_PRODUCTS_REQUESTED", fetchMyProducts);
    yield takeLatest("FETCH_ALL_MY_PRODUCTS_REQUESTED", fetchAllMyProducts);
    yield takeLatest("FETCH_NEW_PRODUCTS_REQUESTED", fetchNew);
    yield takeLatest("FETCH_WISHLIST_REQUESTED", fetchWishlist);
    yield takeLatest("FETCH_TENDANCES_SAUVAGES_REQUESTED", fetchTendancesSauvages);
    yield takeEvery("GET_SHOPS_PRODUCTS_REQUESTED", getShopsProducts);
    yield takeLatest("GET_SHOP_SIMILAR_PRODUCTS_REQUESTED", getShopSimilarProducts);
    yield takeLatest("ADD_TO_WISHLIST", subscribeToProduct);
    yield takeLatest("ADD_TO_CART", addProductToCart);
    yield takeLatest("REMOVE_FROM_CART", removeProductFromCart);
    yield takeEvery("SAVE_PRODUCT_REQUESTED", saveProduct);
    yield takeLatest("REMOVE_PRODUCT_REQUESTED", removeProduct);
    yield takeLatest("DELETE_PRODUCT_REQUESTED", deleteProduct);
    yield takeLatest("REFRESH_CART_REQUESTED", refreshCart);
}

export default mySaga;
