import React from "react";
import { connect } from 'react-redux'
import Link from "@material-ui/core/Link/Link";
import StaticCommon from "./StaticCommon";
import Banner from "../../../Assets/Images/banners/Politique_de_confidentialité.jpg"

function Component({title}) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "POLITIQUE DE CONFIDENTIALITÉ DE VIOLETTE SAUVAGE SAS",
        blocks: [
            {
                title: "1. DÉFINITIONS",
                content: [
                    { text: [<strong>Site – </strong>, " désigne le site Web à l’adresse https://www.Violettesauvage.fr ainsi que les versions mobiles VIOLETTESAUVAGE PWA sur smartphones ou tablettes ;"]},
                    { text: [<strong>Utilisateur – </strong>, " désigne toute personne physique qui, après s’être inscrite sur le Site, est habilitée à utiliser tous les Services du Site, afin de satisfaire ses besoins personnels, liés ou non à une activité professionnelle ;"]},
                    { text: [<strong>Compte – </strong>, " désigne le résultat de l’inscription de l’Utilisateur sur le Site, le compte créé contenant des données personnelles, notamment des informations relatives à l’utilisation des Services sur le Site ;"]},
                    { text: [<strong>Visiteur – </strong>, " désigne toute personne physique non inscrite sur le Site susceptible d’utiliser le Site sans avoir à s’inscrire, conformément aux conditions et à la procédure indiquées dans les Conditions Générales d’Utilisation ;"]},
                    { text: [<strong>Articles – </strong>, " désigne les marchandises/articles dont la circulation n’est pas interdite en vertu du droit français applicable, et qui sont déposé(e)s par l’Utilisateur sur le Catalogue du Site, aux fins de les vendre ou les échanger, d’effectuer une autre Transaction, ou simplement à titre informatif ;"]},
                    { text: [<strong>Services – </strong>, " désigne : l’ensemble des services proposés par VIOLETTE SAUVAGE via le Site ;"]},
                    { text: [<strong>Comptes de Site – </strong>, " désigne les comptes sur Facebook, Google+, Twitter, Pinterest, Youtube et autres réseaux sociaux, où des informations sont fournies à propos du Site et des Services qu’il propose, en partageant les contenus publiés par les Utilisateurs ;"]},
                    { text: [<strong>Cookie  – </strong>, " désigne un petit fichier texte contenant des informations stockées sur l’appareil de toute personne qui visite le Site (par exemple, un ordinateur, une tablette ou un mobile) ;"]},
                    { text: [<strong>Navigateur – </strong>, " désigne le programme conçu pour afficher les sites Web (pages Web) sur le Web ou sur un ordinateur personnel ;"]},
                    { text: [<strong>Données Personnelles – </strong>, " désigne les informations relatives à une personne physique, qui est ou peut être identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs critères qui lui est/sont propre(s) ;"]},
                    { text: [<strong>Administrateur du Site – </strong>, " désigne la personne responsable de l’administration du Site, à savoir VIOLETTE SAUVAGE SAS ;"]},
                    { text: [<strong>Adresse IP – </strong>, " désigne un numéro unique assigné à chaque ordinateur connecté à Internet ; ce numéro est connu comme une adresse de Protocole Internet (IP) ;"]},
                    { text: [<strong>Partenaires – </strong>, " désigne les personnes physiques ou morales qui participent à fournir des Services aux Utilisateurs."]},

                ]
            },
            {
                title: "2. DISPOSITIONS GENERALES",
                content: [
                    { text: [<strong>2.1. </strong>, "Les données à caractère personnel sont collectées et traitées par VIOLETTE SAUVAGE SAS"]},
                    { text: [<strong>VIOLETTE SAUVAGE SAS - </strong>, "VIOLETTE SAUVAGE SAS est une société à responsabilité limitée de droit français au capital social de 8.000 €, dont le siège social est 21 rue de la Convention 94270 Le Kremlin Bicêtre, immatriculée au Registre du Commerce et des Sociétés de Créteil sous le numéro 537 532 764"]},
                    { text: [<strong>2.2. </strong>, "Dans le cadre de l’exploitation du Site, VIOLETTE SAUVAGE SAS est susceptible de collecter des données à caractère personnel relatives aux Utilisateurs et Visiteurs du Site. Ces Données Personnelles sont traitées conformément aux finalités prévues lors de la collecte."]},
                    { text: ["La présente Politique de Confidentialité définit la manière dont VIOLETTE SAUVAGE SAS recueille, utilise, conserve et transmet les Données Personnelles fournies par les Utilisateurs ou les Visiteurs, ou recueillies par VIOLETTE SAUVAGE SAS auprès des Utilisateurs ou des Visiteurs du Site."]},
                    { text: [<strong>2.3. </strong>, "VIOLETTE SAUVAGE SAS s’engage à recueillir et utiliser les Données Personnelles des Visiteurs ou des Utilisateurs dans le strict respect de la loi française sur la protection des données, et en particulier la Loi N°78-17 du 6 janvier 1978."]},
                    { text: [<strong>2.4. </strong>, "En utilisant le Site, Utilisateurs et Visiteurs acceptent que leurs Données Personnelles soient traitées de la manière et aux fins indiquées dans les présentes. Les Utilisateurs sont présumés avoir lu et compris la présente Politique de Confidentialité, après l’avoir acceptée lors de leur inscription. La Politique de Confidentialité est disponible sur le Site à tout moment, et peut être imprimée à partir de ce même Site. Les Utilisateurs seront toujours informés par avance de la mise en œuvre des futurs changements et/ou compléments à la présente Politique de Confidentialité, qui seront envoyés par courriel et publiés sur le Site. Lorsque les Utilisateurs commanderont des Services et/ou se connecteront sur le Site après ces modifications, leur attention sera particulièrement attirée sur l’existence de ces changements. La présente Politique de Confidentialité s’applique de plein droit aux Visiteurs qui utilisent le Site."]},
                    { text: [<strong>2.5. </strong>, "Les Utilisateurs et/ou les Visiteurs doivent noter que le Site est susceptible de contenir des liens vers d’autres sites Web de personnes, de sociétés ou d’organismes, et que VIOLETTE SAUVAGE SAS n’est pas responsable du contenu de ces sites ni de leurs politiques de confidentialité. Ces tiers peuvent recueillir les Données Personnelles des Consommateurs et/ou des Visiteurs lorsqu’ils consultent leurs publicités et/ou cliquent dessus. En outre, avant de soumettre des informations les concernant, les Utilisateurs et/ou les Visiteurs sont invités à examiner les règlements, la politique de confidentialité et les autres documents des sites Web en question."]},
                    { text: [<strong>2.6. </strong>, "Les personnes, qui souhaitent s’inscrire, doivent fournir les informations indiquées ci-après, notamment leur adresse électronique, leur nom d’utilisateur et leur mot de passe. Après l’inscription, le Compte de l’Utilisateur est créé. Un Utilisateur peut modifier, ajouter ou supprimer son Compte d’Utilisateur à tout moment."]},
                    { text: [<strong>2.7. </strong>, "Un Visiteur, qui souhaite contacter un Utilisateur à propos d’un Article, peut envoyer à cet Utilisateur un premier message seulement. Il doit entrer son nom et son adresse électronique dans la fenêtre qui s’affiche, et ces données seront sauvegardées dans la base de données de VIOLETTE SAUVAGE SAS."]},
                    { text: [<strong>2.8. </strong>, "Quel que soit le mode de collecte, VIOLETTE SAUVAGE SAS s’engage à informer préalablement les utilisateurs et Visiteurs des finalités du traitement, du caractère facultatif ou obligatoire des réponses à apporter, des conséquences éventuelles d’un défaut de réponse, des destinataires des données, de l’existence et des modalités d’exercice des droits d’accès, de rectification et d’opposition au traitement des Données Personnelles."]},
                ]
            },
            {
                title: "3. RECUEIL ET STOCKAGE DES DONNÉES PERSONNELLES",
                content: [
                    {
                        title: "3.1. Données concernées",
                        text: [<strong>3.1.a. </strong>, "Les données susceptibles d’être collectées et traitées par VIOLETTE SAUVAGE pour la réalisation des finalités décrites à l’article 4.1.a. sont :"]
                    },
                    { text: ["Au moment de l’inscription ou de la modification du compte : nom, prénom, numéro de téléphone, sexe, adresse, date de naissance, adresse électronique, mot de passe et adresse IP."]},
                    { text: ["Au moment de l’utilisation des Services :"]},
                    { text: ["- Article référencé,"]},
                    { text: ["- Messages personnels,"]},
                    { text: ["- Données de localisation des Utilisateurs."]},
                    { text: [<strong>3.1.b. </strong>, "Données de localisation des Utilisateurs : VIOLETTE SAUVAGE SAS s’autorise à recueillir, stocker et utiliser les données de localisation des Utilisateurs en vue de simplifier leur mise en relation permettre d’identifier les Utilisateurs les plus proches et favoriser les Transactions entre Acheteur et Vendeur. L'adresse exacte de localisation ne sera jamais divulguée, et seule une information approximative sera utilisée. Ces données pourront être collectées par différentes technologies et notamment, sans que ce soit limitatif."]},
                    { text: ["VIOLETTE SAUVAGE SAS pourra collecter la localisation précise du téléphone portable ou de la tablette des Utilisateurs si ces derniers autorisent l’application Violette sauvage à accéder aux services de localisation via le système d’autorisation que le système d’exploitation utilise."]},
                    { text: ["VIOLETTE SAUVAGE SAS pourra également obtenir la localisation des Utilisateurs à partir de leur adresse IP. S’ils ne souhaitent pas que leurs données de localisation soient communiquées les Utilisateurs peuvent aller dans Paramètres > Paramètres de confidentialité puis désactiver l’option en décochant la case « Autoriser les membres à voir mes articles dans la section Autour de moi ». \n"]},
                    { text: [<strong>3.2. </strong>, "VIOLETTE SAUVAGE SAS recueille, stocke et utilise les Données Personnelles des Visiteurs en se basant sur des cookies, comme indiqué ci-dessous."]},
                    { text: [<strong>3.3. </strong>, "VIOLETTE SAUVAGE s’engage à ce que les données collectées soient conservées sous une forme permettant l’identification des Utilisateurs et/ou Visiteurs pendant une durée qui n’excède pas la durée nécessaire aux finalités pour lesquelles les Données Personnelles sont collectées et traitées."]},
                ]
            },
            {
                title: "4. OBJECTIFS DE L’UTILISATION DES DONNÉES PERSONNELLES",
                content: [
                    { text: [<strong>4.1. </strong>, "VIOLETTE SAUVAGE SAS utilise les Données Personnelles aux fins suivantes :"]},
                    { text: [<strong>4.1.a. </strong>, "Pour assurer le bon fonctionnement du Site, de ses Services et de ses fonctionnalités aux Utilisateurs et/ou Visiteurs. Afin d’utiliser certains Services proposés sur le Site, un Utilisateur doit fournir des Données Personnelles indispensables pour pouvoir utiliser des Services spécifiques ; "]},
                    { text: [<strong>4.1.b. </strong>, "A des fins d’authentification ;"]},
                    { text: [<strong>4.1.c. </strong>, "Pour fournir les Services de manière efficace. VIOLETTE SAUVAGE SAS veille à ce que l’utilisation du Site et de ses Services soit efficace. VIOLETTE SAUVAGE SAS s’efforce en outre d’éviter les usurpations d’identité ;"]},
                    { text: [<strong>4.1.d. </strong>, "Pour assurer l’amélioration et le développement permanent des Services, et pour fournir à un Utilisateur et/ou un Visiteur les offres de Service les mieux adaptées à ses besoins ;"]},
                    { text: [<strong>4.1.e. </strong>, "Pour respecter les règlements (lesquels peuvent inclure des obligations légales de conservation des données). VIOLETTE SAUVAGE SAS sera également susceptible d’utiliser les Données Personnelles à des fins légales et/ou règlementaires."]},
                    { text: [<strong>4.1.f. </strong>, "Pour la commercialisation des Services ;"]},
                    { text: [<strong>4.1.g. </strong>, "Pour la commercialisation des produits et services de ses Partenaires."]},

                    { text: [<strong>4.2. </strong>, "Conformément aux dispositions de la Loi Informatique et Libertés du 6 janvier 1978, un Utilisateur et/ou un Visiteur dispose d’un droit d’accès, d’opposition, de rectification et de suppression des Données Personnelles le concernant, qu’il peut exercer dans les conditions définies à l’article 7.1.des présentes."]},
                    { text: ["En fonction de la nature du traitement faisant l’objet de l’opposition, l’Utilisateur ou le Visiteur peut se voir de ce fait empêché d’utiliser tout ou partie des Services."]},
                    { text: [<strong>4.3. </strong>, "Lorsque cela est nécessaire au regard de la réglementation applicable, VIOLETTE SAUVAGE SAS s’engage, selon les cas, à recueillir le consentement exprès, spécifique et préalable et/ou permettre à l’Utilisateur et/ou Visiteur de s’opposer à l’utilisation des données pour certaines finalités. L’Utilisateur et/ou Visiteur dispose à ce titre, du droit de retirer à tout moment son consentement."]},
                    { text: [<strong>4.4. </strong>, "Lorsqu’il administre le Site et diagnostique d’éventuels problèmes, l’Administrateur du Site peut utiliser l’adresse IP des Utilisateurs et/ou des Visiteurs."]},
                    { text: [<strong>4.5. </strong>, "Les Données de la messagerie interne sont utilisées par VIOLETTE SAUVAGE SAS uniquement dans l’objectif de respecter la loi ou à la suite d’une réclamation d’un tiers ou dans le cadre d’une violation par l’Utilisateur des Conditions Générales d’Utilisation."]},
                ]
            },
            {
                title: "5. COMPTES DE SITE",
                content: [
                    { text: [<strong>5.1. </strong>, "Les Comptes de Site de VIOLETTE SAUVAGE SAS sont des liens vers certains sites Web extérieurs au Site, tels que définis au paragraphe suivant, auprès de qui VIOLETTE SAUVAGE SAS détient des comptes et sur lesquels les mêmes informations que sur le Site sont fournies, VIOLETTE SAUVAGE SAS étant à la fois le gérant du Site et des Comptes de Site. Les Conditions Générales d’Utilisation, la présente Politique de Confidentialité, et les autres règles applicables publiées sur le site de VIOLETTE SAUVAGE SAS ont force obligatoire et s’appliquent aux Utilisateurs et aux Visiteurs des sites Web contenant les Comptes de Site de VIOLETTE SAUVAGE SAS. Les Utilisateurs et les Visiteurs doivent lire attentivement les Conditions Générales et les Politiques de Confidentialité de ces sites Web sur lesquelles VIOLETTE SAUVAGE SAS n’a aucun contrôle."]},
                    { text: [<strong>5.2. </strong>, "VIOLETTE SAUVAGE SAS possède des Comptes sur les sites Web suivants : Facebook, Google+, Twitter, Pinterest, Youtube, et d’autres réseaux sociaux."]},
                ]
            },
            {
                title: "6. TRANSFERT DE DONNÉES PERSONNELLES À DES TIERS",
                content: [
                    { text: [<strong>6.1. </strong>, "VIOLETTE SAUVAGE SAS peut être tenue de transférer ou de divulguer des Données Personnelles à des Partenaires et/ou des sociétés du groupe VIOLETTE SAUVAGE SAS qui participent à la fourniture Services, à leur amélioration, au développement de nouveaux services, ou dans le but de les commercialiser."]},
                    { text: [<strong>6.2. </strong>, "VIOLETTE SAUVAGE SAS peut également transférer des Données Personnelles à des Partenaires commerciaux. Dès lors que le transfert nécessite l’accord préalable de l’Utilisateur, VIOLETTE SAUVAGE SAS s’engage à obtenir cet accord. L’Utilisateur peut s’opposer à ce transfert à tout moment en envoyant un courriel à l’Administrateur du Site."]},
                    { text: [<strong>6.3. </strong>, "VIOLETTE SAUVAGE SAS exigera du Partenaire à qui il communique les Données Personnelles qu’il les protège correctement, conformément à la loi applicable en matière de protection des données."]},
                    { text: [<strong>6.4. </strong>, "Dans les cas autres que ceux indiqués ci-dessus, VIOLETTE SAUVAGE SAS ne communiquera des Données Personnelles que si un Utilisateur ou un Visiteur a donné son accord expresse, spécifique et préalable, ou si VIOLETTE SAUVAGE SAS y est tenue à des fins légales ou règlementaires, ou si cela est nécessaire aux fins de poursuites judiciaires, ou dans le cadre de celles-ci, ou afin d’exercer ou de défendre ses droits reconnus par la loi."]},
                ]
            },
            {
                title: "7. DROIT D'ACCÈS AUX DONNÉES PERSONNELLES ET DE MODIFICATION",
                content: [
                    { text: [<strong>7.1. </strong>, "Conformément aux dispositions de la loi Informatique et Libertés  n°78-17 du 6 janvier 1978, les Utilisateurs et les Visiteurs peuvent exercer leur droit d’accès, de rectification, de mise à jour, de verrouillage ou d’effacement  des Données Personnelles."]},
                    { text: ["Les Utilisateurs et/ou Visiteurs disposent également des droits suivants qu’ils peuvent exercer dans les conditions définies au RGPD :"]},
                    { text: ["- droit à la limitation du traitement,"]},
                    { text: ["- droit à l’effacement des Données Personnelles et à l’oubli numérique,"]},
                    { text: ["- droit à la portabilité des données,"]},
                    { text: ["- droit d’introduire une réclamation auprès de l’autorité de contrôle."]},
                    { text: ["Néanmoins, des informations et documents peuvent être conservés pour les besoins de traitement d’une demande ou en application des dispositions spécifiques légales et réglementaires nécessitant la conservation de ces données, dans le respect de la réglementation applicable."]},
                    { text: [<strong>7.2. </strong>, "Afin de mettre en œuvre les droits susmentionnés, les Utilisateurs et/ou les Visiteurs peuvent contacter VIOLETTE SAUVAGE SAS par courriel via l’adresse legal@violettesauvage.frCes droits sont personnels, seule la personne concernée peut en faire la demande ou son représentant légal, sauf exceptions prévues par la loi."]},
                    { text: [<strong>7.3. </strong>, "VIOLETTE SAUVAGE SAS peut avoir besoin de vérifier l’identité de l’Utilisateur ou du Visiteur avant de communiquer une partie de ses Données Personnelles, afin de s’assurer que VIOLETTE SAUVAGE SAS ne divulgue pas des Données Personnelles à la mauvaise personne, ou concernant la mauvaise personne."]},
                    { text: [<strong>7.4. </strong>, "Sans préjudice des dispositions applicables aux archives publiques comportant des données à caractère personnel, les Utilisateurs et/ou Visiteurs ont la possibilité de transmettre à VIOLETTE SAUVAGE SAS des directives particulières définissant la manière dont ils entendent que soient exercés, en cas de décès, les droits mentionnés au présent article grâce au formulaire de contact susvisé."]},
                ]
            },
            {
                title: "8. PROTECTION DES DONNÉES PERSONNELLES",
                content: [
                    { text: [<strong>8.1. </strong>, "VIOLETTE SAUVAGE SAS respecte la loi n°78-17 du 6 janvier 1978 dite loi Informatique et Libertés, en matière de sécurité et de confidentialité des Données Personnelles."]},
                    { text: ["VIOLETTE SAUVAGE SAS garantit que les Données Personnelles fournies par un Utilisateur et/ou un Visiteur sont protégées contre toute activité illégale, telle que l’utilisation abusive des Données Personnelles, la modification ou la destruction des Données Personnelles, l’usurpation d’identité, la fraude. La divulgation de l'identité d'un Utilisateur à un autre n'est dévoilée qu'en cas de procédure entre ces derniers."]},
                    { text: [<strong>8.2. </strong>, "VIOLETTE SAUVAGE SAS utilise des techniques de pointe en matière de stockage et de protection des données, comme les pare-feux et l’authentification, pour sécuriser le site et protéger les Données Personnelles contre la perte, l’utilisation ou la modification non autorisée, l’utilisation abusive ou la divulgation. Les mots de passe de tous les Utilisateurs sont cryptés ; seules les personnes autorisées peuvent avoir accès aux serveurs liés aux Données Personnelles et uniquement au moyen d’un certificat sécurisé, qui est également protégé par un mot de passe. De plus, toutes les pages du Site qui nécessitent un accès aux Données Personnelles, sont créées via le protocole https://."]},
                    { text: [<strong>8.3. </strong>, "Tous les Utilisateurs s’engagent à préserver le caractère confidentiel de leur nom d’utilisateur et de leur mot de passe. Les Utilisateurs et les Visiteurs s’engagent à ne pas divulguer leurs Données Personnelles, ni les Données Personnelles d’un tiers si celles-ci ont été mises à leur disposition, et doivent informer VIOLETTE SAUVAGE SAS immédiatement en cas de violations."]},
                ]
            },
            {
                title: "9. COOKIES",
                content: [
                    { text: [<strong>9.1. </strong>, "Le Site utilise des cookies pour distinguer les Utilisateurs/Visiteurs des autres utilisateurs du Site afin d’aider VIOLETTE SAUVAGE SAS à améliorer leur visite lorsqu’ils naviguent sur le Site."]},
                    { text: ["En naviguant sur le Site, les Utilisateurs/ Visiteurs acceptent l’utilisation des cookies."]},
                    { text: ["L’accès aux informations stockées se fera uniquement dans les cas suivants :"]},
                    { text: ["- Permettre ou faciliter la communication par voie électronique ;"]},
                    { text: ["- Améliorer et personnaliser notamment à des fins commerciales la navigation ;"]},
                    { text: ["- Améliorer les Services ;"]},
                    { text: ["- Lorsque cela s’avère nécessaire à la fourniture des Services."]},
                    { text: ["Ces données ne peuvent être conservées au-delà d’un délai de 13 mois, au-delà elles sont supprimées ou anonymisées."]},
                    { text: ["Comme pour les autres Données Personnelles, les Utilisateurs et les Visiteurs peuvent exercer leurs droits d’accès dans les conditions définies à l’article VII des présentes."]},
                    { text: ["Lorsque le navigateur le permet, les cookies peuvent être désactivés en suivant la procédure indiquée par le navigateur."]},
                    { text: ["La désactivation des cookies via le navigateur peut avoir pour conséquence de ralenti et/ou perturber l’accès au Site."]},
                ]
            },
            {
                title: "10. DISPOSITIONS FINALES",
                content: [
                    { text: [<strong>10.1. </strong>, "Pour toutes questions concernant la présente Politique de Confidentialité ou le Site, contactez VIOLETTE SAUVAGE à l’adresse suivante :", <br />,
                        "violettesauvage.fr", <br />,
                        "VIOLETTE SAUVAGE SAS", <br />,
                        "21 rue de la Convention 94270 Le Kremlin Bicêtre"]},
                    { text: [<strong>10.2. </strong>, "La présente Politique de Confidentialité est valable à compter de 27 Mars 2018. L’Utilisateur et/ou le Visiteur sera informé des changements apportés à la Politique de Confidentialité sur le Site, et les Utilisateurs acceptent également de recevoir un courriel à ce sujet."]},
                    { text: ["VIOLETTE SAUVAGE SAS se réserve le droit de modifier ou de mettre à jour à tout moment la présente politique de confidentialité. A ce titre, l’Utilisateur et/ou le Visiteur est invité à consulter régulièrement les présentes, toute navigation au sein du Site emportant acceptation de la nouvelle version."]},
                ]
            }
        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"Politique de confidentialité | Vide dressing Violette Sauvage"}
                      metaDesc={"Retrouvez toute la politique de confidentialité & traitement de vos données personnelles sur le site Violette Sauvage."}
                      arianeCurrentName={"Politique de confidentialité"} />
    );
}

export default Component
