import React from 'react';
import { useDispatch } from 'react-redux'
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";

export function useLivePromotionList(onPromoCreated, onPromoCreationError) {
    const [livePromotionList, setLivePromotionList] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [pagination, setPagination] = React.useState({first: 1, last: 1});
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        refresh();
    }, [page]);

    let refresh = () => {
        setIsLoading(true);
        let params = {itemsPerPage: 10, page};
        axios.get(
            getApiUrl('live_promotions', params),
            {headers: setDefaultHeaders()}
        ).then((response) => {
            setLivePromotionList(response.data['hydra:member']);
            if (response.data['hydra:view']) {
                const first = new URLSearchParams(response.data['hydra:view']["hydra:first"]).get("page");
                const last = new URLSearchParams(response.data['hydra:view']["hydra:last"]).get("page");
                console.log(new URLSearchParams(response.data['hydra:view']["hydra:first"]).get("page"), new URLSearchParams(response.data['hydra:view']["hydra:first"]), response.data['hydra:view']["hydra:first"], first);
                setPagination({
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1,
                })
            }
            setIsLoading(false);
        }).catch((error) => {
            console.error('Hooks/Live.useLivePromotionList: ', error);
            setIsLoading(false);
        });
    }

    let createPromotion = (code, value) => {
        if (!code) {
            onPromoCreationError("Vous devez spécifier un code.")
        } else if (!value) {
            onPromoCreationError("Vous devez spécifier une réduction.")
        } else if (Number.isNaN(parseFloat(value))) {
            onPromoCreationError("La réduction n'est pas un nombre valide.")
        } else if (parseFloat(value) <= 0) {
            onPromoCreationError("La réduction doit être supérieure à 0€.")
        } else {
            setIsLoading(true);
            let params = {code, value: parseFloat(value)};
            axios.post(
                getApiUrl('live_promotions'),
                params,
                {headers: setDefaultHeaders()}
            ).then((response) => {
                onPromoCreated();
                if (page !== 1) {
                    setPage(1);
                } else {
                    refresh();
                }
            }).catch((error) => {
                console.error('Hooks/Live.useLivePromotionList: ', error);
                setIsLoading(false);

                if (error && error.response && error.response.data && error.response.data.violations && error.response.data.violations.length > 0) {
                    onPromoCreationError(error.response.data.violations[0].message);
                } else {
                    onPromoCreationError("Une erreur inconnue a eu lieu");
                }
            });
        }
    }

    return [livePromotionList, pagination, page, setPage, createPromotion, refresh, isLoading];
}
