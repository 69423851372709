import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    instagramBlock: {
        marginLeft: Constants.GET_SIZE(38),
        marginRight: Constants.GET_SIZE(38),
        marginBottom: Constants.GET_SIZE(120),
        textAlign: "center"
    },
    instagramTitle: {
        fontSize: Constants.FONT_SIZE_BIG,
        ...GlobalStyle.uppercaseText,
        marginBottom: Constants.GET_SIZE(45),
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },
    instagramFeedWrapper: {
        ...GlobalStyle.row,
        justifyContent: "space-between"
    },
    instagramFeedItem: {
        width: Constants.GET_SIZE(268),
        height: Constants.GET_SIZE(268),
    }
}));
