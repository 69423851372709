import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        maxWidth: 255,
        maxHeight: 211,
        height: 211,
        marginBottom: 30,
        [theme.breakpoints.down('xs')]: {
            margin: 'auto',
            marginBottom: 15
        }
    },
    cardActionArea: {
        textAlign: 'left',
        position: "relative",
        padding: 0
    },
    image: {
        width: 255,
        height: 150,
        position: "relative",
        float: "left"
    },
    shopPicture: {
        width: 25,
        height: 25,
        margin: "auto",
        borderRadius: "50%"
    },
    loaderWrapper: {
        width: 255,
        height: 150,
        margin: "auto",
        backgroundColor: Constants.BACKGROUND_GRAY
    },
    loaderSubWrapper: {
        left: "50%",
        top: "50%",
        position: "relative",
        transform: "translateY(-50%) translateX(-50%)",
        width: "fit-content"
    },
    infoLine: {
        paddingTop: 8,
        paddingBottom: 5,
    },
    nbProducts: {
        color: Constants.FONT_GRAY,
        lineHeight: 1
    },
    shopName: {
        lineHeight: 1
    },
    '@keyframes blink': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0 },
        '100%': { opacity: 1 }
    },
    liveNow: {
        animationName: '$blink',
        animationDuration: '2s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        position: "absolute",
        top: 10,
        left: 10,
        padding: 4,
        fontWeight: "bold",
        textTransform: "uppercase",
        backgroundColor: Constants.WHITE,
        fontSize: Constants.FONT_SIZE_SMALL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
        }
    },
    picturesWrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        width: 255
    },
    picturesOverlay: {
        position: "absolute",
        bottom: 10,
        left: 0,
        right: 0
    },
    dotIcon: {
        color: Constants.FONT_DARK_GRAY,
        fontSize: 14,
        cursor: "pointer"
    },
    dotIconSelected: {
        color: Constants.BLACK
    },
}));
