import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "../GlobalStyle";
import * as Constants from "../Constants"


export default makeStyles((theme) => ({
    modalTitle: {
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(30),
        width: "100%",
        textAlign: "center",

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    pickerWrapper: {
        width: "100%",
        marginBottom: 20,
    },
    picker: {
        width: "100%",
    },
    pickerLabel: {
        padding: '0 10px'
    },
    productFormControl: {
        width: '100%'
    },
    divider: {
        height: "100%",
        backgroundColor: Constants.BORDER_GRAY,
        width: 1,
    },
    checkboxLabel: {

        color: Constants.FONT_GRAY
    },
    checkBox: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.WHITE,
    },
    inputIconWrapper: {
        width: Constants.GET_SIZE(68),
    },
    button: {
        marginBottom: 0,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
        }
    },
    modalContent: {
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "white",
        padding: Constants.GET_SIZE(30),
        // justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
    },
    bottomModal: {
        justifyContent: "flex-end",
        margin: 0
    },
    productTitle: {
        marginBottom: 10
    }
}));
