import React from "react";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBox from "@material-ui/core/Checkbox";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


function Component({size, containerStyle, containerClass, title, checkedColor, uncheckedColor, checked, checkedIcon, onPress, rightIcon, fullWidth, error, disabled}) {
    return <FormGroup>
        <FormControlLabel
            control={
                <CheckBox
                    color={"primary"}
                    icon={<CheckBoxOutlineBlankIcon fontSize={size} />}
                    checkedIcon={<CheckBoxIcon fontSize={size} />}
                    checked={checked}
                    disabled={disabled}
                    onChange={event => onPress(event.target.checked)}
                />
            }
            label={title}
            labelPlacement={rightIcon ? "start" : "end"}
            style={fullWidth ? {width: "100%"} : {}} />
        {error && <FormHelperText error style={{marginBottom: 20}}>{error.message}</FormHelperText>}
    </FormGroup>
}

export default Component;
