import React from "react";
import {DropzoneArea} from 'material-ui-dropzone';
import {getImageUrl} from "../../Utils/ImageUtils";
import Text from "../../Screens/Component/FontText";
import * as Constants from "../../Style/Constants";

export default function FileField({register, errors, watch, setValue, name, namePrefix, label, required, isFetchingEntity}) {
    let rules = {};

    if (required) {
        rules['required'] = "Ce champ est obligatoire";
    }

    React.useEffect(() => {
        register({name: namePrefix + name});
    }, [register]);

    let picture = watch(namePrefix + name);

    let handleChange = (files) => {
        if (files && files.length > 0 && files[0].path) {
            let id = -1;
            if (picture && picture.contentUrl && picture.id) {
                if (picture.contentUrl.slice(1) === files[0].name) {
                    id = picture.id;
                }
            }

            return setValue(namePrefix + name, {id: id, file: files[0]})
        }
    }

    return (
        <div style={{marginBottom: 20}}>
            <Text style={{marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, width: '100%' }}>{label} :</Text>
            <DropzoneArea
                acceptedFiles={['image/*', 'application/pdf']}
                filesLimit={1}
                maxFileSize={6000000}
                dropzoneText={"Veuillez glisser un fichier ou cliquer"}
                onChange={handleChange}
                initialFiles={picture && picture.contentUrl ? [getImageUrl(picture.contentUrl, "small")] : []}
                />
        </div>
    );
}
