import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    modalContent: {
        backgroundColor: "white",
        padding: Constants.GET_SIZE(22),
        // justifyContent: "center",
        alignItems: "flex-start"
    },
    title: {
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(10),
        width: "100%",
        textAlign: "center",

        fontSize: Constants.FONT_SIZE_BIGGER,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
        }
    },
    autocompleteWrapper: {
        display: "flex",
        flexDirection: "row",
        position: "relative",
        zIndex: 1001,
        marginBottom: Constants.GET_SIZE(15),
        alignItems: "center"
    },
    autocompleteOverlay: {
        backgroundColor: "white",
        position: "absolute",
        top: 60,
        left: 0,
        width: "100%",
        borderWidth: 1,
        borderColor: "lightgrey",
        borderStyle: "solid"
    },
    inputWrapper: {
        display: "flex",
        flexDirection: "row",
        borderColor: "#E0E3E5",
        borderWidth: 1,
        paddingLeft: Constants.GET_SIZE(5),
        paddingRight: Constants.GET_SIZE(5),
        alignItems: 'center',
        // height: Constants.GET_SIZE(30),
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    input: {
        width: "100%",
        // height: Constants.GET_SIZE(20),
    },
    inputLabel: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    button: {
        backgroundColor: Constants.PINK,
        padding: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(30),
        paddingRight: Constants.GET_SIZE(30),
        width: "min-content"
    },
    buttonLabel: {

        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        fontWeight: "bold"
    },
    smallButton: {
        backgroundColor: Constants.PINK,
        padding: Constants.GET_SIZE(10),
        marginRight: Constants.GET_SIZE(10),
        height: "min-content"
    },
    smallButtonLabel: {

        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        fontWeight: "bold"
    },
    markerWrapper: {
        width: Constants.GET_SIZE(150)
    },
    markerText1: {

        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(15)
    },
    markerText2: {

        color: "grey"
    },
    markerText3: {

        color: "grey",
        marginBottom: Constants.GET_SIZE(15)
    },

    relayList: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: 500,
            overflow: "auto"
        }
    },
    relayDivider: {
        height: 1,
        width: "100%",
        backgroundColor: Constants.BORDER_GRAY,
        marginBottom: Constants.GET_SIZE(10),
        marginTop: Constants.GET_SIZE(10),
    },
    relayWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    relayText1: {
        fontWeight: "bold",

        fontSize: Constants.FONT_SIZE_SMALL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
        }
    },
    relayText2: {
        color: Constants.FONT_GRAY,

        fontSize: Constants.FONT_SIZE_SMALL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
        }
    },

    contentWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },

    bottomBar: {
        marginTop: 10
    }

}));
