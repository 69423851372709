import React from "react";
import { TouchableOpacity, TextInput } from "react-native-web";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MessagingStyle"
import * as Constants from "../../Style/Constants"
import Paginator from "../Front/Component/Paginator";
import Image from "react-native-web/dist/exports/Image";
import Moment from "moment";
import UserIcon from "../../Assets/Images/user_icon.svg"
import clsx from "clsx";


function Component({navigation, history, token, user, threads, pagination, fetchThreads}) {
    let Styles = StylesFunc();
    let [page, setPage] = React.useState(1);
    let [search, setSearch] = React.useState("");

    React.useEffect(() => {
        fetchThreads(search, page, 5);
    }, [token, page]);

    React.useEffect(() => {
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    let startSearch = () => {
        fetchThreads(search, page, 5)
    };

    let _getThreadBlock = (thread) => {
        if (user) {
            let otherPerson = thread.author.id === user.id ? thread.recipient : thread.author;
            let isRead = true;
            if (thread.author.id === user.id && !thread.lastMessage.isAuthor && !thread.lastMessage.readAt) { // We didn't wrote the last message and didn't read it either
                isRead = false;
            } else if (thread.author.id !== user.id && thread.lastMessage.isAuthor && !thread.lastMessage.readAt) { // We didn't wrote the last message and didn't read it either
                isRead = false;
            }

            return <TouchableOpacity className={clsx(Styles.threadWrapper)} onPress={() => history.push('/message/' + thread.id)} style={!isRead ? {borderColor: Constants.PINK} : {}}>
                <Image source={UserIcon} className={Styles.authorImage}/>
                <Box className={Styles.textWrapper}>
                    <Text className={Styles.authorName}>{otherPerson.firstname && otherPerson.name ? otherPerson.firstname + " " + otherPerson.name : otherPerson.username}</Text>
                    <Text className={Styles.messagePreview} style={!isRead ? {fontWeight: "bold"} : {}}>{thread.lastMessage.content}</Text>
                </Box>
                <Box className={Styles.dateWrapper}>
                    <Text className={Styles.messageDate}>{Moment(thread.lastMessage.createdAt).format("DD/MM/YY")}</Text>
                    {thread.dispute && <Text className={Styles.disputeTag}>LITIGE</Text>}
                </Box>
            </TouchableOpacity>
        }
    };

    let nbUnreadMessages = user ? user.nbUnreadMessages : 0;

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Messagerie</Text>

            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={"MESSAGE_THREADS"} wrapperStyle={{marginTop: Constants.GET_SIZE(40)}} />
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    <Box className={Styles.threadsHeader}>
                        <Box className={Styles.threadHeaderUnreadMessagesWrapper}>
                            <Text className={Styles.threadHeaderUnreadMessagesLabel}>Messagerie: </Text>
                            <Text className={Styles.threadHeaderUnreadMessages}>{nbUnreadMessages} message{nbUnreadMessages > 1 ? "s" : ""} non lu{nbUnreadMessages > 1 ? "s" : ""}</Text>
                        </Box>

                        <Box className={Styles.threadsHeaderInputWrapper}>
                            <FontAwesome name="search" style={{fontSize: Constants.FONT_SIZE_NORMAL}} />
                            <TextInput
                                className={Styles.threadsHeaderInput}
                                placeholder="Recherche"
                                onSubmitEditing={startSearch}
                                onChangeText={setSearch}
                            />
                        </Box>

                        <Text className={Styles.subTitle}>Mes conversations :</Text>
                        <Text className={Styles.subTitleDesc}>Cliquez sur une des conversations ci-dessous pour afficher la discussion</Text>
                    </Box>
                    <Box className={Styles.threadsWrapper}>
                        {threads.map(_getThreadBlock)}
                    </Box>
                </Box>
            </Grid>
            {pagination && <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE}/>}
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        threads: state.message.threadsList,
        pagination: state.message.threadsListPagination
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchThreads: (search, page, itemsPerPage) => {
            dispatch({type: 'FETCH_MESSAGE_THREADS_REQUESTED', search, page, itemsPerPage})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
