import React from 'react';
import { connect } from 'react-redux'

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";


import Text from "../../Component/FontText";

import ConfirmationModalStylesFunc from "../../../Style/ConfirmationModalStyle"
import TextInputIcon from "./TextInputIcon";
import CustomCheckbox from "../../Component/CustomCheckbox";
import * as Constants from "../../../Style/Constants";


function Component ({visible, setVisible, message, onConfirm, onCancel}) {
    let ConfirmationModalStyles = ConfirmationModalStylesFunc();
    let [ignore, setIgnore] = React.useState(false);

    let _renderButtons = () => {
        if (!onCancel) {
            return (
                <Grid container justify={"center"}>
                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={() => onConfirm(ignore)}>
                        J'ai compris
                    </Button>
                </Grid>
            );
        } else {
            return (
                <Grid container justify={"space-between"}>
                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={onConfirm}>
                        Confirmer
                    </Button>
                    <Button disableElevation size={"small"} variant={"contained"} color={"primary"}  onClick={onCancel}>
                        Annuler
                    </Button>
                </Grid>
            );
        }
    }

    let _renderModalContent = () => (
        <Box className={ConfirmationModalStyles.modalContent}>
            <Text className={ConfirmationModalStyles.modalTitle}>En mode mobile, n'hésitez pas à enregistrer l'application sur votre téléphone.</Text>
            <Text className={ConfirmationModalStyles.modalTitle}>Pour cela, il vous suffit de cliquer sur l'icône de téléchargement de votre navigateur puis de choisir "Sur l'écran d'accueil".</Text>

            <Grid container justify={"center"} style={{marginBottom: 10}}>
                <CustomCheckbox
                    containerClass={ConfirmationModalStyles.checkBox}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={ConfirmationModalStyles.checkboxLabel}>Ne plus afficher ce message</Text>}
                    checked={ignore} onPress={() => setIgnore(!ignore)}
                    size={Constants.CHECKBOX_SIZE_BIG} />
                {_renderButtons()}
            </Grid>
        </Box>
    );

    return (
        <Dialog open={visible} fullWidth>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;
