import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import {
    CreateVideDressing,
    DeleteVideDressing, EditVideDressing,
    FetchVideDressing,
    FetchVideDressings,
    SubscribeToVideDressing,
    UnsubscribeFromVideDressing
} from '../Endpoint/VideDressing'
import { handleError } from "../../Utils/SagaUtils";
import {SavePicture} from "../Endpoint/Picture";

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* fetchVideDressings(action) {
    try {
        const videDressings = yield call(FetchVideDressings);
        yield put({type: "FETCH_VIDE_DRESSINGS_SUCCEEDED", videDressings: videDressings.data["hydra:member"]});
    } catch (e) {
        yield put(handleError("FETCH_VIDE_DRESSINGS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* fetchVideDressing(action) {
    try {
        const videDressing = yield call(FetchVideDressing, action.payload.id);
        yield put({type: "FETCH_VIDE_DRESSING_SUCCEEDED", videDressing: videDressing.data});
    } catch (e) {
        yield put(handleError("FETCH_VIDE_DRESSING_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* saveVideDressing(action) {
    try {
        let result;
        if (action.videDressing.picture && !action.videDressing.picture.id) {
            result = yield call(SavePicture, action.videDressing.picture);
            if (result && result.data) {
                action.videDressing.picture = "/api/media_objects/" + result.data["id"];
            }
        } else {
            delete action.videDressing.picture;
        }

        if (action.videDressing.id) {
            result = yield call(EditVideDressing, action.videDressing);
        } else {
            result = yield call(CreateVideDressing, action.videDressing);
        }
        yield put({type: "SAVE_VIDE_DRESSING_SUCCEEDED", videDressing: result.data});
    } catch (e) {
        yield put(handleError("SAVE_VIDE_DRESSING_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* deleteVideDressing(action) {
    try {
        const videDressing = yield call(DeleteVideDressing, action.id);
        yield put({type: "DELETE_VIDE_DRESSING_SUCCEEDED", id: action.id});
    } catch (e) {
        yield put(handleError("DELETE_VIDE_DRESSING_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* subscribeToVideDressing(action) {
    try {
        let result;
        if (action.subscribe === true) {
            result = yield call(SubscribeToVideDressing, action.id);
        } else {
            result = yield call(UnsubscribeFromVideDressing, action.id);
        }
        yield put({type: "SUBSCRIBE_TO_VIDE_DRESSING_SUCCEEDED", videDressing: result.data});
    } catch (e) {
        yield put(handleError("SUBSCRIBE_TO_VIDE_DRESSING_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* mySaga() {
    yield takeLatest("FETCH_VIDE_DRESSINGS_REQUESTED", fetchVideDressings);
    yield takeLatest("FETCH_VIDE_DRESSING_REQUESTED", fetchVideDressing);
    yield takeLatest("DELETE_VIDE_DRESSING_REQUESTED", deleteVideDressing);
    yield takeLatest("SUBSCRIBE_TO_VIDE_DRESSING_REQUESTED", subscribeToVideDressing);
    yield takeLatest("SAVE_VIDE_DRESSING_REQUESTED", saveVideDressing);
}

export default mySaga;