import {makeStyles} from "@material-ui/core/styles";

import * as Constants from "../Constants"


export default makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingTop: Constants.GET_SIZE(50),
        paddingBottom: Constants.GET_SIZE(50),
        [theme.breakpoints.down('xs')]: {
            alignItems: "center",
            paddingTop: 30,
            paddingBottom: 30,
        }
    }
}));
