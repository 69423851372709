import React from "react";
import { Image} from "react-native";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Text from "../Component/FontText";
import { connect } from 'react-redux'
import VideDressingHeader from "../../Assets/Images/vide_dressing_header.png"
import { useParams } from "react-router-dom";
import Moment from 'moment';

import VideDressingStylesFunc from '../../Style/VideDressingStyle'
import * as Constants from '../../Style/Constants'
import ShopSquare from "./Component/ShopSquare";
import BecomeSellerBlock from "./Component/BecomeSellerBlock";
import InstagramBlock from "./Component/InstagramBlock";
import MetaTags from "react-meta-tags";


function VideDressing({navigation, loadVideDressing, videDressing}) {
    let VideDressingStyles = VideDressingStylesFunc();
    let { id } = useParams();

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title += " - Vide Dressings"
    }, []);

    // On mount only
    React.useEffect(() => {
        loadVideDressing(id);
    }, []);

    let start, end, differentDays;
    let shopsDom = [];
    if (videDressing) {
        for (const shop of videDressing.shops) {
            shopsDom.push(<Grid item xs={12} sm><ShopSquare shop={shop} wrapperClass={VideDressingStyles.shopWrapper}/></Grid>);
        }

        start = Moment(videDressing.datetime).locale('fr');
        end = Moment(videDressing.endDatetime).locale('fr');
        differentDays = videDressing.endDatetime && start.format('DD MMMM YYYY') !== end.format('DD MMMM YYYY');
    }


    return (
        <Box>
            <MetaTags>
                <title>Participez à un vide-dressing | Violette Sauvage</title>
                <meta name="description"
                      content={"Participez à un vide dressing Violette Sauvage, dans les grandes villes de France : Paris, Lille, Lyon, Nantes, Bordeaux..."}/>
            </MetaTags>
            <Box className={VideDressingStyles.contactHeaderWrapper}>
                <Image className={VideDressingStyles.contactHeaderImage} source={VideDressingHeader} />
                <Text className={VideDressingStyles.contactHeaderText} component={"h1"}>Les boutiques présentes{"\n"}au vide dressing</Text>
                <Box className={VideDressingStyles.contactHeaderPinkRectangle} />
            </Box>
            {videDressing &&
                <Box className={VideDressingStyles.container}>
                    <Text className={VideDressingStyles.nameText} component={"h2"}>{videDressing.name}</Text>
                    {differentDays && <Text className={VideDressingStyles.dateText}>Date : du {start.format('DD MMMM YYYY')} au {end.format('DD MMMM YYYY')}</Text>}
                    {!differentDays && <Text className={VideDressingStyles.dateText}>Date : le {start.format('DD MMMM YYYY')}</Text>}
                    {videDressing.endDatetime && <Text className={VideDressingStyles.addressText}>DE {start.format('HH')}H{start.format('mm')} à {end.format('HH')}H{end.format('mm')} - LIEU : {videDressing.address.split("\n")[0]}</Text>}
                    {!videDressing.endDatetime && <Text className={VideDressingStyles.addressText}>{start.format('HH')}H{start.format('mm')} - LIEU : {videDressing.address.split("\n")[0]}</Text>}
                </Box>
            }
            <Grid container className={VideDressingStyles.shopsWrapper} justify={"flex-start"}>
                {shopsDom}
            </Grid>

            <BecomeSellerBlock />
            <InstagramBlock />
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        videDressing: state.videDressing.videDressing
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadVideDressing: (id) => {
            dispatch({type: 'FETCH_VIDE_DRESSING_REQUESTED', payload: {id}})
        }
    }
};

const VisibleVideDressing = connect(
    mapStateToProps,
    mapDispatchToProps
)(VideDressing);

export default VisibleVideDressing

