import React from "react";

import Button from "@material-ui/core/Button"

import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";


function Component({shop, subscribeToShop, wrapperStyle, variant}) {
    return (
        <Button size={"small"} variant={variant} color={"primary"} onClick={() => {subscribeToShop(shop)}} style={wrapperStyle} disableElevation>
            {!shop || !shop.isSubscribed ? "Suivre" : "Ne plus suivre"}
        </Button>
    );
}

const mapStateToProps = state => {
    return {

    }
};

const mapDispatchToProps = dispatch => {
    return {
        subscribeToShop: (shop) => {
            dispatch({type: 'SUBSCRIBE_TO_SHOP_REQUESTED', id: shop.id, subscribe: !shop.isSubscribed})
        }
    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));