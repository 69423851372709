import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle";


export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        subcontentWrapper: {
            flex: 1,
            marginLeft: 10,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0
            }
        },
        grayBackground: {
            backgroundColor: Constants.BACKGROUND_GRAY,
            padding: 20
        },

        tabsWrapper: {
            ...GlobalStyle.rowCenteredHW,
            marginBottom: 40,
        },
        tabButton: {
            ...GlobalStyle.button,
            backgroundColor: Constants.WHITE,
            borderColor: Constants.PINK,
            borderWidth: 1,

        },
        tabButtonLabel: {
            ...GlobalStyle.buttonLabel,
            color: Constants.PINK
        },
        tabButtonActive: {
            borderColor: Constants.PINK,
            borderWidth: 1,
        },
        tabButtonLabelActive: {
            color: Constants.WHITE
        },
        livesWrapper: {
            // ...GlobalStyle.rowCentered,
            // flexWrap: "wrap",
            width: "100%",
            marginBottom: Constants.GET_SIZE(25),
            alignItems: "stretch",
            padding: 50
        },
        liveWrapper: {
            backgroundColor: Constants.WHITE,
            marginBottom: 10,
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 25,
            paddingRight: 25
        },
        image: {
            width: 50,
            height: 50,
            marginRight: 15
        },
        miniWrapper: {
            width: 'max-content',

        },
        text: {
            fontSize: Constants.FONT_SIZE_NORMAL,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
            }
        },
        subText: {
            color: Constants.FONT_GRAY,
            fontSize: Constants.FONT_SIZE_NORMAL,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
            }
        },
        subTitle: {
            textAlign: "center",
            fontSize: Constants.FONT_SIZE_BIG,
            marginTop: 20,
            marginBottom: 10,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_BIG_MOBILE
            }
        },
        verticalCenter: {
            display: "flex",
            alignItems: "center"
        },
        imageLine: {
            marginBottom: 20,
            "& > div:last-child": {
                paddingLeft: 20
            }
        }
    }
});
