import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "../GlobalStyle";
import * as Constants from "../Constants"


export default makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        paddingTop: Constants.GET_SIZE(20),
        paddingBottom: Constants.GET_SIZE(50),
        paddingLeft: Constants.GET_SIZE(50),
        marginRight: Constants.GET_SIZE(33),
        width: Constants.GET_SIZE(333),
        minWidth: Constants.GET_SIZE(333),
        backgroundColor: Constants.GRAY_FOOTER,
        height: "min-content",
        boxSizing: "border-box"
    },
    title: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }

    },
    categoryWrapper: {
        marginTop: Constants.GET_SIZE(30)
    },
    categoryAdminWrapper: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    link: {

        color: Constants.WHITE
    },
    linksWrapper: {
        marginLeft: Constants.GET_SIZE(15),
        marginTop: Constants.GET_SIZE(20),
    },
    selected: {
        fontWeight: "bold",
        color: Constants.PINK
    }
}));
