import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    // Global
    container: {
        backgroundColor: Constants.WHITE,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
        marginBottom: 70,
        paddingLeft: 20,
        paddingRight: 20,
        maxWidth: 1024,
        margin: "auto",

    },

    // Header
    contactHeaderWrapper: {
        position: "relative"
    },
    contactHeaderImage: {
        width: "100%",
        height: Constants.GET_SIZE(250),
    },
    contactHeaderText: {
        fontSize: Constants.FONT_SIZE_BIGGER,
        textTransform: 'uppercase',
        position: "absolute",
        color: Constants.WHITE,
        fontWeight: "bolder",
        top: Constants.GET_SIZE(188),
        left: Constants.GET_SIZE(75),
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER_MOBILE,
        }
    },
    contactHeaderPinkRectangle: {
        position: "absolute",
        backgroundColor: "#F8ADB1",
        bottom: 0,
        left: Constants.GET_SIZE(75),
        width: Constants.GET_SIZE(205),
        height: Constants.GET_SIZE(15),
    },

    // Other
    button: {
        backgroundColor: '#F8ADB1',
        padding: Constants.GET_SIZE(20),
        paddingLeft: Constants.GET_SIZE(80),
        paddingRight: Constants.GET_SIZE(80),
        marginTop: Constants.GET_SIZE(50),
    },
    buttonLabel: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        fontWeight: "bold"
    },
    inputWrapper: {
        width: Constants.GET_SIZE(1012),
    },
    arianeText: {
        ...GlobalStyle.uppercaseText
    },
}));
