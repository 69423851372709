import React from "react";
import clsx from "clsx";
import {TouchableOpacity} from "react-native-web";

import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Moment from "moment";
import { FontAwesome } from '../../FontAwesome';

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/Back/UserListStyle"
import * as Constants from "../../Style/Constants";
import TextInput from "../Component/FontInput";
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import { getApiUrl } from "../../Utils/QueryUtils";
import {LINKS} from "../Component/InternalLink";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";


const shopTypes = [
    'Particulier / Professionnel de la seconde main',
    'Créateur',
];

function Component({history, navigation, token, userPagination, productPagination, shopPagination, disputePagination, usersList, productsList, shopsList, disputesList, fetchUsers, searchProducts, searchShops, searchDisputes, deleteUser, deleteProduct, deleteShop, deleteDispute}) {
    let Styles = StylesFunc();
    let tab, setTab, page, setPage, search, setSearch, type, setType;
    if (history.location.state && history.location.state.listState) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [tab, setTab] = React.useState(history.location.state.listState.tab);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [page, setPage] = React.useState(history.location.state.listState.page);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [search, setSearch] = React.useState(history.location.state.listState.search);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [type, setType] = React.useState(history.location.state.listState.type ? history.location.state.listState.type : -1);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [tab, setTab] = React.useState("USER");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [page, setPage] = React.useState(1);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [search, setSearch] = React.useState("");
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [type, setType] = React.useState(-1);
    }


    let [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
    let [deleteId, setDeleteId] = React.useState(null);

    let pagination = (tab === "USER" ? userPagination : tab === "PRODUCT" ? productPagination : tab === "SHOP" ? shopPagination : disputePagination)

    React.useEffect(() => {
        startSearch()
    }, [token, page, tab, history.location.state, type]);

    React.useEffect(() => {
        console.log(pagination, tab);
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    let startSearch = () => {
        if (tab === "USER") {
            fetchUsers(search, 10, page);
        } else if (tab === "PRODUCT") {
            searchProducts(search, 10, page);
        } else if (tab === "SHOP") {
            searchShops(search, 10, page);
        } else {
            searchDisputes(search, 10, page, type);
        }
    };

    let handleClickEditUser = (user) => {
        history.push("/admin/editer_utilisateur/" + user.id, {listState: {tab, page, search}})
    };

    let handleClickDeleteUser = (user) => {
        setDeleteId(user.id);
        setDeleteModalVisible(true);
    };

    let handleClickEditProduct = (product) => {
        history.push("/admin/editer_produit/" + product.id)
    };

    let handleClickDeleteProduct = (product) => {
        setDeleteId(product.id);
        setDeleteModalVisible(true);
    };

    let handleClickEditShop = (shop) => {
        history.push("/admin/editer_boutique/" + shop.id)
    };

    let handleClickDeleteShop = (shop) => {
        setDeleteId(shop.id);
        setDeleteModalVisible(true);
    };

    let handleClickEditDispute = (dispute) => {
        history.push("/admin/litige/" + dispute.id)
    };

    let handleClickDeleteDispute = (dispute) => {
        setDeleteId(dispute.id);
        setDeleteModalVisible(true);
    };

    let _getTabs = () => {
        return <Box className={Styles.tabRow}>
            <TouchableOpacity className={clsx(Styles.tabButton, {[Styles.tabButtonActive]: tab === "USER"})} onPress={() => setTab("USER")}>
                <Text className={clsx(Styles.tabText, {[Styles.tabTextActive]: tab === "USER"})}>Utilisateurs</Text>
            </TouchableOpacity>
            <TouchableOpacity className={clsx(Styles.tabButton, {[Styles.tabButtonActive]: tab === "PRODUCT"})} onPress={() => setTab("PRODUCT")}>
                <Text className={clsx(Styles.tabText, {[Styles.tabTextActive]: tab === "PRODUCT"})}>Produits</Text>
            </TouchableOpacity>
            <TouchableOpacity className={clsx(Styles.tabButton, {[Styles.tabButtonActive]: tab === "SHOP"})} onPress={() => setTab("SHOP")}>
                <Text className={clsx(Styles.tabText, {[Styles.tabTextActive]: tab === "SHOP"})}>Boutiques</Text>
            </TouchableOpacity>
            <TouchableOpacity className={clsx(Styles.tabButton, {[Styles.tabButtonActive]: tab === "DISPUTE"})} onPress={() => setTab("DISPUTE")}>
                <Text className={clsx(Styles.tabText, {[Styles.tabTextActive]: tab === "DISPUTE"})}>Litiges</Text>
            </TouchableOpacity>
            <Box className={Styles.tabInputWrapper}>
                <FontAwesome name="search" style={{fontSize: Constants.FONT_SIZE_NORMAL}} />
                <TextInput
                    className={Styles.tabInput}
                    placeholder="Recherche"
                    onPressEnter={startSearch}
                    onChange={ev => setSearch(ev.target.value)}
                    value={search}
                />
            </Box>
            <TouchableOpacity className={clsx(Styles.tabButton, Styles.tabButtonActive)} accessibilityRole={"link"} href={getApiUrl((tab === "USER" ? "users" : tab === "PRODUCT" ? "products" : tab === "SHOP" ? "shops" : tab === "DISPUTE" ? "disputes": null) + "/export.csv")} target={'_blank'}>
                <Text className={clsx(Styles.tabText, Styles.tabTextActive)}>Export</Text>
            </TouchableOpacity>
        </Box>
    };

    let _getUserHeaderRow = () => {
        return <Box className={Styles.headerRow}>
            <Text className={clsx(Styles.userDataCell1, Styles.headerCell)}>Pseudo</Text>
            <Text className={clsx(Styles.userDataCell2, Styles.headerCell)}>Numéro utilisateur</Text>
            <Text className={clsx(Styles.userDataCell3, Styles.headerCell)}>Adresse e-mail</Text>
            <Text className={clsx(Styles.userDataCell4, Styles.headerCell)}>Statut</Text>
            <Text className={clsx(Styles.userDataCell5, Styles.headerCell)}>Date inscription</Text>
        </Box>
    };

    let _getUserRow = (user) => {
        return <Box className={Styles.dataRow}>
            <TouchableOpacity className={Styles.userDataCell1} onPress={() => handleClickEditUser(user)}>
                <Text className={Styles.dataCellText}>{user.username}</Text>
            </TouchableOpacity>
            <Text className={clsx(Styles.userDataCell2, Styles.dataCellText)}>{user.id}</Text>
            <Text className={clsx(Styles.userDataCell3, Styles.dataCellText)}>{user.email}</Text>
            <Text className={clsx(Styles.userDataCell4, Styles.dataCellText)}>{user.shop ? "vendeur": "acheteur"}</Text>
            <Text className={clsx(Styles.userDataCell5, Styles.dataCellText)}>{Moment(user.createdAt).format("DD/MM/YY")}</Text>
            <TouchableOpacity className={Styles.userDataCell6} onPress={() => handleClickEditUser(user)}>
                <Text className={clsx(Styles.dataCellText, Styles.link)}>Editer</Text>
            </TouchableOpacity>
            <TouchableOpacity className={Styles.userDataCell7} onPress={() => handleClickDeleteUser(user)}>
                <Text className={clsx(Styles.dataCellText, Styles.link)}>Supprimer</Text>
            </TouchableOpacity>
        </Box>
    };

    let _getProductHeaderRow = () => {
        return <Box className={Styles.headerRow}>
            <Text className={clsx(Styles.productDataCell0, Styles.headerCell)}>ID</Text>
            <Text className={clsx(Styles.productDataCell1, Styles.headerCell)}>Nom</Text>
            <Text className={clsx(Styles.productDataCell2, Styles.headerCell)}>Boutique</Text>
            <Text className={clsx(Styles.productDataCell3, Styles.headerCell)}>Famille</Text>
            <Text className={clsx(Styles.productDataCell4, Styles.headerCell)}>Catégorie</Text>
            <Text className={clsx(Styles.productDataCell7, Styles.headerCell)}>Type de transaction</Text>
        </Box>
    };

    let _getProductRow = (product) => {
        let cat1 = null;
        let cat2 = null;
        let cat3 = null;
        if (!product.category.parent) {
            cat1 = product.category.name;
        } else {
            if (!product.category.parent.parent) {
                cat1 = product.category.parent.name;
                cat2 = product.category.name;
            } else {
                cat1 = product.category.parent.parent.name;
                cat2 = product.category.parent.name;
                cat3 = product.category.name;
            }
        }

        let deliveryModesDom = [];
        if (product.chronopost) {
            deliveryModesDom.push("Chronopost\n")
        }
        if (product.mondialRelay) {
            deliveryModesDom.push("Mondial Relay\n")
        }
        if (product.clickAndCollect) {
            deliveryModesDom.push("Click & Collect\n")
        }
        if (product.handOver) {
            deliveryModesDom.push("En main propre\n")
        }
        return <Box className={Styles.dataRow}>
            <Text className={clsx(Styles.productDataCell0, Styles.dataCellText)}>{product.id}</Text>
            <Text className={clsx(Styles.productDataCell1, Styles.dataCellText)}>{product.name}</Text>
            <Text className={clsx(Styles.productDataCell2, Styles.dataCellText)}>{product.shop.name}</Text>
            <Text className={clsx(Styles.productDataCell3, Styles.dataCellText)}>{product.family.name}</Text>
            <Text className={clsx(Styles.productDataCell4, Styles.dataCellText)}>{product.category.name}</Text>
            <Text className={clsx(Styles.productDataCell7, Styles.dataCellText)}>{deliveryModesDom}</Text>
            <TouchableOpacity className={Styles.productDataCell8} onPress={() => handleClickEditProduct(product)}>
                <Text className={clsx(Styles.dataCellText, Styles.link)}>Editer</Text>
            </TouchableOpacity>
            <TouchableOpacity className={Styles.productDataCell9} onPress={() => handleClickDeleteProduct(product)}>
                <Text className={clsx(Styles.dataCellText, Styles.link)}>Supprimer</Text>
            </TouchableOpacity>
        </Box>
    };

    let _getShopHeaderRow = () => {
        return <Box className={Styles.headerRow}>
            <Text className={clsx(Styles.shopDataCell0, Styles.headerCell)}>N°</Text>
            <Text className={clsx(Styles.shopDataCell1, Styles.headerCell)}>Nom de la boutique</Text>
            <Text className={clsx(Styles.shopDataCell2, Styles.headerCell)}>Pseudo</Text>
            <Text className={clsx(Styles.shopDataCell6, Styles.headerCell)}>Type</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.headerCell)}>Nb d'articles</Text>
        </Box>
    };

    let _getShopRow = (shop) => {
        return <Box className={Styles.dataRow}>
            <Text className={clsx(Styles.shopDataCell0, Styles.dataCellText)}>{shop.id}</Text>
            <Text className={clsx(Styles.shopDataCell1, Styles.dataCellText)}>{shop.name}</Text>
            <Text className={clsx(Styles.shopDataCell2, Styles.dataCellText)}>{shop.owner ? shop.owner.username : ""}</Text>
            <Text className={clsx(Styles.shopDataCell6, Styles.dataCellText)}>{shopTypes[shop.type]}</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.dataCellText)}>{shop.nbProducts}</Text>
            <TouchableOpacity className={Styles.shopDataCell4} onPress={() => handleClickEditShop(shop)}>
                <Text className={clsx(Styles.dataCellText, Styles.link)}>Editer</Text>
            </TouchableOpacity>
            <TouchableOpacity className={Styles.shopDataCell5} onPress={() => handleClickDeleteShop(shop)}>
                <Text className={clsx(Styles.dataCellText, Styles.link)}>Supprimer</Text>
            </TouchableOpacity>
        </Box>
    };

    let _getDisputeHeaderRow = () => {
        return <Box className={Styles.headerRow}>
            <Text className={clsx(Styles.shopDataCell1, Styles.headerCell)}>N° de commande</Text>
            <Text className={clsx(Styles.shopDataCell2, Styles.headerCell)}>Vendeur</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.headerCell)}>Acheteur</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.headerCell)}>Produit</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.headerCell)}>Statut</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.headerCell)}>Date de création</Text>
            <Select
                variant={"outlined"}
                className={clsx(Styles.pickerWrapper)}
                onChange={(event) => {
                    setType(event.target.value);
                }}
                value={type}
            >
                <MenuItem value={0} >En cours</MenuItem>
                <MenuItem value={-1} >Tous</MenuItem>
                <MenuItem value={1} >Résolus</MenuItem>
            </Select>
        </Box>
    };

    let _getDisputeRow = (dispute) => {
        return <Box className={Styles.dataRow}>
            <Text className={clsx(Styles.shopDataCell1, Styles.dataCellText)}>{dispute.purchase.id}</Text>
            <Text className={clsx(Styles.shopDataCell2, Styles.dataCellText)}>{dispute.shop.owner.firstname + " " + dispute.shop.owner.name}</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.dataCellText)}>{dispute.purchase.user.firstname + " " + dispute.purchase.user.name}</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.dataCellText)}>{dispute.product.name}</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.dataCellText)}>{dispute.status === 0 ? "En cours" : "Résolu"}</Text>
            <Text className={clsx(Styles.shopDataCell3, Styles.dataCellText)}>{Moment(dispute.createdAt).format("DD/MM/YY")}</Text>
            <TouchableOpacity className={Styles.shopDataCell4} onPress={() => handleClickEditDispute(dispute)}>
                <Text className={clsx(Styles.dataCellText, Styles.link)}>Editer</Text>
            </TouchableOpacity>
            <TouchableOpacity className={Styles.shopDataCell5} onPress={() => handleClickDeleteDispute(dispute)}>
                <Text className={clsx(Styles.dataCellText, Styles.link)}>Archiver</Text>
            </TouchableOpacity>
        </Box>
    };

    return <Base
        title={"Liste des " + (tab === "USER" ? "utilisateurs" : tab === "PRODUCT" ? "produits" : tab === "SHOP" ? "boutiques" : "litiges")}
        currentScreen={"ADMIN_LIST"}
        pagination={pagination}
        page={page}
        setPage={setPage}
        content={
            <Box className={Styles.container}>
                {_getTabs()}
                {tab === "USER" && _getUserHeaderRow()}
                {tab === "PRODUCT" && _getProductHeaderRow()}
                {tab === "SHOP" && _getShopHeaderRow()}
                {tab === "DISPUTE" && _getDisputeHeaderRow()}
                <Box className={Styles.dataWrapper}>
                    {tab === "USER" && usersList && usersList.map(_getUserRow)}
                    {tab === "PRODUCT" && productsList && productsList.map(_getProductRow)}
                    {tab === "SHOP" && shopsList && shopsList.map(_getShopRow)}
                    {tab === "DISPUTE" && disputesList && disputesList.map(_getDisputeRow)}
                </Box>
                {tab === "USER" && <ConfirmationModal
                    visible={deleteModalVisible}
                    setVisible={setDeleteModalVisible}
                    title={"Suppression de l'utilisateur"}
                    message={"Êtes-vous sûr de vouloir supprimer cet utilisateur ?"}
                    onConfirm={() => {setDeleteModalVisible(false); deleteUser(deleteId); setTimeout(() => startSearch(), 100);}}
                    onCancel={() => {setDeleteModalVisible(false)}}/>}
                {tab === "PRODUCT" && <ConfirmationModal
                    visible={deleteModalVisible}
                    setVisible={setDeleteModalVisible}
                    title={"Suppression du produit"}
                    message={"Êtes-vous sûr de vouloir supprimer ce produit ?"}
                    onConfirm={() => {setDeleteModalVisible(false); deleteProduct(deleteId); setTimeout(() => startSearch(), 100);}}
                    onCancel={() => {setDeleteModalVisible(false)}}/>}
                {tab === "SHOP" && <ConfirmationModal
                    visible={deleteModalVisible}
                    setVisible={setDeleteModalVisible}
                    title={"Suppression de la boutique"}
                    message={"Êtes-vous sûr de vouloir supprimer cette boutique ?"}
                    onConfirm={() => {setDeleteModalVisible(false); deleteShop(deleteId); setTimeout(() => startSearch(), 100);}}
                    onCancel={() => {setDeleteModalVisible(false)}}/>}
                {tab === "DISPUTE" && <ConfirmationModal
                    visible={deleteModalVisible}
                    setVisible={setDeleteModalVisible}
                    title={"Archivage du litige ?"}
                    message={"Un litige clos ne pourra pas être ré-ouvert. Êtes-vous sûr de vouloir archiver ce litige ?"}
                    onConfirm={() => {setDeleteModalVisible(false); deleteDispute(deleteId, search, 10, page); setTimeout(() => startSearch(), 100);}}
                    onCancel={() => {setDeleteModalVisible(false)}}/>}
            </Box>
        }
    />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        usersList: state.user.usersList,
        productsList: state.product.productsList,
        shopsList: state.shop.shopsList,
        disputesList: state.dispute.disputesList,
        userPagination: state.user.usersListPagination,
        productPagination: state.product.pagination,
        shopPagination: state.shop.pagination,
        disputePagination: state.dispute.pagination,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUsers: (search, itemsPerPage, page) => {
            dispatch({type: 'FETCH_USERS_REQUESTED', search, itemsPerPage, page})
        },
        searchProducts: (search, itemsPerPage, page) => {
            dispatch({type: 'SEARCH_PRODUCTS_REQUESTED', name: search, itemsPerPage, page})
        },
        searchShops: (search, itemsPerPage, page) => {
            dispatch({type: 'SEARCH_SHOPS_REQUESTED', name: search, itemsPerPage, page, keepDirty: true})
        },
        searchDisputes: (search, itemsPerPage, page, type) => {
            dispatch({type: 'SEARCH_DISPUTES_REQUESTED', search, itemsPerPage, page, disputeType: type})
        },
        deleteUser: (id) => {
            dispatch({type: 'DELETE_USER_REQUESTED', id})
        },
        deleteProduct: (id) => {
            dispatch({type: 'DELETE_PRODUCT_REQUESTED', id})
        },
        deleteShop: (id) => {
            dispatch({type: 'DELETE_SHOP_REQUESTED', id})
        },
        deleteDispute: (id, search, itemsPerPage, page) => {
            dispatch({type: 'DELETE_DISPUTE_REQUESTED', id, search, itemsPerPage, page})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
