const initialState = {
    videDressingsList: null,
    videDressing: null
};


function reduc(state = initialState, action) {
    let index;
    let result = {};
    switch (action.type) {
        case "FETCH_VIDE_DRESSINGS_SUCCEEDED":
            return Object.assign({}, state, {
                videDressingsList: action.videDressings
            });
        case "FETCH_VIDE_DRESSING_SUCCEEDED":
            return Object.assign({}, state, {
                videDressing: action.videDressing
            });
        case "DELETE_VIDE_DRESSING_SUCCEEDED":
            index = state.videDressingsList.findIndex((element) => element.id === action.id);

            if (index !== -1) {
                result.videDressingsList = [
                    ...state.videDressingsList.slice(0, index),
                    ...state.videDressingsList.slice(index + 1)
                ];
            }
            return Object.assign({}, state, result);

        case "SUBSCRIBE_TO_VIDE_DRESSING_SUCCEEDED":
            index = state.videDressingsList.findIndex((element) => element.id === action.videDressing.id);

            if (index !== -1) {
                result.videDressingsList = [
                    ...state.videDressingsList.slice(0, index),
                    Object.assign({}, state.videDressingsList[index], action.videDressing),
                    ...state.videDressingsList.slice(index + 1)
                ];
            }

            if (state.videDressing && action.videDressing.id === state.videDressing.id) {
                result.videDressing = Object.assign({}, state.videDressing, action.videDressing);
            }
            return Object.assign({}, state, result);

        case "SAVE_VIDE_DRESSING_SUCCEEDED":
            index = state.videDressingsList.findIndex((element) => element.id === action.videDressing.id);

            if (index !== -1) {
                result.videDressingsList = [
                    ...state.videDressingsList.slice(0, index),
                    Object.assign({}, state.videDressingsList[index], action.videDressing),
                    ...state.videDressingsList.slice(index + 1)
                ];
            } else {
                result.videDressingsList = [
                    ...state.videDressingsList,
                    action.videDressing
                ];
            }

            if (state.videDressing && action.videDressing.id === state.videDressing.id) {
                result.videDressing = Object.assign({}, state.videDressing, action.videDressing);
            }
            return Object.assign({}, state, result);

        case "SAVE_SHOP_REQUESTED":
            if (!action.shop.videDressingSeller && state.videDressingsList) {
                let cpy = state.videDressingsList.slice();
                for (const event of cpy) {
                    event.isSubscribed = false;
                }

                return Object.assign({}, state, {
                    videDressingsList: cpy
                })
            }
            break;
    }

    return state
}

export default reduc