import React from "react";
import { Image } from "react-native";

import Box from "@material-ui/core/Box"

import Text from "../../Component/FontText";
import InstagramBlockStylesFunc from "../../../Style/InstagramBlockStyle";
import { connect } from 'react-redux'

function Component({fetchInstagramFeed, instaFeed}) {
    let InstagramBlockStyles = InstagramBlockStylesFunc();
    React.useEffect(() => {
        fetchInstagramFeed();
    }, []);

    if (instaFeed.length > 0) {
        let instaFeedDom = [];
        for (const instaItem of instaFeed) {
            instaFeedDom.push(
                <Image
                    source={instaItem.media_url}
                    className={InstagramBlockStyles.instagramFeedItem}
                    accessibilityRole={"link"}
                    href={instaItem.permalink}
                    target={'_blank'}
                />
            )
        }
        return (
            <Box className={InstagramBlockStyles.instagramBlock}>
                <Text className={InstagramBlockStyles.instagramTitle}>Instagram</Text>
                <Box className={InstagramBlockStyles.instagramFeedWrapper}>
                    {instaFeedDom}
                </Box>
            </Box>
        );
    } else {
        return null;
    }
}


const mapStateToProps = state => {
    return {
        instaFeed: state.instagram.feed
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchInstagramFeed: (id) => {
            dispatch({type: 'FETCH_INSTAGRAM_FEED_REQUESTED'})
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);