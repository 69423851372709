import React from "react";
import clsx from "clsx";

import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import Slide from "@material-ui/core/Slide";

import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import HeaderStylesFunc from "../../Style/HeaderStyle"
import {slugify} from "../../Utils/StringUtils";

function Component({history, navigation, fetchFamilies, fetchMe, familyTree, token, cart, logout, tokenExpired, resetTokenExpired, user}) {
    let HeaderStyles = HeaderStylesFunc();

    let [page, setPage] = React.useState("HOME");

    let [openDrawer, setOpenDrawer] = React.useState(false);
    let [openDrawerFamily, setOpenDrawerFamily] = React.useState(null);
    let [openDrawerCategory, setOpenDrawerCategory] = React.useState(null);
    let [openDrawerAccountCategory, setOpenDrawerAccountCategory] = React.useState(null);

    React.useEffect(() => {
        if (!familyTree || familyTree.length === 0) {
            fetchFamilies();
        }
    }, [familyTree, fetchFamilies]);

    React.useEffect(() => {
        if (!token) {
            setPage("HOME");
            setOpenDrawerFamily(null);
            setOpenDrawerCategory(null);
            setOpenDrawerAccountCategory(null);
        }
    }, [token]);

    let getHomeDom = () => {
        return <Box style={{height: "100%"}}>
            <List disablePadding>
                <ListItem onClick={()  => setPage("CATEGORIES")} divider className={clsx(HeaderStyles.drawer.listItem)}>
                    <ListItemText>Voir les catégories</ListItemText>
                </ListItem>
                <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                    <Link href={"/shops/createurs"} color={"inherit"}>
                        <ListItemText primary={"Créateurs"} />
                    </Link>
                </ListItem>
                <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                    <Link href={"https://www.violettesauvage.fr/inscription-vide-dressing"} target="_blank" color={"inherit"}>
                        <ListItemText primary={"Nos vide-dressings"} />
                    </Link>
                </ListItem>
                <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                    <Link href={"/lives/en_cours"} color={"inherit"}>
                        <ListItemText primary={"Live"} />
                    </Link>
                </ListItem>
                {token && <ListItem onClick={()  => setPage("ACCOUNT")} divider className={clsx(HeaderStyles.drawer.listItem)} style={{position: "relative"}}>
                    <ListItemText primary={"Mon compte"} secondary={token && user && user.nbUnreadMessages > 0 ? <span className={HeaderStyles.secondRow.messagesNotification}>{user.nbUnreadMessages}</span> : null} className={clsx(HeaderStyles.drawer.notificationText)}/>
                </ListItem>}
            </List>
            <List disablePadding style={{position: "absolute", bottom: 0, left: 0, right: 0}}>
                <ListItem onClick={()  => setPage("FOOTER_1")} divider className={clsx(HeaderStyles.drawer.listItem)}>
                    <ListItemText>Aide et assistance</ListItemText>
                </ListItem>
                <ListItem onClick={()  => setPage("FOOTER_2")} divider className={clsx(HeaderStyles.drawer.listItem)}>
                    <ListItemText>A propos de nous</ListItemText>
                </ListItem>
                <ListItem onClick={()  => setPage("FOOTER_3")} divider className={clsx(HeaderStyles.drawer.listItem)}>
                    <ListItemText>Vos services</ListItemText>
                </ListItem>
            </List>
        </Box>
    };

    let getAccountDom = () => {
        return <List disablePadding>
            <ListItem divider onClick={() => openDrawerAccountCategory === 0 ? setOpenDrawerAccountCategory(null) : setOpenDrawerAccountCategory(0)} selected={openDrawerAccountCategory === 0} className={clsx(HeaderStyles.drawer.listItem)}>
                <ListItemText primary={"Mes achats"}/>
                <ExpandMoreIcon />
            </ListItem>
            <Collapse in={openDrawerAccountCategory === 0} className={clsx(HeaderStyles.drawer.collapse)}>
                <List className={clsx(HeaderStyles.drawer.subList)} disablePadding>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/mes_commandes"} color={"inherit"}>
                            <ListItemText primary={"Mes commandes"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/wishlist"} color={"inherit"}>
                            <ListItemText primary={"Mes coups de coeur"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/mes_alertes"} color={"inherit"}>
                            <ListItemText primary={"Mes alertes"} />
                        </Link>
                    </ListItem>
                </List>
            </Collapse>

            <ListItem divider onClick={() => openDrawerAccountCategory === 1 ? setOpenDrawerAccountCategory(null) : setOpenDrawerAccountCategory(1)} selected={openDrawerAccountCategory === 1} className={clsx(HeaderStyles.drawer.listItem)}>
                <ListItemText primary={"Mes ventes"}/>
                <ExpandMoreIcon />
            </ListItem>
            <Collapse in={openDrawerAccountCategory === 1} className={clsx(HeaderStyles.drawer.collapse)}>
                <List className={clsx(HeaderStyles.drawer.subList)} disablePadding>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/mes_transactions"} color={"inherit"}>
                            <ListItemText primary={"Mes transactions"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/mes_annonces"} color={"inherit"}>
                            <ListItemText primary={"Mes annonces"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/ma_boutique"} color={"inherit"}>
                            <ListItemText primary={"Ma boutique"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/ajouter_produit"} color={"inherit"}>
                            <ListItemText primary={"Mettre en vente un article"} />
                        </Link>
                    </ListItem>
                </List>
            </Collapse>

            <ListItem divider onClick={() => openDrawerAccountCategory === 2 ? setOpenDrawerAccountCategory(null) : setOpenDrawerAccountCategory(2)} selected={openDrawerAccountCategory === 2} className={clsx(HeaderStyles.drawer.listItem)}>
                <ListItemText primary={"Lives"}/>
                <ExpandMoreIcon />
            </ListItem>
            <Collapse in={openDrawerAccountCategory === 2} className={clsx(HeaderStyles.drawer.collapse)}>
                <List className={clsx(HeaderStyles.drawer.subList)} disablePadding>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/tuto_live"} color={"inherit"}>
                            <ListItemText primary={"Tuto live"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/mes_lives"} color={"inherit"}>
                            <ListItemText primary={"Mes lives"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/inscription_live"} color={"inherit"}>
                            <ListItemText primary={"Créer un live"} />
                        </Link>
                    </ListItem>
                </List>
            </Collapse>

            <ListItem divider onClick={() => openDrawerAccountCategory === 3 ? setOpenDrawerAccountCategory(null) : setOpenDrawerAccountCategory(3)} selected={openDrawerAccountCategory === 3} className={clsx(HeaderStyles.drawer.listItem)}>
                <ListItemText primary={"Paiements"}/>
                <ExpandMoreIcon />
            </ListItem>
            <Collapse in={openDrawerAccountCategory === 3} className={clsx(HeaderStyles.drawer.collapse)}>
                <List className={clsx(HeaderStyles.drawer.subList)} disablePadding>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/mon_porte_monnaie"} color={"inherit"}>
                            <ListItemText primary={"Mon porte-monnaie"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/mes_coordonnees_bancaires"} color={"inherit"}>
                            <ListItemText primary={"Mes coordonnées bancaires"} />
                        </Link>
                    </ListItem>
                </List>
            </Collapse>

            <ListItem divider onClick={() => openDrawerAccountCategory === 4 ? setOpenDrawerAccountCategory(null) : setOpenDrawerAccountCategory(4)} selected={openDrawerAccountCategory === 4} className={clsx(HeaderStyles.drawer.listItem)} style={{justifyContent: "space-between"}}>
                <ListItemText primary={"Paramètres du compte"} secondary={token && user && user.nbUnreadMessages > 0 ? <span className={HeaderStyles.secondRow.messagesNotification}>{user.nbUnreadMessages}</span> : null} className={clsx(HeaderStyles.drawer.notificationText)} />
                <ExpandMoreIcon />

            </ListItem>
            <Collapse in={openDrawerAccountCategory === 4} className={clsx(HeaderStyles.drawer.collapse)}>
                <List className={clsx(HeaderStyles.drawer.subList)} disablePadding>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"/mes_informations"} color={"inherit"}>
                            <ListItemText primary={"Mes informations"} />
                        </Link>
                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)} style={{position: "relative"}}>
                        <Link href={"/messages"} color={"inherit"}>
                            <ListItemText primary={"Messagerie"} secondary={token && user && user.nbUnreadMessages > 0 ? <span className={HeaderStyles.secondRow.messagesNotification}>{user.nbUnreadMessages}</span> : null} className={clsx(HeaderStyles.drawer.notificationText)} />
                        </Link>

                    </ListItem>
                    <ListItem divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                        <Link href={"#"} color={"inherit"} onClick={() => {logout(); history.push("/")}}>
                            <ListItemText primary={"Déconnexion"} />
                        </Link>
                    </ListItem>
                </List>
            </Collapse>
        </List>
    };

    let getFooter1Dom = () => {
        return <List disablePadding>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/contact"} color={"inherit"}><ListItemText primary={"Nous contacter"} /></Link>
            </ListItem>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/cgv"} color={"inherit"}><ListItemText primary={"CGU/CGV"} /></Link>
            </ListItem>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/politique_de_confidentialite"} color={"inherit"}><ListItemText primary={"Politique de confidentialité"} /></Link>
            </ListItem>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/faq"} color={"inherit"}><ListItemText primary={"FAQ"} /></Link>
            </ListItem>
        </List>
    };

    let getFooter2Dom = () => {
        return <List disablePadding>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/meetingVS"} color={"inherit"}><ListItemText primary={"A la rencontre de Violette Sauvage"} /></Link>
            </ListItem>
        </List>
    };

    let getFooter3Dom = () => {
        return <List disablePadding>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/ouvrir_une_boutique"} color={"inherit"}><ListItemText primary={"Ouvrir une boutique"} /></Link>
            </ListItem>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/manuel_du_vendeur"} color={"inherit"}><ListItemText primary={"Manuel du vendeur"} /></Link>
            </ListItem>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/dashboard"} color={"inherit"}><ListItemText primary={"Votre compte"} /></Link>
            </ListItem>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/conditions_de_livraison"} color={"inherit"}><ListItemText primary={"Tarifs et livraisons"} /></Link>
            </ListItem>
            <ListItem divider className={clsx(HeaderStyles.drawer.listItem)}>
                <Link href={"/associations"} color={"inherit"}><ListItemText primary={"Les associations partenaires"} /></Link>
            </ListItem>
        </List>
    };

    let getFamiliesDom = () => {
        let dom = [];
        if (familyTree != null) {
            for (const value of familyTree) {
                let categoriesDom = [];
                for (const child of value.children) {
                    let subCategoriesDom = [];
                    for (const grandChild of child.children) {
                        const categoryListUrl = "/category/" + value.id + "/" + slugify(value.name) + "/" + child.id + "/" + slugify(child.name) + "/" + grandChild.id + "/" + slugify(grandChild.name);
                        subCategoriesDom.push(<ListItem key={"sub-" + grandChild.id} divider className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subSubListItem)}>
                            <Link href={categoryListUrl} color={"inherit"} className={clsx(HeaderStyles.drawer.largeLink)}><ListItemText primary={grandChild.name} /></Link>
                        </ListItem>)
                    }
                    const categoryListUrl = "/category/" + value.id + "/" + slugify(value.name) + "/" + child.id + "/" + slugify(child.name);
                    categoriesDom.push(<ListItem key={"sub-" + child.id} divider selected={openDrawerCategory === child.id} className={clsx(HeaderStyles.drawer.listItem, HeaderStyles.drawer.subListItem)}>
                        {/*<ListItemText primary={child.name} />*/}
                        <Link href={categoryListUrl} color={"inherit"} className={clsx(HeaderStyles.drawer.largeLink)}><ListItemText primary={child.name} /></Link>
                        <ExpandMoreIcon onClick={() => openDrawerCategory === child.id ? setOpenDrawerCategory(null) : setOpenDrawerCategory(child.id)} />
                    </ListItem>);
                    categoriesDom.push(
                        <Collapse in={openDrawerCategory === child.id} className={clsx(HeaderStyles.drawer.collapse)}  key={"sub-" + child.id}>
                            <List className={clsx(HeaderStyles.drawer.subList)} disablePadding>{subCategoriesDom}</List>
                        </Collapse>
                    )
                }
                const categoryListUrl = "/category/" + value.id + "/" + slugify(value.name);
                dom.push(<ListItem key={"value-" + value.id} divider selected={openDrawerFamily === value.id} className={clsx(HeaderStyles.drawer.listItem)}>
                    <Link href={categoryListUrl} color={"inherit"} className={clsx(HeaderStyles.drawer.largeLink)}><ListItemText primary={value.name.toUpperCase()} /></Link>
                    <ExpandMoreIcon onClick={() => openDrawerFamily === value.id ? setOpenDrawerFamily(null) : setOpenDrawerFamily(value.id)} />
                </ListItem>);
                dom.push(
                    <Collapse key={"value2-" + value.id} in={openDrawerFamily === value.id} className={clsx(HeaderStyles.drawer.collapse)}>
                        <List className={clsx(HeaderStyles.drawer.subList)} disablePadding>{categoriesDom}</List>
                    </Collapse>
                )
            }
        }
        return <List disablePadding>{dom}</List>;
    };

     return (
        <Hidden smUp>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpenDrawer(!openDrawer)}
                edge="start"
                className={HeaderStyles.secondRow.drawerButton}
            >
                <MenuIcon />
                {token && user && user.nbUnreadMessages > 0 && <span className={HeaderStyles.secondRow.burgerMessagesNotification}>{user.nbUnreadMessages}</span>}
            </IconButton>
            {window.location.pathname !== "/" && <IconButton
                color="inherit"
                aria-label="back"
                onClick={() => window.history.back()}
                edge="start"
                className={HeaderStyles.secondRow.backButton}
            >
                <ArrowBackIos />
            </IconButton>}
            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}>
                <Box className={HeaderStyles.drawer.header}>
                    {page !== "HOME" && <Box className={HeaderStyles.drawer.headerArrowBack}>
                        <ArrowBack onClick={() => setPage("HOME")} style={{height: "100%"}}/>
                    </Box>}
                    <Typography className={HeaderStyles.drawer.headerTitle}>Violette Sauvage</Typography>
                </Box>
                <Slide in={page === "HOME"} direction={"left"} unmountOnExit>
                    {getHomeDom()}
                </Slide>
                <Slide in={page === "CATEGORIES"} direction={"left"} unmountOnExit>
                    {getFamiliesDom()}
                </Slide>
                <Slide in={page === "ACCOUNT"} direction={"left"} unmountOnExit>
                    {getAccountDom()}
                </Slide>
                <Slide in={page === "FOOTER_1"} direction={"left"} unmountOnExit>
                    {getFooter1Dom()}
                </Slide>
                <Slide in={page === "FOOTER_2"} direction={"left"} unmountOnExit>
                    {getFooter2Dom()}
                </Slide>
                <Slide in={page === "FOOTER_3"} direction={"left"} unmountOnExit>
                    {getFooter3Dom()}
                </Slide>
            </Drawer>
        </Hidden>
    );
}

const mapStateToProps = state => {
    return {
        familyTree: state.family.familyTree,
        token: state.login.token,
        user: state.user.me,
        cart: state.product.cart,
        tokenExpired: state.login.tokenExpired
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchFamilies: () => {
            dispatch({type: 'FETCH_FAMILIES_REQUESTED'})
        },
        fetchMe: (token) => {
            dispatch({type: 'FETCH_ME_REQUESTED', payload: {token}})
        },
        logout: () => {
            dispatch({type: 'LOGOUT'})
        },
        resetTokenExpired:() => {
            dispatch({type: 'RESET_TOKEN_EXPIRED'})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
