import React from "react";
import clsx from 'clsx';
import { Image, Picker } from "react-native";
import { TouchableOpacity } from 'react-native-web';

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

import Text from "../Component/FontText";
import CustomCheckbox from "../Component/CustomCheckbox";
import { connect } from 'react-redux'
// import { MaterialCommunityIcons } from '@expo/vector-icons';
import {FontAwesome} from "../../FontAwesome";

import { withRouter } from "react-router-dom";
import { getImageUrl } from '../../Utils/ImageUtils'
import PointRelaisLogo from "../../Assets/Images/pointrelais_logo.svg";
import ILogo from "../../Assets/Images/i_logo.svg";
import HandsLogo from "../../Assets/Images/hand_logo.svg";
import ClickAndCollectLogo from "../../Assets/Images/clickandcollect_logo.svg";
import ChronopostLogo from "../../Assets/Images/chronopost_logo.svg";
import ErrorStyleFunc from "../Styles/Error"
import Moment from 'moment';

import ShoppingCartStylesFunc from "../../Style/ShoppingCartStyle"
import * as Constants from "../../Style/Constants"
import InstagramBlock from "./Component/InstagramBlock";
import ConfirmationModal from "./Component/ConfirmationModal";
import {getPurchaseDeliveryPrice, getSingleProductDeliveryPrice} from "../../Utils/PriceUtils";


function Component({history, navigation, token, cart, removeFromCart, setDeliveryForProduct, setQuantityForProduct, shippingPrices, params, refreshCart}) {
    let ErrorStyle = ErrorStyleFunc();
    let ShoppingCartStyles = ShoppingCartStylesFunc();
    let [error, setError] = React.useState("");
    let [showOverlay, setShowOverlay] = React.useState(null);
    let [overlayPosition, setOverlayPosition] = React.useState([0, 0]);
    let [errorModalVisible, setErrorModalVisible] = React.useState(false);
    let [liveErrorProduct, setLiveErrorProduct] = React.useState(null);

    React.useEffect(() => {
        window.scrollTo(0, 0);

        if (history.location.state && history.location.state.error) {
            if (history.location.state.error === 'product_sold_out') {
                setErrorModalVisible(true);
            }
        }

        for (const p of cart) {
            let product = p.product;
            if (product.currentLive && product.currentLive !== -1) {
                setLiveErrorProduct(product);
                removeFromCart(product);
            }
        }
        refreshCart();

    }, []);

    React.useEffect(() => {
        for (const p of cart) {
            let product = p.product;
            if (product.currentLive !== -1) {
                setLiveErrorProduct(product);
                removeFromCart(product);
            }
        }
    }, [cart]);


    let productPrice = 0;

    // if (cart.length === 0) {
    //     history.goBack();
    // }

    // Sort products by shop
    let shops = [];
    let shopIds = [];
    for (const product of cart) {
        if (!shops[product.product.shop.id]) {
            shopIds.push(product.product.shop.id);
            shops[product.product.shop.id] = {
                "shop": product.product.shop,
                "products": []
            };
        }
        shops[product.product.shop.id].products.push(product);
    }

    let buildCheckboxDom = (item, deliveryId, logo, logoStyle, name) => {
        let price = 0;

        if (deliveryId === 0) {
            price = getSingleProductDeliveryPrice(item.product, shippingPrices, params, "chronoPrice", item.quantity);
        } else if (deliveryId === 1) {
            price = getSingleProductDeliveryPrice(item.product, shippingPrices, params, "chronoRelayPrice", item.quantity);
        } else if (deliveryId === 2) {
            price = getSingleProductDeliveryPrice(item.product, shippingPrices, params, "mondialRelayPrice", item.quantity);
        }
        return (
            <Box>
                <CustomCheckbox
                    size={"large"}
                    fullWidth
                    containerWrapper={ShoppingCartStyles.deliveryCheckbox}
                    checked={item.delivery === deliveryId}
                    title={
                        <Box className={ShoppingCartStyles.deliveryWrapper}>
                            <Box className={ShoppingCartStyles.deliveryLogo}>
                                <Image source={logo} className={logoStyle} />
                            </Box>
                            <Text className={clsx(ShoppingCartStyles.deliveryName, {[ShoppingCartStyles.deliveryActive]: item.delivery === deliveryId})}>
                                {name}
                                <Image
                                    source={ILogo}
                                    className={ShoppingCartStyles.iLogo}
                                    onMouseEnter={(event) => {setShowOverlay(deliveryId)}}
                                    onMouseMove={(event) => {console.log(event.target.x, event.target.y, event.target, event.target.getBoundingClientRect()); setOverlayPosition([event.target.getBoundingClientRect().x, event.target.getBoundingClientRect().y])}}
                                />
                            </Text>
                            <Text className={clsx(ShoppingCartStyles.deliveryPrice, {[ShoppingCartStyles.deliveryActive]: item.delivery === deliveryId})}>{price} €</Text>
                        </Box>
                    }
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    checkedIcon={"square"}
                    onPress={() => {
                        setDeliveryForProduct(item.product, deliveryId);
                    }}
                />
            </Box>

        );
    };


    // Build dom
    let shopsDom = [];
    let deliveryPrice = getPurchaseDeliveryPrice(cart, shippingPrices, params);
    for (const shopId of shopIds) {
        let shop = shops[shopId];
        let productsDom = [];

        for (const item of shop.products) {
            let product = item.product;
            let clickAndCollect = {valid: product.clickAndCollect && product.shop.events && product.shop.events.length > 0};
            let clickAndCollectEvents = [];
            if (clickAndCollect.valid) {
                for (const event of product.shop.events) {
                    clickAndCollectEvents.push(Moment(event.datetime).locale('fr').format('DD/MM/YYYY') + " à " + event.city);
                }
                clickAndCollect["label"] = "Click & collect - " + clickAndCollectEvents.join(" ou ");
            }
            productPrice += item.quantity * product.realPrice;
            let pickerValues = [];
            for (let i = 1; i < item.product.stock + 1; i++) {
                pickerValues.push(<Picker.Item label={i} value={i} />)
            }
            productsDom.push(
                <Box>
                    <Grid container className={ShoppingCartStyles.shopInfoWrapper} alignItems={"center"}>
                        <Grid item xs={12} className={ShoppingCartStyles.productImageWrapper}>
                            <Link href={"/product/" + product["id"]}>
                                <Image source={getImageUrl(product.pictures[0] ? product.pictures[0].contentUrl : null, "medium")} className={ShoppingCartStyles.productImage}/>
                            </Link>
                        </Grid>
                        <Box className={ShoppingCartStyles.productNameWrapper}>
                            <Text className={ShoppingCartStyles.productName}>{product.name}</Text>
                            {item.size && <Text className={ShoppingCartStyles.productName2}>Taille {item.size.value}</Text>}
                            {product.brand && <Text className={ShoppingCartStyles.productName2}>{product.brand.value}</Text>}
                        </Box>
                        <Text className={ShoppingCartStyles.productPrice}>
                            {(product.realPrice).toFixed(2)} €
                        </Text>
                        <Box className={ShoppingCartStyles.productQuantityWrapper}>
                            <Picker
                                className={ShoppingCartStyles.productQuantityPicker}
                                selectedValue={item.quantity}
                                onValueChange={(value) => setQuantityForProduct(product, value)}>
                                {pickerValues}
                            </Picker>
                            <Button size={"small"} onClick={() => removeFromCart(product)} style={{minWidth: 10, padding: 10}}>
                                <FontAwesome name={"trash-alt"} prefix={"fas"} size={Constants.FONT_SIZE_MEDIUM} style={{color: Constants.PINK}} />
                            </Button>
                        </Box>
                        <Text className={ShoppingCartStyles.productPriceTotal}>
                            {(item.quantity * product.realPrice).toFixed(2)} €
                        </Text>
                    </Grid>
                    <Box className={ShoppingCartStyles.shopDivider} />
                    <Box className={ShoppingCartStyles.shopDeliveryGlobalWrapper}>
                        <Text className={ShoppingCartStyles.shopDeliveryTitle}>MODE DE LIVRAISON</Text>
                        <Box className={ShoppingCartStyles.shopDeliveryWrapper}>
                            {product.chronopost && <Box>
                                {buildCheckboxDom(
                                    item,
                                    0,
                                    ChronopostLogo,
                                    ShoppingCartStyles.chronopostLogo,
                                    "Chronopost (livraison à domicile)"
                                )}
                            </Box>}
                            {product.chronopost && <Box>
                                {buildCheckboxDom(
                                    item,
                                    1,
                                    ChronopostLogo,
                                    ShoppingCartStyles.chronopostLogo,
                                    "Chronopost (point relais)"
                                )}
                            </Box>}
                            {product.mondialRelay && <Box>
                                {buildCheckboxDom(
                                    item,
                                    2,
                                    PointRelaisLogo,
                                    ShoppingCartStyles.mondialRelayLogo,
                                    "Mondial relay (point relais)"
                                )}
                            </Box>}
                            {clickAndCollect.valid && <Box>
                                {buildCheckboxDom(
                                    item,
                                    3,
                                    ClickAndCollectLogo,
                                    ShoppingCartStyles.clickAndCollectLogo,
                                    clickAndCollect.label
                                )}
                            </Box>}
                            {product.handOver && <Box>
                                {buildCheckboxDom(
                                    item,
                                    4,
                                    HandsLogo,
                                    ShoppingCartStyles.handsLogo,
                                    "Remise en main propre"
                                )}
                            </Box>}
                        </Box>
                    </Box>
                    <Box className={ShoppingCartStyles.shopDivider} />
                </Box>
            );
        }

        let nbTotalProducts = 0;
        for (const p of shop.products) {
            nbTotalProducts += parseInt(p.quantity);
        }
        shopsDom.push(
            <Box className={ShoppingCartStyles.shopWrapper}>
                <Text className={ShoppingCartStyles.shopTitle}>{nbTotalProducts} article(s) selectionné(s) dans la boutique {shop.shop.name}</Text>
                {productsDom}
            </Box>
        );
    }

    const onClickContinue = () => {
        for (const product of cart) {
            if (product["delivery"] === -1) {
                setError("Vous devez choisir un mode de livraison pour tous vos articles.")
                return;
            }
        }
        if (!token) {
            history.push("/login", {"redirectTo": "/order"})
        } else {
            history.push("/order")
        }
    };

    let nbTotalProducts = 0;
    for (const p of cart) {
        nbTotalProducts += parseInt(p.quantity);
    }

    return (
        <Box>
            <Grid container className={ShoppingCartStyles.wrapper}>
                <Grid xs={12} sm className={ShoppingCartStyles.leftColumn}>
                    {shopsDom}
                    {shopsDom.length === 0 && <Text className={ShoppingCartStyles.noProductsText}>Votre panier est vide ! Les articles de votre panier ne sont pas réservés et peuvent être achetés à tout moment par un autre client. N’attendez pas pour confirmer vos achats !</Text>}
                </Grid>
                <Box className={ShoppingCartStyles.shoppingCartWrapper}>
                    <Text className={ShoppingCartStyles.shoppingCartTitle}>MON PANIER</Text>
                    <Box className={clsx(ShoppingCartStyles.shoppingCartBlock, ShoppingCartStyles.shoppingCartBlockFirst)}>
                            <Box className={ShoppingCartStyles.shoppingCartRow}>
                                <Text className={ShoppingCartStyles.shoppingCartText}>{nbTotalProducts} ARTICLE{nbTotalProducts > 1 ? "S" : ""}</Text>
                                <Text className={ShoppingCartStyles.shoppingCartText}>{productPrice.toFixed(2)} €</Text>
                            </Box>
                            <Box className={ShoppingCartStyles.shoppingCartRow}>
                                <Text className={ShoppingCartStyles.shoppingCartText}>LIVRAISON{nbTotalProducts > 1 ? "S" : ""} </Text>
                                <Text className={ShoppingCartStyles.shoppingCartText}>{deliveryPrice.toFixed(2)} €</Text>
                            </Box>
                        </Box>
                        <Box className={clsx(ShoppingCartStyles.shoppingCartBlock, ShoppingCartStyles.shoppingCartBlockLast)}>
                            <Box className={ShoppingCartStyles.shoppingCartRow}>
                                <Text className={ShoppingCartStyles.shoppingCartTotalText}>TOTAL</Text>
                                <Text className={ShoppingCartStyles.shoppingCartTotalText}>{(deliveryPrice + productPrice).toFixed(2)} €</Text>
                            </Box>
                        </Box>
                    <Button variant={"contained"} color={"primary"} disableElevation onClick={onClickContinue} disabled={cart.length === 0} className={ShoppingCartStyles.shoppingCartButtonWrapper}>
                        Continuer
                    </Button>
                    {error !== "" && <Text className={clsx(ErrorStyle.error, ShoppingCartStyles.shoppingCartErrorText)}>{error}</Text>}
                    <Box className={clsx(ShoppingCartStyles.shoppingCartDeliveryBlock, ShoppingCartStyles.shoppingCartDeliveryBlockFirst)}>
                        <Text className={ShoppingCartStyles.shoppingCartDeliveryTitle}>Expédition rapide</Text>
                        <Text className={ShoppingCartStyles.shoppingCartDeliveryText}>Toutes les commandes sont envoyées sous 3 jours ouvrés maximum. Vous souhaitez recevoir votre colis en express chez vous? Sélectionnez l'option Chronopost à domicile pour avoir votre colis en 24h !</Text>
                    </Box>
                    <Box className={ShoppingCartStyles.shoppingCartDeliveryBlock}>
                        <Text className={ShoppingCartStyles.shoppingCartDeliveryTitle}>Paiement sécurisé</Text>
                        <Text className={ShoppingCartStyles.shoppingCartDeliveryText}>Tous les paiements sont pris en charge par la plateforme Stripe, qui garantie la bonne conformité et sécurité des règlements.</Text>
                    </Box>
                    <Box className={ShoppingCartStyles.shoppingCartDeliveryBlock}>
                        <Text className={ShoppingCartStyles.shoppingCartDeliveryTitle}>Respect de l'environnement</Text>
                        <Text className={ShoppingCartStyles.shoppingCartDeliveryText}>En effectuant votre achat, vous contribuez à l'économie circulaire et luttez contre la pollution environnementale. Merci de votre geste !</Text>
                    </Box>
                </Box>
            </Grid>

            {showOverlay !== null && overlayPosition[0] !== undefined && overlayPosition[1] !== undefined &&
            <Box className={ShoppingCartStyles.deliveryOverlay} style={{position: "fixed", top: overlayPosition[1], left: overlayPosition[0]}} onMouseLeave={() => {setShowOverlay(null)}}>
                {showOverlay === 0 && <Text className={ShoppingCartStyles.deliveryOverlayText}>Livraison en 24h à domicile ou en point relais avec le suivi de colis via l’application Chronopost</Text>}
                {showOverlay === 1 && <Text className={ShoppingCartStyles.deliveryOverlayText}>Livraison en 24h à domicile ou en point relais avec le suivi de colis via l’application Chronopost</Text>}
                {showOverlay === 2 && <Text className={ShoppingCartStyles.deliveryOverlayText}>Livraison en 3 jours dans un point relais près de chez vous</Text>}
                {showOverlay === 3 && <Text className={ShoppingCartStyles.deliveryOverlayText}>Colis à venir récupérer gratuitement durant les vide-dressings Violette Sauvage</Text>}
                {showOverlay === 4 && <Text className={ShoppingCartStyles.deliveryOverlayText}>Remise en main propre gratuite entre vendeur et acheteur</Text>}
            </Box>
            }
            <ConfirmationModal
                visible={errorModalVisible}
                setVisible={setErrorModalVisible}
                title={"Problème lors de la commande"}
                message={"Un ou plusieurs produits ont été retirés de votre panier car plus disponibles. Veuillez réessayer."}
                onConfirm={() => {setErrorModalVisible(false); history.replace({...history.location, state: {}})}} />

            <ConfirmationModal
                visible={liveErrorProduct !== null}
                setVisible={visibility => setLiveErrorProduct(null)}
                title={"Problème lors de la commande"}
                message={"Votre produit \"" + (liveErrorProduct ? liveErrorProduct.name : "") + "\" a été retiré de votre panier car il est actuellement en vente dans un live. Voulez-vous aller voir ce live ?"}
                onConfirm={() => {setLiveErrorProduct(null); history.push('/voir_live/' + liveErrorProduct.currentLive)}}
                onCancel={() => {setLiveErrorProduct(null);}}/>
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        cart: state.product.cart,
        shippingPrices: state.family.shippingPrices,
        params: state.params.params
    }
};

const mapDispatchToProps = dispatch => {
    return {
        removeFromCart: (product) => {
            dispatch({type: 'REMOVE_FROM_CART', product})
        },
        setDeliveryForProduct: (product, delivery) => {
            dispatch({type: 'SET_DELIVERY_FOR_PRODUCT', product, delivery})
        },
        setQuantityForProduct: (product, quantity) => {
            dispatch({type: 'SET_QUANTITY_FOR_PRODUCT', product, quantity})
        },
        refreshCart: () => {
            dispatch({type: 'REFRESH_CART_REQUESTED'})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
