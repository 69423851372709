const initialState = {
    familyTree: null,
    shippingPrices: null,
    error: null
};

function reduc(state = initialState, action) {
    switch (action.type) {
        case "FETCH_FAMILIES_SUCCEEDED":
            return Object.assign({}, state, {
                familyTree: action.familyTree,
                shippingPrices: action.prices
            });
        case "FETCH_FAMILIES_FAILED":
            return Object.assign({}, state, {
                error: action.message
            });
    }

    return state
}

export default reduc
