
const paramKeys = {
    'chronoPrice'           : 'shipping_price_chrono_extra',
    'chronoRelayPrice'      : 'shipping_price_chrono_relay_extra',
    'mondialRelayPrice'     : 'shipping_price_mondial_relay_extra',
    'clickAndCollectPrice'  : 'shipping_price_click_and_collect_extra',
    'handsOverPrice'        : 'shipping_price_hands_over_extra',
};

function getPriceForWeight(weight, shippingPrices, params, key, toFixed = true) {
    let result = 0;

    if (shippingPrices) {
        const allWeights = Object.keys(shippingPrices).map(x => parseInt(x)).sort((a, b) => a - b);

        let w;
        for (w of allWeights) {
            if (weight <= w) {
                break;
            }
        }
        result += shippingPrices[w][key];

        if (weight > w) {
            // console.log("Product over max value. Using extra params...");
            let extra;
            for (const param of params) {
                if (param.name === paramKeys[key]) {
                    extra = parseFloat(param.value);
                }
            }

            let extraWeight = Math.ceil((weight - w) / 1000);
            result += extraWeight * extra;
        }
    }

    return toFixed ? result.toFixed(2) : result;
}


export function getSingleProductDeliveryPrice(product, shippingPrices, params, key, quantity = 1) {
    const totalWeight = (product['productWeight'] + product['packageWeight']) * quantity;

    return getPriceForWeight(totalWeight, shippingPrices, params, key);
}

export function getPurchaseDeliveryPrice(products, shippingPrices, params) {
    let result = 0;
    let matrix = [];
    for (const productOrder of products) {
        // Only if we have a delivery selected
        if ('delivery' in productOrder && productOrder.delivery !== -1) {
            // We arrange them by shop
            if (!matrix[productOrder.product.shop.id]) {
                matrix[productOrder.product.shop.id] = [];
            }

            // And then by delivery type
            if (!matrix[productOrder.product.shop.id][Object.keys(paramKeys)[productOrder.delivery]]) {
                matrix[productOrder.product.shop.id][Object.keys(paramKeys)[productOrder.delivery]] = [];
            }

            matrix[productOrder.product.shop.id][Object.keys(paramKeys)[productOrder.delivery]].push(productOrder);
        }
    }

    for (const shop of matrix) {
        for (const delivery in shop) {
            let totalWeight = shop[delivery].reduce((a, b) => (a + ((b.product.productWeight + b.product.packageWeight) * b.quantity)), 0);

            // console.log("Total weight of [" + totalWeight + "] in shop [" + shop + "] and delivery [" + delivery + "].");
            result += getPriceForWeight(totalWeight, shippingPrices, params, delivery, false);
        }
    }

    return result;
}


