import React from 'react';
import { useDispatch } from 'react-redux'
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";

export function useContact(callback = null) {
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (isLoading) {
            dispatch({type: 'START_LOADING_REQUESTED'});
        } else {
            dispatch({type: 'START_LOADING_SUCCEEDED'})
        }
    }, [isLoading, dispatch]);

    let trigger = ({name, firstname, mail, city, message}) => {
        setIsLoading(true);
        axios.post(
            getApiUrl('contacts'),
            {name, firstname, email: mail, city, message},
            {headers: setDefaultHeaders()}
        ).then(response => {
            callback();
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }


    return [trigger, isLoading];
}
