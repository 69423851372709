import React from "react";

import Link from "@material-ui/core/Link";

import StaticCommon from "./StaticCommon";

import Banner from "../../../Assets/Images/banners/FAQ.jpg"


function Component({title}) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const content = {
        title: "FAQ",
        blocks: [
            {
                title: "",
                content: [
                    {
                        title: 'J’ai acheté un article qui ne me convient pas, puis-je le retourner ?',
                        text: 'Si vous souhaitez retourner votre article, vous devez prendre contact directement avec le vendeur via la messagerie de votre compte personnel. Cependant tous les vendeurs n’acceptent pas systématiquement les retours, nous vous conseillons donc de bien vous renseigner sur la politique de retour de chaque vendeur avant d’effectuer vos achats.'
                    },
                    {
                        title: 'Je suis acheteur, mon colis n’est toujours pas arrivé, que dois-je faire ?',
                        text: 'Vous pouvez suivre votre colis grâce à notre service de tracking Chronopost ou en point relais. Si le problème reste inchangé, merci de vous rapprocher des services de livraison en les interrogeant directement.'
                    },
                    {
                        title: 'L\'article que je souhaitais n’est plus disponible, que puis-je faire ?',
                        text: 'Pour chaque article non disponible, nous vous proposons des articles similaires d’autres boutiques afin de satisfaire au mieux votre demande. N’ayez aucune crainte, des milliers d’articles sont proposés tous les jours, vous y trouverez votre bonheur !'
                    },
                    {
                        title: 'Vais-je trouver seulement des articles d’occasions ?',
                        text: 'Non, pas seulement ! Notre Marketplace a la particularité de proposer également des produits uniques de créateurs indépendants que vous pouvez découvrir dans leur rubrique dédiée.'
                    },
                    {
                        title: 'Je suis vendeur, quand vais-je recevoir mon paiement ?',
                        text: 'Dès que l’acheteur a confirmé la bonne réception de son article, le vendeur reçoit son paiement 72 heures au plus tard dans son porte-monnaie vendeur. Les virements du porte-monnaie vers votre compte bancaire prennent entre 24 et 48 heures.'
                    },
                    {
                        title: 'Je suis vendeur et dois m’absenter quelques jours, que dois-je faire ?',
                        text: 'Vous avez une option dans votre compte afin de mettre votre boutique « en pause » le temps de votre absence. Les acheteurs verront ainsi que vous n’êtes pas disponible pour un envoi mais pourront ajouter leur article dans leur wishlist afin de l’acheter plus tard. Mais n’oubliez pas d’enlever cette option à votre retour.'
                    },
                    {
                        title: 'Puis-je être vendeur et acheteur ?',
                        text: 'Absolument et c’est même recommandé ! Lorsque vous créez un compte vendeur, vous êtes automatiquement acheteur. Et si vous commencez par créer un compte acheteur, il vous suffira d’aller dans votre compte et de remplir le formulaire vendeur afin d’ouvrir votre boutique sur la Marketplace.'
                    },
                    {
                        title: 'Je souhaite m’inscrire au vide-dressing physique, où dois-je me rendre ?',
                        text: ['Vous pouvez cliquer sur la date qui vous intéresse sur la homepage dans la rubrique « Nos vide-dressings » dans la limite des places disponibles ou allez directement sur ', <Link target={"_blank"} href={'https://www.violettesauvage.fr/inscription-vide-dressing/'}>https://www.violettesauvage.fr/inscription-vide-dressing/</Link>]
                    }
                ]
            }
        ]
    }

    return (
        <StaticCommon content={content} image={Banner} title={title}
                      metaTitle={"Foire aux questions | Vide dressing Violette Sauvage"}
                      metaDesc={"Vous avez des questions sur le fonctionnement di vide dressing en ligne Violette Sauvage ? Parcourez la FAQ"}
                      arianeCurrentName={"FAQ"} />
    );
}

export default Component
