import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 60,
        maxWidth: 1440,
        paddingLeft: 50,
        paddingRight: 50,
        margin: "auto",
        [theme.breakpoints.down("xs")]: {
            width: "90%",
            marginTop: 30,
            paddingLeft: 5,
            paddingRight: 5,
        }
    },
    topBar: {
        ...GlobalStyle.row,
        justifyContent: "space-between",
        width: "100%"

    },
    share: {
        alignItems: "center",
        marginBottom: 40,
        maxWidth: 200,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
            marginTop: 10,
        }
    },
    shareTitle: {
        marginBottom: Constants.GET_SIZE(20),
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    row: {
        ...GlobalStyle.row
    },
    buttonsRow: {
        ...GlobalStyle.row,
        justifyContent: "space-between",
        marginBottom: Constants.GET_SIZE(25),
    },
    button: {
        ...GlobalStyle.button,
        paddingLeft: Constants.GET_SIZE(30),
        paddingRight: Constants.GET_SIZE(30),
        paddingTop: Constants.GET_SIZE(10),
        paddingBottom: Constants.GET_SIZE(10),
    },
    grayButton: {
        ...GlobalStyle.button,
        paddingLeft: Constants.GET_SIZE(10),
        paddingRight: Constants.GET_SIZE(10),
        paddingTop: Constants.GET_SIZE(10),
        paddingBottom: Constants.GET_SIZE(10),
        backgroundColor: '#DEDEDE'
    },
    wishlistButtonActivated: {
        ...GlobalStyle.button,
        paddingLeft: Constants.GET_SIZE(10),
        paddingRight: Constants.GET_SIZE(10),
        paddingTop: Constants.GET_SIZE(10),
        paddingBottom: Constants.GET_SIZE(10)
    },
    buttonLabel: {
        ...GlobalStyle.buttonLabel
    },
    subscribeButtonLabel: {
        ...GlobalStyle.buttonLabel,
        color: Constants.BLACK
    },
    addToCartButton: {
        [theme.breakpoints.down('xs')]: {
            marginRight: 10
        }
    },
    addToWishlistButton: {
        [theme.breakpoints.down('xs')]: {
            width: "max-content"
        }
    },
    subscribeButton: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        width: Constants.GET_SIZE(193),
        height: Constants.GET_SIZE(40),
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: "center",
        marginLeft: "auto"
    },
    ariane: {
        ...GlobalStyle.row,
        alignSelf: "flex-start",
    },
    arianeText: {
        ...GlobalStyle.uppercaseText
    },
    productImages: {
        ...GlobalStyle.row,
        paddingRight: Constants.GET_SIZE(20),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    productImagesThumbnails: {
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    productImagesContact: {
        flex: 4,
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    buttonFull: {
        ...GlobalStyle.button,
        width: "100%"
    },
    productImagesContactSmall: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block"
        }
    },
    productInfo: {
        flex: 3,
        [theme.breakpoints.down('xs')]: {
            textAlign: "center",
            marginTop: 30
        }
    },
    productBloc: {
        ...GlobalStyle.row
    },
    image: {
        cursor: 'pointer',
        width: Constants.GET_SIZE(650),
        height: Constants.GET_SIZE(550),
        marginTop: Constants.GET_SIZE(15),
        marginBottom: Constants.GET_SIZE(30),
        resizeMode: "contain",
        [theme.breakpoints.down('xs')]: {
            width: "90%",
            height: "initial",
            marginBottom: Constants.GET_SIZE(20),
        }
    },
    thumbnail: {
        width: Constants.GET_SIZE(100),
        height: Constants.GET_SIZE(85),
        marginLeft: Constants.GET_SIZE(30),
        marginRight: Constants.GET_SIZE(30),
        marginTop: Constants.GET_SIZE(15),
        marginBottom: Constants.GET_SIZE(15),
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1
    },
    productContact: {
        alignItems: "center",
        justifyContent: "center",
    },
    productInfoNameText: {
        fontSize: Constants.FONT_SIZE_BIG,
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },
    productInfoCatBrandPrice: {
        ...GlobalStyle.row,
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center"
        }
    },
    productInfoCatBrandText: {
        fontSize: Constants.FONT_SIZE_BIG,
        color: Constants.FONT_LIGHT_GRAY,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    productInfoPriceText: {
        fontSize: Constants.FONT_SIZE_BIG,
        width: Constants.GET_SIZE(100),
        textAlign: "center",
        color: Constants.PINK,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
            display: "none"
        }
    },
    productInfoPriceTextMobile: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
            display: "block",
            margin: "auto"
        }
    },
    productInfoStateMat: {
        marginTop: Constants.GET_SIZE(30),
        marginBottom: Constants.GET_SIZE(30)
    },
    productInfoStateMatText: {
    },
    productQuantity: {
        zIndex: 10
    },
    sectionTitle: {

        marginTop: Constants.GET_SIZE(20),
        marginBottom: Constants.GET_SIZE(10),
        color: Constants.FONT_MEDIUM_GRAY
    },
    productInfoSizeValue: {
        fontWeight: "bold",
        padding: Constants.GET_SIZE(10),
        alignSelf: "flex-start",
        marginRight: Constants.GET_SIZE(10),
        marginBottom: Constants.GET_SIZE(10),

    },
    productInfoSizeValueSelected: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderStyle: "solid"
    },
    productInfoColorValue: {
        fontWeight: "bold",
        padding: Constants.GET_SIZE(10),
        alignSelf: "flex-start",
        marginRight: Constants.GET_SIZE(10),
        marginBottom: Constants.GET_SIZE(10),
    },
    productInfoColorValueSelected: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderStyle: "solid"
    },
    productInfoColorsWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    productInfoColorsWrapperMobile: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    productInfoSizesWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    productInfoSizesWrapperMobile: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    productBloc2: {
        alignSelf: "start",
        marginBottom: Constants.GET_SIZE(50),
        marginLeft: Constants.GET_SIZE(124),
        marginRight: Constants.GET_SIZE(124),
        [theme.breakpoints.down('xs')]: {
            margin: "auto",
            marginTop: 30,
            marginBottom: 50,
            textAlign: "center"
        }
    },
    productionDescription: {
    },
    productionDescriptionTitle: {
        color: Constants.PINK,
        fontSize: Constants.FONT_SIZE_BIG,
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(25),
        marginTop: Constants.GET_SIZE(25),
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    productionDescriptionContent: {

        fontWeight: "bold",
    },
    productDeliveryInfoLine: {
        ...GlobalStyle.row,
        marginTop: Constants.GET_SIZE(10),
        [theme.breakpoints.down('xs')]: {
            marginTop: Constants.GET_SIZE(20),
        }
    },
    chronopostLogo: {
        width: 90,
        height: 20,
        [theme.breakpoints.down('xs')]: {
            width: 60,
            height: 13,
            margin: "auto"
        }
    },
    pointRelaisLogo: {
        width: 75,
        height: 18,
        [theme.breakpoints.down('xs')]: {
            width: 50,
            height: 12,
            margin: "auto"
        }
    },
    clickAndCollectLogo: {
        width: 29,
        height: 29,
        [theme.breakpoints.down('xs')]: {
            width: 20,
            height: 20,
            margin: "auto"
        }
    },
    handsLogo: {
        width: 29,
        height: 24,
        [theme.breakpoints.down('xs')]: {
            width: 20,
            height: 16,
            margin: "auto"
        }
    },
    logoWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        minWidth: 110,
        [theme.breakpoints.down('xs')]: {
            width: 73,
        }
    },
    deliveryDescription: {
        fontSize: Constants.GET_FONT_SIZE(12),
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },
    deliveryDescriptionItalic: {

        fontStyle: "italic",
        fontFamily: "Montserrat-Light"
    },
    quantityPicker: {
        height: Constants.GET_SIZE(37),
        width: Constants.GET_SIZE(50),
        backgroundColor: Constants.WHITE
    },
    quantityPickerItem: {
    },


    grayBlock: {
        width: "100%",
        backgroundColor: "#F3F3F3",
    },
    shopWrapper: {
        maxWidth: 1150,
        paddingTop: Constants.GET_SIZE(50),
        paddingBottom: Constants.GET_SIZE(35),
        margin: "auto"
    },
    shopTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: Constants.GET_SIZE(35)
    },
    shopTitleSubWrapper: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
            marginBottom: 15,
        }
    },
    shopTitle: {
        fontSize: Constants.FONT_SIZE_BIG,
        color: Constants.PINK,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    shopTitle2: {
        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    shopRow: {
        ...GlobalStyle.row,
        justifyContent: "space-around"
    },
    shopImage: {
        minHeight: 217,
        width: 188,
        // marginRight: Constants.GET_SIZE(50),
        borderRadius: "10%",
        objectFit: "contain",
        [theme.breakpoints.down('xs')]: {
            margin: "auto"
        }
    },
    shopDescriptionColumn: {
        minHeight: 217,
        maxWidth: 215,
        justifyContent: "space-between",
        // marginRight: Constants.GET_SIZE(70),
        [theme.breakpoints.down('xs')]: {
            maxWidth: "100%",
            margin: "auto",
            alignItems: "center",
            textAlign: "center"
        }
    },
    shopDescriptionDate: {

        color: Constants.FONT_LIGHT_GRAY
    },
    shopDescriptionName: {

        color: Constants.FONT_LIGHT_GRAY,
        marginBottom: Constants.GET_SIZE(15),
    },
    shopDescriptionCity: {

        textTransform: "uppercase",
        fontWeight: "bold"
    },
    shopDescriptionDivider: {

        fontWeight: "bold"
    },
    shopDescriptionNbProducts: {

        fontWeight: "bold"
    },
    shopDescriptionNbProducts2: {

        fontWeight: "bold"
    },
    shopOtherProductsWrapper: {
        alignItems: "center",
        textAlign: "center",
        maxWidth: Constants.GET_SIZE(645),
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            width: "90%",
            margin: "auto",
            marginTop: 20
        }
    },
    shopOtherProductsTitle: {

        color: Constants.FONT_LIGHT_GRAY
    },

    shopOtherProductsItem: {
        width: Constants.GET_SIZE(181),
        height: Constants.GET_SIZE(198),
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
        margin: Constants.GET_SIZE(10)
    },
    shopOtherProductsImage: {
        width: Constants.GET_SIZE(121),
        height: Constants.GET_SIZE(121),
    },
    shopOtherProductsSlider: {
        justifyContent: "flex-start",
    },

    similarProductsWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems:"center",
        width: "100%",
        maxWidth: 1150,
        margin: "auto",
        backgroundColor: Constants.WHITE,
        marginTop: Constants.GET_SIZE(50),
        [theme.breakpoints.down('xs')]: {
            width: "90%"
        }
    },
    similarProductsTitle: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        fontSize: Constants.FONT_SIZE_BIG,
        marginBottom: "30px",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    similarProductsList: {
        ...GlobalStyle.row,
        width: "100%",
        paddingBottom: "10px",
        justifyContent: "center",
        gap: '10px',
        marginBottom: Constants.GET_SIZE(20),
    },
    similarProductsListSlider: {
        width: "100%",
        paddingBottom: "20px",
        [theme.breakpoints.down('xs')]: {
            width: "97%"
        }
    },
    similarProductsListItem: {
        marginRight: Constants.GET_SIZE(20),
    },

    productBlocMap: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderStyle: "solid",
        marginLeft: Constants.GET_SIZE(124),
        marginRight: Constants.GET_SIZE(124),
        marginBottom: Constants.GET_SIZE(50),
        maxWidth: Constants.GET_SIZE(1115),
        width: "100%",
        height: 300,
        alignItems: "center",
        textAlign: "center"
    },

    productMap: {
        width: "100%",
        height: Constants.GET_SIZE(220),
        margin: "auto"
    },
    productMapAddress1: {

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        },
        color: Constants.PINK,
        marginTop: Constants.GET_SIZE(25),
    },

    previewBottomBar: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#A0A0A0A0",
        borderTopColor: Constants.PINK,
        borderTopStyle: "solid",
        borderTopWidth: 1,
        justifyContent: "center",
        display: "flex",
        zIndex: 99999,
        padding: 20
    },

    notFoundText: {
        fontSize: Constants.FONT_SIZE_BIG,
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE
        }
    },

    liveLinkWrapper: {
        borderColor: Constants.PINK,
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 5,
        marginBottom: 20,
        boxSizing: 'border-box'
    },
    liveLinkLabel: {
        textAlign: 'center',
        fontSize: Constants.FONT_SIZE_NORMAL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
        }
    },
    liveLink: {
        color: Constants.PINK,
        textDecoration: 'none',
        textAlign: 'center',
        fontSize: Constants.FONT_SIZE_NORMAL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_NORMAL_MOBILE
        }
    },

    productsLikeTooWrapper: {
        width: "100%",
        backgroundColor: Constants.WHITE,
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "20px",
        alignContent: "start",
        display: "grid",
        gridAutoRows: "auto",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 245px))",
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        }
    },

    swipeWrapComponent: {
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            float: 'left',
            maxWidth: 'initial',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            paddingLeft: 5,
            paddingRight: 5
        }
    },
}));
