import React from "react";
import {useFieldArray} from "react-hook-form";
import Form from "./Form";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as Constants from "../../Style/Constants";
import Text from "../../Screens/Component/FontText";

export default function ListField({register, errors, control, watch, setValue, name, label, required, form, options}) {
    const { fields, append, remove } = useFieldArray({ control, name, keyName: 'rhfId' });

    let width = '100%';
    if (options?.columns) {
        width = 100 / options.columns + "%";
    }

    return (
        <div style={{marginBottom: 20}}>
            <Text style={{marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, width: '100%' }}>{label} :</Text>
            <Grid container>
                {fields.map((field, index) => {
                    return (
                        <Grid key={field.rhfId} item style={{padding: 10, width: width, boxSizing: 'border-box'}}>
                            <Box style={{border: '1px solid #E8E8E8', padding: 10, borderRadius: 10}}>
                                <Form commonFields={{register, errors, control, arrayField: field, setValue, watch}} formName={name} index={index} data={form} />
                                <Button disableElevation size={'small'} color={'primary'} variant={"outlined"} onClick={() => remove(index)}>Supprimer</Button>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
            <Button disableElevation size={'small'} color={'primary'} variant={"contained"} onClick={() => append({youtubeLink: ""})}>Ajouter un élément</Button>
        </div>
    );
}
