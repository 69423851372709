// import Constants from 'expo-constants';
import React from "react";

const Constants = {
    manifest: {
        extra: {
            API: {
                host: process.env.REACT_APP_API_HOST,
                protocol: process.env.REACT_APP_API_PROTOCOL
            },
            Live: {
                host: process.env.REACT_APP_LIVE_HOST,
                port: process.env.REACT_APP_LIVE_PORT,
                app: process.env.REACT_APP_LIVE_APP,
                protocol: process.env.REACT_APP_LIVE_PROTOCOL
            },
            Chat: {
                host: process.env.REACT_APP_CHAT_HOST,
                port: process.env.REACT_APP_CHAT_PORT,
                protocol: process.env.REACT_APP_CHAT_PROTOCOL
            },
            SendInBlue: {
                protocol: process.env.REACT_APP_SENDINBLUE_PROTOCOL,
                host: process.env.REACT_APP_SENDINBLUE_HOST,
                port: process.env.REACT_APP_SENDINBLUE_PORT
            },
            Stripe: {
                protocol: process.env.REACT_APP_STRIPE_PROTOCOL,
                host: process.env.REACT_APP_STRIPE_HOST,
                port: process.env.REACT_APP_STRIPE_PORT,
                publicKey: process.env.REACT_APP_STRIPE_KEY,
            },
            Maps: {
                apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                url: process.env.REACT_APP_GOOGLE_MAPS_API_URL
            }
        }
    }
};

export const API_URL = Constants.manifest.extra.API.protocol + "://" + Constants.manifest.extra.API.host + "/";
export const LIVE_URL = Constants.manifest.extra.Live.protocol + "://" + Constants.manifest.extra.Live.host + ":" + Constants.manifest.extra.Live.port + "/rtc/v1/whep/?app=" + Constants.manifest.extra.Live.app ;
export const LIVE_URL_RMTP =  "rmtp://" + Constants.manifest.extra.Live.host + ":" + Constants.manifest.extra.Live.port + "/"  + Constants.manifest.extra.Live.app ;
export const CHAT_URL = Constants.manifest.extra.Chat.protocol + "://" + Constants.manifest.extra.Chat.host + ":" + Constants.manifest.extra.Chat.port;
export const SENDINBLUE_URL = Constants.manifest.extra.SendInBlue.protocol + "://" + Constants.manifest.extra.SendInBlue.host + ":" + Constants.manifest.extra.SendInBlue.port + "/";
export const STRIPE_URL = Constants.manifest.extra.Stripe.protocol + "://" + Constants.manifest.extra.Stripe.host + ":" + Constants.manifest.extra.Stripe.port + "/";

export const MAPS_API_URL = Constants.manifest.extra.Maps.url + "?key=" + Constants.manifest.extra.Maps.apiKey;
export const JWT_TOKEN_NAME = "JWToken";
export const JWT_TOKEN_PREFIX = "Bearer";

export const IMAGES_URL = API_URL + "media/cache/resolve/{mode}";
export const STRIPE_PUBLIC_KEY = Constants.manifest.extra.Stripe.publicKey;
