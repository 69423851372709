import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useGetBrandIdFromName = () => {
    const dispatch = useDispatch();
    const brands = useSelector(state => state.criteria.brands);
    const fetchCriteria = useCallback(() => {
        dispatch({ type: 'FETCH_CRITERIA_REQUESTED' });
    }, [dispatch]);

    useEffect(() => {
        if (brands.length === 0) {
            fetchCriteria();
        }
    }, [brands, fetchCriteria]);

    return useCallback((brandName) => {
        let result = -1;
        if (brandName) {
            for (const brand of brands) {
                if (brand.value.toLowerCase() === brandName.toLowerCase()) {
                    result = brand.id;
                    break;
                }
            }
        }

        return result;
    }, [brands]);
};