import React from "react";

import axios from "axios";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";


const useFetch = (entityName, id) => {
    const queryClient = useQueryClient();
    const [hasFetchedOnce, setHasFetchedOnce] = React.useState(false);
    let { status, data, error, isFetching } = useQuery({
        queryKey: [entityName, id],
        queryFn: async () => {
            if (id) {
                const {data} = await axios.get(
                    getApiUrl(entityName + 's/' + id),
                    {headers: setDefaultHeaders()}
                );
                return data;
            } else {
                return null;
            }
        }
    });

    React.useEffect(() => {
        if (!hasFetchedOnce && !isFetching) {
            setHasFetchedOnce(true);
        }
    }, [isFetching]);
    return { status, data, error, isFetching, hasFetchedOnce };
}

export default useFetch;
