import axios from 'axios';
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils';

export const FetchCriterionBrand = (params) => {
    return axios.get(getApiUrl('criterion_brands.json',  buildParams({...params, brands: []})));
};

export const SearchCriterionBrand = (search, itemsPerPage, page, sort, sortOrder) => {
    let url = getApiUrl('criterion_brands', {value: search, itemsPerPage, page, pagination: true, ["order[" + sort + "]"]: sortOrder});
    return axios.get(url);
};

export const UpdateCriterionBrand = (brand) => {
    let url = getApiUrl('criterion_brands/' + brand.id);
    return axios.put(url, brand, {headers: setDefaultHeaders()});
};

export const FetchCriterionColor = (params) => {
    return axios.get(getApiUrl('criterion_colors.json',  buildParams({...params, colors: []})));
};

export const FetchCriterionHeel = (params) => {
    return axios.get(getApiUrl('criterion_heels.json',  buildParams({...params, heels: []})));
};

export const FetchCriterionMaterial = (params) => {
    return axios.get(getApiUrl('criterion_materials.json',  buildParams({...params, materials: []})));
};

export const FetchCriterionSize = (params) => {
    return axios.get(getApiUrl('criterion_sizes.json',  buildParams({...params, sizes: []})));
};

export const FetchCriterionState = (params) => {
    return axios.get(getApiUrl('criterion_states.json',  buildParams({...params, states: []})));
};

export const FetchCriterionStyle = (params) => {
    return axios.get(getApiUrl('criterion_styles.json',  buildParams({...params, styles: []})));
};

const buildParams = ({brands, category, colors, deliveries, family, families, materials, price, shopTypes, sizes, states}) => {
    return {
        brands,
        category,
        chronopost: deliveries.includes(0) ? '1' : null,
        clickAndCollect: deliveries.includes(1) ? '1' : null,
        colors,
        family,
        families,
        handOver: deliveries.includes(2) ? '1' : null,
        materials,
        mondialRelay: deliveries.includes(0) ? '1' : null,
        "price[gt]": price ? price[0] : null,
        "price[lt]": price ? price[1] : null,
        "shop.type": shopTypes,
        sizes,
        states,
        pagination: false
    };
}
