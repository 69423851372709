import axios from 'axios';
import {API_URL} from '../../Config/API'
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils'

export const ApiUserCreate = (login, mail, password, newsletter, recaptchaToken) => {
    return axios.post(API_URL + 'api/users', {email: mail, plainPassword: password, username: login, newsletter: newsletter, recaptchaToken: recaptchaToken});
};

export const ApiFetchMe = (token) => {
    let url = getApiUrl('users/me');

    return axios.get(url, {headers: setDefaultHeaders({"Accept": "application/json"})});
};

export const ApiFetchUser = (id) => {
    let url = getApiUrl('users/' + id);

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const RegisterNewsletter = (mail) => {
    let url = getApiUrl('users/register_newsletter', {mail});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const EmptyWishlist = (mail) => {
    let url = getApiUrl('users/me/empty_wishlist', {mail});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const SaveUser = (user) => {
    let url = getApiUrl('users/' + user.id);

    return axios.put(url, user, {headers: setDefaultHeaders()});
};

export const BlockUser = (id, block, text) => {
    let url;
    if (block) {
        url = getApiUrl('users/' + id + "/block");
    } else {
        url = getApiUrl('users/' + id + "/unblock");
    }

    return axios.put(url, {"reason": text}, {headers: setDefaultHeaders()});
};

export const DeleteUser = (id) => {
    let url = getApiUrl('users/' + id + "/delete");

    return axios.put(url, {}, {headers: setDefaultHeaders()});
};

export const FetchUsers = (search, itemsPerPage, page) => {
    let url = getApiUrl('users', {search, itemsPerPage, page});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const FetchBestSellers = (itemsPerPage, page) => {
    let url = getApiUrl('users/best_sellers', {itemsPerPage, page});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const FetchBestBuyers = (itemsPerPage, page) => {
    let url = getApiUrl('users/best_buyers', {itemsPerPage, page});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const FetchAllUsers = () => {
    let url = getApiUrl('users/all');

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const ReceivePayment = (amount) => {
    let url = getApiUrl('users/me/receive_payment', {amount});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const SendFirebasePush = (title, body, push, mail, target) => {
    let url = getApiUrl('notification/send', {title, body, push, mail, target});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const SubscribeToFirebase = (token) => {
    let url = getApiUrl('notification/subscribe', {token});
    
    return axios.get(url, {headers: setDefaultHeaders()});
};

export const ConfirmEmail = (token) => {
    return axios.patch(API_URL + 'api/users/confirm-account', {token: token});
};