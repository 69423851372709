import React from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import StylesFunc from "../../../Style/MiddleOffice/LiveAddProductModalStyle"
import * as Constants from "../../../Style/Constants"
import ProductSquare from "../../Front/Component/ProductSquare"
import Paginator from "../../Front/Component/Paginator";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../../Utils/QueryUtils";


function Component({navigation, history, screenKey, token, user, visible, setVisible, liveProducts, addLiveProduct, removeLiveProduct, nbProductsMax, addLiveProducts, clearLiveProducts}) {
    let Styles = StylesFunc();
    let [loading, setLoading] = React.useState(false);
    let [products, setProducts] = React.useState([]);
    let [selectAll, setSelectAll] = React.useState(false);
    let [filter, setFilter] = React.useState("");

    React.useEffect(() => {
        if (user && !user.shop) {
            history.push("/creer_ma_boutique");
        }
    }, [user]);

    React.useEffect(() => {
        if (visible) {
            setLoading(true);
            axios.get(
                getApiUrl('products/my_products_for_live?pagination=false'),
                {headers: setDefaultHeaders()}
            ).then((response) => {
                setProducts(response.data['hydra:member']);
            }).catch((error) => {

            }).finally(() => {
                setLoading(false);
            });
        }
    }, [visible]);

    React.useEffect(() => {
        if (liveProducts.length < 1) {
            setSelectAll(false);
        }
    }, [liveProducts]);

    let isLiveProduct = (product) => {
        for (const liveProduct of liveProducts) {
            if (product.id === liveProduct.product.id) {
                return true;
            }
        }
        return false;
    }

    const clickSelectAll = () => {
        if (!selectAll) {
            let newProds = [];
            for (const p of products) {
                if (!isLiveProduct(p)) {
                    newProds.push(p);
                }
            }
            addLiveProducts(newProds);
        } else {
            clearLiveProducts();
        }
        setSelectAll(!selectAll);
    }

    const handleRemoveLiveProduct = (product) => {
        removeLiveProduct(product);
    }

    let filteredProducts = [];

    for (const p of products) {
        if (p.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            filteredProducts.push(p);
        }
    }

    return <Box>
        <Dialog open={visible} fullWidth>
            <Box style={{padding: 10}}>
                <TextField
                    variant={"outlined"}
                    label={"Rechercher"}
                    onChange={e => setFilter(e.target.value)}
                    value={filter}
                />
            </Box>
            <Box container className={Styles.container}>
                {!!loading && <Box className={Styles.loaderWrapper}>
                    <Box className={Styles.loaderSubWrapper}>
                        <CircularProgress color={"primary"} className={Styles.loader}/>
                    </Box>
                </Box>}
                <Box className={Styles.subcontentWrapper}>
                    {filteredProducts && <Grid container className={Styles.productsWrapper}>
                        {filteredProducts.map(product =>
                            <Grid item xs={12} sm={6} style={{marginBottom: 10, paddingRight: 5, paddingLeft: 5}}>
                                <ProductSquare smaller wrapperStyle={Styles.productWrapper} product={product} addLiveProduct={addLiveProduct} removeLiveProduct={handleRemoveLiveProduct} isLiveProduct={isLiveProduct(product)} canAddLiveProduct={liveProducts.length < nbProductsMax} />
                            </Grid>
                        )}
                    </Grid>}
                </Box>
            </Box>
            <Button disableElevation size={'small'} variant={!selectAll ? 'outlined' : 'contained'} color={'primary'} onClick={() => clickSelectAll()}>
                {!selectAll ? 'Tout sélectionner' : 'Tout déselectionner'}
            </Button>
            <Button disableElevation size={'small'} variant={'contained'} color={'primary'} onClick={() => setVisible(false)}>
                Confirmer
            </Button>
        </Dialog>
    </Box>
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        productsList: state.product.productsList,
        pagination: state.product.pagination,
        isLoading: state.loading.isLoadingGeneral
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchAllMyProducts: () => {
            dispatch({type: 'FETCH_ALL_MY_PRODUCTS_REQUESTED'})
        },
        removeProduct: (product) => {
            dispatch({type: 'REMOVE_PRODUCT_REQUESTED', id: product.id})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
