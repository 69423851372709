import { STORE_TOKEN } from '../Actions/Login'

const initialState = {
    displayedMenu: null
};


function reduc(state = initialState, action) {
    switch (action.type) {
        case "OPEN_DROPDOWN_MENU":
            return Object.assign({}, state, {
                displayedMenu: action.menu
            });
        case "CLOSE_DROPDOWN_MENU":
            return Object.assign({}, state, {
                displayedMenu: null
            });
    }

    return state
}

export default reduc