/*************************************************************************/
/********************************* SIZES *********************************/
/*************************************************************************/

export const MAQUETTE_WIDTH = 1440;
export const MAQUETTE_HEIGHT = 900;

export const MAQUETTE_WIDTH_VW = MAQUETTE_WIDTH / 100;
export const MAQUETTE_HEIGHT_VW = MAQUETTE_HEIGHT / 100;



/*************************************************************************/
/******************************* FUNCTIONS *******************************/
/*************************************************************************/

export const GET_SIZE = (size) => {
    return size + "px";
}

export const GET_FONT_SIZE = (size) => {
    return (size / 16) + "rem";
    // return (size / MAQUETTE_WIDTH_VW) + "vw"
}


/*************************************************************************/
/******************************** COLORS *********************************/
/*************************************************************************/

export const WHITE = "white";
export const BLACK = "black";
export const PINK = "#F8ADB1";
export const BORDER_GRAY = "#E8E8E8";
export const BORDER_DARK_GRAY = "#C4C4C4";
export const BACKGROUND_GRAY = "#DEDEDE";
export const BACKGROUND_DARK_GRAY = "#6B6B6B";
export const BACKGROUND_LIGHT_GRAY = "#F8F8F8";
export const GRAY_FOOTER = "#282E33";
export const LIGHT_GRAY = "#979797";
export const FONT_GRAY = "#737373";
export const FONT_LIGHT_GRAY = "#CACACA";
export const FONT_MEDIUM_GRAY = "#B3B3B3";
export const FONT_DARK_GRAY = "#46494C";
export const COLOR_ERROR = "#CA8A8F";

export const BACKGROUND_LIVE_CHAT_GRAY = "#E2E2E2";

/*************************************************************************/
/****************************** FONT_SIZES *******************************/
/*************************************************************************/

export const FONT_SIZE_8        = GET_FONT_SIZE(8);
export const FONT_SIZE_9        = GET_FONT_SIZE(9);
export const FONT_SIZE_10       = GET_FONT_SIZE(10);
export const FONT_SIZE_11       = GET_FONT_SIZE(11);
export const FONT_SIZE_12       = GET_FONT_SIZE(12);
export const FONT_SIZE_13       = GET_FONT_SIZE(13);
export const FONT_SIZE_14       = GET_FONT_SIZE(14);
export const FONT_SIZE_15       = GET_FONT_SIZE(15);
export const FONT_SIZE_16       = GET_FONT_SIZE(16);
export const FONT_SIZE_17       = GET_FONT_SIZE(17);
export const FONT_SIZE_18       = GET_FONT_SIZE(18);
export const FONT_SIZE_19       = GET_FONT_SIZE(19);
export const FONT_SIZE_20       = GET_FONT_SIZE(20);
export const FONT_SIZE_21       = GET_FONT_SIZE(21);
export const FONT_SIZE_22       = GET_FONT_SIZE(22);
export const FONT_SIZE_23       = GET_FONT_SIZE(23);
export const FONT_SIZE_24       = GET_FONT_SIZE(24);
export const FONT_SIZE_28       = GET_FONT_SIZE(28);
export const FONT_SIZE_30       = GET_FONT_SIZE(30);
export const FONT_SIZE_36       = GET_FONT_SIZE(36);
export const FONT_SIZE_38       = GET_FONT_SIZE(38);

export const FONT_SIZE_SMALL    = FONT_SIZE_10;
export const FONT_SIZE_NORMAL   = FONT_SIZE_14;
export const FONT_SIZE_MEDIUM   = FONT_SIZE_18;
export const FONT_SIZE_BIG      = FONT_SIZE_24;
export const FONT_SIZE_BIGGER   = FONT_SIZE_30;
export const FONT_SIZE_BIGGEST  = FONT_SIZE_38;

export const FONT_SIZE_SMALL_MOBILE    = FONT_SIZE_10;
export const FONT_SIZE_NORMAL_MOBILE   = FONT_SIZE_12;
export const FONT_SIZE_MEDIUM_MOBILE   = FONT_SIZE_16;
export const FONT_SIZE_BIG_MOBILE      = FONT_SIZE_19;
export const FONT_SIZE_BIGGER_MOBILE   = FONT_SIZE_24;
export const FONT_SIZE_BIGGEST_MOBILE  = FONT_SIZE_30;

export const CHECKBOX_SIZE_BIG  = GET_FONT_SIZE(38);
