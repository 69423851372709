import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon"

function HeartIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="m 0.35811888,8.4985977 c 0.093333,4.7372343 3.67886202,7.7789473 3.71464202,7.8082143 2.803114,2.600182 7.0341521,5.340364 7.9519461,5.925026 0.917793,-0.583129 5.145765,-3.32331 7.936413,-5.912693 0.05133,-0.0416 3.638375,-3.083313 3.730174,-7.8344139 -0.0062,-0.2415984 -0.209998,-6.2527577 -6.278157,-6.2512177 l -0.02333,-0.00154 c -0.250465,0 -2.52465,0.06616 -5.142632,2.3340109 L 12.024707,4.758303 11.802242,4.5659843 C 9.1842589,2.298133 6.9100749,2.2319734 6.6596099,2.2319734 l -0.02333,0.00154 C 0.56811748,2.2319734 0.36433888,8.2431327 0.35811888,8.4985977 Z"
                id="path3761"
                style={{clipRule: "evenodd", fillRule: "evenodd", stroke: "currentColor", strokeWidth: 0.66666216}} />
        </SvgIcon>
    )
}

export default HeartIcon
