import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    paginator: {
        ...GlobalStyle.rowCenteredHW,
        paddingBottom: Constants.GET_SIZE(50),
        backgroundColor: Constants.WHITE,
        width: "100%",
    },
    page: {

        color: Constants.PINK,
        marginLeft: Constants.GET_SIZE(10),
        marginRight: Constants.GET_SIZE(10),
    },
    currentPage: {
        color: Constants.BLACK
    },
    smallBottom: {
        paddingBottom: 10
    },
    button: {
        minWidth: 0
    },
    hidden: {
        visibility: 'hidden'
    }
}));
