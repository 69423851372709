import React from 'react';
import { connect } from 'react-redux'

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";


import Text from "../../Component/FontText";

import ConfirmationModalStylesFunc from "../../../Style/ConfirmationModalStyle"
import CustomCheckbox from "../../Component/CustomCheckbox";
import * as Constants from "../../../Style/Constants";
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../../Utils/QueryUtils";
import {CircularProgress} from "@material-ui/core";
import Link from "@material-ui/core/Link";


function Component ({visible, setVisible, updateMe}) {
    let ConfirmationModalStyles = ConfirmationModalStylesFunc();

    const [ignore, setIgnore] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const onConfirm = () => {
        if (ignore) {
            setLoading(true);
            axios.get(
                getApiUrl('me/ignore_live_create_popup'),
                {headers: setDefaultHeaders()}
            ).then(response => {
                updateMe({
                    hideLiveCreatePopup: true
                })
                setVisible(false);
            }).catch(error => {

            }).finally(() => {
                setLoading(false);
            })
        } else {
            setVisible(false);
        }
    }

    let _renderButtons = () => {
        return (
            <Grid container justify={"center"}>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"} onClick={onConfirm} disabled={loading}>
                    J'ai compris
                    {loading && <Grid container justify={"center"} alignItems={"center"} style={{position: "absolute", left: 0, right: 0, top: 0, bottom:0}}>
                        <CircularProgress color={"primary"} size={22} />
                    </Grid>}
                </Button>
            </Grid>
        );
    }

    let _renderModalContent = () => (
        <Box className={ConfirmationModalStyles.modalContent}>
            <Text className={ConfirmationModalStyles.modalTitle}>Création du live</Text>
            <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam tincidunt fermentum nunc nec molestie. In vulputate dolor vel leo laoreet aliquam. Donec et ornare arcu. Aliquam volutpat mollis molestie. Pellentesque vehicula nec metus vel rhoncus. Cras ut euismod mauris. Cras accumsan tortor lacinia massa tristique vulputate. Etiam nec elit cursus, gravida odio sed, feugiat dolor. Pellentesque quis purus dolor. Phasellus imperdiet tempor ante, quis consectetur odio molestie vel.</Text>

            <Grid container style={{marginBottom: 10}}>
                <CustomCheckbox
                    containerClass={ConfirmationModalStyles.checkBox}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={ConfirmationModalStyles.checkboxLabel}>Ne plus afficher ce message</Text>}
                    checked={ignore} onPress={() => setIgnore(!ignore)}
                    size={Constants.CHECKBOX_SIZE_BIG} />
                {_renderButtons()}
            </Grid>
        </Box>
    );

    return (
        <Dialog open={visible} fullWidth>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateMe: (payload) => {
            dispatch({type: 'UPDATE_ME', payload})
        },
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;