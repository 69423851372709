import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    modalTitle: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(30),
        width: "100%",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    button: {
        backgroundColor: Constants.PINK,
        padding: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(30),
        paddingRight: Constants.GET_SIZE(30)
    },
    buttonLabel: {
        ...GlobalStyle.uppercaseText,
        color: Constants.WHITE,
        fontWeight: "bold"
    },
    modalContent: {
        backgroundColor: "white",
        padding: Constants.GET_SIZE(30),
        // justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
    },
    buttonRow: {
        width: "100%",
        alignItems: "center",
        marginTop: 10,
        flexDirection: "row",
        justifyContent: "space-around"
    },
    checkboxLabel: {
        color: Constants.FONT_GRAY
    },
    checkBox: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.WHITE,
    },
}));
