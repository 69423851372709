import React from "react";

import Box from "@material-ui/core/Box";

import SocialShareBarStylesFunc from '../../Style/SocialShareBarStyle'

import { FacebookShare, WhatsappShare, TwitterShare, EmailShare, LinkedinShare } from 'react-share-kit';

function Component({link, title}) {
    let SocialShareBarStyles = SocialShareBarStylesFunc();

    return (
        <Box className={SocialShareBarStyles.wrapper}>
            <FacebookShare size={'32px'} style={{marginLeft: "5px"}} url={link} quote={title} />
            <WhatsappShare size={'32px'} style={{marginLeft: "5px"}} url={link} title={title} />
            <TwitterShare  size={'32px'} style={{marginLeft: "5px"}} url={link} title={title} />
            <LinkedinShare size={'32px'} style={{marginLeft: "5px"}} url={link} />
            <EmailShare    size={'32px'} style={{marginLeft: "5px"}} url={link} subject={title} body="body" />
        </Box>
    );
}

export default Component;
