import React from 'react';
import { connect } from 'react-redux'

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";


import Text from "../../Component/FontText";

import ConfirmationModalStylesFunc from "../../../Style/ConfirmationModalStyle"
import CustomCheckbox from "../../Component/CustomCheckbox";
import * as Constants from "../../../Style/Constants";
import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../../Utils/QueryUtils";
import {CircularProgress} from "@material-ui/core";
import Link from "@material-ui/core/Link";


function Component ({visible, setVisible, showCheckbox, isAdmin}) {
    let ConfirmationModalStyles = ConfirmationModalStylesFunc();

    const [ignore, setIgnore] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const onConfirm = () => {
        if (showCheckbox && ignore) {
            setLoading(true);
            axios.get(
                getApiUrl('me/ignore_live_popup'),
                {headers: setDefaultHeaders()}
            ).then(response => {
                setVisible(false);
            }).catch(error => {

            }).finally(() => {
                setLoading(false);
            })
        } else {
            setVisible(false);
        }
    }

    let _renderButtons = () => {
        return (
            <Grid container justify={"center"}>
                <Button disableElevation size={"small"} variant={"contained"} color={"primary"} onClick={onConfirm} disabled={loading}>
                    J'ai compris
                    {loading && <Grid container justify={"center"} alignItems={"center"} style={{position: "absolute", left: 0, right: 0, top: 0, bottom:0}}>
                        <CircularProgress color={"primary"} size={22} />
                    </Grid>}
                </Button>
            </Grid>
        );
    }

    let _renderModalContent = () => (
        <Box className={ConfirmationModalStyles.modalContent}>
            <Text className={ConfirmationModalStyles.modalTitle}>Bienvenue sur le live !</Text>
            {!isAdmin && <Text>Pour une expérience optimale voici quelques explications de son fonctionnement.</Text>}
            {isAdmin && <Text>Bienvenue dans votre propre live Violette Sauvage.</Text>}
            <br />
            {!isAdmin && <Text>1) Vous pouvez proposer un prix d'achat pour chaque produit. Pour cela, cliquez sur l'un des produits pour lequel vous sougaitez faire votre proposition. Puis inscrivez le montant (sous cette forme là : 68) dans le champ "Proposition" prévu à cet effet. Si vous êtes plusieurs acheteurs à faire une proposition pour le même produit, le vendeur aura le choix d'accepter la proposition qu'il jugera la plus intéressante pour lui.</Text>}
            {isAdmin && <Text>Pour pouvoir configurer correctement votre live, vous pouvez accéder à notre tutorial ici : <Link target={"_blank"} href={"/tuto_live"}>Cliquez ici</Link></Text>}
            <br />
            {!isAdmin && <Text>2) Vous pouvez acheter un produit en direct, sauf quand un acheteur a déjà fait une proposition.</Text>}
            {isAdmin && <Text>Vous pourrez trouver ce tutorial dans la catégorie "Live" de la partie "Mon compte"</Text>}
            <br />

            {showCheckbox && <Grid container style={{marginBottom: 10}}>
                <CustomCheckbox
                    containerClass={ConfirmationModalStyles.checkBox}
                    checkedColor={Constants.PINK}
                    uncheckedColor={Constants.FONT_LIGHT_GRAY}
                    title={<Text className={ConfirmationModalStyles.checkboxLabel}>Ne plus afficher ce message</Text>}
                    checked={ignore} onPress={() => setIgnore(!ignore)}
                    size={Constants.CHECKBOX_SIZE_BIG} />
                {_renderButtons()}
            </Grid>}
            {!showCheckbox && _renderButtons()}
        </Box>
    );

    return (
        <Dialog open={visible} fullWidth>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        mondialRelayPoints: state.relayPoints.mondialRelayPoints,
        chronoRelayPoints: state.relayPoints.chronoRelayPoints,
        addresses: state.relayPoints.addresses
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchRelayPoints: (relayType, city, zipcode) => {
            dispatch({type: 'FETCH_RELAY_POINTS_REQUESTED', relayType, city, zipcode})
        },
        searchAddresses: (zipcode) => {
            dispatch({type: 'SEARCH_ADDRESS_REQUESTED', zipcode})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;