import React from "react";

import StylesFunc from "../../Style/AssociationsStyle";

function callPlayer(frame_id, func, args) {
    var iframe = document.getElementById(frame_id);
    if (iframe && iframe.tagName.toUpperCase() != 'IFRAME') {
        iframe = iframe.getElementsByTagName('iframe')[0];
    }
    if (iframe) {
        // Frame exists,
        iframe.contentWindow.postMessage(JSON.stringify({
            "event": "command",
            "func": func,
            "args": args || [],
            "id": frame_id
        }), "*");
    }
}

function Component({id, youtubeLink}) {
    let Styles = StylesFunc();

    const [playerState, setPlayerState] = React.useState(false);
    const [x, setX] = React.useState(-50);

    let handleMouseDown = e => {
        setX(e.screenX);
    }

    const toggleState = e => {
        const delta = Math.abs(e.screenX - x);

        if (delta < 10) {
            if (playerState) {
                setPlayerState(false);
                callPlayer("youtube-frame-" + id, "pauseVideo");
            } else {
                setPlayerState(true);
                callPlayer("youtube-frame-" + id, "playVideo");
            }
        } else {
            setPlayerState(false);
            callPlayer("youtube-frame-" + id, "pauseVideo");
        }

        setX(-50);
    }

    return <div style={{position: "relative", width: '100%', height: '100%'}}>
        <iframe
            id={"youtube-frame-" + id}
            className={Styles.associationCardImage}
            src={youtubeLink + "?rel=0&enablejsapi=1"}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
        <div className={Styles.youtubeOverlay} onClick={toggleState} onMouseDown={handleMouseDown}></div>
    </div>;
}

export default Component

