import { STORE_TOKEN } from '../Actions/Login'

const initialState = {
    feed: []
};


function reduc(state = initialState, action) {
    switch (action.type) {
        case "FETCH_INSTAGRAM_FEED_SUCCEEDED":
            return Object.assign({}, state, {
                feed: action.instaFeed
            });
    }

    return state
}

export default reduc