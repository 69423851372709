import { call, put, takeLatest, cancelled } from 'redux-saga/effects'
import { SearchAddress } from '../Endpoint/Nominatim'
import { handleError } from "../../Utils/SagaUtils";

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* searchAddress(action) {
    try {
        const addresses = yield call(SearchAddress, action.zipcode);

        yield put({type: "SEARCH_ADDRESS_SUCCEEDED", addresses: addresses.data});
    } catch (e) {
        yield put(handleError("SEARCH_ADDRESS_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}


function* mySaga() {
    yield takeLatest("SEARCH_ADDRESS_REQUESTED", searchAddress);
}

export default mySaga;