import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"

import Text from "../Component/FontText"
import StylesFunc from '../../Style/Back/BaseStyle'
import LeftMenu from "../Middle/Partial/LeftMenu";
import Paginator from "../Front/Component/Paginator";
import * as Constants from "../../Style/Constants";

function Component({navigation, history, token, user, content, title, currentScreen, pagination, page, setPage}) {
    let Styles = StylesFunc();
    React.useEffect(() => {
        if (!token) {
            history.replace("/login", {redirectTo: history.location.pathname})
        }
    },[token]);

    React.useEffect(() => {
        if (user.roles.indexOf("ROLE_ADMIN") === -1) {
            history.replace("/")
        }
    },[user]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box className={Styles.container}>
            <Text className={Styles.title}>{title}</Text>
            <Box className={Styles.contentAndMenuWrapper}>
                <Hidden xsDown>
                    <LeftMenu currentPage={currentScreen} />
                </Hidden>
                <Box className={Styles.contentWrapper}>
                    {content}
                </Box>
            </Box>
            {pagination && <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE}/>}
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me
    }
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
