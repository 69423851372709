import React, { useState } from 'react';

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Text from "../../Component/FontText";
import Color from "./Color";
import Picker from "../../Component/CustomPicker";

import { connect } from 'react-redux'

import AddToCartModalStylesFunc from "../../../Style/AddToCartModalStyle"
import * as Constants from "../../../Style/Constants"
import Grid from "@material-ui/core/Grid";


function Component ({visible, setVisible, product, addToCart}) {
    let AddToCartModalStyles = AddToCartModalStylesFunc();
    let [quantity, setQuantity] = React.useState(1);
    let [size, setSize] = React.useState(product.sizes[0]);
    let [color, setColor] = React.useState(product.colors[0]);

    React.useEffect(() => {
        setSize(product.sizes[0]);
        setColor(product.colors[0]);
    }, [product]);

    let onConfirm = () => {
        console.log(product, quantity, color, size);
        addToCart(product, quantity, color, size);
        setVisible(false);
    };

    let onCancel = () => {
        setVisible(false);
    };

    let _renderButtons = () => (
        <Grid container justify={"space-between"}>
            <Button disableElevation size={"small"} variant={"contained"} color={"primary"} onClick={onConfirm} className={AddToCartModalStyles.button}>
                Ajouter au panier
            </Button>
            <Button disableElevation size={"small"} variant={"contained"}  onClick={onCancel}>
                Annuler
            </Button>
        </Grid>
    );

    let onPickColor = (color) => {
        setColor(color);
    };

    let onPickSize = (size) => {
        setSize(product.sizes[size]);
    };

    let getColorValues = () => {
        const values = [];
        for (const color of product.colors) {
            values.push(
                {
                    react: <Color
                                color={color}
                                circleSize={Constants.FONT_SIZE_NORMAL}
                                textStyle={{fontSize: Constants.FONT_SIZE_NORMAL}}
                                wrapperStyle={{
                                    padding: Constants.GET_SIZE(10),
                                    paddingLeft: 0
                                }}
                            />,
                    value: color,
                    label: <Color
                                color={color}
                                circleSize={Constants.FONT_SIZE_NORMAL}
                                textStyle={{fontSize: Constants.FONT_SIZE_NORMAL}}
                                wrapperStyle={{
                                    padding: Constants.GET_SIZE(10),
                                    paddingLeft: 0
                                }}
                            />
                }
            );
        }
        return values;
    };

    let getSizeValues = () => {
        const values = [];
        for (const key in product.sizes) {
            values.push({
                value: product.sizes[key].value,
                label: product.sizes[key].value
            })
        }
        return values;
    };

    let _renderModalContent = () => (
        <Box className={AddToCartModalStyles.modalContent}>
            <Text className={AddToCartModalStyles.modalTitle}>Ajouter au panier</Text>

            <Box className={AddToCartModalStyles.controls}>
                <Box className={AddToCartModalStyles.controlWrapper} style={{zIndex:1000}}>
                    <Text className={AddToCartModalStyles.controlLabel}>Quantité : </Text>
                    <Picker maxValue={product.stock}
                            defaultValue={"1"}
                            onPickValue={setQuantity}
                            width={200}
                    />
                </Box>
                <Box className={AddToCartModalStyles.controlWrapper} style={{zIndex:999}}>
                    <Text className={AddToCartModalStyles.controlLabel}>Couleur : </Text>
                    <Picker
                        reactValues={getColorValues()}
                        defaultValue={color}
                        onPickValue={onPickColor}
                        width={200}
                    />
                </Box>
                {product.sizes && product.sizes.length > 0 && size &&
                <Box className={AddToCartModalStyles.controlWrapper} style={{zIndex:998}}>
                        <Text className={AddToCartModalStyles.controlLabel}>Taille : </Text>
                        <Picker
                            simpleValues={getSizeValues()}
                            defaultValue={size.value}
                            onPickValue={onPickSize}
                            width={200}
                        />
                    </Box>
                }
            </Box>

            <Box className={AddToCartModalStyles.buttonRow}>
                {_renderButtons()}
            </Box>
        </Box>
    );

    return (
        <Dialog open={visible}>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        mondialRelayPoints: state.relayPoints.mondialRelayPoints,
        chronoRelayPoints: state.relayPoints.chronoRelayPoints,
        addresses: state.relayPoints.addresses
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addToCart: (product, quantity, color, size) => {
            dispatch({type: 'ADD_TO_CART', product, quantity, color, size})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;