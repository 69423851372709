import axios from "axios";
import {useQuery, useQueryClient} from "react-query";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";


const useApiForm = (entityName) => {
    const queryClient = useQueryClient();
    const { status, data, error, isFetching } = useQuery({
        queryKey: entityName + 'Form',
        queryFn: async () => {
            const { data } = await axios.get(
                getApiUrl('form/' + entityName),
                {headers: setDefaultHeaders()}
            );
            return data;
        }
    });

    return {status, data, error, isFetching}
}

export default useApiForm;
