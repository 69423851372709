import { select, call, put, takeLatest, cancelled } from 'redux-saga/effects'
import { FetchFeed } from "../Endpoint/Instagram";
import { handleError } from "../../Utils/SagaUtils";

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* fetchFeed(action) {
    try {
        let result = yield call(FetchFeed);
        const instaFeed = result.data.data;
        yield put({type: "FETCH_INSTAGRAM_FEED_SUCCEEDED", instaFeed});
    } catch (e) {
        yield put(handleError("FETCH_INSTAGRAM_FEED_FAILED", e));
    } finally {
        if (yield cancelled()) {
            yield put({type: "REMOVE_LOADER", general: false});
        }
    }
}

function* mySaga() {
    yield takeLatest("FETCH_INSTAGRAM_FEED_REQUESTED", fetchFeed);
}

export default mySaga;