import axios from 'axios';
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils'

export const FetchParams = () => {
    let url = getApiUrl('params', {});

    return axios.get(url, {headers: setDefaultHeaders()});
};

export const SaveParam = (paramName, paramValue) => {
    let url = getApiUrl('params', {});

    return axios.post(url, {"value": paramValue, "name": paramName}, {headers: setDefaultHeaders()});
};
