import { makeStyles } from '@material-ui/core/styles';
import * as Constants from "../Style/Constants";


let topRow = makeStyles((theme) => ({
    wrapper: {
        alignItems: "center",
        backgroundColor: Constants.PINK,
        height: 50,
        justifyContent: 'flex-end'
    },
    itemWrapper: {
        height: "100%",
        maxWidth: Constants.GET_SIZE(180),
        [theme.breakpoints.down('sm')]: {
            width: "33%"
        }
    },
    grayItemWrapper: {
        backgroundColor: Constants.BACKGROUND_GRAY
    },
    link: {
        textDecoration: "none",
        color: Constants.BLACK,
    },
    linkTypo: {
        textTransform: "uppercase",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    "link-gray": {
        textDecoration: "none",
        color: Constants.BLACK,
        backgroundColor: Constants.BACKGROUND_GRAY
    },
    "linkTypo-gray": {
        textTransform: "uppercase",
        textAlign: "center"
    },
    icon: {
        width: Constants.FONT_SIZE_NORMAL,
        height: Constants.FONT_SIZE_NORMAL,
        marginRight: 5
    },
    messagesNotification: {
        lineHeight: "20px",
        position: "absolute",
        backgroundColor: Constants.WHITE,
        width: 20,
        height: 20,
        borderRadius: 50,
        top: 5,
        right: 5,
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    }
}));

let secondRow = makeStyles((theme) => ({
    wrapper: {
        alignItems: "center",
        backgroundColor: Constants.WHITE,
        height: Constants.GET_SIZE(93),
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center"
        }
    },
    drawerButton: {
        position: "absolute",
        left: 20,
        top: 5
    },
    backButton: {
        position: "absolute",
        left: 25,
        bottom: 5
    },
    logoWrapper: {
        cursor: "pointer",
        maxWidth: 205
    },
    logo: {
        width: Constants.GET_SIZE(135),
        height: Constants.GET_SIZE(62),
        marginLeft: Constants.GET_SIZE(30),
        marginRight: Constants.GET_SIZE(30),
    },
    item: {
        textTransform: "uppercase",
        fontWeight: "bold",
        width: "max-content",
        marginLeft: Constants.GET_SIZE(8),
        marginRight: Constants.GET_SIZE(8),
        fontSize: Constants.GET_SIZE(13),
    },
    caretIcon: {
        marginLeft: -7,
        fontSize: Constants.FONT_SIZE_28,
        color: Constants.PINK,
        verticalAlign: "middle",
        transition: "transform 0.2s",
        transform: "rotate(-0deg)"
    },
    caretIconOpened: {
        marginLeft: -7,
        fontSize: Constants.FONT_SIZE_28,
        color: Constants.PINK,
        verticalAlign: "middle",
        transform: "rotate(-180deg)"
    },
    dropdownWrapper: {
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        borderStyle: "solid",
        boxShadow: "none",
        marginTop: 22
    },
    dropdownHeader: {
        backgroundColor: Constants.WHITE,
        paddingLeft: Constants.GET_SIZE(20),
        paddingRight: Constants.GET_SIZE(20),
    },
    dropdownBody: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        width: "100%",
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::after': { //
            content: '""',
            margin: 'auto',
            display: 'block',
            position: "absolute",
            top: 1,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderLeftWidth: 20,
            borderRightWidth: 20,
            borderBottomWidth: 20,
            borderColor: `transparent transparent white transparent`,
        },
        '&::before': {
            content: '""',
            position: "absolute",
            top: 0,
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderLeftWidth: 20,
            borderRightWidth: 20,
            borderBottomWidth: 20,
            borderColor: 'transparent transparent ' + Constants.BORDER_GRAY + ' transparent',
        },
    },
    arrowBorder: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderLeftWidth: Constants.GET_SIZE(16),
            borderRightWidth: Constants.GET_SIZE(16),
            borderBottomWidth: Constants.GET_SIZE(16),
            borderColor: `transparent transparent #00FF00 transparent`,
        },
    },
    messagesNotification: {
        position: "absolute",
        backgroundColor: Constants.PINK,
        fontSize: 13,
        lineHeight: "20px",
        width: 20,
        height: 20,
        borderRadius: 50,
        top: 0,
        right: -23,
        textAlign: "center",
        color: Constants.BLACK,
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    burgerMessagesNotification: {
        position: "absolute",
        backgroundColor: Constants.PINK,
        fontSize: 13,
        lineHeight: "20px",
        width: 20,
        height: 20,
        borderRadius: 50,
        top: 5,
        right: 5,
        textAlign: "center",
        color: Constants.BLACK,
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    }
}));

let thirdRow = makeStyles((theme) => ({
    wrapper: {
        alignItems: "center",
        backgroundColor: Constants.PINK,
        height: 50,
        [theme.breakpoints.down('md')]: {
            height: 90,
        }
    },
    itemWrapper: {
        maxWidth: 300,
        [theme.breakpoints.down('md')]: {
            maxWidth: 500,
        }
    },
    item: {
        fontSize: Constants.FONT_SIZE_14,
        textTransform: "uppercase",
        marginLeft: Constants.GET_SIZE(42),
        marginRight: Constants.GET_SIZE(10),
        color: Constants.WHITE,
        fontWeight: "bold"
    },
    searchMenu: {

    },
    searchMenuItem: {
        fontSize: Constants.FONT_SIZE_14,
        textTransform: "uppercase",
        paddingLeft: Constants.GET_SIZE(20),
        paddingRight: Constants.GET_SIZE(20),
        paddingTop: Constants.GET_SIZE(15),
        paddingBottom: Constants.GET_SIZE(15),
        color: Constants.BLACK,
        fontWeight: "bold"
    },
    searchInputNotchedOutline: {
        borderRadius: 45,
    },
    searchInput: {
        fontSize: Constants.FONT_SIZE_14,
        maxWidth: Constants.GET_SIZE(700),
        height: Constants.GET_SIZE(31),
        backgroundColor: "white",
        borderRadius: 45,
        paddingLeft: Constants.FONT_SIZE_NORMAL,
        paddingRight: Constants.FONT_SIZE_NORMAL,
    },
    searchInputWrapper: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: 20,
            paddingRight: 20,
        }
    }
}));

let drawer = makeStyles((theme) => ({
    collapse: {
        paddingTop: 0,
        paddingBottom: 0
    },
    subList: {
        // paddingLeft: 10
    },
    listItem: {
        cursor: "pointer",
    },
    notificationText: {
        position: "relative",
        maxWidth: "max-content"
    },
    largeLink: {
        width: "100%"
    },
    subListItem: {
        paddingLeft: 25
    },
    subSubListItem: {
        paddingLeft: 34
    },
    header: {
        position: "relative",
        padding: 20,
        paddingLeft: 40,
        paddingRight: 40,
        borderBottomStyle: "solid",
        borderWidth: 2,
        borderColor: Constants.BORDER_GRAY,
    },
    headerTitle: {
        margin: "auto",
        textAlign: "center",
    },
    headerArrowBack: {
        cursor: "pointer",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0
    }
}));

export default function Style() {
    return {
        topRow: topRow(),
        secondRow: secondRow(),
        thirdRow: thirdRow(),
        drawer: drawer(),
    }
}

