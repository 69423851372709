import React from "react";
import { Picker } from "react-native";
import {ScrollView, TouchableOpacity} from "react-native-web";

import Box from "@material-ui/core/Box";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Moment from "moment";

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/Back/MoneyDashboardStyle"
import * as Constants from "../../Style/Constants";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import TextInputIcon from "../Front/Component/TextInputIcon";

registerLocale('fr', fr)


function Component({history, navigation, token, bestSellers, bestBuyers, fetchStatsFromTo, fetchNbTransactions, fetchNbReturns, fetchNbDisputes, loadBestSellers, loadBestBuyers, fromToStatDays, nbTransacDay, nbTransacWeek, nbTransacMonth, nbTransacYear, nbReturnsDay, nbReturnsMonth, nbReturnsYear, nbReturnsForUser, nbDisputes, countReturnsForUser, fetchNbShops,fetchNbProducts, nbShops, nbProducts, fetchAllUsers, allUsersList}) {
    let Styles = StylesFunc();
    let [startDate, setStartDate] = React.useState(Moment().startOf('year').toDate());
    let [endDate, setEndDate] = React.useState(Date.now());
    let [perDay, setPerDay] = React.useState(Date.now());
    let [perWeek, setPerWeek] = React.useState(Date.now());
    let [perMonth, setPerMonth] = React.useState(Date.now());
    let [perYear, setPerYear] = React.useState(Date.now());

    let [nbBestSellers, setNbBestSellers] = React.useState(10);
    let [nbBestBuyers, setNbBestBuyers] = React.useState(10);

    let [sellerAutocomp, setSellerAutocomp] = React.useState("");
    let [displayUserAutocomplete, setDisplayUserAutocomplete] = React.useState(false);

    let [searchUsersList, setSearchUsersList] = React.useState([]);

    React.useEffect(() => {
        fetchStatsFromTo(Moment(startDate).unix(), Moment(endDate).unix());
    }, [startDate, endDate]);

    React.useEffect(() => {
        fetchStatsFromTo(Moment(startDate).unix(), Moment(endDate).unix());
    }, [startDate, endDate]);

    React.useEffect(() => {
        let day = Moment(perDay);
        fetchNbTransactions("DAY", day.year(), day.month() + 1, day.date());
    }, [perDay]);

    React.useEffect(() => {
        let week = Moment(perWeek);
        fetchNbTransactions("WEEK", week.year(), null, week.week());
    }, [perWeek]);

    React.useEffect(() => {
        let month = Moment(perMonth);
        fetchNbTransactions("MONTH", month.year(), month.month() + 1, null);
    }, [perMonth]);

    React.useEffect(() => {
        let year = Moment(perYear);
        fetchNbTransactions("YEAR", year.year(), null, null);
    }, [perYear]);

    React.useEffect(() => {
        let month = Moment(perMonth);
        fetchNbReturns("MONTH", month.year(), month.month() + 1, null);
    }, [perMonth]);

    React.useEffect(() => {
        let day = Moment(perDay);
        fetchNbReturns("DAY", day.year(), day.month() + 1, day.date());
    }, [perDay]);

    React.useEffect(() => {
        loadBestSellers(nbBestSellers);
    }, [nbBestSellers]);

    React.useEffect(() => {
        loadBestBuyers(nbBestBuyers);
    }, [nbBestBuyers]);

    React.useEffect(() => {
        setSearchUsersList(allUsersList);
    }, [allUsersList]);

    React.useEffect(() => {
        fetchNbDisputes();
        fetchNbProducts();
        fetchNbShops();
        fetchAllUsers();
    }, []);

    const ExampleCustomInput = ({ value, onClick }) => (
        <TouchableOpacity onPress={() => onClick()}>
            <Text className={Styles.datePickerInput}>{value}</Text>
        </TouchableOpacity>
    );

    const WeekCustomInput = ({ value, onClick }) => {
        return (
            <TouchableOpacity onPress={() => onClick()}>
                <Text className={Styles.datePickerInput}>{Moment(value, "DD/MM/YY").startOf('week').format("DD/MM/YY")} - {Moment(value, "DD/MM/YY").endOf('week').format("DD/MM/YY")}</Text>
            </TouchableOpacity>
        );
    }

    let totalMoneyFromTo = 0;
    let revenueFromTo = 0;

    for (const statDay of fromToStatDays) {
        totalMoneyFromTo += statDay.moneyTotal;
        revenueFromTo += statDay.revenue;
    }

    let nbTransactionsDay = 0;
    for (const nb of nbTransacDay) {
        nbTransactionsDay += nb.nbTransactions;
    }

    let nbTransactionsWeek = 0;
    for (const nb of nbTransacWeek) {
        nbTransactionsWeek += nb.nbTransactions;
    }

    let nbTransactionsMonth = 0;
    for (const nb of nbTransacMonth) {
        nbTransactionsMonth += nb.nbTransactions;
    }

    let nbTransactionsYear = 0;
    for (const nb of nbTransacYear) {
        nbTransactionsYear += nb.nbTransactions;
    }

    let totalReturnsMonth = 0;
    for (const nb of nbReturnsMonth) {
        totalReturnsMonth += nb.nbReturns;
    }

    let totalReturnsDay = 0;
    for (const nb of nbReturnsDay) {
        totalReturnsDay += nb.nbReturns;
    }

    let handleSellerAutocomplete = (value) => {
        setSellerAutocomp(value);
        setSearchUsersList(allUsersList.filter(user => {
            return (user.firstname + " " + user.name + " " + user.firstname).toLowerCase().indexOf(value.toLowerCase()) !== -1
        }));
        setDisplayUserAutocomplete(true);
    };

    let handleClickSellerAutocomplete = (user) => {
        setDisplayUserAutocomplete(false);
        if (sellerAutocomp !== user.name + " " + user.firstname) {
            setSellerAutocomp(user.name + " " + user.firstname);
            countReturnsForUser(user);
        }
    };

    let handleSellerSearchBlur = () => {
        setDisplayUserAutocomplete(false);
    };

    let handleSellerSearchFocus = () => {
        console.log("TOTO");
        setDisplayUserAutocomplete(true);
    };

    return <Base
        title={"Suivi des transactions"}
        currentScreen={"MONEY_DASHBOARD"}
        content={
            <Box className={Styles.container}>
                <Box className={Styles.row} style={{marginLeft: Constants.GET_SIZE(75), zIndex: 999}}>
                    <Box className={Styles.datePickerWrapper}>
                        <Text className={Styles.datePickerLabel}>Date de début</Text>
                        <DatePicker selected={startDate} onChange={setStartDate} customInput={<ExampleCustomInput />} locale={"fr"} dateFormat="dd/MM/yy" />
                    </Box>
                    <Box className={Styles.datePickerWrapper}>
                        <Text className={Styles.datePickerLabel}>Date de fin</Text>
                        <DatePicker selected={endDate} onChange={setEndDate} customInput={<ExampleCustomInput />} locale={"fr"} dateFormat="dd/MM/yy" />
                    </Box>
                </Box>
                <Box className={Styles.row} style={{marginLeft: Constants.GET_SIZE(80), marginBottom: Constants.GET_SIZE(20), marginTop : Constants.GET_SIZE(50)}}>
                    <Box className={Styles.row} style={{marginRight: Constants.GET_SIZE(70)}}>
                        <Text className={Styles.label}>
                            Nombre de boutiques :
                        </Text>
                        <Text className={Styles.value}>
                            {nbShops}
                        </Text>
                    </Box>
                    <Box className={Styles.row} style={{marginLeft: Constants.GET_SIZE(70)}}>
                        <Text className={Styles.label}>
                            Nombre d'articles :
                        </Text>
                        <Text className={Styles.value}>
                            {nbProducts}
                        </Text>
                    </Box>
                </Box>
                <Box className={Styles.grayBlock}>
                    <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(20)}}>
                        <Text className={Styles.label}>Trafic monétaire du site du</Text>
                        <Text className={Styles.value}>{Moment(startDate).format("DD/MM/YY")}</Text>
                        <Text className={Styles.label}> au </Text>
                        <Text className={Styles.value}>{Moment(endDate).format("DD/MM/YY")}</Text>
                        <Text className={Styles.label}>:</Text>
                        <Text className={Styles.value}>{totalMoneyFromTo} €</Text>
                    </Box>
                    <Box className={Styles.row}>
                        <Text className={Styles.label}>Recettes Violette Sauvage du </Text>
                        <Text className={Styles.value}>{Moment(startDate).format("DD/MM/YY")}</Text>
                        <Text className={Styles.label}> au </Text>
                        <Text className={Styles.value}>{Moment(endDate).format("DD/MM/YY")}</Text>
                        <Text className={Styles.label}>:</Text>
                        <Text className={Styles.value}>{revenueFromTo} €</Text>
                    </Box>
                </Box>
                <Text className={Styles.label} style={{marginLeft: Constants.GET_SIZE(80), marginBottom: Constants.GET_SIZE(15), marginTop: Constants.GET_SIZE(50)}}>Nombre de transactions réalisées :</Text>
                <Box className={Styles.grayBlock} style={{zIndex: 999}}>
                    <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(20)}}>
                        <Box className={Styles.datePickerWrapper}>
                            <Text className={Styles.datePickerLabel}>Par jour</Text>
                            <DatePicker selected={perDay} onChange={setPerDay} customInput={<ExampleCustomInput />} locale={"fr"} dateFormat="dd/MM/yy" />
                            <Text className={Styles.value}>{ nbTransactionsDay }</Text>
                        </Box>
                        <Box className={Styles.datePickerWrapper}>
                            <Text className={Styles.datePickerLabel}>Par semaine</Text>
                            <DatePicker selected={perWeek} onChange={setPerWeek}
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<WeekCustomInput />}
                                        locale={"fr"}
                            />
                            <Text className={Styles.value}>{ nbTransactionsWeek }</Text>
                        </Box>
                        <Box className={Styles.datePickerWrapper}>
                            <Text className={Styles.datePickerLabel}>Par mois</Text>
                            <DatePicker selected={perMonth} onChange={setPerMonth}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        customInput={<ExampleCustomInput />}
                                        locale={"fr"}
                            />
                            <Text className={Styles.value}>{ nbTransactionsMonth }</Text>
                        </Box>
                        <Box className={Styles.datePickerWrapper}>
                            <Text className={Styles.datePickerLabel}>Par année</Text>
                            <DatePicker selected={perYear} onChange={setPerYear}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        customInput={<ExampleCustomInput />}
                                        locale={"fr"}
                            />
                            <Text className={Styles.value}>{ nbTransactionsYear }</Text>
                        </Box>
                    </Box>
                </Box>

                <Box className={Styles.row} style={{marginLeft: Constants.GET_SIZE(80), marginBottom: Constants.GET_SIZE(15), marginTop: Constants.GET_SIZE(50)}}>
                    <Text className={Styles.label}>Acheteurs les plus importants :</Text>

                    <Box className={Styles.pickerWrapper}>
                        <Picker
                            className={Styles.picker}
                            selectedValue={nbBestBuyers}
                            onValueChange={value => setNbBestBuyers(value)} >
                            <Picker.Item label={'10'} value={10} className={Styles.pickerItem} />
                            <Picker.Item label={'20'} value={20} className={Styles.pickerItem} />
                            <Picker.Item label={'30'} value={30} className={Styles.pickerItem} />
                            <Picker.Item label={'40'} value={40} className={Styles.pickerItem} />
                            <Picker.Item label={'50'} value={50} className={Styles.pickerItem} />
                        </Picker>
                    </Box>
                </Box>
                <ScrollView className={Styles.grayBlock} style={{maxHeight: Constants.GET_SIZE(475)}}>
                    {bestBuyers.map(user =>
                        <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(5), paddingRight: Constants.GET_SIZE(220), justifyContent: "space-between"}}>
                            <Text className={Styles.label}>{user[0].firstname} {user[0].name}</Text>
                            <TouchableOpacity className={Styles.button} onPress={() => history.push("/admin/editer_utilisateur/" + user[0].id)}>
                                <Text className={Styles.buttonLabel}>Consulter</Text>
                            </TouchableOpacity>
                        </Box>)}
                </ScrollView>

                <Box className={Styles.row} style={{marginLeft: Constants.GET_SIZE(80), marginBottom: Constants.GET_SIZE(15), marginTop: Constants.GET_SIZE(50)}}>
                    <Text className={Styles.label}>Vendeurs les plus importants :</Text>

                    <Box className={Styles.pickerWrapper}>
                        <Picker
                            className={Styles.picker}
                            selectedValue={nbBestSellers}
                            onValueChange={value => setNbBestSellers(value)} >
                            <Picker.Item label={'10'} value={10} className={Styles.pickerItem} />
                            <Picker.Item label={'20'} value={20} className={Styles.pickerItem} />
                            <Picker.Item label={'30'} value={30} className={Styles.pickerItem} />
                            <Picker.Item label={'40'} value={40} className={Styles.pickerItem} />
                            <Picker.Item label={'50'} value={50} className={Styles.pickerItem} />
                        </Picker>
                    </Box>
                </Box>

                <ScrollView className={Styles.grayBlock} style={{maxHeight: Constants.GET_SIZE(475)}}>
                    {bestSellers.map(user =>
                        <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(5), paddingRight: Constants.GET_SIZE(220), justifyContent: "space-between"}}>
                            <Text className={Styles.label}>{user[0].firstname} {user[0].name}</Text>
                            <TouchableOpacity className={Styles.button} onPress={() => history.push("/admin/editer_utilisateur/" + user[0].id)}>
                                <Text className={Styles.buttonLabel}>Consulter</Text>
                            </TouchableOpacity>
                        </Box>)}
                </ScrollView>

                <Box className={Styles.row} style={{marginLeft: Constants.GET_SIZE(80), marginBottom: Constants.GET_SIZE(15), marginTop: Constants.GET_SIZE(50)}}>
                    <Text className={Styles.label}>Nombre de retours :</Text>
                </Box>
                <Box className={Styles.grayBlock} style={{zIndex: 999}}>
                    <Box className={Styles.row} style={{marginBottom: Constants.GET_SIZE(20)}}>
                        <Box className={Styles.datePickerWrapper}>
                            <Text className={Styles.datePickerLabel}>Par vendeur :</Text>
                            <TextInputIcon
                                value={sellerAutocomp}
                                setValue={handleSellerAutocomplete}
                                placeholder={"Nom du vendeur"}
                                onBlur={handleSellerSearchBlur}
                                onFocus={handleSellerSearchFocus}
                                autoCompleteType={"off"}
                                wrapperClass={Styles.smallInputWrapper}
                                inputComponentClass={Styles.smallInput} />
                            <ScrollView className={Styles.userAutocompleteWrapper} style={{visibility: displayUserAutocomplete && searchUsersList.length > 0 ? 'visible' : 'hidden'}}>
                                {searchUsersList.map(user => <TouchableOpacity className={Styles.shopAutocompleteButton} onPress={() => handleClickSellerAutocomplete(user)}>
                                    <Text className={Styles.shopAutocompleteLabel}>{user.name} {user.firstname}</Text>
                                </TouchableOpacity>)}
                            </ScrollView>
                            <Text className={Styles.value}>{nbReturnsForUser}</Text>
                        </Box>
                        <Box className={Styles.datePickerWrapper}>
                            <Text className={Styles.datePickerLabel}>Par jour :</Text>
                            <DatePicker
                                selected={perDay}
                                onChange={setPerDay}
                                customInput={<ExampleCustomInput />}
                                locale={"fr"}
                                dateFormat="dd/MM/yy"
                            />
                            <Text className={Styles.value}>{totalReturnsDay}</Text>
                        </Box>
                        <Box className={Styles.datePickerWrapper}>
                            <Text className={Styles.datePickerLabel}>Par mois :</Text>
                            <DatePicker selected={perMonth} onChange={setPerMonth}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        customInput={<ExampleCustomInput />}
                                        locale={"fr"}/>
                            <Text className={Styles.value}>{totalReturnsMonth}</Text>
                        </Box>
                    </Box>
                </Box>

                <Box className={Styles.row} style={{marginLeft: Constants.GET_SIZE(80), marginBottom: Constants.GET_SIZE(15), marginTop: Constants.GET_SIZE(50)}}>
                    <Text className={Styles.label}>Nombre de litiges en cours :</Text>
                    <Text className={Styles.value}>{nbDisputes}</Text>
                </Box>
            </Box>
        }
    />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        bestSellers: state.user.stats.bestSellers,
        bestBuyers: state.user.stats.bestBuyers,
        fromToStatDays: state.stat.fromToStatDays,
        nbTransacDay: state.stat.nbTransacDay,
        nbTransacWeek: state.stat.nbTransacWeek,
        nbTransacMonth: state.stat.nbTransacMonth,
        nbTransacYear: state.stat.nbTransacYear,
        nbReturnsDay: state.stat.nbReturnsDay,
        nbReturnsMonth: state.stat.nbReturnsMonth,
        nbReturnsYear: state.stat.nbReturnsYear,
        nbReturnsForUser: state.stat.nbReturnsForUser,
        nbDisputes: state.stat.nbDisputes,
        nbShops: state.stat.nbShops,
        nbProducts: state.stat.nbProducts,
        searchUsersList: state.user.usersList,
        allUsersList: state.user.allUsersList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadBestSellers: (itemsPerPage) => {
            dispatch({type: 'FETCH_BEST_SELLERS_REQUESTED', itemsPerPage, page: 1})
        },
        loadBestBuyers: (itemsPerPage) => {
            dispatch({type: 'FETCH_BEST_BUYERS_REQUESTED', itemsPerPage, page: 1})
        },
        fetchStatsFromTo: (startDate, endDate) => {
            dispatch({type: 'FETCH_FROM_TO_STATS_REQUESTED', startDate, endDate})
        },
        fetchNbTransactions: (timeType, year, month, day) => {
            dispatch({type: 'FETCH_NB_TRANSACTIONS_REQUESTED', timeType, year, month, day})
        },
        fetchNbReturns: (timeType, year, month, day) => {
            dispatch({type: 'FETCH_NB_RETURNS_REQUESTED', timeType, year, month, day})
        },
        fetchNbDisputes: () => {
            dispatch({type: 'FETCH_NB_DISPUTES_REQUESTED'})
        },
        fetchNbShops: () => {
            dispatch({type: 'FETCH_NB_SHOPS_REQUESTED'})
        },
        fetchNbProducts: () => {
            dispatch({type: 'FETCH_NB_PRODUCTS_REQUESTED'})
        },
        searchUsers: (search) => {
            dispatch({type: 'FETCH_USERS_REQUESTED', search, itemsPerPage: 5, page: 1, hideLoader: true})
        },
        countReturnsForUser: (user) => {
            dispatch({type: 'FETCH_NB_RETURNS_FOR_USER_REQUESTED', id: user.id})
        },
        fetchAllUsers: () => {
            dispatch({type: 'FETCH_ALL_USERS_REQUESTED'})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
