import React from 'react';
import { Marker as LeafletMarker } from 'leaflet';
import { LeafletProvider, withLeaflet, MapLayer } from 'react-leaflet';

class RotatedMarker extends MapLayer {
    // "Hijack" the component lifecycle.

    componentDidMount() {
        // Call the Marker class componentDidMount (to make sure everything behaves as normal)
        super.componentDidMount();

        // Access the marker element and open the popup.
        if (this.props.popupOpen) {
            this.leafletElement.openPopup();
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        if (this.props.popupOpen) {
            this.leafletElement.openPopup();
        }
    }

    createLeafletElement(props) {
        const el = new LeafletMarker(props.position, this.getOptions(props));
        this.contextValue = { ...props.leaflet, popupContainer: el };
        return el;
    }

    updateLeafletElement(fromProps, toProps) {
        if (toProps.position !== fromProps.position) {
            this.leafletElement.setLatLng(toProps.position);
        }
        if (toProps.icon !== fromProps.icon) {
            this.leafletElement.setIcon(toProps.icon);
        }
        if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
            this.leafletElement.setZIndexOffset(toProps.zIndexOffset);
        }
        if (toProps.opacity !== fromProps.opacity) {
            this.leafletElement.setOpacity(toProps.opacity);
        }
        if (toProps.draggable !== fromProps.draggable) {
            if (toProps.draggable === true) {
                this.leafletElement.dragging.enable();
            } else {
                this.leafletElement.dragging.disable();
            }
        }
    }

    render() {
        const { children } = this.props;
        return children == null || this.contextValue == null ? null : (
            <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
        );
    }
}

export default  withLeaflet(RotatedMarker);