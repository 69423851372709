import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"


export default makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        // alignItems: "center",
        width: "100%"
    },
    dataWrapper: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY,
        paddingTop: Constants.GET_SIZE(43),
        paddingBottom: Constants.GET_SIZE(18),
        width: "100%"
    },
    tabRow: {
        ...GlobalStyle.rowCentered,
        justifyContent: "space-between",
        width: Constants.GET_SIZE(1000),
        marginBottom: Constants.GET_SIZE(50)
    },
    tabButton: {
        backgroundColor: Constants.WHITE,
        borderColor: Constants.PINK,
        borderWidth: 1,
        paddingTop: Constants.GET_SIZE(10),
        paddingBottom: Constants.GET_SIZE(10),
        paddingLeft: Constants.GET_SIZE(25),
        paddingRight: Constants.GET_SIZE(25),
    },
    tabButtonActive: {
        backgroundColor: Constants.PINK
    },
    tabText: {
        ...GlobalStyle.buttonLabel,
        color: Constants.PINK
    },
    tabTextActive: {
        color: Constants.WHITE
    },
    tabInputWrapper: {
        ...GlobalStyle.rowCentered,
        width: Constants.GET_SIZE(260),
        height: Constants.GET_SIZE(31),
        backgroundColor: "white",
        borderRadius: 45,
        borderColor: Constants.LIGHT_GRAY,
        borderWidth: 1,
        paddingLeft: Constants.FONT_SIZE_NORMAL,
        paddingRight: Constants.FONT_SIZE_NORMAL,
    },
    tabInput: {
        marginLeft: 10,
        width: "100%"
    },
    headerRow: {
        ...GlobalStyle.rowCentered,
        width: "100%",
        marginBottom: Constants.GET_SIZE(25),
        paddingLeft: Constants.GET_SIZE(30),
        paddingRight: Constants.GET_SIZE(30),
        boxSizing: "border-box"
    },
    dataRow: {
        ...GlobalStyle.rowCentered,
        width: "100%",
        marginBottom: Constants.GET_SIZE(25),
        paddingLeft: Constants.GET_SIZE(30),
        paddingRight: Constants.GET_SIZE(30),
        boxSizing: "border-box"
    },

    headerCell: {
        fontSize: Constants.GET_FONT_SIZE(12),
        ...GlobalStyle.uppercaseText,
        paddingLeft: 10,
        paddingRight: 10
    },
    dataCell: {
        fontSize: Constants.GET_FONT_SIZE(12),
        ...GlobalStyle.uppercaseText,
        paddingLeft: 10,
        paddingRight: 10,
        color: Constants.FONT_GRAY
    },
    dataCellText: {

    },
    userDataCell1: {
        width: Constants.GET_SIZE(175),
        textAlign: "center"
    },
    userDataCell2: {
        width: Constants.GET_SIZE(157),
        textAlign: "center"
    },
    userDataCell3: {
        width: Constants.GET_SIZE(202),
        textAlign: "center"
    },
    userDataCell4: {
        width: Constants.GET_SIZE(100),
        textAlign: "center"
    },
    userDataCell5: {
        width: Constants.GET_SIZE(146),
        textAlign: "center"
    },
    userDataCell6: {
        width: Constants.GET_SIZE(80),
        textAlign: "center"
    },
    userDataCell7: {
        width: Constants.GET_SIZE(120),
        textAlign: "center"
    },

    productDataCell1: {
        width: Constants.GET_SIZE(220),
        textAlign: "center"
    },
    productDataCell2: {
        width: Constants.GET_SIZE(175),
        textAlign: "center"
    },
    productDataCell3: {
        width: Constants.GET_SIZE(100),
        textAlign: "center"
    },
    productDataCell4: {
        width: Constants.GET_SIZE(170),
        textAlign: "center"
    },
    // productDataCell4: {
    //     width: Constants.GET_SIZE(100),
    //     textAlign: "center"
    // },
    productDataCell5: {
        width: Constants.GET_SIZE(146),
        textAlign: "center"
    },
    productDataCell6: {
        width: Constants.GET_SIZE(80),
        textAlign: "center"
    },
    productDataCell7: {
        width: Constants.GET_SIZE(110),
        textAlign: "center"
    },
    productDataCell8: {
        width: Constants.GET_SIZE(80),
        textAlign: "center"
    },
    productDataCell9: {
        width: Constants.GET_SIZE(80),
        textAlign: "center"
    },

    shopDataCell0: {
        width: Constants.GET_SIZE(50),
        textAlign: "center"
    },
    shopDataCell1: {
        width: Constants.GET_SIZE(300),
        textAlign: "center"
    },
    shopDataCell2: {
        width: Constants.GET_SIZE(200),
        textAlign: "center"
    },
    shopDataCell3: {
        width: Constants.GET_SIZE(150),
        textAlign: "center"
    },
    shopDataCell4: {
        width: Constants.GET_SIZE(100),
        textAlign: "center"
    },
    shopDataCell5: {
        width: Constants.GET_SIZE(146),
        textAlign: "center"
    },
    link: {
        color: Constants.PINK,
        textDecorationLine: "underline"
    },


    brandsDataCell1: {
        width: Constants.GET_SIZE(100),
        textAlign: "center"
    },
    brandsDataCell2: {
        width: Constants.GET_SIZE(600),
        textAlign: "center"
    },
    brandsDataCell3: {
        width: Constants.GET_SIZE(100),
        textAlign: "center"
    },
    brandsDataCell4: {
        width: Constants.GET_SIZE(100),
        textAlign: "center"
    },
}));
