import React from "react";
import TextField from "@material-ui/core/TextField"

import * as Constants from '../../Style/Constants'


function Component(props) {

    return (
        <TextField {...props} style={{fontSize: Constants.FONT_SIZE_NORMAL}} onKeyPress={(ev) => props.onPressEnter && ev.key === 'Enter' ? props.onPressEnter() : null}>{props.children}</TextField>
    );
}

export default Component;
