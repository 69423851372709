import React from "react";
import clsx from 'clsx';
import {Animated, Easing} from "react-native";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import { TouchableOpacity, Image } from "react-native-web";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { FontAwesome } from '../../FontAwesome';
import { useParams } from "react-router-dom";

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MessagingStyle"
import * as Constants from "../../Style/Constants"
import Paginator from "../Front/Component/Paginator";
import {dataURLtoFile, getImageUrl, getPdfUrl} from "../../Utils/ImageUtils";
import Moment from "moment";
import UserIcon from "../../Assets/Images/user_icon.svg"
import TextInputIcon from "../Front/Component/TextInputIcon";
import {useForm} from "react-hook-form";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Resizer from "react-image-file-resizer";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import {ErrorMessage} from "@hookform/error-message";
import ErrorStyleFunc from "../Styles/Error";


const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1024,
            768,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

function Component({navigation, history, token, user, messages, pagination, fetchedProduct, fetchedShop, newlyCreatedThread, fetchThread, sendMessage, createThread, fetchProduct, fetchShop, cleanMessagesList, isLoadingMessage, createDispute}) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { setValue, setError, handleSubmit, register, errors, watch } = useForm();
    let { id } = useParams();
    let shopId = null;
    let productId = null;
    let recipient = null;
    let canWriteMessage = true;
    let dispute = false;
    let purchaseProduct = null;
    let purchase = null;
    if (id === "nouveau") {
        if (history.location.state && history.location.state.shopId) {
            shopId = history.location.state.shopId;
        } else if (history.location.state && history.location.state.productId) {
            productId = history.location.state.productId;
        }
        if (history.location.state && history.location.state.recipient) {
            recipient = history.location.state.recipient;
        }
        if (history.location.state && history.location.state.dispute) {
            dispute = history.location.state.dispute;
        }
        if (history.location.state && history.location.state.purchaseProduct) {
            purchaseProduct = history.location.state.purchaseProduct;
        }
        if (history.location.state && history.location.state.purchase) {
            purchase = history.location.state.purchase;
        }
    }


    let [page, setPage] = React.useState(1);
    let [spinValue] = React.useState(new Animated.Value(0));

    let inputFileRef = React.useRef(null);
    let pictureButtonRef = React.useRef(null);
    let pictures = watch('pictures', []);

    let handleAddPicture = async (e) => {
        if (pictures.length + (e.target.files ? e.target.files.length : 0) <= 4) {
            if (e.target.files && e.target.files.length > 0) {
                const promises = [];
                const files = Array.from(e.target.files)
                let nfiles = [];
                for (const index in files) {
                    const file = files[index]

                    let resizedFile = await resizeFile(file);

                    const realIndex = parseInt(pictures.length) + parseInt(index);
                    let nfile = {
                        id: -1,
                        uri: resizedFile,
                        key: 'pic-new-' + realIndex,
                        type: 'new',
                        file: dataURLtoFile(resizedFile, 'chat-' + (thread ? thread.id : 'new') + '-image'),
                        sortOrder: realIndex
                    };
                    nfiles.push(nfile);
                }

                // saveCurrentProduct(productKey, { pictures: [...pictures, ...nfiles] });
                setValue('pictures', [...pictures, ...nfiles]);
            }
        } else {
            setError('pictures', { type: 'invalid', message: 'Vous ne pouvez pas ajouter plus de 4 photos' });
        }
        inputFileRef.current.value = '';
    };

    React.useEffect(() => {
        register({ name: 'pictures' });
    }, [register])

    React.useEffect(() => {
        if (id !== "nouveau") {
            fetchThread(id, page, 6);
        } else if (productId !== null) {
            cleanMessagesList();
            fetchProduct(productId);
        } else if (shopId !== null) {
            cleanMessagesList();
            fetchShop(shopId);
        }
    }, [id, token, page]);

    React.useEffect(() => {
        if (page > pagination.last) {
            setPage(pagination.last);
        } else if (page < pagination.first) {
            setPage(pagination.first);
        }
    }, [pagination]);

    React.useEffect(() => {
        if (id === "nouveau" && newlyCreatedThread !== null) {
            history.push("/message/" + newlyCreatedThread.id);
        }
    }, [newlyCreatedThread]);

    let handleSendMessage = ({message, pictures}) => {
        if (id !== "nouveau") {
            sendMessage(id, message, pictures, page, 6);
        } else {
            if (dispute) {
                createDispute(message, purchase, shop, purchaseProduct.product, pictures);
            } else {
                createThread(productId ? product : null, shopId ? shop : null, message, pictures, recipient !== null ? recipient['@id'] : null);
            }
        }
        setValue("pictures", []);
        setValue("message", "");
    };

    let firstPerson = null;
    let otherPerson = null;
    let thread = null;
    let product = null;
    let shop = null;

    if (messages && messages.length > 0) {
        thread = messages[0].thread;
        firstPerson = thread.author.id === user.id ? thread.author : thread.recipient;
        otherPerson = thread.author.id === user.id ? thread.recipient : thread.author;
        product = thread.product;
        shop = thread.shop;

        canWriteMessage = false;
        if (user) {
            if (thread.dispute) {
                if (user.id === thread.dispute.purchase.user.id || user.id === thread.dispute.shop.owner.id) {
                    canWriteMessage = true;
                }
            } else if (user.id === thread.recipient.id || user.id === thread.author.id) {
                canWriteMessage = true;
            }
        }
    } else if (productId !== null && fetchedProduct) {
        otherPerson = fetchedProduct.shop.owner;
        product = fetchedProduct;
    } else if (shopId !== null && fetchedShop) {
        otherPerson = fetchedShop.owner;
        shop = fetchedShop;
    }


    let handleRemovePicture = (index) => {
        let newPictures = [...pictures.slice(0, index), ...pictures.slice(index + 1)];
        setValue('pictures', newPictures);
    };

    let _getMessageBlock = (message) => {
        let amAuthor = (thread.author.id === user.id && message.isAuthor) || (thread.author.id !== user.id && !message.isAuthor);
        let messageAuthorName;
        if (amAuthor) {
            messageAuthorName = firstPerson.firstname && firstPerson.name ? firstPerson.firstname + " " + firstPerson.name : firstPerson.username;
        } else {
            messageAuthorName = otherPerson.firstname && otherPerson.name ? otherPerson.firstname + " " + otherPerson.name : otherPerson.username;
        }

        let authorShop = null;
        if (!amAuthor && otherPerson.shop) {
            authorShop = otherPerson.shop;
        } else if (amAuthor && user.shop) {
            authorShop = user.shop;
        }

        return <Grid container className={clsx(Styles.messageWrapper, {[Styles.threadWrapperLeft]: amAuthor}, {[Styles.threadWrapperRight]: amAuthor})}>
            {!authorShop && <Image source={UserIcon} className={Styles.authorImage} />}
            {authorShop && <Link href={"/shop/" + authorShop.id}><Image source={UserIcon} className={Styles.authorImage} /></Link>}
            <Box className={Styles.textWrapper}>
                {!authorShop && <Text className={Styles.authorName}>{messageAuthorName}</Text>}
                {authorShop && <Link href={"/shop/" + authorShop.id}><Text className={Styles.authorName}>{messageAuthorName}</Text></Link>}
                <Text className={Styles.messageComplete} style={message.isNewMessage ? {fontWeight: "bold"} : {}}>{message.content}</Text>
            </Box>
            <Text className={Styles.messageDate}>&nbsp;- {Moment(message.createdAt).format("DD/MM/YY")}</Text>
            <Text className={Styles.messageCompleteMobile} style={message.isNewMessage ? {fontWeight: "bold"} : {}}>{message.content}</Text>
            <Grid container xs={12} style={{marginTop: 5}}>
                {message.pictures.map(picture => (
                    <Link href={getImageUrl(picture.contentUrl, 'real')} target={"_blank"}>
                        <Image source={picture.id === -1 ? picture.uri : getImageUrl(picture.contentUrl, 'small')}
                               className={Styles.pictureThumb}/>
                    </Link>
                ))}
            </Grid>
        </Grid>
    };

    Animated.loop(
        Animated.timing(
            spinValue,
            {
                toValue: 1,
                duration: 1500,
                easing: Easing.linear
            }
        ),
        {
            iterations: -1
        }
    ).start();

    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
    });

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Messagerie</Text>

            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={"MESSAGE_THREADS"} wrapperStyle={{marginTop: Constants.GET_SIZE(90)}}/>
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    <TouchableOpacity className={Styles.threadHeader} onPress={() => product !== null ? history.push("/product/" + product.id) : (shop !== null ? history.push("/shop/" + shop.id) : null)}>
                        {product !== null && <Image source={getImageUrl(product.pictures[0].contentUrl, "small")} className={clsx([Styles.image, Styles.imageProduct])} />}
                        {shop !== null && <Image source={shop.picture ? getImageUrl(shop.picture.contentUrl, "small") : null    } className={clsx([Styles.image, Styles.imageShop])} />}
                        <Box className={Styles.threadHeaderText}>
                            {recipient !== null && <Text className={Styles.threadHeaderText1}>{recipient.firstname && recipient.name ? recipient.firstname + " " + recipient.name : recipient.username}</Text>}
                            {recipient === null && <Text className={Styles.threadHeaderText1}>{otherPerson !== null && (otherPerson.firstname && otherPerson.name ? otherPerson.firstname + " " + otherPerson.name : otherPerson.username)}</Text>}
                            {product !== null && <Text className={Styles.threadHeaderText2}>{product.name}</Text>}
                            {shop !== null && <Text className={Styles.threadHeaderText2}>{shop.name}</Text>}
                            {thread && thread.dispute && <Text className={Styles.threadHeaderDispute}>Commande N°{thread.dispute.purchase.id}</Text>}
                            <Text className={Styles.threadHeaderText3}>{otherPerson !== null && ("Membre depuis le " + Moment(otherPerson.createdAt).format("DD/MM/YY"))}</Text>
                        </Box>
                        {thread && thread.dispute && <Box className={Styles.threadHeaderText} style={{alignItems: "flex-end", maxWidth: 300}}>
                            <Text className={Styles.disputeTag} style={{width: "fit-content", marginBottom: 10}}>LITIGE</Text>
                            <Text className={Styles.threadHeaderText3} style={{fontStyle: "italic"}}>Les administrateurs de Violette Sauvage peuvent lire les messages en cas de litige</Text>
                        </Box>}
                    </TouchableOpacity>
                    <Box className={Styles.threadsWrapper}>
                        {messages.slice().reverse().map(_getMessageBlock)}
                    </Box>
                    {canWriteMessage && <Box className={Styles.sendMessageWrapper}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            placeholder={"Ecrire un message"}
                            rules={{
                                required: "Ce champ est obligatoire",
                            }}
                            name={"message"}
                            multiline={true}
                        />
                        <Grid container>
                            <input id="picture-input" type="file" accept="image/*" style={{ display: 'none' }} ref={inputFileRef} onChange={(result) => handleAddPicture(result)} multiple/>
                            <Button variant={'contained'} color={'secondary'} disableElevation className={Styles.pictureButton} startIcon={<AddCircleIcon style={{color: Constants.PINK}}/>} onClick={() => inputFileRef.current.click()} ref={pictureButtonRef}>
                                Ajouter une pièce jointe
                            </Button>

                            {pictures.map((picture, index) => (
                                <Grid item
                                      className={Styles.pictureWrapper}>
                                    <Image source={picture.id === -1 ? picture.uri : getImageUrl(picture.contentUrl, 'medium')}
                                           className={Styles.picture}/>
                                    <IconButton className={Styles.pictureDeleteIconWrapper} onClick={() => handleRemovePicture(index)}>
                                        <CancelIcon/>
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                        <Text className={Styles.pictureButtonLegend}>Jusqu'à 4 images à la fois, 5 MO max par photos, format accepté : jpg, jpeg & png</Text>
                        <ErrorMessage name={'pictures'} errors={errors} as={Text} className={ErrorStyle.error}/>
                        <TouchableOpacity className={Styles.button} style={{flexDirection: "row"}} onPress={handleSubmit(handleSendMessage)}>
                            <Text className={Styles.buttonLabel} style={{marginRight: Constants.GET_SIZE(10)}}>Envoyer</Text>
                            {isLoadingMessage &&
                                <Animated.View
                                    style={{
                                        transform: [{rotate: spin}]
                                    }}>
                                    <FontAwesome name={"spinner"} color={"white"} style={{fontSize: Constants.FONT_SIZE_NORMAL}}/>
                                </Animated.View>
                            }
                        </TouchableOpacity>
                        {thread && thread.dispute && !thread.dispute.purchaseProduct.returnParcel && (
                            <Button style={{marginTop: 10}} size={"small"} disableElevation variant={"contained"} color={"primary"} onClick={() => history.push("/return_product/" + thread.dispute.purchaseProduct.id)}>Payer le bordereau de retour</Button>
                        )}
                        {thread && thread.dispute && thread.dispute.purchaseProduct.returnParcel && thread.dispute.purchase.user.id === user.id && (
                            <Button style={{marginTop: 10}} size={"small"} disableElevation variant={"contained"} color={"primary"} href={getPdfUrl(thread.dispute.purchaseProduct.returnParcel.contentUrl)} target={'_blank'}>Télécharger le bordereau de retour</Button>
                        )}
                    </Box>}
                </Box>
            </Grid>
            {pagination && <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE}/>}
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        fetchedProduct: state.product.currentProduct,
        fetchedShop: state.shop.currentShop,
        messages: state.message.messages,
        pagination: state.message.messagesPagination,
        newlyCreatedThread: state.message.newlyCreatedThread,
        isLoadingMessage: state.loading.isLoadingMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchThread: (id, page, itemsPerPage) => {
            dispatch({type: 'FETCH_MESSAGE_THREAD_REQUESTED', id, page, itemsPerPage})
        },
        sendMessage: (id, message, pictures, page, itemsPerPage) => {
            dispatch({type: 'SEND_MESSAGE_REQUESTED', id, message, pictures, page, itemsPerPage})
        },
        createThread: (product, shop, message, pictures, recipient) => {
            dispatch({type: 'CREATE_THREAD_REQUESTED', product, shop, message, pictures, recipient})
        },
        createDispute: (text, purchase, shop, product, pictures) => {
            dispatch({type: 'CREATE_DISPUTE_REQUESTED', text, purchase, shop, product, pictures})
        },
        fetchProduct: (id) => {
            dispatch({type: 'FETCH_PRODUCT_REQUESTED', id})
        },
        fetchShop: (id) => {
            dispatch({type: 'FETCH_SHOP_REQUESTED', id})
        },
        cleanMessagesList: () => {
            dispatch({type: 'CLEAN_MESSAGES_LIST'})
        },
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
