import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";


export default function SelectField({control, errors, name, label, required, choices}) {

    return (
        <Controller
            render={({ onChange, value, ...props }) => (
                <Autocomplete
                    {...props}
                    value={typeof value === 'string' ? choices.find(o => o.name === value) : (value || null)}
                    options={choices}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                    freeSolo
                    autoSelect
                    style={{marginBottom: 20}}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            inputProps={{...params.inputProps}}
                            label={label}
                            variant="outlined"
                        />}
                    onChange={(e, data) => {
                        return onChange(typeof data === 'string' ? {name: data} : data)
                    }}
                />
            )}
            onChange={([, data]) => data}
            name={name}
            control={control}
        />
    );
}
