import React from "react";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Text from "../../Component/FontText";
import MeetingVSHeaderImage from "../../../Assets/Images/static/static_meetingvs_header.png"
import { connect } from 'react-redux'
import StylesFunc from "../../../Style/StaticCommon";
import BecomeSellerBlock from "../Component/BecomeSellerBlock";
import InstagramBlock from "../Component/InstagramBlock";
import Grid from "@material-ui/core/Grid/Grid";
import MetaTags from "react-meta-tags";
import Link from "@material-ui/core/Link";

function Component({content, image, title, metaTitle, metaDesc, arianeCurrentName}) {
    let Styles = StylesFunc();

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = document.title + (title ? " - " + title : "")
    }, []);

    let getBlockContent = content => {
        let title = null;
        let result = null;
        if (content.title) {
            title = <Text className={Styles.blockSubTitle} component={"h2"}>{content.title}</Text>
        }
        if (content.text) {
            result = <Text className={Styles.blockText}>{content.text}</Text>
        } else if (content.h1) {
            result = <Text className={Styles.blockText} component={"h1"}>{content.text}</Text>
        } else if (content.h2) {
            result = <Text className={Styles.blockText} component={"h2"}>{content.text}</Text>
        } else if (content.table) {
            result = <Table className={Styles.blockTable}>
                <TableHead>
                    <TableRow>
                        {content.table.header.map(h => <TableCell align={"center"}>{h}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {content.table.rows.map(r => <TableRow>{r.map(c => <TableCell align={"center"}>{c}</TableCell>)}</TableRow>)}
                </TableBody>
            </Table>
        }

        return title ? <Box>{title}{result}</Box> : result;
    };

    let getBlockDom = block => {
        return <Box className={Styles.block}>
            <Text className={Styles.blockTitle} component={"h2"}>{block.title}</Text>
            {block.content.map(c => getBlockContent(c))}
        </Box>
    };

    let meta =
        <MetaTags>
            <title>{metaTitle}</title>
            <meta name="description"
                  content={metaDesc}/>
        </MetaTags>;

    return (
        <Box className={Styles.container}>
            {meta}
            <Grid container item xs={12} className={Styles.homepageHeaderWrapper}>
                <Box className={Styles.homepageHeaderLeftWrapper} style={{backgroundImage: "url(" + (image ? image : MeetingVSHeaderImage) + ")"}}>
                    <Box className={clsx(Styles.homepageHeaderRectangle, Styles.homepageHeaderPinkRectangle)} />
                    <Text className={clsx(Styles.headerImageTitle, Styles.homepageHeaderText)} component={"h1"}>{content.title}</Text>
                </Box>
            </Grid>
            <Box className={Styles.block}>
                <Grid item container xs={12} sm>
                    <Link href={"/"} style={{textDecoration: "none", color: "black"}}><Text className={Styles.arianeText}>Accueil&nbsp;/&nbsp;</Text></Link>
                    <Text className={Styles.arianeText}>{arianeCurrentName}</Text>
                </Grid>
            </Box>
            {content.blocks.map(getBlockDom)}

            <BecomeSellerBlock hideLinks={false}/>
            <InstagramBlock />
        </Box>
    );
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent
