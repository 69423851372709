import React from "react";
import TextInputIcon from "../../Screens/Front/Component/TextInputIcon";
import MUITextField from "@material-ui/core/TextField";

export default function TextField({register, errors, name, namePrefix, label, required, multiline, arrayField}) {
    let rules = {};

    if (required) {
        rules['required'] = "Ce champ est obligatoire";
    }

    if (arrayField) {
        return <TextInputIcon
            errors={errors}
            register={register}
            rules={rules}
            defaultValue={arrayField[name]}
            name={namePrefix + name}
            topLabel={label + (required ? '*' : '')}
            multiline={multiline}
        />
    } else {
        return <TextInputIcon
            errors={errors}
            register={register}
            rules={rules}
            name={name}
            topLabel={label + (required ? '*' : '')}
            multiline={multiline}
        />
    }
}
