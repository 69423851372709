import axios from 'axios';
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils';


export const CreateDispute = (text, shop, purchase, product, picturesIds) => {
    return axios.post(
        getApiUrl('disputes'),
        {
            firstMessage: text,
            picturesIds,
            shop,
            purchase,
            product
        },
        {
            headers: setDefaultHeaders()
        }
    );
};

export const SearchDisputes = (search, itemsPerPage, page, disputeType) => {
    return axios.get(
        getApiUrl('disputes',
            {
                search,
                itemsPerPage,
                page,
                disputeType
            }),
        {
            headers: setDefaultHeaders()
        }
    );
};

export const FetchDispute = (id) => {
    return axios.get(
        getApiUrl('disputes/' + id),
        {
            headers: setDefaultHeaders()
        }
    );
};

export const DeleteDispute = (id) => {
    return axios.delete(
        getApiUrl('disputes/' + id),
        {
            headers: setDefaultHeaders()
        }
    );
};

export const UpdateDispute = (id, status) => {
    return axios.put(
        getApiUrl('disputes/' + id),
        {
            status
        },
        {
            headers: setDefaultHeaders()
        }
    );
};
