const initialState = {
    registerErrors: [],
    me: null,
    newsletterError: null,
    newsletterOk: false,
    currentUser: null,
    usersList: [],
    allUsersList: [],
    usersListPagination: {
        first: 1,
        last: 1
    },
    stats: {
        bestSellers: [],
        bestBuyers: [],
        bestSellersPagination: {
            first: 1,
            last: 1
        },
        bestBuyersPagination: {
            first: 1,
            last: 1
        },
    },

    confirmationModalVisible: false
};


function reduc(state = initialState, action) {
    let first;
    let last;
    switch (action.type) {
        case "HIDE_USER_CONFIRMATION_MODAL":
            return Object.assign({}, state, {
                confirmationModalVisible: false
            });
        case "DISPLAY_USER_CONFIRMATION_MODAL":
            return Object.assign({}, state, {
                confirmationModalVisible: true
            });

        case "REGISTER_SUCCEEDED":
            return Object.assign({}, state, {
                registerErrors: []
            });
        case "SAVE_USER_FAILED":
        case "REGISTER_FAILED":
            return Object.assign({}, state, {
                registerErrors: action.errors
            });
        case "FETCH_ME_SUCCEEDED":
        case "RECEIVE_PAYMENT_SUCCEEDED":
            return Object.assign({}, state, {
                me: action.user
            });
        case "FETCH_USER_SUCCEEDED":
            return Object.assign({}, state, {
                currentUser: action.user
            });
        case "FETCH_ME_FAILED":
            return Object.assign({}, state, {
                me: null
            });
        case "BLOCK_USER_SUCCEEDED":
            if (action.user.id === state.currentUser.id) {
                return Object.assign({}, state, {
                    currentUser: Object.assign({}, state.currentUser, {
                        status: state.currentUser.status === 1 ? 0 : 1
                    })
                });
            } else {
                return state;
            }
        case "FETCH_USERS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                usersList: action.users,
                usersListPagination: {
                    first: first ? parseInt(first) : 1,
                    last: last ? parseInt(last) : 1
                }
            });
        case "FETCH_ALL_USERS_SUCCEEDED":
            return Object.assign({}, state, {
                allUsersList: action.users
            });

        case "REGISTER_NEWSLETTER_REQUESTED":
            return Object.assign({}, state, {
                newsletterOk: false,
                newsletterError: null
            });
        case "REGISTER_NEWSLETTER_SUCCEEDED":
            return Object.assign({}, state, {
                newsletterOk: true
            });
        case "REGISTER_NEWSLETTER_FAILED":
            return Object.assign({}, state, {
                newsletterOk: false,
                newsletterError: action.message
            });
        case "CONFIRM_NEWSLETTER":
            return Object.assign({}, state, {
                newsletterOk: false
            });
        case "SAVE_SHOP_SUCCEEDED":
            return Object.assign({}, state, {
                me: Object.assign({}, state.me, {
                    shop: action.shop
                })
            });

        case "FETCH_BEST_SELLERS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                stats: Object.assign({}, state.stats, {
                    bestSellers: action.users,
                    bestSellersPagination: {
                        first: first ? parseInt(first) : 1,
                        last: last ? parseInt(last) : 1
                    }
                })
            });
        case "FETCH_BEST_BUYERS_SUCCEEDED":
            first = new URLSearchParams(action.pagination["hydra:first"]).get("page");
            last = new URLSearchParams(action.pagination["hydra:last"]).get("page");
            return Object.assign({}, state, {
                stats: Object.assign({}, state.stats, {
                    bestBuyers: action.users,
                    bestBuyersPagination: {
                        first: first ? parseInt(first) : 1,
                        last: last ? parseInt(last) : 1
                    }
                })
            });
        case "UPDATE_ME":
            return Object.assign({}, state, {
                me: Object.assign({}, state.me, action.payload)
            })
        case "LOGOUT":
            return Object.assign({}, state, {
                me: null
            });


    }

    return state
}

export default reduc