import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default (theme) => ({
    shoppingCartWrapper: {
        flex: 1,
        paddingLeft: Constants.GET_SIZE(50),
        paddingRight: Constants.GET_SIZE(50),
        [theme.breakpoints.down('xs')]: {
            flex: "initial",
            textAlign: "center",
            paddingLeft: 0,
            paddingRight: 0,
            width: "100%"
        }
    },
    shoppingCartTitle: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        marginBottom: Constants.GET_SIZE(25),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
            textAlign: "center"
        }
    },
    shoppingCartBlock: {
        backgroundColor: Constants.GRAY_FOOTER,
        marginBottom: Constants.GET_SIZE(5),
        padding: Constants.GET_SIZE(50),
        paddingTop: Constants.GET_SIZE(35),
        paddingBottom: Constants.GET_SIZE(35),
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },
    shoppingCartBlockFirst: {
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 1,
            marginBottom: 0
        }
    },
    shoppingCartBlockLast: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 5,
        }
    },
    shoppingCartRow: {
        flexDirection: "row",
        marginBottom: 15,
        marginTop: 15,
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            marginBottom: 5,
            marginTop: 5,
            display: "flex"
        }
    },
    shoppingCartText: {
        color: Constants.WHITE,
        [theme.breakpoints.down('xs')]: {
            width: "max-content"
        }
    },
    shoppingCartTotalText: {
        color: Constants.WHITE,
        fontWeight: "bold",

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    shoppingCartButtonWrapper: {
        // width: "fit-content",
        marginTop: Constants.GET_SIZE(50),
        [theme.breakpoints.down('xs')]: {
            marginTop: Constants.GET_SIZE(20),
        }
    },
    shoppingCartDeliveryBlock: {
        marginBottom: Constants.GET_SIZE(25),
    },
    shoppingCartDeliveryBlockFirst: {
        marginTop: Constants.GET_SIZE(50),
    },
    shoppingCartDeliveryTitle: {
        color: Constants.PINK,
        marginBottom: Constants.GET_SIZE(25),
        fontWeight: "bold",
        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    shoppingCartDeliveryText: {

        fontWeight: "bold",
    },
    shoppingCartErrorText: {

        marginTop: Constants.GET_SIZE(10)
    },
    noProductsText: {
        textAlign: "center",
        marginTop: Constants.GET_SIZE(150),
        marginLeft: Constants.GET_SIZE(50),
        marginRight: Constants.GET_SIZE(50),
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            marginBottom: 20
        }
    }
});
