import React from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Text from "../Component/FontText"
import LeftMenu from "./Partial/LeftMenu"
import Base from "./Base"
import StylesFunc from "../../Style/MiddleOffice/MyLivesStyle"
import * as Constants from "../../Style/Constants"
import ProductSquare from "../Front/Component/ProductSquare"
import Paginator from "../Front/Component/Paginator";
import {getImageUrl} from "../../Utils/ImageUtils";
import CardMedia from "@material-ui/core/CardMedia";
import Moment from "moment";

const ITEMS_PER_PAGE = 5;

function Component({navigation, history, screenKey, token, user, livesList, pagination, fetchMyLives}) {
    let Styles = StylesFunc();
    let [page, setPage] = React.useState(1);
    let [tab, setTab] = React.useState(0); // 0 prochains / 1 passés

    React.useEffect(() => {
        if (!user || !user.shop) {
            history.push("/creer_ma_boutique");
        }
    }, [user]);

    React.useEffect(() => {
        fetchMyLives(tab, page, ITEMS_PER_PAGE, false);
    }, [tab, page]);

    let _getTabs = () => {
        return <Box className={Styles.tabsWrapper}>
            <Button variant={tab === 0 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setTab(0)} size={"small"}>
                Prochains
            </Button>
            <Button variant={tab === 1 ? "contained" : "outlined"} color={"primary"} disableElevation onClick={() => setTab(1)} size={"small"}>
                Passés
            </Button>
        </Box>
    }

    return <Base content={
        <Box className={Styles.container}>
            <Text className={Styles.title}>Mes lives</Text>
            <Grid container>
                <Hidden xsDown>
                    <LeftMenu currentPage={"MY_LIVES"} />
                </Hidden>
                <Box className={Styles.subcontentWrapper}>
                    {_getTabs()}
                    <Box className={Styles.grayBackground}>
                        {livesList && <Grid container className={Styles.livesWrapper}>
                            {livesList.map(live => <Grid item container xs={12} className={Styles.liveWrapper} justify={"space-between"}>
                                <Grid item container className={Styles.miniWrapper} alignItems={"center"}>
                                    <CardMedia
                                        image={live.liveProducts.length > 0 ? getImageUrl(live.liveProducts.sort((a, b) => a.position - b.position)[0].product.pictures[0] != null ? live.liveProducts.sort((a, b) => a.position - b.position)[0].product.pictures[0].contentUrl : null, "small") : null}
                                        className={Styles.image}
                                    />
                                    <Box>
                                        <Text className={Styles.text}>{ Moment(live.startAt).format("DD/MM/YY HH:mm") }</Text>
                                        <Text className={Styles.subText}>{ live.liveProducts.length } produit{live.liveProducts.length > 1 ? 's' : ''} mis en vente</Text>
                                    </Box>
                                </Grid>
                                <Grid item container className={Styles.miniWrapper} alignItems={"center"}>
                                    {tab === 0 && <Text className={Styles.subText}>{live.nbSubscribedUsers} personne{live.nbSubscribedUsers > 1 ? 's':''} intéressée{live.nbSubscribedUsers > 1 ? 's':''}</Text>}
                                    {tab === 1 && <Text className={Styles.subText}>{live.liveConnections.length} spectateur{live.liveConnections.length > 1 ? 's' : ''}</Text>}
                                </Grid>
                                <Grid item container className={Styles.miniWrapper} alignItems={"center"}>
                                    {tab !== 1 && <Button
                                        style={{padding: 8, paddingLeft: 30, paddingRight: 30}}
                                        disableElevation
                                        size={"small"}
                                        variant={"contained"}
                                        color={"primary"}
                                        href={'editer_live/' + live.id}>
                                        Modifier
                                    </Button>}
                                </Grid>
                            </Grid>)}
                        </Grid>}
                        <Paginator firstPage={pagination.first} lastPage={pagination.last} page={page} setPage={setPage} backgroundColor={Constants.WHITE} />
                    </Box>
                </Box>
            </Grid>
        </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.me,
        livesList: state.live.livesList,
        pagination: state.live.pagination
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchMyLives: (tab, page, itemsPerPage, hideLoader) => {
            dispatch({type: 'FETCH_MY_LIVES_REQUESTED', tab, page, itemsPerPage, hideLoader})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
