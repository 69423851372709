import React from 'react';
import TextInput from "../../Component/FontInput";
import { TouchableOpacity } from 'react-native-web';

import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Text from "../../Component/FontText";
import Map from "../../Component/Map"
import { connect } from 'react-redux'
import * as Constants from '../../../Style/Constants'
import StylesFunc from '../../../Style/RelaySelectionModalStyle'

const DEFAULT_BOUNDS = [
    [41.2632185, -5.4534286],
    [51.268318, 9.8678344]
];


function Component ({visible, setVisible, mondialRelayPoints, chronoRelayPoints, searchRelayPoints, addresses, searchAddresses, defaultCity, defaultZipcode, setValue, type}) {
    let [showAutocomplete, setShowAutocomplete] = React.useState(false);
    let [launchedSearch, setLaunchedSearch] = React.useState(false);
    let [selectedMarker, setSelectedMarker] = React.useState(null);

    let relayPoints = type === "Chronopost" ? chronoRelayPoints : mondialRelayPoints;
    let Styles = StylesFunc();


    let c = 0;
    let launchSearch = (input) => {
        c += 1;
        setTimeout(() => {
            c -= 1;
            if (c === 0) {
                if (input !== "") {
                    searchAddresses(input);
                }
                setShowAutocomplete(false);
                setLaunchedSearch(true);
            }

        }, 1000);
    };

    React.useEffect(() => {
        if (defaultZipcode) {
            searchRelayPoints(type, defaultCity, defaultZipcode);
        }
    }, [defaultZipcode]);

    let searchRelay = (city, zipcode) => {
        setShowAutocomplete(false);
        searchRelayPoints(type, city, zipcode);
    };

    let autocompleteDom = [];
    if (addresses.length > 0) {
        for (const address of addresses) {
            autocompleteDom.push(
                <Button style={{padding: 10}} onClick={() => {searchRelay(address.address.city, address.address.postcode)}}>
                    <Text style={{textTransform: "none"}}>{address.display_name}</Text>
                </Button>
            );
            autocompleteDom.push(
                <Box style={{height: 1, width: "100%", backgroundColor: "lightgrey"}}/>
            );
        }
    }

    if (addresses.length > 0 && launchedSearch) {
        setLaunchedSearch(false);
        setShowAutocomplete(true);
    }

    let markers = [];
    let relayPointsDom = [];
    let bound = DEFAULT_BOUNDS;
    if (relayPoints.length > 0) {
        let minLat = null, maxLat = null, minLong = null, maxLong = null;

        const localMarkers = [];
        for (const rp of relayPoints) {
            let currLat = parseFloat(rp["latitude"]), currLong = parseFloat(rp["longitude"]);
            if (currLat < minLat || minLat === null) {
                minLat = currLat;
            }
            if (currLat > maxLat || maxLat === null) {
                maxLat = currLat;
            }
            if (currLong < minLong || minLong === null) {
                minLong = currLong;
            }
            if (currLong > maxLong || maxLong === null) {
                maxLong = currLong;
            }

            localMarkers.push({
                text: <Box className={Styles.markerWrapper}>
                        <Text className={Styles.markerText1}>
                            {rp.name}
                        </Text>
                        <Text className={Styles.markerText2}>
                            {rp.address1}
                        </Text>
                        <Text className={Styles.markerText3}>
                            {rp.zipCode} {rp.city}
                        </Text>
                        <Button disableElevation color={"primary"} variant={"contained"} size={"small"} onClick={() => {setValue(rp); setVisible(false)}} style={{width: "100%"}}>
                            Choisir
                        </Button>
                    </Box>,
                position: [currLat, currLong],
                selected: selectedMarker === rp
            });

            relayPointsDom.push(
                <Grid container direction={"row-reverse"}>
                    <Grid item xs={12} md={9}>
                        <Button onClick={() => setSelectedMarker(rp)} style={{width: "100%"}}>
                            <Box>
                                <Text className={Styles.relayText1}>
                                    {rp.name}
                                </Text>
                                <Text className={Styles.relayText2}>
                                    {rp.address1}
                                </Text>
                                <Text className={Styles.relayText2}>
                                    {rp.zipCode} {rp.city}
                                </Text>
                            </Box>
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button disableElevation color={"primary"} variant={"contained"} size={"small"} onClick={() => {setValue(rp); setVisible(false)}} style={{width: "100%"}}>
                            Choisir
                        </Button>
                    </Grid>
                </Grid>
            );
            relayPointsDom.push(
                <Box className={Styles.relayDivider} />
            )
        }

        relayPointsDom.pop();


        if (minLat === null) {
            bound = DEFAULT_BOUNDS;
        } else {
            bound = [[minLat, minLong], [maxLat, maxLong]];
            // bound = {
            //     nw: {lat: parseFloat(minLat), lng: parseFloat(minLong)},
            //     se: {lat: parseFloat(maxLat), lng: parseFloat(maxLong)}
            // };
        }
        markers = localMarkers;
    }


    let _renderModalContent = () => (
        <Box className={Styles.modalContent}>
            <Text className={Styles.title}>Choisissez votre point relais</Text>
            <Box className={Styles.autocompleteWrapper}>
                <Text className={Styles.inputLabel}>Code postal : </Text>
                <Box className={Styles.inputWrapper}>
                    <TextInput className={Styles.input} onChange={(event) => {launchSearch(event.target.value)}} variant={"outlined"} InputProps={{className: Styles.input}} label={"Code postal"}/>
                </Box>
                {showAutocomplete && <Box className={Styles.autocompleteOverlay}>
                    {autocompleteDom}
                </Box>}
            </Box>

            <Grid container className={Styles.contentWrapper}>
                <Grid item xs={12} md={3} className={Styles.relayList}>
                    {relayPointsDom}
                </Grid>
                <Grid item xs={12} md={9}>
                    <Map mapStyle={{height: Constants.GET_SIZE(500), flex: 3}} bounds={bound} markers={markers} visible={visible} />
                </Grid>
            </Grid>

            <Grid container justify={"center"} className={Styles.bottomBar}>
                <Button disableElevation color={"primary"} variant={"contained"} size={"small"} onClick={() => setVisible(false)}>
                    Fermer
                </Button>
            </Grid>
        </Box>
    );

    return (
        <Dialog open={visible} fullWidth maxWidth={"lg"}>
            {_renderModalContent()}
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        mondialRelayPoints: state.relayPoints.mondialRelayPoints,
        chronoRelayPoints: state.relayPoints.chronoRelayPoints,
        addresses: state.relayPoints.addresses
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchRelayPoints: (relayType, city, zipcode) => {
            dispatch({type: 'FETCH_RELAY_POINTS_REQUESTED', relayType, city, zipcode})
        },
        searchAddresses: (zipcode) => {
            dispatch({type: 'SEARCH_ADDRESS_REQUESTED', zipcode})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent;