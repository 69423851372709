import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        backgroundColor: Constants.BACKGROUND_LIGHT_GRAY
    },
    row: {
        ...GlobalStyle.row,
    },
    // Header
    homepageHeaderWrapper: {
        maxHeight: 200,
        height: 200,
    },
    homepageHeaderLeftWrapper: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        width: "100%",
        borderRight: "1px solid white"
    },

    homepageHeaderText: {
        color: Constants.FONT_LIGHT_GRAY,
        // width: 200
    },

    headerImageTitle: {
        ...GlobalStyle.headerImageTitle,
        position: "absolute",
        bottom: 50,
        left: 37,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM,
            bottom: 20,
            left: 25,
            width: "85%",
            overflowWrap: "break-word"
        }
    },

    homepageHeaderRectangle: {
        position: "absolute",
        bottom: 0,
        left: 37,
        width: 205,
        height: 15,
        [theme.breakpoints.down('xs')]: {
            left: 25,
        }
    },

    homepageHeaderPinkRectangle: {
        backgroundColor: Constants.PINK
    },
    container : {
        maxWidth: 1440,
        margin: "auto"
    },
    // rowSpaceBetween: {
    //     ...GlobalStyle.row,
    //     justifyContent: "space-between"
    // },
    rowSpaceBetween: {
        ...GlobalStyle.row,
        width: "auto"
    },
    shopListWrapper: {
        maxWidth: 1440,
        width: "100%",
        margin: "auto",
        paddingTop: 50,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 50,
    },
    shopSquareWrapper: {
        width: 320,
        marginBottom: Constants.GET_SIZE(32),
        [theme.breakpoints.down('xs')]: {
            width: 300
        }
        // marginRight: Constants.GET_SIZE(5),
        // marginLeft: Constants.GET_SIZE(5),
    },
    productWrapper: {
        marginRight: "auto",
        marginLeft: "auto",
        width: 240,
        height: 340
    },
    page: {
        color: "#F8ADB1",
    },
    currentPage: {
        color: "black"
    },
    bloggerDescription: {
        textAlign: "center",

        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    bloggerDescriptionWrapper: {
        paddingLeft: Constants.GET_SIZE(150),
        paddingRight: Constants.GET_SIZE(150),
        paddingTop: Constants.GET_SIZE(50),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
        }
    },
    shopWrapper: {
        position: "relative",
        margin: Constants.GET_SIZE(25),
    },
    shopImage: {
        width: Constants.GET_SIZE(60),
        height: Constants.GET_SIZE(60),
        borderRadius: "50%",
        marginRight: Constants.GET_SIZE(15),
        [theme.breakpoints.down('xs')]: {
            marginRight: Constants.GET_SIZE(8),
        }
    },
    shopName: {
        color: Constants.PINK,
        textAlign: "left",
        fontSize: Constants.FONT_SIZE_MEDIUM,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
    shopName2: {
        textAlign: "left",
        fontStyle: 'italic',
    },
    shopContact: {

        marginLeft: "auto",
    },
    subscribeWrapper: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 5,
            marginBottom: 5
        }
    },
    shopSubscribe: {
        marginRight: Constants.GET_SIZE(50),
        marginLeft: Constants.GET_SIZE(20),
        backgroundColor: Constants.WHITE,
        paddingLeft: Constants.GET_SIZE(20),
        paddingRight: Constants.GET_SIZE(20),
        paddingTop: Constants.GET_SIZE(10),
        paddingBottom: Constants.GET_SIZE(10),
        borderColor: Constants.BORDER_GRAY,
        borderWidth: 1,
        height: Constants.FONT_SIZE_BIGGEST
    },
    shopSubscribeLabel: {

        ...GlobalStyle.uppercaseText
    },
    overlay: {

        position: "absolute",
        maxWidth: 531,
        width: "100%",
        // left: Constants.GET_SIZE(80),
        top: Constants.GET_SIZE(73),
        backgroundColor: Constants.WHITE,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: Constants.BORDER_GRAY,
        padding: Constants.GET_SIZE(40),
        zIndex: 999,
        [theme.breakpoints.down('xs')]: {
            boxSizing: "border-box"
        }
    },

    slider: {
        [theme.breakpoints.down('xs')]: {
            width: "97%"
        }
    },
    noShopsText: {
        fontSize: Constants.FONT_SIZE_MEDIUM,
        textAlign: "center",
        width: "100%",
        marginTop: Constants.GET_SIZE(30),
        marginBottom: Constants.GET_SIZE(30),
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
        }
    },
}));
