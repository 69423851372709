import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "../GlobalStyle";
import * as Constants from "../Constants"


export default makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 1440,
        margin: "auto",
        marginTop: 100,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
            width: '90%'
        }
    },
    title: {
        ...GlobalStyle.uppercaseText,
        fontWeight: "bold",
        width: "max-content",
        marginBottom: Constants.GET_SIZE(50),

        fontSize: Constants.FONT_SIZE_BIG,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG_MOBILE,
        }
    },
    contentWrapper: {
        ...GlobalStyle.row,
        flex: 1,
        marginLeft: Constants.GET_SIZE(25),
    },
    contentAndMenuWrapper: {
        ...GlobalStyle.row,
        marginBottom: Constants.GET_SIZE(50),
        width: "100%"
    },
}));
