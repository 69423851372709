import History from '../../History';


const initialState = {
    RGPDValidated: false,
    cookieAnalytics: false,
    iOSPWAIgnore: false,
    hideLiveListPopup: false,
    token: null,
    loginError: false,
    resetPasswordMailSent: false,
    resetPasswordError: false,
    changePasswordMailSent: false,
    changePasswordError: false,
    tokenExpired: false
};


function reduc(state = initialState, action) {
    // Check if JWT token is invalid in any request and redirect to login page
    if (action.type.endsWith("_FAILED") && action.message === "Expired JWT Token") {
        return Object.assign({}, state, {
            token: null,
            tokenExpired: true
        });
    }

    switch (action.type) {
        case "LOGIN_REQUESTED":
            return Object.assign({}, state, {
                token: null,
                loginError: false
            });
        case "LOGIN_SUCCEEDED":
            return Object.assign({}, state, {
                token: action.token,
                loginError: false
            });
        case "LOGIN_FAILED":
            return Object.assign({}, state, {
                loginError: action.message ? action.message : true
            });
        case "LOGOUT":
            return Object.assign({}, state, {
                loginError: false,
                token: null
            });

        case "PASSWORD_RESET_SUCCEEDED":
            return Object.assign({}, state, {
                resetPasswordMailSent: true,
                resetPasswordError: false
            });
        case "PASSWORD_RESET_FAILED":
            return Object.assign({}, state, {
                resetPasswordError: true
            });

        case "CHANGE_PASSWORD_SUCCEEDED":
            return Object.assign({}, state, {
                changePasswordMailSent: true,
                changePasswordError: false
            });
        case "CHANGE_PASSWORD_FAILED":
            return Object.assign({}, state, {
                changePasswordError: true
            });
        case "FETCH_ME_FAILED":
            return Object.assign({}, state, {
                // token: null
            });
        case "SET_RGPD_VALIDATED":
            return Object.assign({}, state, {
                RGPDValidated: action.valid,
                cookieAnalytics: action.analytics
            });
        case "SET_IOS_PWA_IGNORE":
            return Object.assign({}, state, {
                iOSPWAIgnore: action.ignore
            })
        case "SET_HIDE_LIVE_LIST_POPUP":
            return Object.assign({}, state, {
                hideLiveListPopup: action.ignore
            })
    }

    return state
}

export default reduc
