import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";
import {API_URL, STRIPE_PUBLIC_KEY} from "../../Config/API";
import {dataURLtoFile} from "../../Utils/ImageUtils";
import Moment from "moment";
import {useStripe} from "@stripe/react-stripe-js";



async function uploadStripeFile(file, name) {
    const data = new FormData();
    data.append('file', file);
    data.append('purpose', 'identity_document');
    const fileResult = await fetch('https://uploads.stripe.com/v1/files', {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + STRIPE_PUBLIC_KEY},
        body: data,
    });
    return await fileResult.json();
}

async function createStripePersonToken(stripe, data) {

    let fileData = data.identityCardFront && data.identityCardFront.file ? await uploadStripeFile(data.identityCardFront.file, "idCard1") : null;
    let fileData2 = data.identityCardBack && data.identityCardBack.file ? await uploadStripeFile(data.identityCardBack.file, "idCard2") : null;
    let fileData3 = data.addressProof && data.addressProof.file ? await uploadStripeFile(data.addressProof.file, "addressValidation") : null;

    let momentDob = new Date(data.ownerBirthdate);
    let personData = {
        person: {
            first_name: data.ownerFirstname,
            last_name: data.ownerName,
            dob: {
                year: momentDob.getUTCFullYear(),
                month: momentDob.getUTCMonth() + 1,
                day: momentDob.getUTCDate()
            },
            email: data.ownerEmail,
            phone: '+33' + data.ownerPhone.slice(1),
            address: {
                line1: data.street,
                city: data.city,
                postal_code: data.zipcode,
            },
            relationship: {
                representative: true,
                owner: true,
                director: true,
                executive: true,
                title: "Dirigeant"
            }
        },
    };
    if (fileData || fileData2 || fileData3) {
        personData.verification = {};
        if (fileData3) {
            personData.verification.additional_document = {
                front: fileData3.id
            };
        }
        if (fileData || fileData2) {
            personData.verification.document = {};
            if (fileData) {
                personData.verification.document.front = fileData.id;
            }
            if (fileData2) {
                personData.verification.document.back = fileData2.id;
            }
        }
    }
    let personResult = await stripe.createToken('person', personData);

    console.log("person", personResult);
    return personResult && personResult.token ? personResult.token.id : null;
}

async function createStripeAccountToken(stripe, data) {


    let accountData = {
        tos_shown_and_accepted: true
    };

    accountData.business_type = 'non_profit';
    accountData.company = {
        name: data.name,
        address: {
            line1: data.street,
            city: data.city,
            postal_code: data.zipcode,
        },
        phone: '+33' + data.ownerPhone.slice(1),
        tax_id: data.SIRET,
        owners_provided: true,
        directors_provided: true,
        executives_provided: true,
    };


    let accountResult = await stripe.createToken('account', accountData);

    console.log("account", accountResult);
    return accountResult && accountResult.token ? accountResult.token.id : null;
}

const useSave = (entityName, onSuccess, onError) => {
    const queryClient = useQueryClient();
    const stripe = useStripe();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (data) => {
            // TODO : Hack for Stripe (that's a lot of hacks...) => find better way
            data.stripePersonToken = await createStripePersonToken(stripe, data);
            data.stripeAccountToken = await createStripeAccountToken(stripe, data);


            // TODO : Hack for pictures => find better way
            if (data.medias) {
                for (let i = 0; i < data.medias.length; i++) {
                    if (data.medias[i].picture && data.medias[i].picture.file) {
                        let pictureId = -1;
                        if (data.medias[i].picture.id === -1) {
                            let formData = new FormData();
                            formData.append('file', data.medias[i].picture.file);
                            let result = await axios.post(API_URL + 'api/media_objects', formData, {
                                headers: setDefaultHeaders(
                                    {"Content-type": "multipart/form-data"}
                                )
                            });
                            pictureId = result.data['id'];
                        } else {
                            pictureId = data.medias[i].picture.id;
                        }
                        data.medias[i].picture = '/api/media_objects/' + pictureId;
                    } else {
                        delete data.medias[i].picture;
                    }
                }
            }
            // TODO : Hack for files => find better way
            const fileFields = ['addressProof', 'identityCardFront', 'identityCardBack'];
            for (let i = 0; i < fileFields.length; i++) {
                let currentField = fileFields[i];

                console.log('a ' + currentField);
                if (data[currentField]) {
                    if (data[currentField].file) {
                        let pictureId = -1;
                        if (data[currentField].id === -1) {
                            let formData = new FormData();
                            formData.append('file', data[currentField].file);
                            let result = await axios.post(API_URL + 'api/media_objects', formData, {
                                headers: setDefaultHeaders(
                                    {"Content-type": "multipart/form-data"}
                                )
                            });
                            pictureId = result.data['id'];
                        } else {
                            pictureId = data[currentField].id;
                        }
                        console.log('b ' + currentField);
                        data[currentField] = '/api/media_objects/' + pictureId;
                    } else {
                        data[currentField] = '/api/media_objects/' + data[currentField].id;
                    }
                } else {
                    delete data[currentField]
                }
            }

            if (data.id) {
                return axios.put(
                    getApiUrl(entityName + 's/' + data.id),
                    data,
                    {headers: setDefaultHeaders()}
                );
            } else {
                return axios.post(
                    getApiUrl(entityName + 's'),
                    data,
                    {headers: setDefaultHeaders()}
                );
            }
        },
        onSuccess: async () => {
            await queryClient.removeQueries(entityName);
            await queryClient.removeQueries(entityName + 's');
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: async (e) => {
            if (onError) {
                onError(e.response.data);
            }
        }
    });

    return { save: mutate, isSaving: isLoading };
}

export default useSave;
