import axios from "axios";
import {getApiUrl, setDefaultHeaders} from "../../Utils/QueryUtils";

let getAssociations = async () => {
    const { data } = await axios.get(
        getApiUrl('associations'),
        {headers: setDefaultHeaders()}
    );
    return data;
}

let getAssociationsByCategory = async () => {
    const { data } = await axios.get(
        getApiUrl('association_categories'),
        {headers: setDefaultHeaders()}
    );
    return data;
}

let deleteAssociation = async (id) => {
    return axios.delete(
        getApiUrl('associations/' + id),
        {headers: setDefaultHeaders()}
    );
}

let updateAssociation = async (data) => {
    return axios.post(
        getApiUrl('associations/' + data.id),
        data,
        {headers: setDefaultHeaders()}
    );
}

let getAssociation = async (id) => {
    const { data } = await axios.get(
        getApiUrl('associations/' + id),
        {headers: setDefaultHeaders()}
    );
    return data;
}

export {getAssociations, getAssociationsByCategory, deleteAssociation, getAssociation, updateAssociation}
