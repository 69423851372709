import axios from 'axios';
import {API_URL} from '../../Config/API'
import { getApiUrl, setDefaultHeaders } from '../../Utils/QueryUtils'

export const SearchProducts = (family, families, category, name, respShop, createurShop, blogShop, sizes, brand, state, price, delivery, materials, colors, shop, page, itemsPerPage, sortBy) => {
    const params = {
        family,
        families,
        category,
        name,
        sizes,
        brand,
        state,
        delivery,
        materials,
        colors,
        page,
        "shop.type": [respShop ? "1" : null, createurShop ? "2" : null, blogShop ? "3": null],
        shop,
        "price[gt]": price ? price[0] : null,
        "price[lt]": price ? price[1] : null,
        itemsPerPage,
        sortBy
    };
    return axios.get(getApiUrl('products', params), {headers: setDefaultHeaders()});
};

export const FetchProduct = (id) => {
    return axios.get(getApiUrl('products/' + id), {headers: setDefaultHeaders()});
};

export const GetShopsProducts = (id, page, itemsPerPage) => {
    const params = {
        itemsPerPage,
        page
    };
    return axios.get(getApiUrl('shops/' + id + '/products', params), {headers: setDefaultHeaders()});
};

export const GetShopSimilarProducts = (id, size) => {
    return axios.get(getApiUrl('shops/' + id + '/products', {sizes: size}), {headers: setDefaultHeaders()});
};

export const FetchTendancesSauvages = () => {
    return axios.get(getApiUrl('products/tendances_sauvages'), {headers: setDefaultHeaders()});
};

export const FetchNew = () => {
    return axios.get(getApiUrl('products/new'), {headers: setDefaultHeaders()});
};

export const FetchWishlist = (page, itemsPerPage) => {
    return axios.get(getApiUrl('products/wishlist', {itemsPerPage, page}), {headers: setDefaultHeaders()});
};

export const SubscribeToProduct = (id) => {
    return axios.get(API_URL + 'api/products/' + id + '/subscribe', {
        headers: setDefaultHeaders()
    })
};

export const UnsubscribeFromProduct = (id) => {
    return axios.get(API_URL + 'api/products/' + id + '/unsubscribe', {
        headers: setDefaultHeaders()
    })
};

export const AddProductToCart = (id, quantity, size, color) => {
    return axios.get(getApiUrl('products/' + id + '/add_to_cart', {quantity, size, color}), {
        headers: setDefaultHeaders()
    })
};

export const RefreshProductsState = (ids) => {
    return axios.post(getApiUrl('refresh_products_state'),
        ids,
        {
            headers: setDefaultHeaders()
        }
    );
};

export const RemoveProductFromCart = (id) => {
    return axios.get(API_URL + 'api/products/' + id + '/remove_from_cart', {
        headers: setDefaultHeaders()
    })
};

export const SaveProduct = (product) => {
    if (product.id) {
        return axios.put(API_URL + 'api/products/' + product.id, product,  {
            headers: setDefaultHeaders()
        })
    } else {
        return axios.post(API_URL + 'api/products', product,  {
            headers: setDefaultHeaders()
        })
    }
};

export const SavePicture = (pictureData) => {
    let data = new FormData();
    data.append('file', pictureData);
    return axios.post(API_URL + 'api/media_objects', data,  {
        headers: setDefaultHeaders(
            {"Content-type": "multipart/form-data"}
        )
    })
};

export const SaveBrand = (brandName) => {
    return axios.post(getApiUrl('criterion_brands'), {value: brandName},{headers: setDefaultHeaders()});
};

export const FetchMyProducts = (type, page, itemsPerPage) => {
    return axios.get(getApiUrl('products/my_products', {type, page, itemsPerPage}), {headers: setDefaultHeaders()});
};

export const FetchAllMyProducts = () => {
    return axios.get(getApiUrl('products/my_products', {type: 0, pagination: false}), {headers: setDefaultHeaders()});
};

export const RemoveProduct = (id) => {
    return axios.delete(getApiUrl('products/' + id), {headers: setDefaultHeaders()});
};

export const DeleteProduct = (id) => {
    return axios.delete(getApiUrl('products/' + id), {headers: setDefaultHeaders()});
};
