import React from "react";
import clsx from 'clsx';
import {Picker} from "react-native";
import { TouchableOpacity } from "react-native-web";
import { connect } from 'react-redux';
import { withRouter, useParams } from "react-router-dom";
import Moment from 'moment';

import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";

import Text from "../Component/FontText"
import Base from "./Base"
import StylesFunc from "../../Style/Back/UserEditStyle"
import TextInputIcon from "../Front/Component/TextInputIcon";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import ErrorStyleFunc from "../Styles/Error";
import ConfirmationModal from "../Front/Component/ConfirmationModal";
import BlockModal from "./Component/BlockModal";
import * as Constants from "../../Style/Constants";
import Grid from "@material-ui/core/Grid";


function Component({navigation, history, screenKey, token, user, saveUser, fetchUser, blockUser, deleteUser}) {
    let Styles = StylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const { setValue, setError, handleSubmit, control, register, errors, watch } = useForm({defaultValues: {civility: ""}});

    let { id } = useParams();

    let [ modalVisible, setModalVisible ] = React.useState(false);
    let [ modal2Visible, setModal2Visible ] = React.useState(false);
    let [ modal2Block, setModal2Block ] = React.useState(user ? user.status === 0 : true);
    let [ blockModalVisible, setBlockModalVisible ] = React.useState(false);
    let [ deleteModalVisible, setDeleteModalVisible ] = React.useState(false);

    let [ deliveryMode, setDeliveryMode ] = React.useState('handOver');
    let [ status, setStatus ] = React.useState(0);

    let loadUser = () => {
        if (user) {
            // Global info
            setValue("civility", user.civility);
            setValue("name", user.name);
            setValue("firstname", user.firstname);
            setValue("email", user.email);
            setValue("phone", user.phone);

            if (user.shop) {
                setStatus(1);
            }
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    React.useEffect(() => {
        if (id) {
            fetchUser(id)
        }
    }, [id]);

    React.useEffect(() => {
        loadUser();
    }, [user]);


    let handleSaveUser = ({civility, name, firstname, email, phone}) => {
        let newUser = {
            id: user.id,
            civility: parseInt(civility).toString(),
            name: name,
            firstname: firstname,
            email: email,
            phone: phone,

        };

        saveUser(newUser);
        setModalVisible(true);

    };

    let handleClickContinue = (data) => {
        handleSaveUser(data);
    };

    let handleClickBack = () => {
        if (history.location.state && history.location.state.listState) {
            history.push("/admin/liste", {listState: history.location.state.listState})
        } else {
            history.push("/admin/liste", {listState: {tab: "USER", page: 1, search: ""}})
        }
    };

    let handleClickDelete = () => {
        setDeleteModalVisible(true);
    };

    let handleClickBlock = () => {
        if (user && user.status === 0) {
            setBlockModalVisible(true);
        } else {
            blockUser(user.id, false);
            setModal2Block(false);
            setModal2Visible(true);
        }
    };

    let _getSellerBlock = () => {
        return <Grid item xs={12} sm={6}>
            <Text style={{marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, width: "100%"}}>Statut : </Text>

            <Select variant={"outlined"} className={Styles.picker} value={status} onChange={(event) => setStatus(event.target.value)}>
                <MenuItem value={0}>Acheteur</MenuItem>
                {user && user.shop && <MenuItem value={1}>Vendeur</MenuItem>}
            </Select>

            <TextInputIcon
                value={user.shop.name}
                disabled={true}
                topLabel={"Nom de la boutique"}
            />
            <TextInputIcon
                value={(user.shop.nbProducts ? user.shop.nbProducts : 0) + ""}
                disabled={true}
                topLabel={"Nombre d'articles en vente"}
            />
            <TextInputIcon
                value={(user.shop.nbSoldProducts ? user.shop.nbSoldProducts : 0) + ""}
                disabled={true}
                topLabel={"Nombre d'articles vendus"}
            />
            <TextInputIcon
                value={(user.shop.currentMoney ? user.shop.currentMoney : 0) + " €"}
                disabled={true}
                topLabel={"Wallet"}
            />
            <TextInputIcon
                value={user.shop.bankAccountIBAN}
                disabled={true}
                topLabel={"Coordonnées bancaires"}
            />
            <Text style={{marginTop: Constants.GET_SIZE(12), fontSize: Constants.FONT_SIZE_NORMAL, width: "100%"}}>Types de transaction : </Text>
            <Select variant={"outlined"} className={Styles.picker} value={deliveryMode} onChange={(event) => setDeliveryMode(event.target.value)}>
                <MenuItem value={'handOver'}>En main propre</MenuItem>
                <MenuItem value={'clickAndCollect'}>Click & Collect</MenuItem>
                <MenuItem value={'chronopost'}>Chronopost</MenuItem>
                <MenuItem value={'mondialRelay'}>Mondial Relay</MenuItem>
            </Select>
            {deliveryMode in user.shop.availableDeliveryModes &&
                <TextInputIcon
                    value={(user.shop.availableDeliveryModes[deliveryMode] ? user.shop.availableDeliveryModes[deliveryMode] : 0) + ""}
                    disabled={true}
                    topLabel={"Nombre d'articles en vente"}
                />}
        </Grid>;
    };

    let _getBuyerBlock = () => {
        return <Grid item xs={12} sm={6}>
            <Text style={{marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, width: "100%"}}>Statut : </Text>

            <Select variant={"outlined"} className={Styles.picker} value={status} onChange={(event) => setStatus(event.target.value)}>
                <MenuItem value={0}>Acheteur</MenuItem>
                {user && user.shop && <MenuItem value={1}>Vendeur</MenuItem>}
            </Select>

            <TextInputIcon
                value={(user && user.nbBoughtProducts ? user.nbBoughtProducts : 0) + ""}
                disabled={true}
                topLabel={"Nombre d'articles achetés"}
            />
        </Grid>;
    };

    let _getPage = () => {
        return <Box>
            <Grid container>
                <Grid xs={12} sm={6} style={{boxSizing: "border-box", paddingRight: 20}}>
                    <TextInputIcon
                        value={user ? user.id : ""}
                        disabled={true}
                        topLabel={"Numéro utilisateur"}
                    />
                    <Text style={{marginTop: Constants.GET_SIZE(10), fontSize: Constants.FONT_SIZE_NORMAL, width: "100%"}}>Civilité :</Text>
                    <Controller
                        as={
                            <Select variant={"outlined"} className={Styles.picker}>
                                <MenuItem value={""} disabled>Civilité</MenuItem>
                                <MenuItem value={0}>Mr.</MenuItem>
                                <MenuItem value={1}>Mme.</MenuItem>
                            </Select>
                        }
                        control={control}
                        rules={{required: "Ce champ est obligatoire"}}
                        name={"civility"}
                    />
                    <ErrorMessage name={"civility"} errors={errors} as={Text} className={ErrorStyle.error} />
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"name"}
                        topLabel={"Nom"}
                    />
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"firstname"}
                        topLabel={"Prénom"}
                    />
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"email"}
                        topLabel={"E-mail"}
                    />
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        rules={{
                            required: "Ce champ est obligatoire",
                            pattern: {
                                value: /^0[0-9]{9}$/,
                                message: "Format du numéro de téléphone invalide"
                            }
                        }}
                        name={"phone"}
                        topLabel={"Téléphone portable"}
                    />
                    <TextInputIcon
                        value={user ? Moment(user.createdAt).format('DD/MM/YYYY') : 0}
                        disabled={true}
                        topLabel={"Date inscription"}
                    />
                </Grid>
                {status == 1 && user.shop ? _getSellerBlock() : _getBuyerBlock()}
            </Grid>

            <Grid item container style={{marginTop: 20}} >
                <Button variant={"contained"} color={"primary"} size={"small"} disableElevation onClick={handleSubmit(handleClickContinue)} style={{marginRight: 20}}>
                    Enregistrer
                </Button>
                <Button variant={"contained"} color={"primary"} size={"small"} disableElevation onClick={handleClickBlock} style={{marginRight: 20}}>
                    {user && user.status !== 0 ? "Débloquer" : "Bloquer"}
                </Button>
                <Button variant={"contained"} color={"primary"} size={"small"} disableElevation onClick={handleClickDelete} style={{marginRight: 20}}>
                    Supprimer
                </Button>
                <Button variant={"outlined"} color={"primary"} size={"small"} disableElevation onClick={handleClickBack}>
                    Retour
                </Button>
            </Grid>
        </Box>
    };

    return <Base
        title={"Modifier l'utilisateur"}
        currentScreen={"ADMIN_LIST"}
        content={
            <Box style={{width: "100%", maxWidth: 800}}>
                {_getPage()}
                <ConfirmationModal
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    title={"Modification utilisateur " + (user ? user.username : "")}
                    message={"Les informations de l'utilisateur ont bien été modifiées"}
                    onConfirm={() => {setModalVisible(false)}} />
                <ConfirmationModal
                    visible={modal2Visible}
                    setVisible={setModal2Visible}
                    title={(modal2Block ? "Blocage" : "Déblocage") + "utilisateur " + (user ? user.username : "")}
                    message={"L'utilisateur a bien été " + (modal2Block ? "bloqué" : "débloqué")}
                    onConfirm={() => {setModal2Visible(false)}} />
                <ConfirmationModal
                    visible={deleteModalVisible}
                    setVisible={setDeleteModalVisible}
                    title={"Suppression de l'utilisateur"}
                    message={"Êtes-vous sûr de vouloir supprimer cet utilisateur ?"}
                    onConfirm={() => {setDeleteModalVisible(false); deleteUser(user.id); setTimeout(handleClickBack, 100);}}
                    onCancel={() => {setDeleteModalVisible(false)}}/>
                <BlockModal
                    visible={blockModalVisible}
                    setVisible={setBlockModalVisible}
                    user={user}
                    callback={() => {setModal2Block(true); setModal2Visible(true);}} />
            </Box>
    } />
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        user: state.user.currentUser,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveUser: (user) => {
            dispatch({type: 'SAVE_USER_REQUESTED', user})
        },
        fetchUser: (id) => {
            dispatch({type: 'FETCH_USER_REQUESTED', id})
        },
        blockUser: (id, block) => {
            dispatch({type: 'BLOCK_USER_REQUESTED', id, block})
        },
        deleteUser: (id) => {
            dispatch({type: 'DELETE_USER_REQUESTED', id})
        }
    }
};

const VisibleComponent = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Component));

export default VisibleComponent
