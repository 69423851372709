import React from "react";

import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import {Image} from "react-native";
import Text from "../Component/FontText";
import { connect } from 'react-redux';
import ContactHeader from "../../Assets/Images/banners/Nous_Contacter.jpg";
import TextInputIcon from "./Component/TextInputIcon";
import {useForm} from "react-hook-form";

import ContactStylesFunc from "../../Style/ContactStyle"
import BecomeSellerBlock from "./Component/BecomeSellerBlock";
import InstagramBlock from "./Component/InstagramBlock";
import ConfirmationModal from "./Component/ConfirmationModal";
import {useContact} from "../../API/Hook/Contact";
import {Button} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutline from "@material-ui/icons/CheckBoxOutlineBlank";
import * as Constants from "../../Style/Constants";
import CheckBoxOutlineChecked from "@material-ui/icons/CheckBoxOutlined";
import clsx from "clsx";
import ErrorStyleFunc from "../Styles/Error";
import Link from "@material-ui/core/Link";
import MetaTags from "react-meta-tags";

function Component({navigation, token, onContact}) {
    let ContactStyles = ContactStylesFunc();
    let ErrorStyle = ErrorStyleFunc();
    const defaultValues = {
        name: "",
        firstname: "",
        mail: "",
        city: "",
        message: "",
        cgu: false
    }
    const { register, handleSubmit, errors, reset } = useForm({defaultValues});

    let [ modalVisible, setModalVisible ] = React.useState(false);
    let [onClickSend, isLoading] = useContact(() => {setModalVisible(true); reset(defaultValues)});

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let meta =
        <MetaTags>
            <title>Contactez Violette Sauvage, votre vide-dressing en ligne</title>
            <meta name="description"
                  content={"Vous souhaitez contacter Violette Sauvage, votre vide dressing en ligne ? Remplissez le formulaire pour toute demande d'informations"}/>
        </MetaTags>;

    return (
        <Box>
            {meta}
            <Box className={ContactStyles.contactHeaderWrapper}>
                <CardMedia className={ContactStyles.contactHeaderImage} component={"img"} src={ContactHeader} />
                <Text className={ContactStyles.contactHeaderText} component={"h1"}>Nous{"\n"}contacter</Text>
                <Box className={ContactStyles.contactHeaderPinkRectangle} />
            </Box>
            <Grid container className={ContactStyles.container}>
                <Grid item container xs={12} sm style={{marginBottom: 20}}>
                    <Link href={"/"} style={{textDecoration: "none", color: "black"}}><Text className={ContactStyles.arianeText}>Accueil&nbsp;/&nbsp;</Text></Link>
                    <Text className={ContactStyles.arianeText}>Contact</Text>
                </Grid>
                <Grid xs={12} item className={ContactStyles.inputWrapper}>
                    <TextInputIcon
                        register={register}
                        errors={errors}
                        placeholder={"Nom*"}
                        rules={{
                            required: "Ce champ est obligatoire"
                        }}
                        name={"name"}
                    />
                </Grid>
                <Grid xs={12} item className={ContactStyles.inputWrapper}>
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        placeholder={"Prénom*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"firstname"}
                    />
                </Grid>
                <Grid xs={12} item className={ContactStyles.inputWrapper}>
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        placeholder={"E-mail*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                            pattern: {
                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Format de l'adresse e-mail invalide"
                            }
                        }}
                        name={"mail"}
                    />
                </Grid>
                <Grid xs={12} item className={ContactStyles.inputWrapper}>
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        placeholder={"Ville*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"city"}
                    />
                </Grid>
                <Grid xs={12} item className={ContactStyles.inputWrapper}>
                    <TextInputIcon
                        errors={errors}
                        register={register}
                        placeholder={"Message*"}
                        rules={{
                            required: "Ce champ est obligatoire",
                        }}
                        name={"message"}
                        multiline={true}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={"cgu"}
                                inputRef={register({required: "Vous devez accepter les conditions générales d'utilisation"})}
                                icon={<CheckBoxOutline style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.FONT_LIGHT_GRAY}}/>}
                                checkedIcon={<CheckBoxOutlineChecked style={{fontSize: Constants.CHECKBOX_SIZE_BIG, color: Constants.PINK}}/>}
                            />
                        }
                        label={
                            <Box style={{textAlign: "left"}}>
                                <Text component={"span"} className={clsx(ContactStyles.checkboxLabel, {[ErrorStyle.error]: errors["cgu"]})}>J'accepte les <Link href={'/CGV'} target={"_blank"}>CGV/CGU et la politique de confidentialité</Link> de Violette Sauvage</Text>
                                {/*<Link href={"/CGV"}>CGV et la politique de confidentialité</Link>*/}
                            </Box>
                        }
                    />
                </Grid>

                <Button
                    onClick={handleSubmit(onClickSend)}
                    className={ContactStyles.button}>
                    <Text className={ContactStyles.buttonLabel}>ENVOYER</Text>
                </Button>
            </Grid>

            <BecomeSellerBlock />
            <InstagramBlock />
            <ConfirmationModal
                visible={modalVisible}
                setVisible={setModalVisible}
                title={"Demande de contact"}
                message={"Votre message a bien été envoyé"}
                onConfirm={() => {setModalVisible(false)}} />
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login.token
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onContact: (name, firstname, email, city, message) => {
            dispatch({type: 'CONTACT_REQUESTED', payload: {name, firstname, email, city, message}})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent
