import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./GlobalStyle";
import * as Constants from "./Constants"


export default makeStyles((theme) => ({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        // width: "90%",
        marginBottom: 20
    },
    disabledOverride: {
        color: Constants.BLACK,
        borderColor: "red"
    },
    inputIcon: {
        fontSize: Constants.FONT_SIZE_BIGGEST,
        color: Constants.FONT_LIGHT_GRAY,
        textAlign: "center",
        paddingRight: Constants.FONT_SIZE_NORMAL,
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIGGER
        }
    },
    inputIconRight: {
        fontSize: Constants.FONT_SIZE_BIGGER,
        color: Constants.FONT_LIGHT_GRAY,
        textAlign: "center",
        marginRight: Constants.FONT_SIZE_NORMAL,
        cursor: "pointer",
        [theme.breakpoints.down('xs')]: {
            fontSize: Constants.FONT_SIZE_BIG
        }
    },
}));
