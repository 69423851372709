import React from "react";

import Grid from "@material-ui/core/Grid";

import Text from "../Component/FontText";
import { connect } from 'react-redux'
import { useLocation } from "react-router-dom";
import TextInputIcon from "./Component/TextInputIcon";
import {useForm} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
import ErrorStyleFunc from "../Styles/Error";
import { LINKS } from "../Component/InternalLink";

import ForgotPasswordStylesFunc from "../../Style/ForgotPasswordStyle"
import LoginStylesFunc from "../../Style/LoginStyle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MetaTags from "react-meta-tags";


function Component({navigation, token, onPasswordReset, onChangePassword, resetPasswordMailSent, resetPasswordError, changePasswordMailSent, changePasswordError}) {
    let ErrorStyle = ErrorStyleFunc();
    let ForgotPasswordStyles = ForgotPasswordStylesFunc();
    let LoginStyles = LoginStylesFunc();
    const { setError, clearErrors, handleSubmit, register, errors, watch } = useForm();

    let [sMail, setSMail] = React.useState(null);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const query = new URLSearchParams(useLocation().search);
    const ptoken = query.get('ptoken');

    let onClickPasswordReset = ({mail}) => {
        if (mail) {
            setSMail(mail);
        }
        if (mail) {
            onPasswordReset(mail)
        } else {
            onPasswordReset(sMail)
        }
    };

    let onClickChangePassword = ({password}) => {
        onChangePassword(ptoken, password)
    };

    React.useEffect(() => {
        clearErrors();
        if (resetPasswordError) {
            setError("mail", {type: "invalid", message: "Nous ne trouvons pas cette adresse email dans notre base de données"});
        }

        if (changePasswordError) {
            setError("global", {type: "invalid", message: "Le lien de changement de mot de passe n'est plus valide."});
        }
    }, [resetPasswordError, changePasswordError]);

    let meta =
        <MetaTags>
            <title>Vous avez oublié votre mot de passe ? | Violette Sauvage</title>
            <meta name="description"
                  content={"Vous avez oublié votre mot de passe de connexion ? Demandez dès maintenant sa réinitialisation et reconnectez-vous vite !"}/>
        </MetaTags>;

    if (ptoken === null) {
        if (!resetPasswordMailSent) {
            return (
                <Grid container xs={12} className={ForgotPasswordStyles.container} alignItems={"center"}>
                    {meta}
                    <Grid item xs={12}>
                        <Text className={ForgotPasswordStyles.welcomeLabel}>Tu as oublié ton mot de passe ?</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            icon={"user-circle"}
                            placeholder={"Adresse e-mail"}
                            rules={{
                                required: "Ce champ est obligatoire",
                                pattern: {
                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Format de l'adresse e-mail invalide"
                                }
                            }}
                            name={"mail"}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={handleSubmit(onClickPasswordReset)} color={"primary"} variant={"contained"} disableElevation className={ForgotPasswordStyles.button}>
                            Réinitialiser le mot de passe
                        </Button>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container xs={12} className={ForgotPasswordStyles.container} alignItems={"center"}>
                    {meta}
                    <Grid item xs={12}>
                        <Text className={ForgotPasswordStyles.connectLabel}>Un e-mail a été envoyé. Il contient un lien sur lequel il te faudra cliquer pour réinitialiser ton mot de passe.</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <Text className={ForgotPasswordStyles.connectLabel}>Si tu ne reçois pas un email, vérifie ton dossier spam et ou essaye à nouveau.</Text>
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={handleSubmit(onClickPasswordReset)} color={"primary"} variant={"contained"} disableElevation className={ForgotPasswordStyles.button}>
                            Renvoyer le mail
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    } else {
        if (!changePasswordMailSent) {
            return (
                <Grid container xs={12} className={ForgotPasswordStyles.container} alignItems={"center"}>
                    {meta}
                    <Grid item xs={12}>
                        <Text className={ForgotPasswordStyles.welcomeLabel}>Ton nouveau mot de passe</Text>
                    </Grid>

                    {/* HACK autocomplete username on chrome */}
                    <TextField
                        name={'username'}
                        style={{opacity: 0, position: 'absolute', width: 0}}
                    />
                    {/* ENDHACK */}
                    <Grid item xs={12}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            icon={"key"}
                            placeholder={"Mot de passe"}
                            rules={{
                                required: "Ce champ est obligatoire",
                                validate: (value) => value === watch('password2') || "Les deux mots de passe doivent être identiques"
                            }}
                            name={"password"}
                            secure={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInputIcon
                            errors={errors}
                            register={register}
                            icon={"key"}
                            placeholder={"Confirmation du mot de passe"}
                            rules={{
                                required: "Ce champ est obligatoire",
                                validate: (value) => value === watch('password') || "Les deux mots de passe doivent être identiques"
                            }}
                            name={"password2"}
                            secure={true}
                        />
                    </Grid>

                    <ErrorMessage name={"global"} errors={errors} as={Text} className={ErrorStyle.error}/>

                    <Grid item xs={12}>
                        <Button onClick={handleSubmit(onClickChangePassword, () => {clearErrors("global"); handleSubmit(onClickChangePassword)()})} color={"primary"} variant={"contained"} disableElevation className={ForgotPasswordStyles.button}>
                            Modifier le mot de passe
                        </Button>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container xs={12} className={ForgotPasswordStyles.container} alignItems={"center"}>
                    {meta}
                    <Grid item xs={12}>
                        <Text className={ForgotPasswordStyles.connectLabel}>Votre mot de passe a bien été changé. Vous pouvez désormais vous connecter.</Text>
                    </Grid>

                    <Grid item xs={12}>
                        <Button href={LINKS["LOGIN"]["web"]} color={"primary"} variant={"contained"} disableElevation className={ForgotPasswordStyles.button}>
                            Se connecter
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        resetPasswordMailSent: state.login.resetPasswordMailSent,
        resetPasswordError: state.login.resetPasswordError,
        changePasswordMailSent: state.login.changePasswordMailSent,
        changePasswordError: state.login.changePasswordError
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onPasswordReset: (mail) => {
            dispatch({type: 'PASSWORD_RESET_REQUESTED', payload: {mail}})
        },
        onChangePassword: (id, password) => {
            dispatch({type: 'CHANGE_PASSWORD_REQUESTED', payload: {id, password}})
        }
    }
};

const VisibleComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default VisibleComponent
