import {makeStyles} from "@material-ui/core/styles";

import * as Constants from "./Constants"

export default {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 1440
    },
    row: {
        display: "flex",
        flexDirection: "row"
    },
    rowCentered: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
    },
    rowCenteredHW: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "center"
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    columnCentered: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    columnCenteredHW: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    uppercaseText: {
        textTransform: "uppercase"
    },
    headerImageTitle: {
        fontSize: Constants.FONT_SIZE_BIGGER,
        textTransform: "uppercase",
        fontWeight: "bolder",
        color: Constants.WHITE
    },
    button: {
        backgroundColor: Constants.PINK,
        padding: Constants.GET_SIZE(20),
        paddingLeft: Constants.GET_SIZE(60),
        paddingRight: Constants.GET_SIZE(60),
        justifyContent: "center"
    },
    buttonLabel: {
        // fontSize: Constants.FONT_SIZE_NORMAL,
        textTransform: "uppercase",
        color: Constants.WHITE,
        fontWeight: "bold",
        textAlign: "center"
    },
};
