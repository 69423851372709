import {makeStyles} from "@material-ui/core/styles";

import GlobalStyle from "./../GlobalStyle";
import * as Constants from "./../Constants"
import CommonStyle from "./CommonStyle"
import Box from "@material-ui/core/Box/Box";
import React from "react";
import List from "@material-ui/core/List/List";

export default makeStyles((theme) => {
    let CommonStyles = CommonStyle(theme);
    return {
        ...CommonStyles,
        subcontentWrapper: {
            flex: 1,
            maxWidth: 810,
            marginLeft: 50,
            [theme.breakpoints.down('xs')]: {
                margin: 0
            }
        },

        buttonFull: {
            width: "100%",
        },

        buttonSave: {
            width: "100%",
        },

        buttonAddLink: {
            width: "100%",
            fontSize: "0.8rem",
            marginBottom: 10,
            marginTop: 10,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 10,
                marginTop: 10,
            }
        },

        headerWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 55,
            [theme.breakpoints.down('xs')]: {
                justifyContent: "center",
                marginBottom: 20,
            }
        },
        description2Wrapper: {
            marginBottom: 100,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 20,
            }
        },
        headerStep: {
            ...GlobalStyle.uppercaseText,
            color: Constants.LIGHT_GRAY,
            fontWeight: "bold",
            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
                display: "none"
            }
        },
        headerStepActive: {
            color: Constants.PINK,
            display: "block"
        },

        step1Wrapper: {
            flex: 1
        },
        step1Label: {

            marginTop: Constants.GET_SIZE(15),
        },
        step1SubLabel: {
            fontStyle: "italic",

            fontSize: Constants.FONT_SIZE_SMALL,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
            }

        },
        step1CheckboxRow: {
            ...GlobalStyle.row
        },
        checkboxLabel: {

            color: Constants.FONT_GRAY
        },
        checkBox: {
            backgroundColor: Constants.WHITE,
            borderColor: Constants.WHITE,
            width: Constants.GET_SIZE(300)
        },
        checkboxLinkWrapper: {
            ...GlobalStyle.rowCentered
        },

        step2Wrapper: {
            flex: 1
        },
        step2Title: {
            fontWeight: "bold",
            marginBottom: 50,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 20,
            }
        },
        step2BulletWrapper: {
            ...GlobalStyle.rowCentered,
            marginBottom: Constants.GET_SIZE(10),
        },
        step2Bullet: {
            marginLeft: Constants.GET_SIZE(30),
            marginRight: Constants.GET_SIZE(10),

            fontSize: Constants.FONT_SIZE_SMALL,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_SMALL_MOBILE,
            }
        },
        step2BulletTitle: {

            marginBottom: Constants.GET_SIZE(30),
        },
        step2SiretLabel: {},
        step2BulletItem: {},
        checkBoxLarge: {
            backgroundColor: Constants.WHITE,
            borderColor: Constants.WHITE,
            marginBottom: Constants.GET_SIZE(15),
            marginTop: Constants.GET_SIZE(15)
        },
        snInputWrapper: {
            ...GlobalStyle.rowCentered,
            marginBottom: 20
        },
        snInputRemoveLabel: {
            marginLeft: 20,
            color: Constants.FONT_GRAY,
            cursor: "pointer",

            fontSize: Constants.FONT_SIZE_MEDIUM,
            [theme.breakpoints.down('xs')]: {
                fontSize: Constants.FONT_SIZE_MEDIUM_MOBILE,
            }
        },
        snInput: {
            marginBottom: 0
        },
        imageWrapper: {
            width: 150,
            marginLeft: 50,
            marginBottom: 50,
            alignItems: "center",
            [theme.breakpoints.down('xs')]: {
                margin: "auto",
                marginBottom: 50,
            }
        },
        image: {
            width: Constants.GET_SIZE(150),
            height: Constants.GET_SIZE(150),
            marginBottom: Constants.GET_SIZE(20),
            borderRadius: "50%"
        },
        imageButtonLabel: {

            color: Constants.PINK,
            textDecorationLine: "underline",
            textAlign: "center",
            cursor: "pointer"
        },
        stripeBlock: {
            marginLeft: Constants.GET_SIZE(50),
            marginBottom: Constants.GET_SIZE(10),
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                width: "100%",
                textAlign: "center",
                marginBottom: Constants.GET_SIZE(40),
            }
        },
        documentButtonLabel: {
            color: Constants.PINK,
            textDecorationLine: "underline",
            cursor: "pointer"
        },

        step5ButtonWrapper: {
            width: "100%",
            alignItems: "center"
        },

        missingDocumentsTitle: {

            marginBottom: Constants.GET_SIZE(10)
        },







        buttonWhite: {
            ...GlobalStyle.button,
            marginBottom: Constants.GET_SIZE(50),
            marginTop: Constants.GET_SIZE(37),
            width: Constants.GET_SIZE(225),

            borderWidth: 1,
            backgroundColor: Constants.WHITE,
            borderColor: Constants.PINK,
        },
        buttonWhiteLabel: {
            ...GlobalStyle.buttonLabel,
            color: Constants.PINK,
        },


        cityAutocomplete: {
            position: "absolute",
            backgroundColor: "white",
            zIndex: 999,
            left: 0,
            right: 0,
            border: "solid 1px black",
            borderRadius: 10,
            marginTop: -20
        },
        cityAutocompleteItem: {
            cursor: "pointer",
            paddingLeft: 25,
            backgroundColor: "white"
        },
    }
});
